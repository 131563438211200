<div class="document-link-wrapper p-4">
  <div>
    <h4 class="c-modal-header"><app-i18n [key]="'getLink'"></app-i18n></h4>
  </div>

  <div class="row">
    <div class="col-lg-12 col-12">
      <div class="link-content-wrapper">
        <input #linkInput class="form-control link-parsed" type="text" readonly [value]="linkParsed" (click)="linkInput?.select()">

        <button class="btn btn-copy-link" [ngClass]="{'copied' : isCopied}" (click)="copyLink()">
          <span class="material-icons-outlined icon">
            content_copy
          </span>
          <span><app-i18n [key]="isCopied ? 'copied' : 'Copy Link'"></app-i18n></span>
        </button>
      </div>

      <div class="actions text-right pt-3" *ngIf="isShowCloseBtn">
        <button class="btn btn-primary" (click)="onCancel()"><app-i18n [key]="'Close'"></app-i18n></button>
      </div>
    </div>
  </div>
</div>
import { createSelector } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { BoardEpic, BoardLabel, BoardReleaseListResponse, BoardSprint, BoardStatus, Project, ProjectMemberUser, ProjectPlatform, ProjectPriority, TaskType, User } from 'src/app/shared';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';
import { selectLocationEvent } from '../../_store/event';
import { selectEpics, selectLabels, selectPlatforms, selectPriorities, selectProjectMembers, selectProjects, selectReleases, selectSprints, selectStatuses, selectStoryLines } from '../../_store/project';
import { selectTaskTypes } from '../../_store/task';
import { LocationEvent } from './work-calendar-event.model';
import { selectAllUsers } from '../../_store/user';

export enum WorkCalenderType {
  Event,
  Task,
  Reminder,
  Leave,
  Holiday
}

export enum WorkCalendarOptionIndex {
  Project,
  Member,
  Status,
  Priority,
  Release,
  Epic,
  Sprint,
  Label,
  Storyline,
  Platform,
  TaskType,
  Location,
  MemberEvent,
}

export type WorkCalendarOptions = [
  Project[],
  ProjectMemberUser[],
  BoardStatus[],
  ProjectPriority[],
  BoardReleaseListResponse[],
  BoardEpic[],
  BoardSprint[],
  BoardLabel[],
  BoardStoryline[],
  ProjectPlatform[],
  TaskType[],
  LocationEvent[],
  User[],
];

export const selectWorkCalendarOptions = createSelector(
  selectProjects,
  selectProjectMembers,
  selectStatuses,
  selectPriorities,
  selectReleases,
  selectEpics,
  selectSprints,
  selectLabels,
  selectStoryLines,
  selectPlatforms,
  selectTaskTypes,
  selectLocationEvent,
  selectAllUsers,
  (...state) => state
);

export enum NgxSelectTemplate {
  CommonTemplate = 'CommonTemplate',
  UserTemplate = 'UserTemplate',
  MultiUserTemplate = 'MultiUserTemplate',
  EpicTemplate = 'EpicTemplate',
  PlatformTemplate = 'PlatformTemplate',
  LabelTemplate = 'LabelTemplate',
  ReleasesTemplate = 'ReleasesTemplate',
  StorylineTemplate = 'StorylineTemplate',
  ProjectTemplate = 'ProjectTemplate'
}

export const setOption = (key: string , options: Observable<any> | any, config: FormlyFieldConfig[]) => {
  const _config = config.find(e => e.key === key);

  _config.props.options = options;
}

import { AppInjector } from 'src/app/app.module';
import { FileResponse, FileType, PageInfo, Pageable } from 'src/app/shared';
import { FileService } from '../_services/file.service';
import { DocumentFile } from './document-file';
import { DocumentFileBase, DocumentLoadMoreConfig } from './document-file-base';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export interface GetStoragePayload {
  keyword?: string;
  parentNull?: boolean;
}

export class DocumentStorageFile extends DocumentFile {
  baseType = 'DocumentStorageFile';
  pageable: Pageable = {
    page: 0,
    size: 100,
    sort: 'fileSize,desc'
  };
  constructor(
    file: FileResponse
  ) {
    super(file);
  }

  canForceDelete() {
    return this.file.editable;
  }

  canDownload() {
    return true;
  }

  canAccessFolderChild() {
    return false;
  }

  getChilds() {
    this.getStorageFiles({}, this.pageable);
  }

  getStorageFiles(payload: GetStoragePayload, pageable: Pageable) {
    this.getSubscription?.unsubscribe();
    this.getSubscription = this.fileService.getStorageFiles(payload, pageable).subscribe(
      (res) => {
        const items = res.content.map(e => {
          const item = new DocumentStorageFile(e);
          item.parent = this;
          return item;
        });

        if (pageable.page > 0) {
          this.items = this.items.concat(items);
        } else {
          this.items = items;
        }
        this.pageInfo = PageInfo.mapFromList2Res(res);
        this.delegate?.loadChildsDone(this, this.items);
        this.isLoading$.next(false);
      },
      (err) => {
        this.items = [];
        this.errorFn(err);
        this.delegate?.loadChildsDone(this, this.items);
        this.isLoading$.next(false);
      }
    );
  }

  search() {
    const payload: GetStoragePayload = {
      keyword: this.keyword
    };

    if (this.pageable.sort === 'path,asc') {
      payload.parentNull = true;
    }

    this.getStorageFiles(payload, this.pageable);
  }

  onSortChange(sort: string) {
    this.pageable.sort = sort;
    this.search();
  }

  onKeywordChange(keyword: string) {
    this.keyword = keyword;
    this.search();
  }

  onLoadMore(viewport: CdkVirtualScrollViewport) {
    const config: DocumentLoadMoreConfig = {
      pageable: this.pageable,
      pageInfo: this.pageInfo,
      callback: () => {
        this.search();
      }
    };
    super.onLoadMore(viewport, config);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';
import { Event, EventSearchRequest, EventTimePayload, EventUpsert, LocationEvent, LocationEventRequest } from '../work-calendar/models/work-calendar-event.model';
import { List2Res } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private http: HttpClient,
  ) {}

  createEvent(payload: EventUpsert) {
    return this.http.post<Event>(`${environment.apiUrl}/wc-event/`, payload);
  }

  getEvent(payload: EventSearchRequest) {
    const params =  queryString.stringify({ ...payload });
    return this.http.get<Event[]>(`${environment.apiUrl}/wc-event/?${params}`);
  }

  deleteEvent(id: number) {
    return this.http.delete<Event>(`${environment.apiUrl}/wc-event/${id}`);
  }

  updateEvent(id: number, payload: EventUpsert) {
    return this.http.put<Event[]>(`${environment.apiUrl}/wc-event/${id}`, payload);
  }

  updateTimeEvent(id: number, payload: EventTimePayload) {
    return this.http.put<Event>(`${environment.apiUrl}/wc-event/update-time/${id}`, payload);
  }

  getLocations(payload: LocationEventRequest) {
    const { pageable, keyword} = payload;
    const params = queryString.stringify({ ...pageable, keyword });
    return this.http.get<List2Res<LocationEvent>>(`${environment.apiUrl}/wc-location/?${params}`);
  }

  createLocation(payload: Partial<LocationEvent>) {
    return this.http.post<LocationEvent>(`${environment.apiUrl}/wc-location/?`, payload);
  }

  updateLocation(locationId: number, payload: Partial<LocationEvent>) {
    return this.http.put<LocationEvent>(`${environment.apiUrl}/wc-location/${locationId}`, payload);
  }

  deleteLocation(locationId: number) {
    return this.http.delete(`${environment.apiUrl}/wc-location/${locationId}`);
  }
}

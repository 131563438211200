<div class="file-preview-wrapper">
  <div class="file-body">
    <div class="file-header">
      <div class="file-info">
        <div>{{selectedFile?.name}}</div>
        <div>{{selectedFile?.type}}</div>
      </div>
      <div class="file-actions">
        <button class="btn btn-icon mr-3" *ngIf="selectedFile" (click)="onDownload(selectedFile)">
          <span class="material-icons-outlined">
            cloud_download
          </span>
        </button>

        <button class="btn btn-icon" (click)="onClose()">
          <span class="material-icons-outlined">
            close
          </span>
        </button>
      </div>
    </div>
    <button class="btn btn-secondary btn-icon btn-switch btn-switch-left"
      *ngIf="currentIndex > 0"
      (click)="onPrevFile()">
      <span class="material-icons">
        chevron_left
      </span>
    </button>
    <button class="btn btn-secondary btn-icon btn-switch btn-switch-right"
      *ngIf="currentIndex < fileDatas.length - 1"
      (click)="onNextFile()">
      <span class="material-icons">
        chevron_right
      </span>
    </button>

    <div class="spinner-border spinner-border-lg" role="status" *ngIf="!selectedFile?.isLoaded">
      <span class="sr-only"><app-i18n [key]="'loading'"></app-i18n>...</span>
    </div>
  
    <ng-container *ngFor="let file of fileDatas; let index = index">
      <div class="viewer-wrapper" [ngSwitch]="file.type" *ngIf="currentIndex === index">
        <div class="pdf-viewer" *ngSwitchCase="FilePreviewType.document">
          <pdf-viewer
            *ngIf="file.uint8Array"
            [src]="file.uint8Array"
            [zoom]="file.zoom"
            [original-size]="true"
            [render-text]="true"
            [original-size]="true">
          </pdf-viewer>

          <app-aws-signature [url]="file.previewUrl || file.url" [(objectURL)]="file.objectUrl" [template]="pdfViewerTemplate"></app-aws-signature>

          <ng-template #pdfViewerTemplate let-objectUrl>
            <pdf-viewer
              #pdfViewer
              *ngIf="!file.uint8Array"
              [src]="objectUrl"
              [zoom]="file.zoom"
              [original-size]="true"
              [render-text]="true"
              [original-size]="true"
              (after-load-complete)="onComplete(file, $event)"
              (error)="onError($event)">
            </pdf-viewer>
          </ng-template>
        </div>
  
        <div class="image-viewer" *ngSwitchCase="FilePreviewType.image">
          <app-aws-signature [url]="isFormatToPdf(file.url) ? (file.previewUrl || file.url) : file.url" [(objectURL)]="file.objectUrl" [template]="imageViewerTemplate"></app-aws-signature>
          <ng-template #imageViewerTemplate let-objectUrl>
            <span>{{file.width}}</span>
            <img
              #image
              [src]="objectUrl | safeUrl" alt=""
              [ngStyle]="file.width > 0 && {'width' : (file.width < IMAGE_MIN_WIDTH ? IMAGE_MIN_WIDTH : file.width) * file.zoom + 'px'}"
              (load)="loadImage($event, file)">
          </ng-template>
        </div>

        <div class="video-viewer px-3" *ngSwitchCase="FilePreviewType.video">
          <ng-container *ngIf="!file.previewUrl; else loadedVideo">
            <app-aws-signature-video [url]="file.url" [(previewUrl)]="file.previewUrl" (previewUrlChange)="file.isLoaded = true"></app-aws-signature-video>
          </ng-container>

          <ng-template #loadedVideo>
            <video [src]="file.previewUrl" controls autoplay></video>
          </ng-template>
        </div>

        <div class="unknown-viewer" *ngSwitchCase="FilePreviewType.unknown">
          <div>
            <span class="material-icons icon">
              report
            </span>
          </div>
          <div>
            <div><app-i18n [key]="'somethingWentWrong'"></app-i18n></div>
            <div><app-i18n [key]="'couldnotGeneratePreview'"></app-i18n></div>
            <div><app-i18n [key]="'tryDownloadToView'"></app-i18n></div>

            <button class="btn btn-primary mt-4" (click)="onDownload(selectedFile)"><app-i18n [key]="'download'"></app-i18n></button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  <div class="file-footer" *ngIf="selectedFile?.type !== 'unknown'">
    <button class="btn btn-icon mr-3" (click)="onZoomOut()" [disabled]="selectedFile?.zoom <= 0.25">
      <span class="material-icons-outlined">
        zoom_out
      </span>
    </button>
    
    <button class="btn btn-icon" (click)="onZoomIn()" [disabled]="selectedFile?.zoom >= 2">
      <span class="material-icons-outlined">
        zoom_in
        </span>
    </button>

    <span class="current-zoom">{{getZoomText(selectedFile?.zoom)}}</span>
  </div>
</div>
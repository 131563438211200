import { Component, Input } from '@angular/core';
import { ProjectMemberUser } from 'src/app/shared';

@Component({
  selector: 'app-single-user-label',
  template: `
    <div class="custom-option">
      <app-avatar [url]="item.avatar" [name]="item.fullName"></app-avatar>
      <span class="option-label">{{ item.fullName }}</span>
    </div>
  `
})
export class SingleUserLabelComponent {
  @Input() item: ProjectMemberUser;
}

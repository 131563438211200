import { Component, Input } from '@angular/core';

const DEFAULT_CIRCLE_SIZE = 20;
const DEFAULT_TITLE_SIZE = 10;

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent {
  @Input() progress: number = 0;
  @Input() color = 'var(--x-primary)';
  @Input() size = DEFAULT_CIRCLE_SIZE;
  @Input() borderColor = 'var(--x-logan)';
  @Input() showTitle = false;
  @Input() suffix = '';
  @Input() titleSize = DEFAULT_TITLE_SIZE;
  @Input() titleClass = '';
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() defaultValue: string;
  @Input() showClear: boolean = true;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;


  ngOnInit(): void {
    this.control.valueChanges.subscribe(res => {
      this.input.nativeElement.value = res;
    })
  }

  clearControlValue() {
    this.control.reset();
  }
}

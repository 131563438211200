import { Token } from 'antlr4ts';
import { TaskFieldChild } from 'src/app/shared/_models';
import { XQLLexer } from '../_antlr/XQLLexer';

export enum TokenGroupType {
  field = 'field',
  operator = 'operator',
  operand = 'operand',
  keyword = 'keyword',
  sortKeyword = 'sort-keyword',
  unknow = 'unknow',
  eof = 'eof',
}

export enum SymbolicName {
  IDENTIFIER = 'IDENTIFIER',
  IN_ = 'IN_',
  LIKE_ = 'LIKE_',
  BETWEEN_ = 'BETWEEN_',
  LT = 'LT',
  LT_EQ = 'LT_EQ',
  EQ = 'EQ',
  GT = 'GT',
  GT_EQ = 'GT_EQ',
  NOT_EQ1 = 'NOT_EQ1',
  NOT_EQ2 = 'NOT_EQ2',
  NOT_ = 'NOT_',
  IS_ = 'IS_',
  STRING_LITERAL = 'STRING_LITERAL',
  NUMERIC_LITERAL = 'NUMERIC_LITERAL',
  BLOB_LITERAL = 'BLOB_LITERAL',
  TRUE_ = 'TRUE_',
  FALSE_ = 'FALSE_',
  NULL_ = 'NULL_',
  AND_ = 'AND_',
  OR_ = 'OR_',
  ORDER_ = 'ORDER_',
  BY_ = 'BY_',
  ASC_ = 'ASC_',
  DESC_ = 'DESC_',
  EOF = 'EOF',
  COMMA = 'COMMA',
  OPEN_PAR = 'OPEN_PAR',
  CLOSE_PAR = 'CLOSE_PAR',
}

export const XqlSymbolic = {
  fields: ['IDENTIFIER'],
  operators: ['IN_', 'LIKE_', 'BETWEEN_', 'LT', 'LT_EQ', 'EQ', 'GT', 'GT_EQ', 'NOT_EQ1', 'NOT_EQ2', 'NOT_', 'IS_'],
  operands: ['STRING_LITERAL', 'NUMERIC_LITERAL', 'BLOB_LITERAL', 'TRUE_', 'FALSE_', 'NULL_'],
  keywords: ['AND_', 'OR_', 'ORDER_', 'BY_'],
  sortKeywords: ['ASC_', 'DESC_'],
  eof: ['EOF'],
};

export class TokenLeaf {
  type: number;
  groupType: TokenGroupType;
  text: string;
  symbolicName: SymbolicName;
  displayName: string;
  literalName: string;
  token: Token;

  constructor(data: any) {
    this.type = data.type;
    this.text = data.text;
    this.symbolicName = data.symbolicName;
    this.displayName = data.displayName;
    this.literalName = data.literalName;
    this.token = data.token;

    if (XqlSymbolic.fields.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.field;
    } else if (XqlSymbolic.operators.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.operator;
    } else if (XqlSymbolic.operands.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.operand;
    } else if (XqlSymbolic.keywords.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.keyword;
    } else if (XqlSymbolic.sortKeywords.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.sortKeyword;
    } else if (XqlSymbolic.eof.includes(this.symbolicName)) {
      this.groupType = TokenGroupType.eof;
    } else {
      this.groupType = TokenGroupType.unknow;
    }
  }
}

export interface XqlField {
  name: string;
  type: string;
  operators: string[];
  values?: XqlFieldValue[];
}

export interface XqlFieldValue {
  id: number;
  name: string;
  value: any;
}

export class XqlInputToken {
  text: string;
  type: TokenGroupType;
  value?: any;
  autocompleteData?: any[];
  nextInputToken?: XqlInputToken;

  constructor(object?: any) {
    this.text = object?.text || null;
    this.value = object?.value || null;
  }

  predicate(key: string): any[] {
    return this.autocompleteData.filter(e => e.startsWith(key));
  }

  updateValue(value: any) {
    this.value = value;
  }

  getOptionLabel(value: any) {
    return value;
  }

  get isErrorToken() {
    return true;
  }
}

export class XqlInputTokenField extends XqlInputToken {
  type: TokenGroupType = TokenGroupType.field;
  value: TaskFieldChild;
  autocompleteData: TaskFieldChild[] = [];

  updateValue(value: TaskFieldChild) {
    this.value = value;
    this.text = value.name;
  }

  getOptionLabel(value: TaskFieldChild) {
    return value.name;
  }
}

export class XqlInputTokenOperator extends XqlInputToken {
  type: TokenGroupType = TokenGroupType.operator;
  autocompleteData: string[] = [];
}

export class XqlInputTokenOperand extends XqlInputToken {
  type: TokenGroupType = TokenGroupType.operand;
  value: string;
}

export class XqlInputKeyword extends XqlInputToken {
  type: TokenGroupType = TokenGroupType.keyword;
  autocompleteData: string[] = ['AND', 'OR'];
  constructor(object?: any) {
    super(object);
  }
}

export class XqlOptionBase<T = any> {
  value: T;
  delegate: any;

  constructor(data: T) {
    this.value = data;
  }

  selectSelf() {
    this.delegate?.onSelectOption(this.value);
  }

  get text(): string {
    return '';
  }

  htmlLabel(keyword: string) {
    const first = this.text.slice(0, keyword.length);
    const last = this.text.slice(keyword.length, this.text.length);
    return `<b>${first}</b>${last}`;
  }
}

export class XqlOption extends XqlOptionBase<string> {
  get text() {
    return this.value ?? '';
  }
}

export class XqlOptionField extends XqlOptionBase<XqlField> {

  get text() {
    return this.value.name ?? '';
  }
}

export const SpecialFieldMethod = {
  user: {
    types: ['com.xcorp.domains.user.UsersEntity'],
    method: 'currentUser()'
  },
  date: {
    types: ['java.time.*'],
    method: 'now()'
  }
};
import { NgModule } from '@angular/core';
import { AutoWidthDirective } from './auto-width.directive';
import { CustomDropdownDirective } from './custom-dropdown.directive';
import { CustomTooltipDirective } from './custom-tooltip.directive';

const DIRECTIVES = [
  AutoWidthDirective,
  CustomDropdownDirective,
  CustomTooltipDirective,
]

@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    ...DIRECTIVES,
  ]
})
export class XDirectivesModule { }

import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { Component, ComponentRef, Input } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { XTrigger } from '../trigger/trigger.service';

export abstract class BaseOverlay<T, K> {
  protected _overlayRef: OverlayRef;
  protected _componentRef: ComponentRef<T>;
  protected _element: HTMLElement;

  public isOpen: boolean;
  public preventShow: boolean;
  public preventHide: boolean;

  constructor(protected overlay: Overlay) {}

  abstract component(): ComponentPortal<T> | null;
  abstract position(): ConnectionPositionPair[];
  abstract open(
    element: HTMLElement,
    data: K,
    position: ConnectionPositionPair[]
  ): void;
  abstract hide(): void;
  abstract config(element: HTMLElement): OverlayConfig;
}

@Component({
  template: ``
})
export abstract class BaseOverlayComponent<T, K, A extends XTrigger<any> = any> {
  @Input() data: T;
  @Input() overlay: K;
  @Input() trigger?: A;
}

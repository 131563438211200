import { Component, EventEmitter, OnInit, Output, OnDestroy, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShortCutUpsert } from 'src/app/shared';
import { FormUpsert, UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';
import { ShortcutIcon } from 'src/app/shared/_models/app/icon.model';

import { TypeShortcutIcons } from '../../../shared/_utils';
@Component({
  selector: 'app-document-shortcut-upsert',
  templateUrl: './document-shortcut-upsert.component.html',
  styleUrls: ['./document-shortcut-upsert.component.scss']
})
export class DocumentShortcutUpsertComponent extends UpsertComponent implements FormUpsert {
  @Output() formSubmit = new EventEmitter<ShortCutUpsert>();
  form: UntypedFormGroup;
  shortCutIcon: ShortcutIcon[] = TypeShortcutIcons;
  selectedIcon: ShortcutIcon = this.shortCutIcon?.[0];
  constructor(
    protected fb: UntypedFormBuilder
  ) {
    super(fb);
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', [
        Validators.required,
      ]],
      url: ['', [
        Validators.required,
      ]],
    })
  }

  selectIcon(icon: ShortcutIcon) {
    this.selectedIcon = icon;
  }

  submit() {
    const formValue = this.form.getRawValue();
    this.formSubmit.next({ ...formValue, ...this.selectedIcon});
  }

  onCancel(): void {
    this.formSubmit.next(null);
  }

}

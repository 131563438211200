<input
  #input
  [class]="'text-' + align + (transparent ? ' input-transparent ' : '')"
  [ngClass]="className"
  [formControl]="control"
  [placeholder]="placeholder"
  (focusout)="onFocusout()"
  (keyup.enter)="onKeyupEnter(input)"
  (input)="onInput()"
  (keydown)="onkeydown($event)"
  (focus)="onFocusInput()"
  [mask]="type === 'text' ? '' : 'separator.9999'"
  [thousandSeparator]="separator"
  [suffix]="suffix"
  [allowNegativeNumbers]="allowNegativeNumbers"
>

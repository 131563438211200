import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DropdownService } from './dropdown.service';
import { IconType } from '../../../app/shared/_models';
import { Placement } from 'src/@xcorp/utils';

@Component({
  selector: 'x-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [DropdownService],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent {
  @Input() icon = 'more_horiz';
  @Input() iconType = IconType.MAT;
  @Input() showArrow = false;
  @Input() setBtnWidth = true;
  @Input() setBtnHeight = true;
  @Input() border = false;
  @Input() placement: Placement;
  @Input() buttonRef: TemplateRef<any>;
  @Input() clickHide = true;
  @Input() disabled: boolean;
  @Input() noBackground = false;
  @Input() tooltip = '';
  @Input() menuClasses = '';

  @ViewChild('dropdown') dropdown: TemplateRef<any>;
  @ViewChild('button') button: ElementRef<HTMLElement>;

  type = IconType;

  constructor(private _dropdown: DropdownService) {
    this._dropdown.placement = this.placement;
  }

  onClick() {
    if (this.disabled) {
      return;
    }

    if (this._dropdown.isOpen) {
      this._dropdown.hide();
      return;
    }
    this._dropdown.open(this.button.nativeElement, this.dropdown);
  }

  onHide() {
    if (!this.clickHide) {
      return;
    }
    this._dropdown.hide();
  }
}

<div class="sync-jira-data-toast-wrapper">
  <div class="header d-flex-center">
    <div class="sync-data-icon">
      <mat-icon class="icon-md" [svgIcon]="'sync-data'"></mat-icon>
    </div>
    <span class="text-18px fw-6 ml-2">
      <app-i18n [key]="'Sync Data From Jira'"></app-i18n>
    </span>
  </div>

  <div class="body mt-3">
    <app-progress-group [items]="progressItems" [height]="5"></app-progress-group>
  </div>

  <div class="footer d-flex-center jc_sb mt-1">
    <a class="text-primary" (click)="onViewDetail()">
    <app-i18n [key]="'View Detail'"></app-i18n>
    </a>

    <span class="text-secondary">
      <ng-container *ngIf="syncStatus?.taskLogs && !syncStatus.taskLogs.finishFlg">
        {{syncStatus?.taskLogs?.current}}/{{syncStatus?.taskLogs?.total}} task{{syncStatus?.taskLogs?.total > 1 ? 's' : ''}}
      </ng-container>

      <ng-container *ngIf="syncStatus?.linkTaskLogs && !syncStatus.linkTaskLogs.finishFlg && syncStatus?.taskLogs?.finishFlg">
        {{syncStatus?.linkTaskLogs?.current}}/{{syncStatus?.linkTaskLogs?.total}} task{{syncStatus?.linkTaskLogs?.total > 1 ? 's' : ''}} linked
      </ng-container>

      <ng-container *ngIf="
          (!syncStatus?.taskLogs && !syncStatus?.linkTaskLogs && syncStatus?.fileLogs) || 
          (syncStatus?.linkTaskLogs?.finishFlg && syncStatus?.taskLogs?.finishFlg)
        "
      >
        {{syncStatus?.fileLogs?.current}}/{{syncStatus?.fileLogs?.total}} file{{syncStatus?.fileLogs?.total > 1 ? 's' : ''}}
      </ng-container>
    </span>
  </div>
</div>

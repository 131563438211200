import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { environment } from 'src/environments/environment';
import { I18nService } from 'src/app/site-management/_services';
import { MultiCompanyService } from 'src/app/site-management/company/_services/multi-company.service';
import { AccountsService } from 'src/app/authentication/_services/accounts.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private i18nService: I18nService,
    private multiCompanyService: MultiCompanyService,
    private accountsService: AccountsService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }

    let token: string = this.authService.getAccessToken();

    if (request.url.includes(`/account-companies`)) {
      token = this.multiCompanyService.getBaseToken();
    }

    if (!token && request.url === `${environment.apiUrl}/files/get-authorization`) {
      token = this.accountsService.getMainAccount()?.authResponse?.accessToken
    }

    if (!token) {
      return next.handle(request);
    }

    const langCode = this.i18nService.getCurrentLang();
    const headers = {
      'Accept-Language': langCode,
      'Authorization': `Bearer ${token}`,
      'company-secret': this.authService.getLoggedUser()?.company?.companySecret || '',
    };

    if (request.responseType === 'blob') {
      headers['ngsw-bypass'] = 'true';
    }

    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }
}

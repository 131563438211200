import { Pageable, Project, UserShort } from "src/app/shared";

export interface Workspace {
  id: number;
  name: string;
  uuid: string;
}

export interface StatusChannel {
  name: string;
  value: boolean;
}

export interface Channel {
  id: number;
  name: string;
  uuid: string;
  workspace: Workspace;
}

export interface ChannelSearchRequest {
  keyword?: string;
  pageable?: Pageable;
}

export interface WorkspaceSearchRequest {
  keyword?: string;
  pageable?: Pageable;
}

export interface SlackChannelProject {
  id: number;
  connected: boolean;
  connectedDate: string;
  connector: UserShort;
  slackChannel: Channel;
  project?: Project;
}

export interface SlackChannelProjectFilter {
  keyword?: string;
  pageable?: Pageable;
  status?: boolean;
  connectorId?: number;
  workspaceId?: number;

  // For Org > Integration > Slack
  workspaceIds?: number[];
  projectIds?: number[];
  connectors?: number[];
  connected?: boolean;
}

export interface ChannelParam {
  projectId: number;
  workspaceId: number;
  channelId: number;
  force?: boolean;
}

import FroalaEditor from 'froala-editor';
import { head } from 'lodash';
import { AppInjector } from 'src/app/app.module';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { FroalaEvent, FroalaUpload } from '../models';

FroalaEditor.PLUGINS.autoUploadVideo = function (editor: FroalaEditor) {
  const fileService: FileService = AppInjector.get(FileService);

  function _init() {
    editor.events.on('video.uploaded', function (response) {
      if (!editor.opts.autoUpload?.lazy) {
        const files = JSON.parse(response);
        const file = FroalaUpload.getPreviewUrl(
          head(files),
          editor.integrator.module()
        );

        // Trigger attachmentPushed event
        editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [{ files }], true);

        if (file) {
          fileService.getAuthorization(file.url).subscribe({
            next: ([url]) => {
              editor.video.insert(`
              <video src="${url.fullUrl}" origin="${file.url}" style="width: 600px;" controls class="fr-draggable"></video>
              `);
              editor.events.trigger(FroalaEvent.CONTENT_CHANGED, [], true);
            }
          });
        }
        return false;
      }
    });
  }

  return {
    _init
  };
};

export interface Team {
  id?: number;
  name: string;
  description?: string;
  leaderId: number;
  leaderName?: string;
  status: string;
  deleted?: boolean;
  totalMember?: number;
  defaultFlg?: boolean;
  groupId?: number;
  groupName?: string;
}

export interface TeamRequest {
  name?: string;
  excludeProjectId?: number;
  excludeWorkspaceId?: number;
  excludeHiringBoardId?: number;
  payrollId?: number;
  statuses?: string[];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { List2Res, Pageable } from 'src/app/shared';
import { WikiItem, WikiMentionMember, WikiMemberListRequest, WikiMentionRequest, WikiRequest, WikiDeleteRequest } from '../_store/wiki';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  constructor(private http: HttpClient) { }

  getProjectWikis(pageable: Pageable, parentId: number) {
    const params = queryString.stringify({ ...pageable, parentId });
    return this.http.get<List2Res<WikiItem>>(`${environment.apiUrl}/wikis/projects/view?${params}`);
  }

  getCompanyDocumentWikis(pageable: Pageable, parentId: number) {
    const params = queryString.stringify({ ...pageable, parentId });
    return this.http.get<List2Res<WikiItem>>(`${environment.apiUrl}/wikis/company-document/view?${params}`);
  }

  getTopicItems(id: number) {
    return this.http.get<List2Res<WikiItem>>(`${environment.apiUrl}/wikis/${id}`);
  }

  activateWiki(id: number, payload: WikiRequest) {
    return this.http.post<WikiItem>(`${environment.apiUrl}/wikis/${id}/topics`, payload);
  }

  /** Topic CRUD */
  createTopic(id: number, payload: WikiRequest) {
    return this.http.post<WikiItem>(`${environment.apiUrl}/wikis/${id}/sub-topics`, payload);
  }

  getTopic(id: number) {
    return this.http.get<WikiItem>(`${environment.apiUrl}/wikis/topics/${id}`);
  }

  editTopic(id: number, payload: WikiRequest) {
    return this.http.put<WikiItem>(`${environment.apiUrl}/wikis/topics/${id}`, payload);
  }

  deleteTopic(id: number, payload: WikiDeleteRequest) {
    return this.http.request('delete', `${environment.apiUrl}/wikis/topics/${id}`, { body: payload });
  }


  /** Page CRUD */
  createPage(id: number, payload: WikiRequest) {
    return this.http.post<WikiItem>(`${environment.apiUrl}/wikis/${id}/pages`, payload);
  }

  getPage(id: number) {
    return this.http.get<WikiItem>(`${environment.apiUrl}/wikis/pages/${id}`);
  }

  editPage(id: number, payload: WikiRequest) {
    return this.http.put<WikiItem>(`${environment.apiUrl}/wikis/pages/${id}`, payload);
  }

  deletePage(id: number) {
    return this.http.delete(`${environment.apiUrl}/wikis/pages/${id}`);
  }

  getMemberList(request: WikiMemberListRequest, pageable: Pageable) {
    const params = queryString.stringify({ ...request, ...pageable });
    return this.http.get<List2Res<WikiMentionMember>>(`${environment.apiUrl}/wikis/list-member-users?${params}`);
  }

  mentionMember(id: number, payload: WikiMentionRequest) {
    return this.http.post<any>(`${environment.apiUrl}/wikis/${id}/mention`, payload);
  }
}

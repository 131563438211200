import { ViewContainerRef } from '@angular/core';
import FroalaEditor from 'froala-editor';
import { AppInjector } from 'src/app/app.module';
import { ToastService } from 'src/app/core';
import { FroalaEvent } from '../models';
import { EditorModule } from 'src/app/shared/_components/x-editor/_models';
import { Subject } from 'rxjs';

FroalaEditor.PLUGINS.integrator = function (editor: FroalaEditor) {
  let _view: ViewContainerRef;
  let _module: EditorModule;

  const toast = AppInjector.get(ToastService);
  let destroyed$: Subject<any>;

  function setView(view) {
    _view = view;
  }

  function view() {
    return _view;
  }

  function setModule(module: EditorModule) {
    _module = module;
  }

  function module(): EditorModule {
    return _module;
  }

  function destroy(): Subject<any> {
    return destroyed$;
  }

  function isFocused() {
    return editor.$el.is(':focus');
  }

  function showToast(error, response) {
    const resObject = JSON.parse(response);
    toast.error(resObject?.message);
  }

  function addMultipleAttr() {
    editor.$tb.find('input[type="file"]').attr('multiple', true)
  }

  function _init() {
    destroyed$ = new Subject<void>();

    editor.events.trigger(FroalaEvent.GET_INSTANCE, [editor]);

    editor.events.on(FroalaEvent.VIDEO_ERROR, showToast);
    editor.events.on(FroalaEvent.IMAGE_ERROR, showToast);
    editor.events.on(FroalaEvent.FILE_ERROR, showToast);

    editor.events.on(FroalaEvent.POPUPS_IMAGE_INSERT, addMultipleAttr);
    editor.events.on(FroalaEvent.POPUPS_FILE_INSERT, addMultipleAttr);

    editor.events.on(FroalaEvent.DESTROY, function () {
      destroyed$?.next(null);
      destroyed$?.complete();
    });
  }

  return {
    _init,
    destroy,
    view,
    setView,
    setModule,
    module,
    isFocused,
  };
};

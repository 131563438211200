import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-get-link',
  templateUrl: './get-link.component.html',
  styleUrls: ['./get-link.component.scss']
})
export class GetLinkComponent implements OnInit {
  @Input() element: HTMLElement;

  @Input() url: string;
  @Input() tooltipText: string = 'Get link';

  @Output() getLink = new EventEmitter();

  isShow = false;

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit(): void {
    this.element?.addEventListener('mouseover', () => {
      this.isShow = true;
    });
    this.element?.addEventListener('mouseleave', () => {
      this.isShow = false;
    })
  }

  onCopyLinkToClipboard() {
    if (this.url) {
      this.clipboardService.copy(this.url);
    }

    this.getLink.emit();
  }
}

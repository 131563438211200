import { PageInfo } from "src/app/shared";
import { Event, LocationEvent } from "../../work-calendar/models/work-calendar-event.model";

export interface EventState {
  events: Event[],
  eventCreated: Event,
  eventDeleted: Event,
  eventsUpdated: Event[],
  locations: LocationEvent[],
  locationPageInfo: PageInfo
}

<div class="formly-ng-select-wrapper">
  <ng-select
    class="form-control"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [ngClass]="{ 'is-invalid': showError }"
    [items]="props?.options | optionWithObservable | async"
    [bindLabel]="props.bindLabel"
    [bindValue]="props.bindValue"
    [clearable]="props?.clearable"
    [searchable]="props?.searchable"
    [placeholder]="field.props?.placeholder"
    [multiple]="props?.multiple"
    >
    <!-- option -->
    <ng-template ng-option-tmp let-item="item">
      <ng-container [ngSwitch]="optionType">
        <app-common-option *ngSwitchCase="optionNgxSelectType.CommonTemplate " [item]="item"></app-common-option>
        <app-epic-option *ngSwitchCase="optionNgxSelectType.EpicTemplate" [item]="item"></app-epic-option>
        <app-platform-option *ngSwitchCase="optionNgxSelectType.PlatformTemplate" [item]="item"></app-platform-option>
        <app-user-option *ngSwitchCase="optionType | getOptionUser" [item]="item"></app-user-option>
        <app-label-option *ngSwitchCase="optionNgxSelectType.LabelTemplate" [item]="item"></app-label-option>
        <app-releases-option *ngSwitchCase="optionNgxSelectType.ReleasesTemplate" [item]="item"></app-releases-option>
        <app-storyline-option *ngSwitchCase="optionNgxSelectType.StorylineTemplate" [item]="item"></app-storyline-option>
        <app-project-option  *ngSwitchCase="optionNgxSelectType.ProjectTemplate" [item]="item"></app-project-option>
      </ng-container>
    </ng-template>

    <!-- single label -->
    <ng-template ng-label-tmp let-item="item" *ngIf="!(optionType | isMultiLabel)">
      <ng-container [ngSwitch]="optionType">
        <app-single-user-label *ngSwitchCase="optionNgxSelectType.UserTemplate" [item]="item"></app-single-user-label>
        <app-epic-label *ngSwitchCase="optionNgxSelectType.EpicTemplate" [item]="item"></app-epic-label>
        <app-common-label *ngSwitchCase="optionNgxSelectType.CommonTemplate " [item]="item"></app-common-label>
        <app-platform-label *ngSwitchCase="optionNgxSelectType.PlatformTemplate" [item]="item"></app-platform-label>
        <app-storyline-label *ngSwitchCase="optionNgxSelectType.StorylineTemplate" [item]="item"></app-storyline-label>
        <app-project-label  *ngSwitchCase="optionNgxSelectType.ProjectTemplate" [item]="item"></app-project-label>
      </ng-container>
    </ng-template>

    <!-- multi label -->
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" *ngIf="optionType | isMultiLabel">
      <ng-container [ngSwitch]="optionType">
        <app-multi-user-label *ngSwitchCase="optionNgxSelectType.MultiUserTemplate" [items]="items" [clear]="clear" [disabled]="props.disabled"></app-multi-user-label>
        <app-multi-label-label *ngSwitchCase="optionNgxSelectType.LabelTemplate" [items]="items" [clear]="clear"></app-multi-label-label>
        <app-releases-label *ngSwitchCase="optionNgxSelectType.ReleasesTemplate" [items]="items" [clear]="clear"></app-releases-label>
      </ng-container>
    </ng-template>
  </ng-select>
</div>

export const MoreMiscDefault = [
  'undo',
  'redo',
  // 'fullscreen',
  // 'getPDF',

]

export const MarkdownMoreMisc = [
  ...MoreMiscDefault,
]

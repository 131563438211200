import FroalaEditor from 'froala-editor';
import { head } from 'lodash';
import { FroalaEvent } from '../models';
import { EDITOR_PREVIEW_FILE } from 'src/app/shared';

FroalaEditor.PLUGINS.lazyUploadVideo = function (editor: FroalaEditor) {
  const _files: { file: File; blobUrl: string }[] = [];

  function files() {
    return _files;
  }

  function _init() {
    editor.events.on('video.beforeUpload', function (files: FileList) {
      if (editor.opts.autoUpload?.lazy) {
        const file = head(files);

        fileToBlob(file, ({ blobUrl }) => {
          editor.video.insert(`
              <video origin="${blobUrl}" style="width: 600px;" controls class="fr-draggable"></video>
              `);

          $(`video[origin="${blobUrl}"]`).attr('src', blobUrl);

          // Add file to current plugin
          _files.push({ file, blobUrl });

          // Trigger attachmentPushed event
          editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [
            { file: file, previewUrl: EDITOR_PREVIEW_FILE, blobUrl }
          ]);
        });

        return false;
      }
    });
  }

  function fileToBlob(file, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      const blobUrl = URL.createObjectURL(blob);
      callback({ blob, blobUrl });
    };

    reader.readAsArrayBuffer(file);
  }

  return {
    _init,
    files
  };
};

import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService, UtilService } from 'src/app/core';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { IMAGE_MIN_WIDTH, fileFormatToPdfs } from '../../_utils';

export enum FilePreviewType {
  image = 'image',
  document = 'document',
  video = 'video',
  unknown = 'unknown',
}
export class FilePreview {
  id?: number;
  name: string;
  url: string;
  objectUrl: string;
  previewUrl?: string;
  type?: FilePreviewType;
  dowload$?: () => Observable<HttpEvent<Blob>>;
  isLoaded?: boolean;
}

export class FilePreviewData extends FilePreview {
  type: FilePreviewType;
  zoom: number;
  width: number;
  // isOpen: boolean;
  isLoaded: boolean;
  uint8Array: Uint8Array;
  isExporting: boolean;
}

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit, OnDestroy {
  @Input() files: FilePreview[] = [];
  @Input() currentIndex = 0;
  @Output() closePreview = new EventEmitter<any>();

  fileDatas: FilePreviewData[] = [];
  destroyed$ = new Subject<void>();
  FilePreviewType = FilePreviewType;
  IMAGE_MIN_WIDTH = IMAGE_MIN_WIDTH;

  constructor(
    private bsModalRef: BsModalRef,
    private taskService: TaskService,
    private fileService: FileService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.updateFileDatas();
  }

  updateFileDatas() {
    this.fileDatas = this.files.map((e, index) => {
      const data = this.buildFileData(e);
      if (e.id && (!data?.url || fileFormatToPdfs.some(e => e === UtilService.getUrlExtension(data.url)))) {
        this.fileService.getFile(e.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (res) => {
              const newData = this.buildFileData({...e, url: res.previewUrl || res.webViewLink});
              this.fileDatas[index] = newData;
            },
            (error: string) => { }
          );
      }
      return data;
    });
  }

  buildFileData(e: FilePreview) {
    let name = e.name || this.getNameFromUrl(e.url);
    let type = this.getFileType(e);
    const isLoaded = this.getIsLoaded(e, type);
    let objectUrl = e.objectUrl || '';

    if (e.url?.includes(';base64,')) {
      type = FilePreviewType.image;
      objectUrl = e.url;
      name = 'Unnamed';
    }
    return {
      id: e.id,
      name,
      zoom: 1,
      width: 0,
      type,
      url: e.url,
      objectUrl,
      previewUrl: e.previewUrl || '',
      isLoaded,
      uint8Array: null,
      isExporting: false,
      dowload$: e.dowload$,
    };
  }

  getFileType(e: FilePreview): FilePreviewType {
    if (e.type) {
      return e.type;
    }

    if (UtilService.isImageURL(e.url)) {
      return FilePreviewType.image;
    }

    if (UtilService.isVideoURL(e.url)) {
      return FilePreviewType.video;
    }

    if (this.isFormatToPdf(e.previewUrl || e.url)) {
      return FilePreviewType.document;
    }

    return FilePreviewType.unknown;
  }

  getIsLoaded(e: FilePreview, type: FilePreviewType) {
    if (type === FilePreviewType.document || type === FilePreviewType.video || !e.url) {
      return false;
    }
    if (type === FilePreviewType.unknown) {
      return true;
    }

    return e.hasOwnProperty('isLoaded') ? e.isLoaded : true;
  }

  isFormatToPdf(url: string) {
    return fileFormatToPdfs.some(e => e === UtilService.getUrlExtension(url));
  }

  updateFileObjectUrl(index: number, file: FilePreview) {
    this.fileDatas[index].isLoaded = file.isLoaded;
    this.fileDatas[index].objectUrl = file.objectUrl;
  }

  loadImage(event: Event, file: FilePreviewData) {
    if (file.width <= 0) {
      const windowWidth = window.innerWidth;
      const loadedImage: any = event.currentTarget;
      const imgWidth = loadedImage.width;
      const imgHeight = loadedImage.height;

      file.width = imgWidth;
      if (imgWidth > windowWidth) {
        const zoom = this.parseZoom((windowWidth / imgWidth)) - 0.05; // Reduce 5% = 0.05
        file.zoom = zoom;
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  get selectedFile(): FilePreviewData {
    return this.fileDatas[this.currentIndex];
  }

  parseZoom(zoom: number) {
    return parseFloat(zoom.toFixed(2));
  }

  onPrevFile() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  onNextFile() {
    if (this.currentIndex < this.fileDatas.length - 1) {
      this.currentIndex++;
    }
  }

  onZoomOut() {
    if (!this.selectedFile) {
      return;
    }

    if (this.selectedFile.zoom > 0.25) {
      const zoom = this.parseZoom(this.selectedFile.zoom - 0.25);
      this.selectedFile.zoom = zoom;
    }
  }

  onZoomIn() {
    if (!this.selectedFile) {
      return;
    }

    if (this.selectedFile.zoom < 2) {
      const zoom = this.parseZoom(this.selectedFile.zoom + 0.25);
      this.selectedFile.zoom = zoom;
    }
  }

  getZoomText(zoom: number) {
    return `${(zoom * 100).toFixed()} %`;
  }

  onDownload(file: FilePreviewData) {
    if (file.isExporting) {
      return;
    }

    file.isExporting = true;
    const observable = file.dowload$ ? file.dowload$() : this.taskService.downloadAttachment(file.url);
    observable
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (event) => {
          if (event instanceof HttpResponse && event.body) {
            UtilService.saveServerFile(event);
          }
        },
        (error: string) => {
          file.isExporting = false;
          const httpError = JSON.parse(error);
            this.toast.error(httpError?.message);
        }
      );
  }

  onClose() {
    this.closePreview.emit();
    this.files = [];
    this.bsModalRef.hide();
  }

  getNameFromUrl(url: string = '') {
    const strings = url.split('/');
    return strings[strings.length - 1];
  }

  onComplete(file: FilePreviewData, pdf: PDFDocumentProxy) {
    file.isLoaded = true;
    pdf.getData().then(
      (data) => {
        file.uint8Array = data as any;
      },
      (error) => { }
    );
  }

  onError(error: any) {
    // do anything
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      if (this.currentIndex < this.fileDatas.length - 1) {
        this.onNextFile();
      }
    } else if (event.key === 'ArrowLeft') {
      if (this.currentIndex > 0) {
        this.onPrevFile();
      }
    }
  }
}

<div @fadeFast
     class="loading-wrapper"
     *ngIf="visible || forever">
  <img class="loading-gif"
       src="/assets/img/loading/logo-fast.gif" />

  <div *ngIf="showMessage"
       class="loading-message"
       [ngClass]="level">
    {{ message }}
  </div>

  <div class="left">
    <img class="loading-svg"
         src="/assets/img/loading/loading-left.svg" />
  </div>

  <div class="right">
    <img class="loading-svg"
         src="/assets/img/loading/loading-right.svg" />
  </div>
</div>
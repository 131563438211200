import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgxSelectTemplate } from 'src/app/site-management/work-calendar/models/work-calendar-overlay.formly';

@Component({
  selector: 'formly-ngx-select',
  templateUrl: './ngx-select.component.html',
  styleUrls: ['./ngx-select.component.scss']
})
export class NgxSelectType extends FieldType<FieldTypeConfig> implements OnInit{
  optionType: NgxSelectTemplate;
  optionNgxSelectType = NgxSelectTemplate;

  ngOnInit (): void
  {
    this.optionType = this.field.props?.template;
  }
}

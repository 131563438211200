import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskActions } from './task.actions';
import {
  catchError,
  exhaustMap,
  map,
  of,
  tap,
  withLatestFrom,
  take
} from 'rxjs';
import { ProjectsService } from '../../projects/_services';
import { Store } from '@ngrx/store';
import { selectTaskTypes } from './task.selectors';
import { isEmpty } from 'lodash';
import { TaskService } from '../../tasks/_services/task.service';
import { ToastService } from 'src/app/core';
import { TaskUtilsService } from '../../tasks/_services/task-utils.service';
import { LoadAllPageable } from 'src/app/shared';

@Injectable()
export class TaskEffects {
  loadAllTaskTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.getAllTaskTypes),
      withLatestFrom(this.store.select(selectTaskTypes).pipe(take(1))),
      exhaustMap(([props, taskTypes]) => {
        if (!props.force && !isEmpty(taskTypes) && !props?.keyword) {
          return of(TaskActions.setTaskTypes({ taskTypes }));
        }

        return this.projectService
          .getWorkItemTypes(props.projectId, props.keyword)
          .pipe(
            map((taskTypes) => TaskActions.setTaskTypes({ taskTypes })),
            catchError(() => of(TaskActions.setTaskTypes({ taskTypes: [] })))
          );
      })
    );
  });

  createTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.createTask),
      exhaustMap((props) =>
        this.taskService.add(props.payload as any).pipe(
          map((task) => TaskActions.createTaskSuccess({ task })),
          catchError((error) => of(TaskActions.createTaskFail({ error })))
        )
      )
    );
  });

  updateTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.updateTask),
      exhaustMap((props) =>
        this.taskService.patch(props.taskId, props.payload).pipe(
          map((task) => TaskActions.updateTaskSuccess({ task })),
          catchError((error) => of(TaskActions.updateTaskFail({ error })))
        )
      )
    );
  });

  updateStartEndTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.updateStartEndTask),
      exhaustMap((props) =>
        // Update start time
        this.taskService.updateTime(props.taskId, props.payload).pipe(
          map((task) => TaskActions.updateTaskSuccess({ task })),
          catchError((error) => of(TaskActions.updateTaskFail({ error })))
        )
      )
    );
  });

  createTaskSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.createTaskSuccess),
        tap(({ task }) => {
          this.taskUtilsService.onShowToast(task);
        })
      );
    },
    { dispatch: false }
  );

  updateTaskSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.updateTaskSuccess),
        tap(() => {
          this.toast.success('Update successfully');
        })
      );
    },
    { dispatch: false }
  );

  createTaskFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.createTaskFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  updateTaskFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.updateTaskFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  getWorkCalendarTasks = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.getWorkCalendarTasks),
      exhaustMap(({ payload }) => {
        return this.taskService
          .getWorkCalendar(payload, new LoadAllPageable())
          .pipe(
            map((res) =>
              TaskActions.setWorkCalendarTasks({
                workCalendarTasks: res.content
              })
            ),
            catchError((err) =>
              of(TaskActions.setWorkCalendarTasks({ workCalendarTasks: [] }))
            )
          );
      })
    );
  });

  deleteWorkCalendarTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskActions.deleteWorkCalendarTask),
      exhaustMap(({ taskId }) => {
        return this.taskService.deleteWorkCalendar(taskId).pipe(
          map((res) =>
            TaskActions.deleteWorkCalendarTaskSuccess({
              workCalendarTask: res
            })
          ),
          catchError((error) =>
            of(TaskActions.deleteWorkCalendarTaskFail({ error }))
          )
        );
      })
    );
  });

  deleteWorkCalendarTaskSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.deleteWorkCalendarTaskSuccess),
        tap(() => {
          this.toast.success('Deleted successfully');
        })
      );
    },
    { dispatch: false }
  );

  deleteWorkCalendarTaskFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TaskActions.deleteWorkCalendarTaskFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private taskUtilsService: TaskUtilsService,
    private actions$: Actions,
    private projectService: ProjectsService,
    private taskService: TaskService,
    private store: Store,
    private toast: ToastService
  ) {}
}

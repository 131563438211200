import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first, isArray, isEmpty } from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CreatedAtSortType, DATE_UI_FORMAT, Pageable, PageSize, PageableModel, StatusSearchParams, Task, TaskDetailFormType } from 'src/app/shared';
import { UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';
import { ProjectActions, selectEpics, selectLabels, selectPlatforms, selectPriorities, selectProjectMembers, selectReleases, selectSprints, selectStatuses, selectStoryLines } from 'src/app/site-management/_store/project';
import { TaskDetailFields, followUpItems } from '../backlog-context-menu';
import { ProjectsService } from '../../../_services';
import { nonEmpty } from 'src/app/libs/rxjs/custom-operator.rxjs';
import { TaskActions, selectTaskTypes } from 'src/app/site-management/_store/task';
import { UtilService } from 'src/app/core';

export enum FormCtrlName {
  type = 'type',
  value = 'value',
  sendMail = 'sendMail',
}

export interface BulkChangeTask {
  type: string;
  value: string;
  sendMail: boolean;
}

@Component({
  selector: 'app-backlog-bulk-change-tasks',
  templateUrl: './backlog-bulk-change-tasks.component.html',
  styleUrls: ['./backlog-bulk-change-tasks.component.scss']
})
export class BacklogBulkChangeComponent extends UpsertComponent<BulkChangeTask> {
  @ViewChild('assigneeTemplate') assigneeTemplate: TemplateRef<any>;
  @ViewChild('priorityTemplate') priorityTemplate: TemplateRef<any>;
  @ViewChild('epicTemplate') epicTemplate: TemplateRef<any>;
  @ViewChild('releasesTemplate') releasesTemplate: TemplateRef<any>;
  @ViewChild('sprintTemplate') sprintTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ViewChild('ccTemplate') ccTemplate: TemplateRef<any>;
  @ViewChild('labelTemplate') labelTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  @ViewChild('platformTemplate') platformTemplate: TemplateRef<any>;
  @ViewChild('storylineTemplate') storylineTemplate: TemplateRef<any>;
  @ViewChild('followUpTemplate') followUpTemplate: TemplateRef<any>;
  @ViewChild('reporterTemplate') reporterTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate') typeTemplate: TemplateRef<any>;

  @Input() tasks: Task[];

  fields = TaskDetailFields;
  projectId: number;
  bsConfig: Partial<BsDatepickerConfig> = { dateInputFormat: DATE_UI_FORMAT, customTodayClass: 'bs-datepicker-today' };
  followUpItems =  followUpItems;
  formCtrlName = FormCtrlName;

  fieldOptions = {
    status: [],
    assignee: [],
    reporter: [],
    cc: [],
    priority: [],
    type: [],
    epic: [],
    sprint: [],
    label: [],
    platform: [],
    releases: [],
    storylines: [],
  };

  constructor(
    protected fb: UntypedFormBuilder,
    private projectsService: ProjectsService,
    private store: Store,
  ) {
    super(fb);
    this.projectId = this.projectsService.getCurrentProject()?.id;
  }

  init() {
    super.init();
    this.listenObserveFromStore();
    this.changeValueFormControl();
  }

  createForm() {
    this.form = this.fb.group({
      [FormCtrlName.type]: [first(this.fields) ?? '', [Validators.required]],
      [FormCtrlName.value]: ['', Validators.required],
      [FormCtrlName.sendMail]: [false]
    });

    // set default values
    if (this.form.get(FormCtrlName.type)?.value) {
      const data = this.form.get(FormCtrlName.type)?.value;
      this.getItemsOfField(data?.value);
    }
  }

  listenObserveFromStore() {
    combineLatest([
      this.store.select(selectStatuses).pipe(nonEmpty()),
      this.store.select(selectProjectMembers).pipe(nonEmpty()),
      this.store.select(selectPriorities).pipe(nonEmpty()),
      this.store.select(selectTaskTypes).pipe(nonEmpty()),
      this.store.select(selectReleases).pipe(nonEmpty()),
      this.store.select(selectEpics).pipe(nonEmpty()),
      this.store.select(selectSprints).pipe(nonEmpty()),
      this.store.select(selectLabels).pipe(nonEmpty()),
      this.store.select(selectStoryLines).pipe(nonEmpty()),
      this.store.select(selectPlatforms).pipe(nonEmpty()),
    ])
    .pipe(debounceTime(100), takeUntil(this.destroyed$))
    .subscribe(([statuses, members, priorities, taskTypes, releases, epics, sprints, labels, storylines, platforms]) => {
      this.fieldOptions = {
        status: statuses,
        assignee: members,
        reporter: members,
        cc: members,
        priority: priorities,
        type: taskTypes,
        epic: epics,
        sprint: sprints,
        label: labels,
        platform: platforms,
        releases,
        storylines
      };

      const type = this.form.get(FormCtrlName.type)?.value;
      if (type?.value) {
        this.getFormCtrl(FormCtrlName.value).setValue(first(this.fieldOptions[type.value]), {emitEvent: false});
      }
    });
  }

  changeValueFormControl() {
    this.form.get(FormCtrlName.type)
    .valueChanges
    .subscribe((data) => {
      if (data && !this.isDateType() && !this.isBooleanType()) {
        this.getItemsOfField(data.value);
      } else {
        this.getFormCtrl(FormCtrlName.value).setValue('', {emitEvent: false});
      }
    });
  }

  getItemsOfField(key: string) {
    if (this.hasOptions(key)) {
      this.getFormCtrl(FormCtrlName.value).setValue(first(this.fieldOptions[key]), {emitEvent: false});
      return;
    }

    const fieldMapping = {
      [TaskDetailFormType.Assignee]: () => this.getUsers(),
      [TaskDetailFormType.Reporter]: () => this.getUsers(),
      [TaskDetailFormType.CC]: () => this.getUsers(),
      [TaskDetailFormType.Type]: () => this.getTypes(),
      [TaskDetailFormType.Priority]: () => this.getPriorities(),
      [TaskDetailFormType.Epic]: () => this.getEpics(),
      [TaskDetailFormType.Releases]: () => this.getReleases(),
      [TaskDetailFormType.Sprint]: () => this.getSprints(),
      [TaskDetailFormType.Status]: () => this.getStatuses(),
      [TaskDetailFormType.Label]: () => this.getLabels(),
      [TaskDetailFormType.Platform]: () => this.getPlatforms(),
      [TaskDetailFormType.Storyline]: () => this.getStorylines(),
    };

    const selectedField = fieldMapping[key];
    // call function to dispatch
    if (selectedField) {
      selectedField();
    }
  }

  add() {
    const formValue = this.form.getRawValue();
    if (this.isDateType()) {
      this.objectResponse.next({...formValue, value: this.isDueDate() ? UtilService.getDateFormat(formValue.value) : formValue.value});
      return;
    }

    if (this.isBooleanType() || this.isDateType()) {
      this.objectResponse.next({...formValue, value: formValue.value?.value});
      return;
    }

    formValue.value = isArray(formValue?.value) ? `[${formValue.value?.map(e => e.id)}]` : '' + formValue.value?.id;
    this.objectResponse.next(formValue);
  }

  isDateType() {
    const valueField = this.form.get(FormCtrlName.type).value;
    return [TaskDetailFormType.DueDate, TaskDetailFormType.EndDate, TaskDetailFormType.StartDate].some(e => e === valueField?.value);
  }

  isDueDate() {
    return this.getFormCtrl(FormCtrlName.type).value?.value === TaskDetailFormType.DueDate;
  }

  isBooleanType() {
    return this.getFormCtrl(FormCtrlName.type).value?.value === TaskDetailFormType.FollowUp;
  }

  getTemplateByType(): TemplateRef<any> {
    const type = this.form.get(FormCtrlName.type).value;
    return this[type.template];
  }

  getStatuses(keyword: string = '') {
    const params = {...new StatusSearchParams(), keyword};
    this.store.dispatch(ProjectActions.getStatuses({projectId: this.projectId, params}));
  }


  getUsers(keyword: string = '') {
    this.store.dispatch(ProjectActions.getProjectAllMembers({projectId: this.projectId, keyword}));
  }

  getTypes(keyword: string = '') {
    this.store.dispatch(TaskActions.getAllTaskTypes({ projectId: this.projectId, keyword }));
  }

  getPriorities(keyword: string = '') {
    const params = new PageableModel({ size : PageSize.loadAll });
    params.keyword = keyword;
    this.store.dispatch(ProjectActions.getPriorities({ projectId: this.projectId, params }));
  }

  getEpics(keyword: string = '') {
    const payload = {
      projectId: this.projectId,
      keyword
    };
    const pageable = new Pageable({ size : PageSize.loadAll });
    this.store.dispatch(ProjectActions.getEpics({payload, pageable}));
  }

  getReleases(keyword: string = '') {
    const payload = {
      projectId: this.projectId,
      keyword
    };
    const pageable = new Pageable({
      size : PageSize.loadAll,
      sort: CreatedAtSortType.desc
    });
    this.store.dispatch(ProjectActions.getReleases({payload, pageable}));
  }

  getSprints(keyword: string = '') {
    const payload = {
      projectId: this.projectId,
      keyword
    };
    const pageable = new Pageable({ size : PageSize.loadAll });
    this.store.dispatch(ProjectActions.getSprints({payload, pageable}));
  }

  getLabels(keyword: string = '') {
    const payload = {
      projectId: this.projectId,
      keyword
    };
    const pageable = new Pageable({ size : PageSize.loadAll });
    this.store.dispatch(ProjectActions.getLabels({payload, pageable}));
  }

  getPlatforms() {
    this.store.dispatch(ProjectActions.getPlatforms({projectId: this.projectId}));
  }

  getStorylines(keyword: string = '') {
    const pageable = new Pageable({ size : PageSize.loadAll });
    this.store.dispatch(ProjectActions.getStorylines({projectId: this.projectId, payload: {keyword}, pageable}));
  }

  hasOptions(key: string) {
    return !isEmpty(this.fieldOptions[key]);
  }
}

<div class="ant-notification-notice-content">
  <div class="ant-notification-notice-with-icon">
    <span class="ant-notification-notice-icon">
      <span
        nz-icon
        nzType="close-circle"
        class="ant-notification-notice-icon-error"
      ></span>
    </span>
    <div class="ant-notification-notice-message">
      <app-i18n [key]="'integrationJiraToast.title'"></app-i18n>
    </div>
    <div class="ant-notification-notice-description">
      <app-i18n [key]="'integrationJiraToast.message'"></app-i18n>
      <a class="text-primary" (click)="onNavigateToJira()"
        ><app-i18n [key]="'integrationJiraToast.action'"></app-i18n
      ></a>
    </div>
  </div>
</div>

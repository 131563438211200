<app-modal-container #modal>
  <ng-container header> <app-i18n [key]="'addShortcut'"></app-i18n> </ng-container>
  <ng-container body>
    <div class="row" [formGroup]="form">
      <div class="col-lg-12 col-12">
        <div class="form-group">
          <label><app-i18n [key]="'Name'"></app-i18n></label>
          <input class="form-control custom" formControlName="name" />
          <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
        </div>
        <div class="form-group">
          <label><app-i18n [key]="'url'"></app-i18n></label>
          <input class="form-control custom" formControlName="url" />
          <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
        </div>
        <div class="form-group">
          <label><app-i18n [key]="'iconType'"></app-i18n></label>
          <div class="type-icon">
            <span *ngFor="let icon of shortCutIcon; let index = index">
              <img
                src="{{ icon?.icon }}"
                alt=""
                class="img-icon checkbox-icon"
                (click)="selectIcon(icon)"
                [ngClass]="{ selected: icon.icon === selectedIcon.icon }"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()">
      <app-i18n [key]="'Cancel'"></app-i18n>
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSubmit()">
      <app-i18n [key]="'Create'"></app-i18n>
    </button>
  </ng-container>
</app-modal-container>

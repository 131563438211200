import { NgModule } from '@angular/core';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { TaskFinderItemComponent } from './components/task-item.component';

@NgModule({
  imports: [TaskFinderItemComponent, FroalaEditorModule, FroalaViewModule],
  exports: [FroalaEditorModule, FroalaViewModule],
  declarations: [],
  providers: [],
})
export class FroalaModule { }

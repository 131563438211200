import { FileType } from 'src/app/shared';
import { TreeItem } from '../../document/_services/document-data.service';

export enum WikiType {
  Topic = 'Topic',
  Page = 'Page'
}

export enum WikiMode {
  View = 'view',
  Create = 'create',
  Edit = 'edit'
}

export enum WikiGroup {
  PROJECT = 'Project',
  COMPANY_DOCUMENT = 'Company Document'
}

export interface EditorConfig {
  mode: WikiMode;
  type: WikiType;
}

export interface WikiState {
  data: WikiItem[];
  content: string;
  editorConfigs: EditorConfig;
  error: string;
  mentionList: WikiMentionMember[];
}

export interface WikiItem extends TreeItem {
  items: WikiItem[];
  hidden: boolean;
  uId?: number;
}

export interface WikiRequest {
  content?: string;
  name: string;
  publishFlg?: boolean;
  activateFlg?: true;
}

export interface WikiDeleteRequest {
  deleteFolder: boolean;
}

export interface WikiMentionRequest {
  userIds?: number[];
  teamIds?: number[];
  groupIds?: number[];
  content?: string;
}

export interface MoveWikiRequest {
  source: WikiItem;
  sourceIndex: number;
  destination: WikiItem;
  destinationIndex: number;
}

export interface WikiEditorData {
  title: string;
  content: string;
}

export enum WikiMemberType {
  USER = 'USER',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
}

export interface WikiMentionMember{
  objectEmail: string;
  objectAvatar: string;
  objectName: string;
  objectType: WikiMemberType;
  objectId: number;
}

export interface WikiMemberListRequest {
  keyword: string;
  wikiFlg: boolean;
  type: WikiType;
}

export interface GetWikiOptions {
  syncDocument?: boolean;
  moveWiki?: MoveWikiRequest;
  updateTreeMap?: boolean;
  callback?: any;
}

export const MD_FOLDER_NAME = '.MD';
export const MD_FILE_EXTENSION = '.md';
export const ACTIVATABLE_WIKI_FOLDERS = ['Projects', 'Company Document'];
export const TOPIC_FILE_NAME = 'index.md';

export const PROJECT_WIKI: WikiItem = {
  id: -1,
  uId: -1,
  name: 'Projects',
  editable: true,
  defaultFlg: true,
  type: FileType.dir,
  parentUI: null,
  isOpen: false,
  items: null,
  icon: '../../../../assets/icons/documents/folder_projects.svg',
  wikiGroup: WikiGroup.PROJECT
} as any;

export const COMPANY_DOCUMENT_WIKI: WikiItem = {
  id: -2,
  uId: -2,
  name: 'Company Document',
  editable: true,
  defaultFlg: true,
  type: FileType.dir,
  parentUI: null,
  isOpen: false,
  items: null,
  icon: '../../../../assets/icons/documents/folder_company.svg',
  wikiGroup: WikiGroup.COMPANY_DOCUMENT
} as any;

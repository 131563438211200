import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DEFAULT_TEXT_MAX_LENGTH } from 'src/app/shared';

enum FormCtrlName {
  TEXT = 'text'
}
@Component({
  selector: 'app-cell-text-editable',
  templateUrl: './cell-text-editable.component.html',
  styleUrls: ['./cell-text-editable.component.scss']
})
export class CellTextEditableComponent implements OnInit, OnChanges {
  @ViewChild('nameInput') nameInput: ElementRef;

  @Input() preWidth = 0;
  @Input() text = '';
  @Input() canEdit = true;
  @Input() maxLength = DEFAULT_TEXT_MAX_LENGTH;
  @Input() maxLengthErrorMessage: string = 'Too long';

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  isEditMode = false;

  control = new UntypedFormGroup({
    [FormCtrlName.TEXT]: new UntypedFormControl('',  [
      Validators.required, Validators.maxLength(this.maxLength)
    ])
  });
  FormCtrlName = FormCtrlName;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.control.get(FormCtrlName.TEXT).setValue(this.text);
    }
  }

  showEditMode() {
    this.isEditMode = true;
    setTimeout(() => {
      this.nameInput?.nativeElement?.focus();
    }, 100);
  }

  cancel() {
    this.isEditMode = false;
    this.control.get(FormCtrlName.TEXT).setValue(this.text);
  }

  save() {
    if (!this.control.get(FormCtrlName.TEXT).value || this.control.invalid) {
      return;
    }
    this.text = this.control.get(FormCtrlName.TEXT).value;
    this.isEditMode = false;
    this.textChange.emit(this.text);
  }

}

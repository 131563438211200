import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { filter, forEach, includes, isEmpty, map, reduce } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { ToastService, UtilService } from 'src/app/core';
import {
  FileItem,
  FilePermission,
  FileType,
  SendMailSharedUserRequest,
  ShareFilePermission,
  ShareFileRequest,
  ShareFileResponse,
  SharingItemResponse,
  UpdateShareFileRequest
} from 'src/app/shared';
import { GeneralConfirmComponent } from '../../_components';
import { DocumentLinkComponent } from '../document-link/document-link.component';
import {
  DocumentDataService,
  TreeItem
} from '../_services/document-data.service';
import { FileService } from '../_services/file.service';

@Component({
  selector: 'app-document-share',
  templateUrl: './document-share.component.html',
  styleUrls: ['./document-share.component.scss']
})
export class DocumentShareComponent implements OnInit {
  @Input() fileIds: number[] = [];
  @Output() shared = new EventEmitter();
  selectedFiles: (FileItem | TreeItem)[];
  form: UntypedFormGroup;

  targets: SharingItemResponse[] = [];
  rows: ShareFileResponse[] = [];
  userTypeahead$ = new Subject<string>();
  destroyed$ = new Subject<void>();

  currentPermission: ShareFilePermission;

  get isNotify() {
    return this.form.get('notify')?.value;
  }

  get target(): SharingItemResponse[] {
    return this.form.get('target').value || [];
  }

  get userIds() {
    return this.target
      .filter((e) => e.objectType === 'USER')
      .map((e) => e.objectId);
  }

  get groupIds() {
    return this.target
      .filter((e) => e.objectType === 'GROUP')
      .map((e) => e.objectId);
  }

  get teamIds() {
    return this.target
      .filter((e) => e.objectType === 'TEAM')
      .map((e) => e.objectId);
  }

  get currentUserId() {
    return this.authService.getLoggedUser()?.id;
  }

  get ownerIds() {
    return map(this.selectedFiles, (f) => f.owner?.id);
  }

  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private fileService: FileService,
    private toast: ToastService,
    private authService: AuthService,
    private documentDataService: DocumentDataService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.registerEvents();
    this.getFileShareInfo();
  }

  createForm() {
    this.form = this.fb.group({
      target: [[], [Validators.required]],
      permission: [FilePermission.view, [Validators.required]],
      notify: [],
      message: []
    });
  }

  hasOptions(fcName: string) {
    return (this.form.get(fcName).value || []).length > 0;
  }

  getFileShareInfo() {
    this.fileService.getFileShareInfo(this.fileIds).subscribe(
      (rows) => {
        this.rows = rows;

        forEach(this.rows, (r) => {
          if (r.user?.id === this.currentUserId) {
            r.disable = true;
          }
        });
      },
      (error: string) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );

    this.fileService.getFilesPermission(this.fileIds).subscribe((res) => {
      this.currentPermission = res.permission;
    });
  }

  registerEvents() {
    this.userTypeahead$
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.searchTargets(value || '');
      });

    this.userTypeahead$.next('');
  }

  searchTargets(keyword: string = '') {
    this.fileService
      .getShareTarget(this.fileIds, keyword)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.targets = filter(res.content, (e) => {
            return (
              e.objectId !== this.currentUserId &&
              !includes(this.ownerIds, e.objectId)
            );
          });
        },
        (error) => {
          this.targets = [];
        }
      );
  }

  syncTree(items: number[], index: number = 0) {
    if (!items[index]) {
      return;
    }

    return this.documentDataService.syncTreeItemById(items[index], {
      callback: () => this.syncTree(items, index + 1),
      force: true
    });
  }

  refreshTree() {
    forEach(this.selectedFiles, (file: TreeItem, index) => {
      const treeIds = [file.parentUI.id];

      if (file.type === FileType.dir && !isEmpty(file.items)) {
        treeIds.push(file.id);

        forEach(file.items, (e) => {
          treeIds.push(...this.documentDataService.getTreeItemExpandedId(e.id));
        });
      }

      this.documentDataService.syncTreeItemById(treeIds[0], {
        callback: () => this.syncTree(treeIds, 1),
        force: true
      });
    });
  }

  onSubmit() {
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formValue = this.form.getRawValue();
    const payload: ShareFileRequest = {
      fileIds: this.fileIds,
      users: {
        ids: this.userIds
      },
      groups: {
        ids: this.groupIds
      },
      teams: {
        ids: this.teamIds
      },
      permission: formValue.permission
    };

    this.fileService.shareFile(payload).subscribe(
      () => {
        this.shared.emit();
        this.form.get('target').reset();
        this.getFileShareInfo();
        this.toast.success('File sharing successfully!');

        this.userTypeahead$.next('');
        this.refreshTree()
      },
      (error: string) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );
  }

  getRowId(row: ShareFileResponse) {
    return row.id;
  }

  onDelete(row: ShareFileResponse, index: number) {
    const initialState = {
      title: 'Unshare',
      message: 'Do you want to unshare?'
    };

    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, {
      backdrop: 'static',
      initialState
    });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.fileService.deleteShareFile([this.getRowId(row)]).subscribe(
          (respone) => {
            this.toast.success('Unshare successfully!');
            this.rows.splice(index, 1);
            this.userTypeahead$.next('');
            this.refreshTree();
          },
          (error: string) => {
            const httpError = JSON.parse(error);
            this.toast.error(httpError?.message);
          }
        );
      }
    });
  }

  onDeleteAll() {
    const rowIds = this.rows.map(row => row.id);
    const initialState = {
      title: 'Unshare all',
      message: 'Do you want to unshare all?'
    };

    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, {
      backdrop: 'static',
      initialState
    });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.fileService.deleteShareFile(rowIds).subscribe(
          (response) => {
            this.rows = [];
            this.toast.success('Unshare all successfully!');
            this.userTypeahead$.next('');
            this.refreshTree();
          },
          (error: string) => {
            const httpError = JSON.parse(error);
            this.toast.error(httpError?.message);
          }
        );
      }
    });
  }

  onEdit(permission: ShareFilePermission, row: ShareFileResponse) {
    const payload: UpdateShareFileRequest = {
      shareIds: [this.getRowId(row)],
      permission
    };

    this.fileService.updateShareFile(payload).subscribe(
      (respone) => {
        this.toast.success('Edited Successfully!');
      },
      (error: string) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onSend(link: DocumentLinkComponent) {
    const { message } = this.form.value;

    const shareTarget = reduce(
      this.rows,
      (pre, cur) => {
        const { user, group, team } = cur;

        if (cur.user) {
          pre.users.push(user?.id);
        }

        if (cur.group) {
          pre.groups.push(group?.id);
        }

        if (cur.team) {
          pre.teams.push(team?.id);
        }

        return pre;
      },
      {
        users: [],
        groups: [],
        teams: []
      }
    );

    if (!isEmpty(link.links)) {
      console.error('Cannot get link codes');
    }

    const payload: SendMailSharedUserRequest = {
      fileCodes: link.links.map((e) => e.code),
      users: shareTarget.users,
      groups: shareTarget.groups,
      teams: shareTarget.teams,
      message
    };

    this.fileService.sendMailSharedUser(payload).subscribe(
      () => {
        this.onCancel();
        this.toast.success('Shared successfully!');
      },
      (error) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );
  }

  getUrlExtension(path: string) {
    return UtilService.getUrlExtension(path);
  }


}

import { Component, EventEmitter, Input,  Output,  QueryList } from '@angular/core';
import { AbstractComponent, BoardSprint, MoveMultiTasksRequest, MoveTaskPlacement, Project, Sprint, SprintToBacklogRequest, Task, TaskCloneRequest, TaskDetailFormType, TaskPatch, TasksPatchRequest } from 'src/app/shared';
import { BacklogContextMenuItem, BacklogContextMenuStructure, ContextMenuCode, LabelSubMenu, TaskDetailFields } from './backlog-context-menu';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { takeUntil } from 'rxjs/operators';
import { TaskTemplateService } from 'src/app/site-management/tasks/_services/task-template.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BacklogBulkChangeComponent } from './backlog-bulk-change-tasks/backlog-bulk-change-tasks.component';
import { BacklogService } from '../_service/backlog.service';
import { ContextMenuService } from 'src/app/shared/_components/context-menu/_services/context-menu.service';
import { BacklogDataService } from '../_service/backlog-data-service';
import { BacklogContextMenuDataService } from './_service/backlog-context-menu.service';
import { BacklogComponent } from '../backlog/backlog.component';
import { ProjectsService } from '../../_services';
import { SprintComponent } from '../sprint/sprint.component';
import { cloneDeep } from 'lodash';
import * as pluralize from 'pluralize';
import { GeneralConfirmComponent } from 'src/app/site-management/_components';
import { Store } from '@ngrx/store';
import { PermissionState, selectTaskPermission } from 'src/app/site-management/_store/permission';

export interface ContextMenuMoveTaskData {
  targetSprint: Sprint;
  placement?: MoveTaskPlacement;
}

@Component({
  selector: 'app-backlog-context-menu',
  templateUrl: './backlog-context-menu.component.html',
  styleUrls: ['./backlog-context-menu.component.scss']
})
export class BacklogContextMenuComponent extends AbstractComponent {
  @Input() backlogComponent: BacklogComponent;
  @Input() sprintComponent: QueryList<SprintComponent>;
  @Input() project: Project;
  @Input() tasks: Task[];
  @Input() sprints: Sprint[] = [];

  @Output() moveTask = new EventEmitter<ContextMenuMoveTaskData>();

  backlogContextMenu: BacklogContextMenuItem[] = [];
  sprintLimit = 3;

  constructor(
    private taskService: TaskService,
    private taskTemplateService: TaskTemplateService,
    private contextMenuService: ContextMenuService,
    private modalService: BsModalService,
    private backlogService: BacklogService,
    private backlogDataService: BacklogDataService,
    private backlogContextMenuDataService: BacklogContextMenuDataService,
    private projectService: ProjectsService,
    private _store: Store<PermissionState>,
  ) {
    super();
    this.project = this.projectService.getCurrentProject();
  }

  init() {
    this.backlogContextMenu = cloneDeep(BacklogContextMenuStructure);
    this.addChildrenToMenu();
    this.observeEvents();
  }

  addChildrenToMenu() {
    this.resetContextMenuToDefault();
    this.backlogContextMenu.forEach((childrenMenu) => {
      if (childrenMenu.code === ContextMenuCode.MOVE_TO) {
        const currentSprint = this.getCurrentSprint();
        this.addSprintToChildMenu(currentSprint, childrenMenu);
      }
    });
  }
  
  observeEvents() {
    this._store.select(selectTaskPermission(this.project.id))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(permission => {
        const deleteTaskOption = this.backlogContextMenu.find(menu => menu.code === ContextMenuCode.DELETE_TASK);
        if (deleteTaskOption) {
          deleteTaskOption.disabled = !permission.canDelete;
        }
      })
  }

  addSprintToChildMenu(currentSprint: BoardSprint, childrenMenu: BacklogContextMenuItem) {
    const remainingSprints = this.getRemainingSprint(currentSprint, this.sprints)?.map(sprint => {
      return {
        id: sprint?.id,
        sprint,
        name: sprint.isNextSprint ? LabelSubMenu.nextSprint : sprint?.name,
        code: ContextMenuCode.MOVE_TO_SPRINT
      };
    });

    if (!currentSprint) {
      childrenMenu.children = remainingSprints;
      return;
    }

    childrenMenu.children = [...remainingSprints, ...childrenMenu.children];

  }

  getRemainingSprint(currentSprint: BoardSprint, sprints: Sprint[]) {
    if (!currentSprint && sprints?.length) {
      return sprints?.slice(0, this.sprintLimit);
    }

    const indexOfCurrentSprint = sprints?.findIndex(sprint => currentSprint.id === sprint.id);
    if (indexOfCurrentSprint !== -1 &&  indexOfCurrentSprint + 1 < sprints?.length) {
      const indexOfNextSprint = indexOfCurrentSprint + 1;
      sprints[indexOfNextSprint].isNextSprint = true;
    }

    return sprints?.filter(sprint => sprint.id !== currentSprint.id)?.slice(0, this.sprintLimit);
  }

  onClickMenu(item: BacklogContextMenuItem) {
    if (!item) { return; }

    if (item.code === ContextMenuCode.MOVE_TO_SPRINT) {
      this.handleMoveTasksToSprint(item);
      return;
    }

    if (item.code === ContextMenuCode.MOVE_TO_TOP_BACKLOG) {
      this.handleMoveTasksToBacklog(ContextMenuCode.MOVE_TO_TOP_BACKLOG);
      return;
    }

    if (item.code === ContextMenuCode.MOVE_TO_BOTTOM_BACKLOG) {
      this.handleMoveTasksToBacklog(ContextMenuCode.MOVE_TO_BOTTOM_BACKLOG);
      return;
    }

    if (item.code === ContextMenuCode.BULK_CHANGE) {
      this.handleBulkChangeTasks();
      return;
    }

    if (item.code === ContextMenuCode.DELETE_TASK) {
      this.handleDeleteTasks();
      return;
    }

    if (item.code === ContextMenuCode.SET_TASK_TEMPLATE) {
      this.handleSetTasksTemplate();
      return;
    }

    if (item.code === ContextMenuCode.CLONE_TASKS) {
      this.handleCloneTasks();
      return;
    }
  }

  handleCloneTasks() {
    const payload: TaskCloneRequest = {
      jobTitleIds: [],
      taskIds: this.tasks?.map(t => t.id) || [],
      cloneSubTask: true
    };

    this.cloneTasks(payload, (taskRes) => {
      const sprint = this.getCurrentSprint();
      this.backlogContextMenuDataService.cloneTasks$.next({tasks: taskRes, sprint});
      const message = `Successfully cloned` + ' ' + this.generatePluralizedNoun(this.tasks.length, 'task');
      this.handleDataSuccess(message);
    });
  }

  handleMoveTasksToSprint(item: BacklogContextMenuItem) {
    if (!item) {
      return;
    }

    this.moveTask.emit({
      targetSprint: item.sprint,
    });
    this.hide();
  }

  handleMoveTasksToBacklog(type: string) {
    const placement = type === ContextMenuCode.MOVE_TO_TOP_BACKLOG ? MoveTaskPlacement.top : MoveTaskPlacement.bottom;

    this.moveTask.emit({
      targetSprint: null,
      placement,
    });
    this.hide();
  }

  handleBulkChangeTasks() {
    const ref = this.modalService.show(BacklogBulkChangeComponent, {
      backdrop: 'static',
      initialState: {
        tasks: this.tasks
      }
    });

    ref.content.objectResponse.subscribe((data) => {
      if (data) {
        const payload: TasksPatchRequest = {
          type: (data.type as any)?.type,
          value: data.value,
          taskIds: this.tasks?.map(e => e.id),
          sendMail: data.sendMail
        };

        this.patchTaskByBulkChangeAction(payload, (tasks) => {
          tasks?.forEach(task => {
            this.backlogDataService.emitTaskChange({
              previousValue: this.tasks?.find(t => t.id === task?.id),
              currentValue: task,
            });
          });
          const type = TaskDetailFields.find(f => f.type === (data.type as any)?.type);
          const message = `Successfully set ${type.name.toLowerCase()} of` + ' ' + this.generatePluralizedNoun(this.tasks?.length, 'task');
          this.handleDataSuccess(message);
        });
      }
      ref.hide();
    });
  }

  handleSetTasksTemplate() {
    let numberOfCompletedTasks = 0;

    this.tasks?.forEach(task => {
      const payload: TaskPatch = {
        type: TaskDetailFormType.TaskTemplate,
        value: '' + true
      };

      this.updateTask(task, payload, (taskRes) => {
        numberOfCompletedTasks += 1;
        this.taskTemplateService.updateTaskTemplate$.next(taskRes);
        if (numberOfCompletedTasks === this.tasks?.length) {
          const message = 'Successfully set' + ' ' + this.generatePluralizedNoun(numberOfCompletedTasks, 'task template');
          this.handleDataSuccess(message);
        }
      });
    });
  }

  handleDeleteTasks() {
    const tasks: Task[] = [];
    let numberOfCompletedTasks = 0;

    const initialState = {
      title: 'Delete Task',
      message: 'confirmDeleteTask',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.tasks?.forEach(task => {
          this.deletedTask(task, (deletedTask) => {
            numberOfCompletedTasks += 1;
            tasks.push(deletedTask);

            if (numberOfCompletedTasks === this.tasks?.length) {
              const isTaskBacklog = !task?.sprint ? true : false;
              this.backlogContextMenuDataService.deleteTasks$.next({tasks, isTaskBacklog});
              const message = 'Successfully deleted' + ' ' + this.generatePluralizedNoun(this.tasks.length, 'task');
              this.handleDataSuccess(message);
            }
          });
        });
      }
    });
  }

  updateTask(task: Task, payload: TaskPatch, callback: (task: Task) => void) {
    this.taskService.patch(task.id, payload)
    .subscribe((taskRes) => {
      if (taskRes) {
        this.backlogDataService.emitTaskChange({
          previousValue: task,
          currentValue: taskRes,
        });
        callback(taskRes);
      }
    });
  }

  deletedTask(task: Task, callback: (task: Task) => void) {
    this.taskService.deleteTask(task.id)
      .subscribe((taskRes) => {
        if (taskRes) {
          callback(taskRes);
        }
      });
  }

  patchTaskByBulkChangeAction(payload: TasksPatchRequest, callback: (tasks: Task[]) => void) {
    this.backlogService.patchTasks(payload)
    .subscribe(
      (taskRes) => {
        callback(taskRes);
      },
      (err: string) => {
        this.errorFn(err);
      }
    );
  }

  cloneTasks(payload: TaskCloneRequest, callback: (tasks: Task[]) => void) {
    this.taskService.cloneTasks(payload)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data) => {
      callback(data.tasks);
    });
  }

  hide() {
    this.contextMenuService.hide();
  }

  resetContextMenuToDefault() {
    if (!this.sprints?.length) {
      this.backlogContextMenu = this.backlogContextMenu?.filter(contextmenu => contextmenu.code !== ContextMenuCode.MOVE_TO);
      return;
    }
    this.sprints?.forEach(sprint => sprint.isNextSprint = false);
  }

  handleDataSuccess(message: string) {
    this.toast.success(message);
    this.backlogDataService.resetCdk();
    this.hide();
  }

  private getCurrentSprint() {
    return this.tasks?.[0]?.sprint;
  }

  generatePluralizedNoun(count: number, noun: string) {
    const pluralNoun = pluralize(noun, count);
    return `${count} ${pluralNoun}`;
  }
}

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-horizontal-wrapper',
  template: `
    <div class="form-wrapper">
      <label [attr.for]="id" class="col-form-label" *ngIf="props.label">
        {{ props.label }}
      </label>
      <ng-container *ngIf="props?.icon">
        <mat-icon
          *ngIf="!props?.isNormalIcon; else normalIcon"
          fontSet="material-icons-outlined"
          class="form-icon"
          [ngClass]="props.iconClass"
        >
          {{ props.icon }}
        </mat-icon>
        <ng-template #normalIcon>
          <mat-icon class="fa form-icon" [svgIcon]="props.icon"></mat-icon>
        </ng-template>
      </ng-container>

      <div class="form-icon d-flex align-items-center"  *ngIf="props.interIcon">
        <mat-icon [svgIcon]="props.interIcon"></mat-icon>
      </div>

      <div class="form-field" [ngClass]="{ 'form-field-error': showError }">
        <ng-template #fieldComponent></ng-template>

        <div *ngIf="showError" class="form-error invalid-feedback d-block">
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      </div>
    </div>
  `
})
export class HorizontalWrapper extends FieldWrapper {}

import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class IpHelperService {
  constructor() {
  }

  isVnIp(ip: string) {
    const vnIpRanges: string[] = [
      "1.52.0.0 - 1.55.255.255",
      "101.101.96.0 - 101.101.97.255",
      "101.101.99.0 - 101.101.99.255",
      "101.33.12.0 - 101.33.15.255",
      "101.33.30.0 - 101.33.31.255",
      "101.36.102.0 - 101.36.103.255",
      "101.44.216.0 - 101.44.219.255",
      "101.53.0.0 - 101.53.63.255",
      "101.96.12.0 - 101.96.15.255",
      "101.96.64.0 - 101.96.127.255",
      "101.99.0.0 - 101.99.63.255",
      "102.129.133.0 - 102.129.133.255",
      "102.217.105.0 - 102.217.105.255",
      "103.1.200.0 - 103.1.203.255",
      "103.1.208.0 - 103.1.211.255",
      "103.1.236.0 - 103.1.239.255",
      "103.10.212.0 - 103.10.215.255",
      "103.10.44.0 - 103.10.47.255",
      "103.10.68.0 - 103.10.69.255",
      "103.10.88.0 - 103.10.91.255",
      "103.100.160.0 - 103.100.163.255",
      "103.100.228.0 - 103.100.231.255",
      "103.101.160.0 - 103.101.163.255",
      "103.101.32.0 - 103.101.35.255",
      "103.101.76.0 - 103.101.79.255",
      "103.101.90.0 - 103.101.90.255",
      "103.102.128.0 - 103.102.131.255",
      "103.102.20.0 - 103.102.23.255",
      "103.103.116.0 - 103.103.119.255",
      "103.104.116.0 - 103.104.123.255",
      "103.104.24.0 - 103.104.27.255",
      "103.106.104.0 - 103.106.105.255",
      "103.106.220.0 - 103.106.227.255",
      "103.107.180.0 - 103.107.183.255",
      "103.107.200.0 - 103.107.203.255",
      "103.108.100.0 - 103.108.103.255",
      "103.108.132.0 - 103.108.139.255",
      "103.109.186.0 - 103.109.187.255",
      "103.109.28.0 - 103.109.43.255",
      "103.11.172.0 - 103.11.175.255",
      "103.11.198.0 - 103.11.199.255",
      "103.110.128.0 - 103.110.129.255",
      "103.110.32.0 - 103.110.33.255",
      "103.110.84.0 - 103.110.87.255",
      "103.111.236.0 - 103.111.247.255",
      "103.112.124.0 - 103.112.127.255",
      "103.112.132.0 - 103.112.135.255",
      "103.112.208.0 - 103.112.211.255",
      "103.113.80.0 - 103.113.83.255",
      "103.113.88.0 - 103.113.91.255",
      "103.114.104.0 - 103.114.107.255",
      "103.114.66.0 - 103.114.66.255",
      "103.115.166.0 - 103.115.166.255",
      "103.116.100.0 - 103.116.107.255",
      "103.116.38.0 - 103.116.39.255",
      "103.116.52.0 - 103.116.55.255",
      "103.116.8.0 - 103.116.9.255",
      "103.117.196.0 - 103.117.199.255",
      "103.117.240.0 - 103.117.247.255",
      "103.118.28.0 - 103.118.31.255",
      "103.119.84.0 - 103.119.87.255",
      "103.12.104.0 - 103.12.107.255",
      "103.12.76.0 - 103.12.77.255",
      "103.120.156.0 - 103.120.159.255",
      "103.120.242.0 - 103.120.243.255",
      "103.120.78.0 - 103.120.79.255",
      "103.121.88.0 - 103.121.91.255",
      "103.122.140.0 - 103.122.141.255",
      "103.122.160.0 - 103.122.163.255",
      "103.122.220.0 - 103.122.221.255",
      "103.122.82.0 - 103.122.83.255",
      "103.124.56.0 - 103.124.63.255",
      "103.124.92.0 - 103.124.95.255",
      "103.125.168.0 - 103.125.171.255",
      "103.125.188.0 - 103.125.195.255",
      "103.126.152.0 - 103.126.163.255",
      "103.127.192.0 - 103.127.207.255",
      "103.129.126.0 - 103.129.127.255",
      "103.129.188.0 - 103.129.191.255",
      "103.129.80.0 - 103.129.91.255",
      "103.13.76.0 - 103.13.79.255",
      "103.130.190.0 - 103.130.191.255",
      "103.130.208.0 - 103.130.223.255",
      "103.130.52.0 - 103.130.59.255",
      "103.131.56.0 - 103.131.57.255",
      "103.131.68.0 - 103.131.79.255",
      "103.131.84.0 - 103.131.85.255",
      "103.133.100.0 - 103.133.111.255",
      "103.133.224.0 - 103.133.227.255",
      "103.135.28.0 - 103.135.31.255",
      "103.136.114.0 - 103.136.115.255",
      "103.136.228.0 - 103.136.229.255",
      "103.137.156.0 - 103.137.157.255",
      "103.137.184.0 - 103.137.187.255",
      "103.137.4.0 - 103.137.5.255",
      "103.138.108.0 - 103.138.115.255",
      "103.138.88.0 - 103.138.91.255",
      "103.139.102.0 - 103.139.103.255",
      "103.139.12.0 - 103.139.15.255",
      "103.139.154.0 - 103.139.155.255",
      "103.139.160.0 - 103.139.161.255",
      "103.139.202.0 - 103.139.203.255",
      "103.139.40.0 - 103.139.45.255",
      "103.14.146.0 - 103.14.147.255",
      "103.14.154.0 - 103.14.155.255",
      "103.14.224.0 - 103.14.227.255",
      "103.14.48.0 - 103.14.49.255",
      "103.140.100.0 - 103.140.101.255",
      "103.140.248.0 - 103.140.251.255",
      "103.140.38.0 - 103.140.43.255",
      "103.140.4.0 - 103.140.5.255",
      "103.141.136.0 - 103.141.145.255",
      "103.141.176.0 - 103.141.177.255",
      "103.141.225.0 - 103.141.225.255",
      "103.142.136.0 - 103.142.139.255",
      "103.142.218.0 - 103.142.219.255",
      "103.142.22.0 - 103.142.27.255",
      "103.142.78.0 - 103.142.79.255",
      "103.143.140.0 - 103.143.145.255",
      "103.143.202.0 - 103.143.203.255",
      "103.143.206.0 - 103.143.209.255",
      "103.144.152.0 - 103.144.155.255",
      "103.144.84.0 - 103.144.87.255",
      "103.145.0.0 - 103.145.5.255",
      "103.145.248.0 - 103.145.248.255",
      "103.145.250.0 - 103.145.255.255",
      "103.145.62.0 - 103.145.63.255",
      "103.145.78.0 - 103.145.79.255",
      "103.146.122.0 - 103.146.123.255",
      "103.146.166.0 - 103.146.167.255",
      "103.146.18.0 - 103.146.23.255",
      "103.147.100.0 - 103.147.101.255",
      "103.147.122.0 - 103.147.123.255",
      "103.147.126.0 - 103.147.127.255",
      "103.147.184.0 - 103.147.189.255",
      "103.147.34.0 - 103.147.37.255",
      "103.148.158.0 - 103.148.159.255",
      "103.148.254.0 - 103.148.255.255",
      "103.148.56.0 - 103.148.57.255",
      "103.149.12.0 - 103.149.13.255",
      "103.149.134.0 - 103.149.137.255",
      "103.149.170.0 - 103.149.173.255",
      "103.149.250.0 - 103.150.1.255",
      "103.149.28.0 - 103.149.29.255",
      "103.149.86.0 - 103.149.87.255",
      "103.149.98.0 - 103.149.99.255",
      "103.15.222.0 - 103.15.223.255",
      "103.15.48.0 - 103.15.51.255",
      "103.15.88.0 - 103.15.91.255",
      "103.15.94.0 - 103.15.95.255",
      "103.150.124.0 - 103.150.125.255",
      "103.150.236.0 - 103.150.237.255",
      "103.150.240.0 - 103.150.241.255",
      "103.151.120.0 - 103.151.125.255",
      "103.151.238.0 - 103.151.245.255",
      "103.151.52.0 - 103.151.57.255",
      "103.152.160.0 - 103.152.165.255",
      "103.152.46.0 - 103.152.51.255",
      "103.153.214.0 - 103.153.223.255",
      "103.153.254.0 - 103.154.1.255",
      "103.153.64.0 - 103.153.65.255",
      "103.153.68.0 - 103.153.79.255",
      "103.154.100.0 - 103.154.103.255",
      "103.154.166.0 - 103.154.167.255",
      "103.154.176.0 - 103.154.177.255",
      "103.154.180.0 - 103.154.181.255",
      "103.154.186.0 - 103.154.187.255",
      "103.154.244.0 - 103.154.245.255",
      "103.154.62.0 - 103.154.63.255",
      "103.155.160.0 - 103.155.165.255",
      "103.155.216.0 - 103.155.217.255",
      "103.155.228.0 - 103.155.231.255",
      "103.155.254.0 - 103.156.13.255",
      "103.155.78.0 - 103.155.83.255",
      "103.156.30.0 - 103.156.35.255",
      "103.156.38.0 - 103.156.49.255",
      "103.156.58.0 - 103.156.61.255",
      "103.156.90.0 - 103.156.93.255",
      "103.157.18.0 - 103.157.21.255",
      "103.157.204.0 - 103.157.205.255",
      "103.157.216.0 - 103.157.219.255",
      "103.159.22.0 - 103.159.23.255",
      "103.159.48.0 - 103.159.61.255",
      "103.16.0.0 - 103.16.3.255",
      "103.16.160.0 - 103.16.161.255",
      "103.16.212.0 - 103.16.215.255",
      "103.16.224.0 - 103.16.225.255",
      "103.160.2.0 - 103.160.7.255",
      "103.160.228.0 - 103.160.229.255",
      "103.160.248.0 - 103.160.249.255",
      "103.160.74.0 - 103.160.79.255",
      "103.160.84.0 - 103.160.85.255",
      "103.160.88.0 - 103.160.91.255",
      "103.161.112.0 - 103.161.113.255",
      "103.161.118.0 - 103.161.127.255",
      "103.161.16.0 - 103.161.19.255",
      "103.161.170.0 - 103.161.181.255",
      "103.161.210.0 - 103.161.213.255",
      "103.161.22.0 - 103.161.23.255",
      "103.161.36.0 - 103.161.39.255",
      "103.161.96.0 - 103.161.97.255",
      "103.162.14.0 - 103.162.15.255",
      "103.162.20.0 - 103.162.25.255",
      "103.162.28.0 - 103.162.31.255",
      "103.162.92.0 - 103.162.95.255",
      "103.162.98.0 - 103.162.99.255",
      "103.163.118.0 - 103.163.119.255",
      "103.163.122.0 - 103.163.123.255",
      "103.163.212.0 - 103.163.219.255",
      "103.163.24.0 - 103.163.27.255",
      "103.164.126.0 - 103.164.127.255",
      "103.164.138.0 - 103.164.139.255",
      "103.164.152.0 - 103.164.155.255",
      "103.164.164.0 - 103.164.165.255",
      "103.164.226.0 - 103.164.227.255",
      "103.164.244.0 - 103.164.245.255",
      "103.164.34.0 - 103.164.37.255",
      "103.165.142.0 - 103.165.145.255",
      "103.165.88.0 - 103.165.89.255",
      "103.166.140.0 - 103.166.143.255",
      "103.166.176.0 - 103.166.177.255",
      "103.166.182.0 - 103.166.185.255",
      "103.166.214.0 - 103.166.214.255",
      "103.166.76.0 - 103.166.77.255",
      "103.167.155.0 - 103.167.155.255",
      "103.167.196.0 - 103.167.201.255",
      "103.167.62.0 - 103.167.63.255",
      "103.167.84.0 - 103.167.93.255",
      "103.168.100.0 - 103.168.101.255",
      "103.168.32.0 - 103.168.32.255",
      "103.168.36.0 - 103.168.37.255",
      "103.168.50.0 - 103.168.55.255",
      "103.168.58.0 - 103.168.59.255",
      "103.169.146.0 - 103.169.147.255",
      "103.169.168.0 - 103.169.169.255",
      "103.169.172.0 - 103.169.173.255",
      "103.169.182.0 - 103.169.183.255",
      "103.169.243.0 - 103.169.243.255",
      "103.169.34.0 - 103.169.35.255",
      "103.17.140.0 - 103.17.141.255",
      "103.17.197.0 - 103.17.197.255",
      "103.17.236.0 - 103.17.239.255",
      "103.17.88.0 - 103.17.91.255",
      "103.170.118.0 - 103.170.123.255",
      "103.170.246.0 - 103.170.251.255",
      "103.170.254.0 - 103.171.1.255",
      "103.170.39.0 - 103.170.39.255",
      "103.171.102.0 - 103.171.103.255",
      "103.171.112.0 - 103.171.113.255",
      "103.171.216.0 - 103.171.217.255",
      "103.171.90.0 - 103.171.95.255",
      "103.172.234.0 - 103.172.239.255",
      "103.172.60.0 - 103.172.69.255",
      "103.172.78.0 - 103.172.79.255",
      "103.173.154.0 - 103.173.159.255",
      "103.173.226.0 - 103.173.229.255",
      "103.173.254.0 - 103.173.255.255",
      "103.173.66.0 - 103.173.67.255",
      "103.174.202.0 - 103.174.203.255",
      "103.174.212.0 - 103.174.213.255",
      "103.174.216.0 - 103.174.221.255",
      "103.174.72.0 - 103.174.75.255",
      "103.175.142.0 - 103.175.147.255",
      "103.175.198.0 - 103.175.199.255",
      "103.175.248.0 - 103.175.249.255",
      "103.175.64.0 - 103.175.69.255",
      "103.176.108.0 - 103.176.113.255",
      "103.176.144.0 - 103.176.151.255",
      "103.176.164.0 - 103.176.165.255",
      "103.176.168.0 - 103.176.169.255",
      "103.176.178.0 - 103.176.179.255",
      "103.176.20.0 - 103.176.25.255",
      "103.176.250.0 - 103.176.251.255",
      "103.176.92.0 - 103.176.93.255",
      "103.177.108.0 - 103.177.111.255",
      "103.177.146.0 - 103.177.147.255",
      "103.177.164.0 - 103.177.165.255",
      "103.177.170.0 - 103.177.171.255",
      "103.177.34.0 - 103.177.35.255",
      "103.177.38.0 - 103.177.39.255",
      "103.178.102.0 - 103.178.103.255",
      "103.178.214.0 - 103.178.215.255",
      "103.178.228.0 - 103.178.235.255",
      "103.178.28.0 - 103.178.28.255",
      "103.179.118.0 - 103.179.118.255",
      "103.179.172.0 - 103.179.175.255",
      "103.179.184.0 - 103.179.191.255",
      "103.179.227.0 - 103.179.227.255",
      "103.18.176.0 - 103.18.179.255",
      "103.18.4.0 - 103.18.7.255",
      "103.180.132.0 - 103.180.159.255",
      "103.180.96.0 - 103.180.97.255",
      "103.182.16.0 - 103.182.23.255",
      "103.183.108.0 - 103.183.110.255",
      "103.183.112.0 - 103.183.115.255",
      "103.183.118.0 - 103.183.121.255",
      "103.183.144.0 - 103.183.153.255",
      "103.183.221.0 - 103.183.221.255",
      "103.184.112.0 - 103.184.113.255",
      "103.184.124.0 - 103.184.125.255",
      "103.184.96.0 - 103.184.97.255",
      "103.185.184.0 - 103.185.187.255",
      "103.185.232.0 - 103.185.233.255",
      "103.186.100.0 - 103.186.101.255",
      "103.186.146.0 - 103.186.149.255",
      "103.186.152.0 - 103.186.155.255",
      "103.186.180.0 - 103.186.183.255",
      "103.186.24.0 - 103.186.25.255",
      "103.186.64.0 - 103.186.67.255",
      "103.187.0.0 - 103.187.5.255",
      "103.187.168.0 - 103.187.169.255",
      "103.187.92.0 - 103.187.92.255",
      "103.188.164.0 - 103.188.167.255",
      "103.188.20.0 - 103.188.21.255",
      "103.188.242.0 - 103.188.245.255",
      "103.188.250.0 - 103.188.251.255",
      "103.188.80.0 - 103.188.85.255",
      "103.189.202.0 - 103.189.203.255",
      "103.189.208.0 - 103.189.209.255",
      "103.189.70.0 - 103.189.70.255",
      "103.189.72.0 - 103.189.79.255",
      "103.189.83.0 - 103.189.83.255",
      "103.19.164.0 - 103.19.167.255",
      "103.19.220.0 - 103.19.223.255",
      "103.19.28.0 - 103.19.31.255",
      "103.19.96.0 - 103.19.99.255",
      "103.190.106.0 - 103.190.107.255",
      "103.190.120.0 - 103.190.121.255",
      "103.190.200.0 - 103.190.203.255",
      "103.190.36.0 - 103.190.39.255",
      "103.190.80.0 - 103.190.81.255",
      "103.191.144.0 - 103.191.147.255",
      "103.192.236.0 - 103.192.239.255",
      "103.194.188.0 - 103.194.191.255",
      "103.195.236.0 - 103.195.243.255",
      "103.196.144.0 - 103.196.145.255",
      "103.196.16.0 - 103.196.19.255",
      "103.196.236.0 - 103.196.239.255",
      "103.196.244.0 - 103.196.251.255",
      "103.197.184.0 - 103.197.185.255",
      "103.197.90.0 - 103.197.91.255",
      "103.198.211.0 - 103.198.211.255",
      "103.199.4.0 - 103.199.79.255",
      "103.2.220.0 - 103.2.231.255",
      "103.20.102.0 - 103.20.103.255",
      "103.20.144.0 - 103.20.151.255",
      "103.20.96.0 - 103.20.97.255",
      "103.200.120.0 - 103.200.123.255",
      "103.200.20.0 - 103.200.27.255",
      "103.200.60.0 - 103.200.63.255",
      "103.205.60.0 - 103.205.61.255",
      "103.205.96.0 - 103.205.107.255",
      "103.206.212.0 - 103.206.219.255",
      "103.207.32.0 - 103.207.39.255",
      "103.209.188.0 - 103.209.189.255",
      "103.209.32.0 - 103.209.35.255",
      "103.209.60.0 - 103.209.61.255",
      "103.21.100.0 - 103.21.101.255",
      "103.21.120.0 - 103.21.123.255",
      "103.21.148.0 - 103.21.151.255",
      "103.21.96.0 - 103.21.97.255",
      "103.211.200.0 - 103.211.201.255",
      "103.211.206.0 - 103.211.207.255",
      "103.211.212.0 - 103.211.215.255",
      "103.211.93.0 - 103.211.93.255",
      "103.213.12.0 - 103.213.15.255",
      "103.213.122.0 - 103.213.123.255",
      "103.213.216.0 - 103.213.219.255",
      "103.213.8.0 - 103.213.9.255",
      "103.214.8.0 - 103.214.11.255",
      "103.215.94.0 - 103.215.95.255",
      "103.216.112.0 - 103.216.131.255",
      "103.216.72.0 - 103.216.75.255",
      "103.218.122.0 - 103.218.123.255",
      "103.219.180.0 - 103.219.183.255",
      "103.22.218.0 - 103.22.219.255",
      "103.220.68.0 - 103.220.71.255",
      "103.220.84.0 - 103.220.87.255",
      "103.221.212.0 - 103.221.231.255",
      "103.221.86.0 - 103.221.86.255",
      "103.223.4.0 - 103.223.7.255",
      "103.224.168.0 - 103.224.171.255",
      "103.225.236.0 - 103.225.239.255",
      "103.226.108.0 - 103.226.111.255",
      "103.226.248.0 - 103.226.251.255",
      "103.227.112.0 - 103.227.115.255",
      "103.227.216.0 - 103.227.219.255",
      "103.228.126.0 - 103.228.127.255",
      "103.228.20.0 - 103.228.23.255",
      "103.228.36.0 - 103.228.37.255",
      "103.228.74.0 - 103.228.75.255",
      "103.229.192.0 - 103.229.195.255",
      "103.229.29.0 - 103.229.30.255",
      "103.229.40.0 - 103.229.43.255",
      "103.229.52.0 - 103.229.53.255",
      "103.23.144.0 - 103.23.147.255",
      "103.23.156.0 - 103.23.159.255",
      "103.23.88.0 - 103.23.91.255",
      "103.231.148.0 - 103.231.151.255",
      "103.231.248.0 - 103.231.249.255",
      "103.232.120.0 - 103.232.123.255",
      "103.232.52.0 - 103.232.63.255",
      "103.233.48.0 - 103.233.51.255",
      "103.234.150.0 - 103.234.151.255",
      "103.234.154.0 - 103.234.155.255",
      "103.234.160.0 - 103.234.161.255",
      "103.234.188.0 - 103.234.189.255",
      "103.234.32.0 - 103.234.33.255",
      "103.234.36.0 - 103.234.39.255",
      "103.234.88.0 - 103.234.91.255",
      "103.235.208.0 - 103.235.215.255",
      "103.236.174.0 - 103.236.175.255",
      "103.237.144.0 - 103.237.151.255",
      "103.237.60.0 - 103.237.67.255",
      "103.237.86.0 - 103.237.87.255",
      "103.237.96.0 - 103.237.99.255",
      "103.238.208.0 - 103.238.215.255",
      "103.238.234.0 - 103.238.235.255",
      "103.238.68.0 - 103.238.83.255",
      "103.239.116.0 - 103.239.123.255",
      "103.239.32.0 - 103.239.35.255",
      "103.239.66.0 - 103.239.67.255",
      "103.239.80.0 - 103.239.81.255",
      "103.24.244.0 - 103.24.247.255",
      "103.24.90.0 - 103.24.91.255",
      "103.240.6.0 - 103.240.7.255",
      "103.241.198.0 - 103.241.199.255",
      "103.241.248.0 - 103.241.251.255",
      "103.241.42.0 - 103.241.43.255",
      "103.242.100.0 - 103.242.101.255",
      "103.242.52.0 - 103.242.55.255",
      "103.243.104.0 - 103.243.107.255",
      "103.243.168.0 - 103.243.169.255",
      "103.243.216.0 - 103.243.219.255",
      "103.244.136.0 - 103.244.139.255",
      "103.245.148.0 - 103.245.151.255",
      "103.245.236.0 - 103.245.239.255",
      "103.245.244.0 - 103.245.255.255",
      "103.246.104.0 - 103.246.104.255",
      "103.246.220.0 - 103.246.223.255",
      "103.248.160.0 - 103.248.167.255",
      "103.248.230.0 - 103.248.231.255",
      "103.249.100.0 - 103.249.103.255",
      "103.249.116.0 - 103.249.117.255",
      "103.249.156.0 - 103.249.159.255",
      "103.249.20.0 - 103.249.23.255",
      "103.249.200.0 - 103.249.201.255",
      "103.250.24.0 - 103.250.27.255",
      "103.250.78.0 - 103.250.79.255",
      "103.252.0.0 - 103.252.3.255",
      "103.252.122.0 - 103.252.123.255",
      "103.252.136.0 - 103.252.137.255",
      "103.252.252.0 - 103.252.255.255",
      "103.252.72.0 - 103.252.75.255",
      "103.252.92.0 - 103.252.95.255",
      "103.253.16.0 - 103.253.17.255",
      "103.253.20.0 - 103.253.23.255",
      "103.253.88.0 - 103.253.91.255",
      "103.254.12.0 - 103.254.19.255",
      "103.254.216.0 - 103.254.219.255",
      "103.254.40.0 - 103.254.43.255",
      "103.255.160.0 - 103.255.161.255",
      "103.255.236.0 - 103.255.239.255",
      "103.255.84.0 - 103.255.87.255",
      "103.26.252.0 - 103.26.255.255",
      "103.27.236.0 - 103.27.239.255",
      "103.27.60.0 - 103.27.67.255",
      "103.28.136.0 - 103.28.139.255",
      "103.28.172.0 - 103.28.175.255",
      "103.28.32.0 - 103.28.39.255",
      "103.29.0.0 - 103.29.3.255",
      "103.29.26.0 - 103.29.27.255",
      "103.3.244.0 - 103.3.255.255",
      "103.30.10.0 - 103.30.11.255",
      "103.30.36.0 - 103.30.39.255",
      "103.31.120.0 - 103.31.127.255",
      "103.35.64.0 - 103.35.67.255",
      "103.37.28.0 - 103.37.35.255",
      "103.37.60.0 - 103.37.61.255",
      "103.38.136.0 - 103.38.139.255",
      "103.38.180.0 - 103.38.181.255",
      "103.38.236.0 - 103.38.239.255",
      "103.38.244.0 - 103.38.247.255",
      "103.39.124.0 - 103.39.125.255",
      "103.39.92.0 - 103.39.99.255",
      "103.4.128.0 - 103.4.131.255",
      "103.42.54.0 - 103.42.59.255",
      "103.45.228.0 - 103.45.239.255",
      "103.47.192.0 - 103.47.195.255",
      "103.48.188.0 - 103.48.195.255",
      "103.48.76.0 - 103.48.87.255",
      "103.5.204.0 - 103.5.211.255",
      "103.5.30.0 - 103.5.31.255",
      "103.51.104.0 - 103.51.105.255",
      "103.51.120.0 - 103.51.121.255",
      "103.51.96.0 - 103.51.97.255",
      "103.52.112.0 - 103.52.113.255",
      "103.52.92.0 - 103.52.95.255",
      "103.53.158.0 - 103.53.159.255",
      "103.53.168.0 - 103.53.171.255",
      "103.53.228.0 - 103.53.231.255",
      "103.53.252.0 - 103.53.255.255",
      "103.53.88.0 - 103.53.91.255",
      "103.54.248.0 - 103.54.255.255",
      "103.56.156.0 - 103.56.171.255",
      "103.57.104.0 - 103.57.107.255",
      "103.57.112.0 - 103.57.115.255",
      "103.57.128.0 - 103.57.131.255",
      "103.57.208.0 - 103.57.211.255",
      "103.57.220.0 - 103.57.223.255",
      "103.59.170.0 - 103.59.171.255",
      "103.6.234.0 - 103.6.235.255",
      "103.6.248.0 - 103.6.249.255",
      "103.60.16.0 - 103.60.19.255",
      "103.60.206.0 - 103.60.207.255",
      "103.60.240.0 - 103.60.243.255",
      "103.61.120.0 - 103.61.123.255",
      "103.61.44.0 - 103.61.51.255",
      "103.62.8.0 - 103.62.11.255",
      "103.63.104.0 - 103.63.123.255",
      "103.63.212.0 - 103.63.215.255",
      "103.65.136.0 - 103.65.137.255",
      "103.65.234.0 - 103.65.235.255",
      "103.66.152.0 - 103.66.155.255",
      "103.67.162.0 - 103.67.163.255",
      "103.67.184.0 - 103.67.185.255",
      "103.67.196.0 - 103.67.199.255",
      "103.67.74.0 - 103.67.75.255",
      "103.68.240.0 - 103.68.255.255",
      "103.68.68.0 - 103.68.85.255",
      "103.69.188.0 - 103.69.195.255",
      "103.69.79.0 - 103.69.79.255",
      "103.69.82.0 - 103.69.87.255",
      "103.69.96.0 - 103.69.101.255",
      "103.7.172.0 - 103.7.172.255",
      "103.7.174.0 - 103.7.175.255",
      "103.7.177.0 - 103.7.177.255",
      "103.7.196.0 - 103.7.196.255",
      "103.7.36.0 - 103.7.43.255",
      "103.7.6.0 - 103.7.7.255",
      "103.70.114.0 - 103.70.117.255",
      "103.70.12.0 - 103.70.13.255",
      "103.70.168.0 - 103.70.169.255",
      "103.70.178.0 - 103.70.179.255",
      "103.70.28.0 - 103.70.31.255",
      "103.71.104.0 - 103.71.107.255",
      "103.71.110.0 - 103.71.111.255",
      "103.71.15.0 - 103.71.15.255",
      "103.71.180.0 - 103.71.187.255",
      "103.71.96.0 - 103.71.97.255",
      "103.72.56.0 - 103.72.57.255",
      "103.72.66.0 - 103.72.71.255",
      "103.72.96.0 - 103.72.99.255",
      "103.73.86.0 - 103.73.87.255",
      "103.74.100.0 - 103.74.107.255",
      "103.74.112.0 - 103.74.123.255",
      "103.74.78.0 - 103.74.79.255",
      "103.75.176.0 - 103.75.187.255",
      "103.75.72.0 - 103.75.77.255",
      "103.77.160.0 - 103.77.175.255",
      "103.77.182.0 - 103.77.183.255",
      "103.77.208.0 - 103.77.209.255",
      "103.77.214.0 - 103.77.215.255",
      "103.77.240.0 - 103.77.247.255",
      "103.78.0.0 - 103.78.7.255",
      "103.78.76.0 - 103.78.79.255",
      "103.78.84.0 - 103.78.95.255",
      "103.79.140.0 - 103.79.147.255",
      "103.8.13.0 - 103.8.13.255",
      "103.81.80.0 - 103.81.87.255",
      "103.82.132.0 - 103.82.135.255",
      "103.82.192.0 - 103.82.199.255",
      "103.82.20.0 - 103.82.39.255",
      "103.82.204.0 - 103.82.205.255",
      "103.84.102.0 - 103.84.103.255",
      "103.84.76.0 - 103.84.79.255",
      "103.85.246.0 - 103.85.247.255",
      "103.87.220.0 - 103.87.223.255",
      "103.88.108.0 - 103.88.123.255",
      "103.89.120.0 - 103.89.123.255",
      "103.89.84.0 - 103.89.95.255",
      "103.9.0.0 - 103.9.7.255",
      "103.9.156.0 - 103.9.159.255",
      "103.9.196.0 - 103.9.215.255",
      "103.9.76.0 - 103.9.87.255",
      "103.90.220.0 - 103.90.235.255",
      "103.91.170.0 - 103.91.171.255",
      "103.92.24.0 - 103.92.35.255",
      "103.93.92.0 - 103.93.92.255",
      "103.94.16.0 - 103.94.19.255",
      "103.94.176.0 - 103.94.179.255",
      "103.95.156.0 - 103.95.159.255",
      "103.95.168.0 - 103.95.171.255",
      "103.95.196.0 - 103.95.199.255",
      "103.97.124.0 - 103.97.127.255",
      "103.97.132.0 - 103.97.135.255",
      "103.97.80.0 - 103.97.80.255",
      "103.98.148.0 - 103.98.155.255",
      "103.98.160.0 - 103.98.163.255",
      "103.99.0.0 - 103.99.3.255",
      "103.99.228.0 - 103.99.231.255",
      "103.99.244.0 - 103.99.247.255",
      "103.99.252.0 - 103.99.255.255",
      "103.99.87.0 - 103.99.87.255",
      "104.218.166.0 - 104.218.166.255",
      "104.67.32.0 - 104.67.63.255",
      "107.151.140.0 - 107.151.140.255",
      "107.151.169.0 - 107.151.169.255",
      "107.154.187.0 - 107.154.187.255",
      "107.155.54.0 - 107.155.54.255",
      "107.155.6.0 - 107.155.6.255",
      "107.155.62.0 - 107.155.62.255",
      "108.165.110.0 - 108.165.110.255",
      "108.165.138.0 - 108.165.138.255",
      "108.165.198.0 - 108.165.198.255",
      "109.176.22.0 - 109.176.22.255",
      "110.35.64.0 - 110.35.79.255",
      "110.44.184.0 - 110.44.191.255",
      "111.65.240.0 - 111.65.255.255",
      "111.91.232.0 - 111.91.235.255",
      "112.109.88.0 - 112.109.95.255",
      "112.137.128.0 - 112.137.143.255",
      "112.197.0.0 - 112.197.255.255",
      "112.213.80.0 - 112.213.95.255",
      "112.72.64.0 - 112.72.127.255",
      "112.78.0.0 - 112.78.15.255",
      "113.160.0.0 - 113.191.255.255",
      "113.192.16.0 - 113.192.19.255",
      "113.192.6.0 - 113.192.9.255",
      "113.20.96.0 - 113.20.127.255",
      "113.22.0.0 - 113.23.127.255",
      "113.52.32.0 - 113.52.63.255",
      "113.61.108.0 - 113.61.111.255",
      "115.146.120.0 - 115.146.127.255",
      "115.165.160.0 - 115.165.167.255",
      "115.72.0.0 - 115.79.255.255",
      "115.84.176.0 - 115.84.183.255",
      "116.118.0.0 - 116.118.127.255",
      "116.193.64.0 - 116.193.79.255",
      "116.212.32.0 - 116.212.63.255",
      "116.68.128.0 - 116.68.135.255",
      "116.96.0.0 - 116.111.255.255",
      "117.0.0.0 - 117.7.255.255",
      "117.103.192.0 - 117.103.255.255",
      "117.122.0.0 - 117.122.127.255",
      "118.102.0.0 - 118.102.7.255",
      "118.107.64.0 - 118.107.127.255",
      "118.193.78.0 - 118.193.78.255",
      "118.27.192.0 - 118.27.223.255",
      "118.68.0.0 - 118.69.250.255",
      "118.69.252.0 - 118.71.255.255",
      "119.15.160.0 - 119.15.191.255",
      "119.17.192.0 - 119.17.255.255",
      "119.18.128.0 - 119.18.143.255",
      "119.18.184.0 - 119.18.191.255",
      "119.82.128.0 - 119.82.143.255",
      "120.138.64.0 - 120.138.79.255",
      "120.50.184.0 - 120.50.191.255",
      "120.72.80.0 - 120.72.87.255",
      "120.72.96.0 - 120.72.127.255",
      "121.50.172.0 - 121.50.175.255",
      "122.10.140.0 - 122.10.143.255",
      "122.10.149.0 - 122.10.149.255",
      "122.10.222.0 - 122.10.224.255",
      "122.102.112.0 - 122.102.115.255",
      "122.129.0.0 - 122.129.63.255",
      "122.201.8.0 - 122.201.15.255",
      "123.16.0.0 - 123.31.255.255",
      "123.58.198.0 - 123.58.199.255",
      "124.157.0.0 - 124.157.63.255",
      "124.158.0.0 - 124.158.15.255",
      "125.212.128.0 - 125.212.255.255",
      "125.214.0.0 - 125.214.63.255",
      "125.234.0.0 - 125.235.255.255",
      "125.253.112.0 - 125.253.127.255",
      "125.58.0.0 - 125.58.63.255",
      "125.62.77.0 - 125.62.77.255",
      "128.1.116.0 - 128.1.116.255",
      "128.1.126.0 - 128.1.127.255",
      "128.1.14.0 - 128.1.14.255",
      "128.1.197.0 - 128.1.197.255",
      "128.1.210.0 - 128.1.211.255",
      "128.1.37.0 - 128.1.37.255",
      "128.1.45.0 - 128.1.45.255",
      "128.1.50.0 - 128.1.50.255",
      "128.1.60.0 - 128.1.60.255",
      "128.14.1.0 - 128.14.2.255",
      "128.14.39.0 - 128.14.39.255",
      "128.77.28.0 - 128.77.28.255",
      "130.41.214.0 - 130.41.214.255",
      "130.41.89.0 - 130.41.89.255",
      "134.159.231.0 - 134.159.231.255",
      "134.159.240.0 - 134.159.241.255",
      "134.238.22.0 - 134.238.23.255",
      "137.59.104.0 - 137.59.107.255",
      "137.59.116.0 - 137.59.119.255",
      "137.59.24.0 - 137.59.47.255",
      "139.81.236.0 - 139.81.239.255",
      "139.81.242.0 - 139.81.243.255",
      "14.0.16.0 - 14.0.31.255",
      "14.0.51.0 - 14.0.51.255",
      "14.160.0.0 - 14.191.255.255",
      "14.224.0.0 - 14.255.255.255",
      "140.82.198.0 - 140.82.198.255",
      "141.11.10.0 - 141.11.10.255",
      "141.11.42.0 - 141.11.42.255",
      "144.208.216.0 - 144.208.216.255",
      "144.48.20.0 - 144.48.27.255",
      "146.103.23.0 - 146.103.23.255",
      "146.196.64.0 - 146.196.67.255",
      "148.153.178.0 - 148.153.178.255",
      "148.153.204.0 - 148.153.207.255",
      "148.153.68.0 - 148.153.71.255",
      "149.104.123.0 - 149.104.123.255",
      "149.104.125.0 - 149.104.125.255",
      "149.104.80.0 - 149.104.83.255",
      "149.18.84.0 - 149.18.84.255",
      "149.87.153.0 - 149.87.153.255",
      "149.87.169.0 - 149.87.169.255",
      "150.95.104.0 - 150.95.127.255",
      "150.95.16.0 - 150.95.19.255",
      "152.32.160.0 - 152.32.163.255",
      "152.32.221.0 - 152.32.224.255",
      "152.32.249.0 - 152.32.250.255",
      "152.32.255.0 - 152.32.255.255",
      "154.194.34.0 - 154.194.34.255",
      "154.194.49.0 - 154.194.49.255",
      "154.196.13.0 - 154.196.13.255",
      "154.196.92.0 - 154.196.95.255",
      "154.197.106.0 - 154.197.106.255",
      "154.197.117.0 - 154.197.117.255",
      "154.197.119.0 - 154.197.119.255",
      "154.197.72.0 - 154.197.72.255",
      "154.198.168.0 - 154.198.171.255",
      "154.198.9.0 - 154.198.10.255",
      "154.200.0.0 - 154.200.127.255",
      "154.201.10.0 - 154.201.15.255",
      "154.201.35.0 - 154.201.35.255",
      "154.201.7.0 - 154.201.8.255",
      "154.202.80.0 - 154.202.85.255",
      "154.203.164.0 - 154.203.164.255",
      "154.205.140.0 - 154.205.140.255",
      "154.205.142.0 - 154.205.143.255",
      "154.211.30.0 - 154.211.30.255",
      "154.6.160.0 - 154.6.161.255",
      "154.83.175.0 - 154.83.191.255",
      "154.85.94.0 - 154.85.94.255",
      "154.89.5.0 - 154.89.5.255",
      "154.93.48.0 - 154.93.48.255",
      "154.93.72.0 - 154.93.72.255",
      "154.94.127.0 - 154.94.128.255",
      "154.94.68.0 - 154.94.71.255",
      "156.225.74.0 - 156.225.75.255",
      "156.233.228.0 - 156.233.231.255",
      "156.238.182.0 - 156.238.182.255",
      "156.239.17.0 - 156.239.18.255",
      "156.251.74.0 - 156.251.74.255",
      "156.251.79.0 - 156.251.79.255",
      "156.255.84.0 - 156.255.87.255",
      "156.59.128.0 - 156.59.143.255",
      "156.59.241.0 - 156.59.241.255",
      "156.59.52.0 - 156.59.55.255",
      "156.59.74.0 - 156.59.75.255",
      "156.59.94.0 - 156.59.95.255",
      "157.10.170.0 - 157.10.171.255",
      "157.10.186.0 - 157.10.189.255",
      "157.10.194.0 - 157.10.203.255",
      "157.10.42.0 - 157.10.53.255",
      "157.10.86.0 - 157.10.87.255",
      "157.119.244.0 - 157.119.251.255",
      "157.15.108.0 - 157.15.111.255",
      "157.15.14.0 - 157.15.15.255",
      "157.15.18.0 - 157.15.19.255",
      "157.15.38.0 - 157.15.39.255",
      "157.15.84.0 - 157.15.87.255",
      "157.167.31.0 - 157.167.31.255",
      "157.20.200.0 - 157.20.201.255",
      "157.20.224.0 - 157.20.225.255",
      "157.20.58.0 - 157.20.63.255",
      "157.20.82.0 - 157.20.83.255",
      "157.20.88.0 - 157.20.89.255",
      "157.66.10.0 - 157.66.13.255",
      "157.66.120.0 - 157.66.121.255",
      "157.66.134.0 - 157.66.135.255",
      "157.66.156.0 - 157.66.163.255",
      "157.66.194.0 - 157.66.199.255",
      "157.66.216.0 - 157.66.225.255",
      "157.66.24.0 - 157.66.27.255",
      "157.66.252.0 - 157.66.253.255",
      "157.66.46.0 - 157.66.49.255",
      "157.66.80.0 - 157.66.81.255",
      "157.66.96.0 - 157.66.101.255",
      "158.220.67.0 - 158.220.67.255",
      "159.117.132.0 - 159.117.132.255",
      "160.19.150.0 - 160.19.151.255",
      "160.19.158.0 - 160.19.159.255",
      "160.19.204.0 - 160.19.205.255",
      "160.19.78.0 - 160.19.79.255",
      "162.158.216.0 - 162.158.219.255",
      "162.219.36.0 - 162.219.36.255",
      "163.171.218.0 - 163.171.218.255",
      "163.181.120.0 - 163.181.123.255",
      "163.181.142.0 - 163.181.143.255",
      "163.44.192.0 - 163.44.195.255",
      "163.44.200.0 - 163.44.200.255",
      "163.44.204.0 - 163.44.207.255",
      "163.47.177.0 - 163.47.177.255",
      "164.52.78.0 - 164.52.79.255",
      "165.1.182.0 - 165.1.182.255",
      "165.154.104.0 - 165.154.105.255",
      "165.154.136.0 - 165.154.137.255",
      "165.154.168.0 - 165.154.168.255",
      "165.154.186.0 - 165.154.187.255",
      "165.154.192.0 - 165.154.192.255",
      "165.154.197.0 - 165.154.197.255",
      "165.154.204.0 - 165.154.204.255",
      "165.154.214.0 - 165.154.214.255",
      "165.154.221.0 - 165.154.221.255",
      "165.154.229.0 - 165.154.229.255",
      "165.154.248.0 - 165.154.248.255",
      "165.154.250.0 - 165.154.250.255",
      "165.154.54.0 - 165.154.55.255",
      "165.85.135.0 - 165.85.135.255",
      "166.0.246.0 - 166.0.246.255",
      "17.81.105.0 - 17.81.105.255",
      "17.81.145.0 - 17.81.145.255",
      "17.81.162.0 - 17.81.163.255",
      "17.81.177.0 - 17.81.177.255",
      "17.81.182.0 - 17.81.182.255",
      "17.81.229.0 - 17.81.229.255",
      "17.81.39.0 - 17.81.39.255",
      "17.81.6.0 - 17.81.6.255",
      "17.81.60.0 - 17.81.60.255",
      "17.81.74.0 - 17.81.74.255",
      "17.81.84.0 - 17.81.84.255",
      "17.92.240.0 - 17.92.241.255",
      "17.92.243.0 - 17.92.244.255",
      "171.224.0.0 - 171.255.255.255",
      "172.225.181.0 - 172.225.181.255",
      "172.226.46.0 - 172.226.46.255",
      "172.68.165.0 - 172.68.165.255",
      "172.68.197.0 - 172.68.197.255",
      "172.69.120.0 - 172.69.121.255",
      "172.70.196.0 - 172.70.196.255",
      "172.96.88.0 - 172.96.88.255",
      "175.103.64.0 - 175.103.127.255",
      "175.106.0.0 - 175.106.3.255",
      "176.57.48.0 - 176.57.48.255",
      "178.212.138.0 - 178.212.138.255",
      "180.148.0.0 - 180.148.7.255",
      "180.148.128.0 - 180.148.143.255",
      "180.214.236.0 - 180.214.239.255",
      "180.93.0.0 - 180.93.255.255",
      "182.161.80.0 - 182.161.95.255",
      "182.236.112.0 - 182.236.115.255",
      "182.237.20.0 - 182.237.23.255",
      "183.80.0.0 - 183.81.127.255",
      "183.81.228.0 - 183.81.228.255",
      "183.90.160.0 - 183.90.167.255",
      "183.91.0.0 - 183.91.31.255",
      "183.91.160.0 - 183.91.191.255",
      "185.126.134.0 - 185.126.135.255",
      "185.155.100.0 - 185.155.100.255",
      "185.161.69.0 - 185.161.69.255",
      "185.17.1.0 - 185.17.1.255",
      "185.251.22.0 - 185.251.22.255",
      "188.212.6.0 - 188.212.7.255",
      "188.214.152.0 - 188.214.152.255",
      "188.241.221.0 - 188.241.221.255",
      "192.142.174.0 - 192.142.175.255",
      "193.0.245.0 - 193.0.245.255",
      "193.150.98.0 - 193.150.98.255",
      "194.105.88.0 - 194.105.88.255",
      "194.110.138.0 - 194.110.138.255",
      "194.26.233.0 - 194.26.233.255",
      "194.59.222.0 - 194.59.222.255",
      "199.245.57.0 - 199.245.59.255",
      "199.245.62.0 - 199.245.62.255",
      "2.56.16.0 - 2.56.19.255",
      "2.58.232.0 - 2.58.232.255",
      "2.59.0.0 - 2.59.1.255",
      "202.0.79.0 - 202.0.79.255",
      "202.124.204.0 - 202.124.204.255",
      "202.130.36.0 - 202.130.37.255",
      "202.134.16.0 - 202.134.23.255",
      "202.134.54.0 - 202.134.54.255",
      "202.143.108.0 - 202.143.111.255",
      "202.151.160.0 - 202.151.175.255",
      "202.158.244.0 - 202.158.249.255",
      "202.160.124.0 - 202.160.125.255",
      "202.172.4.0 - 202.172.5.255",
      "202.191.56.0 - 202.191.59.255",
      "202.37.86.0 - 202.37.87.255",
      "202.4.168.0 - 202.4.168.255",
      "202.4.176.0 - 202.4.176.255",
      "202.43.108.0 - 202.43.111.255",
      "202.44.137.0 - 202.44.137.255",
      "202.47.142.0 - 202.47.142.255",
      "202.47.87.0 - 202.47.87.255",
      "202.52.39.0 - 202.52.39.255",
      "202.55.132.0 - 202.55.135.255",
      "202.56.57.0 - 202.56.57.255",
      "202.58.245.0 - 202.58.245.255",
      "202.59.238.0 - 202.59.239.255",
      "202.59.252.0 - 202.59.253.255",
      "202.6.2.0 - 202.6.2.255",
      "202.6.96.0 - 202.6.97.255",
      "202.60.104.0 - 202.60.111.255",
      "202.74.56.0 - 202.74.56.255",
      "202.74.58.0 - 202.74.59.255",
      "202.78.169.0 - 202.78.169.255",
      "202.78.224.0 - 202.78.231.255",
      "202.79.232.0 - 202.79.239.255",
      "202.87.212.0 - 202.87.215.255",
      "202.9.79.0 - 202.9.80.255",
      "202.9.84.0 - 202.9.84.255",
      "202.92.4.0 - 202.92.7.255",
      "202.93.156.0 - 202.93.159.255",
      "202.94.82.0 - 202.94.82.255",
      "202.94.88.0 - 202.94.89.255",
      "203.101.134.0 - 203.101.134.255",
      "203.113.128.0 - 203.113.191.255",
      "203.119.36.0 - 203.119.39.255",
      "203.119.44.0 - 203.119.47.255",
      "203.119.58.0 - 203.119.75.255",
      "203.119.8.0 - 203.119.11.255",
      "203.128.240.0 - 203.128.247.255",
      "203.145.44.0 - 203.145.47.255",
      "203.160.0.0 - 203.160.1.255",
      "203.160.132.0 - 203.160.135.255",
      "203.160.96.0 - 203.160.103.255",
      "203.161.178.0 - 203.161.178.255",
      "203.162.0.0 - 203.162.255.255",
      "203.163.128.0 - 203.163.191.255",
      "203.167.8.0 - 203.167.15.255",
      "203.170.26.0 - 203.170.27.255",
      "203.171.16.0 - 203.171.31.255",
      "203.175.106.0 - 203.175.107.255",
      "203.175.96.0 - 203.175.97.255",
      "203.176.160.0 - 203.176.167.255",
      "203.189.28.0 - 203.189.31.255",
      "203.190.160.0 - 203.190.175.255",
      "203.191.48.0 - 203.191.55.255",
      "203.191.8.0 - 203.191.15.255",
      "203.195.0.0 - 203.195.63.255",
      "203.196.24.0 - 203.196.27.255",
      "203.201.56.0 - 203.201.59.255",
      "203.205.0.0 - 203.205.63.255",
      "203.209.180.0 - 203.209.183.255",
      "203.210.128.0 - 203.210.255.255",
      "203.29.16.0 - 203.29.19.255",
      "203.34.144.0 - 203.34.144.255",
      "203.77.178.0 - 203.77.178.255",
      "203.79.28.0 - 203.79.28.255",
      "203.8.127.0 - 203.8.127.255",
      "203.8.172.0 - 203.8.172.255",
      "203.89.140.0 - 203.89.143.255",
      "203.99.248.0 - 203.99.251.255",
      "208.127.168.0 - 208.127.168.255",
      "208.127.211.0 - 208.127.211.255",
      "208.127.218.0 - 208.127.218.255",
      "209.16.178.0 - 209.16.178.255",
      "210.2.64.0 - 210.2.127.255",
      "210.211.96.0 - 210.211.127.255",
      "210.245.0.0 - 210.245.127.255",
      "210.57.31.0 - 210.57.32.255",
      "210.79.176.0 - 210.79.177.255",
      "210.86.224.0 - 210.86.239.255",
      "212.104.131.0 - 212.104.131.255",
      "212.38.68.0 - 212.38.68.255",
      "213.218.238.0 - 213.218.239.255",
      "218.100.10.0 - 218.100.10.255",
      "218.100.14.0 - 218.100.14.255",
      "218.100.60.0 - 218.100.60.255",
      "220.231.64.0 - 220.231.127.255",
      "221.121.0.0 - 221.121.63.255",
      "221.132.0.0 - 221.132.63.255",
      "221.133.0.0 - 221.133.31.255",
      "222.252.0.0 - 222.255.255.255",
      "223.130.10.0 - 223.130.11.255",
      "223.27.104.0 - 223.27.111.255",
      "23.155.184.0 - 23.155.184.255",
      "23.173.8.0 - 23.173.8.255",
      "23.193.188.0 - 23.193.191.255",
      "23.194.136.0 - 23.194.137.255",
      "23.195.112.0 - 23.195.115.255",
      "23.202.89.0 - 23.202.89.255",
      "23.32.74.0 - 23.32.74.255",
      "23.33.120.0 - 23.33.123.255",
      "23.40.76.0 - 23.40.79.255",
      "23.50.12.0 - 23.50.15.255",
      "23.53.208.0 - 23.53.215.255",
      "23.60.70.0 - 23.60.71.255",
      "23.79.96.0 - 23.79.127.255",
      "23.91.108.0 - 23.91.108.255",
      "27.0.12.0 - 27.0.15.255",
      "27.0.240.0 - 27.0.243.255",
      "27.118.16.0 - 27.118.31.255",
      "27.124.49.0 - 27.124.49.255",
      "27.124.55.0 - 27.124.55.255",
      "27.2.0.0 - 27.3.255.255",
      "27.64.0.0 - 27.79.255.255",
      "34.103.14.0 - 34.103.15.255",
      "34.98.238.0 - 34.98.239.255",
      "34.98.254.0 - 34.98.255.255",
      "36.50.132.0 - 36.50.135.255",
      "36.50.16.0 - 36.50.17.255",
      "36.50.174.0 - 36.50.178.255",
      "36.50.208.0 - 36.50.209.255",
      "36.50.232.0 - 36.50.237.255",
      "36.50.24.0 - 36.50.29.255",
      "36.50.52.0 - 36.50.55.255",
      "36.50.60.0 - 36.50.63.255",
      "38.179.128.0 - 38.179.191.255",
      "38.179.14.0 - 38.179.14.255",
      "38.179.43.0 - 38.179.43.255",
      "38.182.106.0 - 38.182.107.255",
      "38.182.30.0 - 38.182.30.255",
      "38.49.211.0 - 38.49.211.255",
      "38.54.115.0 - 38.54.115.255",
      "38.54.14.0 - 38.54.15.255",
      "38.54.30.0 - 38.54.31.255",
      "38.54.90.0 - 38.54.91.255",
      "38.60.219.0 - 38.60.219.255",
      "38.60.225.0 - 38.60.225.255",
      "38.60.228.0 - 38.60.230.255",
      "38.60.252.0 - 38.60.253.255",
      "42.1.64.0 - 42.1.127.255",
      "42.112.0.0 - 42.119.255.255",
      "42.96.0.0 - 42.96.63.255",
      "43.132.68.0 - 43.132.68.255",
      "43.132.92.0 - 43.132.92.255",
      "43.152.133.0 - 43.152.133.255",
      "43.152.150.0 - 43.152.150.255",
      "43.152.153.0 - 43.152.153.255",
      "43.152.172.0 - 43.152.174.255",
      "43.152.4.0 - 43.152.4.255",
      "43.152.9.0 - 43.152.9.255",
      "43.159.67.0 - 43.159.67.255",
      "43.159.86.0 - 43.159.86.255",
      "43.175.173.0 - 43.175.173.255",
      "43.175.88.0 - 43.175.91.255",
      "43.239.148.0 - 43.239.151.255",
      "43.239.184.0 - 43.239.191.255",
      "43.239.220.0 - 43.239.227.255",
      "43.254.166.0 - 43.254.166.255",
      "45.10.215.0 - 45.10.215.255",
      "45.117.156.0 - 45.117.179.255",
      "45.117.76.0 - 45.117.83.255",
      "45.118.136.0 - 45.118.151.255",
      "45.119.108.0 - 45.119.111.255",
      "45.119.212.0 - 45.119.219.255",
      "45.119.240.0 - 45.119.243.255",
      "45.119.76.0 - 45.119.87.255",
      "45.120.224.0 - 45.120.231.255",
      "45.121.152.0 - 45.121.155.255",
      "45.121.160.0 - 45.121.163.255",
      "45.121.24.0 - 45.121.27.255",
      "45.122.220.0 - 45.122.223.255",
      "45.122.232.0 - 45.122.255.255",
      "45.123.96.0 - 45.123.99.255",
      "45.124.255.0 - 45.124.255.255",
      "45.124.84.0 - 45.124.95.255",
      "45.125.200.0 - 45.125.211.255",
      "45.125.236.0 - 45.125.239.255",
      "45.126.92.0 - 45.126.99.255",
      "45.127.252.0 - 45.127.255.255",
      "45.128.13.0 - 45.128.13.255",
      "45.128.79.0 - 45.128.79.255",
      "45.129.131.0 - 45.129.131.255",
      "45.131.51.0 - 45.131.51.255",
      "45.140.65.0 - 45.140.65.255",
      "45.148.140.0 - 45.148.140.255",
      "45.195.156.0 - 45.195.159.255",
      "45.251.112.0 - 45.251.115.255",
      "45.252.240.0 - 45.252.251.255",
      "45.254.32.0 - 45.254.35.255",
      "45.80.186.0 - 45.80.187.255",
      "45.80.221.0 - 45.80.221.255",
      "45.80.223.0 - 45.80.223.255",
      "45.91.179.0 - 45.91.179.255",
      "45.95.128.0 - 45.95.128.255",
      "46.149.135.0 - 46.149.136.255",
      "47.235.20.0 - 47.235.20.255",
      "47.235.5.0 - 47.235.5.255",
      "47.235.8.0 - 47.235.8.255",
      "47.246.66.0 - 47.246.66.255",
      "47.246.7.0 - 47.246.7.255",
      "47.89.102.0 - 47.89.102.255",
      "47.89.121.0 - 47.89.121.255",
      "49.156.52.0 - 49.156.55.255",
      "49.213.64.0 - 49.213.127.255",
      "49.236.208.0 - 49.236.211.255",
      "49.246.128.0 - 49.246.223.255",
      "57.72.68.0 - 57.72.71.255",
      "57.93.80.0 - 57.93.95.255",
      "58.186.0.0 - 58.187.255.255",
      "58.84.0.0 - 58.84.3.255",
      "59.153.212.0 - 59.153.255.255",
      "61.11.224.0 - 61.11.255.255",
      "61.14.232.0 - 61.14.239.255",
      "61.28.224.0 - 61.28.255.255",
      "62.204.38.0 - 62.204.38.255",
      "63.222.104.0 - 63.222.104.255",
      "68.234.45.0 - 68.234.45.255",
      "69.85.92.0 - 69.85.92.255",
      "77.91.96.0 - 77.91.96.255",
      "8.209.44.0 - 8.209.47.255",
      "8.212.192.0 - 8.212.255.255",
      "80.246.230.0 - 80.246.230.255",
      "80.89.2.0 - 80.89.2.255",
      "82.117.82.0 - 82.117.82.255",
      "82.117.90.0 - 82.117.90.255",
      "82.152.176.0 - 82.152.177.255",
      "82.153.245.0 - 82.153.245.255",
      "83.97.112.0 - 83.97.112.255",
      "87.119.12.0 - 87.119.12.255",
      "91.210.144.0 - 91.210.144.255",
      "91.242.239.0 - 91.242.239.255",
      "92.118.237.0 - 92.118.239.255",
      "93.190.246.0 - 93.190.246.255",
      "93.95.113.0 - 93.95.113.255",
      "95.214.248.0 - 95.214.251.255",
      "98.98.104.0 - 98.98.105.255",
      "98.98.38.0 - 98.98.39.255"
  ];

    return this.isIpInRanges(ip, vnIpRanges);
  }

  private ipToNumber(ip: string): number {
    return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
  }

  private isInRange(ip: string, range: string): boolean {
    const [startIp, endIp] = range.split(' - ');
    const ipNum = this.ipToNumber(ip);
    const startIpNum = this.ipToNumber(startIp);
    const endIpNum = this.ipToNumber(endIp);
    return ipNum >= startIpNum && ipNum <= endIpNum;
  }

  private isIpInRanges(ip: string, ranges: string[]): boolean {
    for (const range of ranges) {
      if (this.isInRange(ip, range)) {
        return true;
      }
    }
    return false;
  }
}
import { permissionCode } from "src/app/shared";
import { FeaturePermissionState } from "../permission.models";

export type ProjectSettingPermissionState = {
  [key in ProjectSettingCategory]?: FeaturePermissionState;
}

export enum ProjectSettingCategory {
  SWIMLANE = 'swimlane',
  GENERAL = 'general',
  PROJECT_MEMBERS = 'projectMembers',
  BOARD_SETTINGS = 'boardSettings',
  TASK_TYPE = 'taskType',
  PRIORITY = 'priority',
  STORYLINE = 'storyline',
  LABEL = 'label',
  HISTORY = 'history',
  GIT = 'git',
  SLACK_INTEGRATION = 'slackIntegration',
}

export const projectSettingPermissionState: ProjectSettingPermissionState = {
  [ProjectSettingCategory.SWIMLANE]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_EDIT],
  },
  [ProjectSettingCategory.GENERAL]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT],
  },
  [ProjectSettingCategory.PROJECT_MEMBERS]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT],
    viewAnother: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW_OTHER],
    editAnother: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT_OTHER],
  },
  [ProjectSettingCategory.BOARD_SETTINGS]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT],
  },
  [ProjectSettingCategory.TASK_TYPE]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT],
  },
  [ProjectSettingCategory.PRIORITY]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT],
  },
  [ProjectSettingCategory.STORYLINE]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT],
  },
  [ProjectSettingCategory.LABEL]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT],
  },
  [ProjectSettingCategory.HISTORY]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT],
  },
  [ProjectSettingCategory.GIT]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GIT_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GIT_EDIT],
  },
  [ProjectSettingCategory.SLACK_INTEGRATION]: {
    view: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_VIEW],
    edit: [permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_EDIT],
  }
}

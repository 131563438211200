import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewContainerRef, SimpleChanges } from '@angular/core';
import { cloneDeep, first } from 'lodash';
import { Subject, fromEvent, skip, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '../_components';
import { TimeObject } from '../_components/time-picker/models/time-picker.model';

@Directive({
  selector: '[ngForScrollCustomize]'
})
export class NgForScrollCustomizeDirective extends AbstractComponent {
  @Input() items: TimeObject[] = [];
  @Input() selectedTime = null;
  @Input() hasMeridiemTime = false;
  @Output() timeChanged = new EventEmitter<TimeObject>();

  defaultItems: TimeObject[] = [];
  limitHeightScrollUp = 100;
  limitHeightScrollDown = 150;
  timeClassName = 'time-column';
  valueChanged = new Subject<any>();

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    super();
  }

  init() {
    this.onScrollParentElement();
    this.emitValueChanged();
  }

  onScrollParentElement() {
    const parent = this.renderer.parentNode(this.elementRef.nativeElement);
    fromEvent(parent, 'scroll')
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        skip(1),
        tap(() => this.createViewElement()),
        debounceTime(100)
      )
      .subscribe(() => {
        this.getValue();
      });
  }

  createViewElement() {
    const parent = this.renderer.parentNode(this.elementRef.nativeElement);
    const shouldCreateAtBeginning = parent.scrollTop <= this.limitHeightScrollUp;
    const shouldCreateAtEnd = parent.scrollTop + parent.clientHeight + this.limitHeightScrollDown > parent.scrollHeight;

    const createEmbeddedView = (items: TimeObject[]) => {
      items?.forEach((item) => {
        this.view.createEmbeddedView(this.template, { $implicit: item }, shouldCreateAtBeginning ? 0 : undefined);
      })
    };

    if (shouldCreateAtBeginning) {
      const _defaultItems = cloneDeep(this.defaultItems);
      createEmbeddedView(_defaultItems.reverse());
      return;
    }

    if (shouldCreateAtEnd) {
      createEmbeddedView(this.defaultItems);
      return;
    }
  }

  emitValueChanged() {
    this.valueChanged
    .pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    )
    .subscribe((closestElement) => {
      const activeElement = this.findParentWithClass(
        closestElement,
        this.timeClassName
      ).querySelector('.active');

      const activeElementTop =  Math.floor(activeElement.getBoundingClientRect().top);
      const closestElementTop = closestElement.getBoundingClientRect().top;
      while (closestElementTop !== activeElementTop) {
        closestElement.scrollIntoView({ behavior: 'smooth' });
        break;
      }
      this.timeChanged.emit({
        value: closestElement.textContent,
        type: first(this.items).type
      });
    });
  }

  changes(changes: SimpleChanges) {
    const { items } = changes;
    if (items?.previousValue !== items?.currentValue) {
      this.setItems(items.currentValue);
      // clear view and update new view
      this.view.clear();
      this.items.forEach((item, index) => {
        this.view.createEmbeddedView(this.template, { $implicit: item }, index);
      });
    }

  }

  getValue() {
    const parentNode = this.renderer.parentNode(this.elementRef.nativeElement);
    const allChildren = parentNode.querySelectorAll('.item');
    let closestElement = null;
    let minDistance = Number.MAX_VALUE;
    allChildren?.forEach((child) => {
      const distance = Math.abs(
        child.getBoundingClientRect().top -
          parentNode.getBoundingClientRect().top
      );
      if (distance < minDistance) {
        minDistance = distance;
        closestElement = child;
      }
    });
    this.valueChanged.next(closestElement);
  }

  setItems(items: TimeObject[]) {
    if (this.hasMeridiemTime) {
      return;
    }
    this.defaultItems = items;
    this.items = [...items, ...items, ...items];
  }

  findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
    while (element) {
      if (element.classList?.contains(className)) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() text = '';
  @Input() placeholder = 'Search';
  @Input() debounce = true;
  @Output() search = new EventEmitter<string>();
  @Output() keywordChange = new EventEmitter<string>();
  private inputSub!: Subscription;

  debounceTime = 0;

  constructor() { }

  ngOnInit(): void {
    this.setupDebounce();

  }

  onSearch() {
    // this.search.emit(this.text);
  }

  onInput(event) {
    // bug telex MAC: use event.srcElement
    this.keywordChange.emit(event.srcElement?.value || '');
  }

  setupDebounce() {
    if (this.debounce) {
      this.debounceTime = 500;
    }
    this.inputSub = this.keywordChange
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((keyword) => {
        this.search.emit(keyword);
      });
  }

  ngOnDestroy() {
    this.inputSub?.unsubscribe();
  }
}

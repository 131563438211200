import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading } from 'src/app/core/store/app';
import { FADE_FAST } from '../../_utils/animate';
import { PROCESSING_MESSAGE } from 'src/app/core/constants/message';
import { LoadingState } from 'src/app/core/models/loading';
import { debounceTime, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'x-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [FADE_FAST]
})
export class LoadingComponent implements OnInit {
  visible = false;
  message = null;
  level: 'info' | 'error' | 'normal' = 'normal';
  timeout = null;
  showMessage = window.location.hostname !== environment.mainDomain;
  forever = false;

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store
      .select(selectLoading)
      .pipe(
        tap((res) => {
          this.setMessage(res);
        }),
        debounceTime(500)
      )
      .subscribe((res) => {
        if (res) {
          this.setMessage(res, res.visible);

          setTimeout(() => {
            if (!res.visible) {
              this.message = PROCESSING_MESSAGE;
            }
          }, 1000);
        }
      });
  }

  setMessage(res: LoadingState, visible?: boolean) {
    this.message = res?.text || this.message || PROCESSING_MESSAGE;
    this.level = res?.level || 'normal';
    this.visible = visible ?? true;

    if (res?.forever) {
      this.forever = res?.forever
    }

    this.cdr.detectChanges();
  }
}

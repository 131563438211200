import { Component, Input, TemplateRef } from '@angular/core';
import { BaseOverlayComponent } from '../../../services/overlay/abtract-overlay';
import { DropdownService } from '../dropdown.service';

@Component({
  selector: 'x-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent extends BaseOverlayComponent<
  TemplateRef<any>,
  DropdownService
> {}

import { Status } from "../_utils";

export interface Group {
  id?: number;
  name: string;
  description: string;
  status: string;
  totalMember?: number;
  deleted?: boolean;
  defaultFlg?: boolean;
}

export interface GroupUpsert {
  id?: number;
  name: string;
  description: string;
  status: string;
}

export interface GroupRequest {
  keyword?: string;
  statuses?: string[];
  locationId?: number;
  excludeProjectId?: number;
  excludeWorkspaceId?: number;
  excludeHiringBoardId?: number;
  payrollId?: number;
}

import { UtilService } from "src/app/core";
import { fileFormatToPdfs } from "src/app/shared";
import { EditorModule } from "src/app/shared/_components/x-editor/_models";
import { environment } from "src/environments/environment";

export enum UploadParams {
  TASK = 'attachments',
  OKR = 'attachments',
  OKR_KEY_RESULT = 'attachments',
  MARKDOWN = 'multipartFiles',
  BLOG = 'multipartFiles',
}

export class FroalaUpload {
  static getPreviewUrl(file: any, module: EditorModule) {
    switch (module) {
      case EditorModule.MARKDOWN:
        return { id: file.id, name: file.name, url: file?.previewUrl || file?.url };
      default:
        return { id: file.fileId, name: file.name, url: file?.url };
    }
  }

  static getUploadUrl(id: number, module: EditorModule) {
    switch (module) {
      case EditorModule.BLOG:
        return `${environment.apiUrl}/files/blogs/attachments`;
      case EditorModule.MARKDOWN:
        return `${environment.apiUrl}/files/${id}/upload`;
      case EditorModule.OKR:
        return `${environment.apiUrl}/okr/${id}/add-attachments`;
      case EditorModule.OKR_KEY_RESULT:
        return `${environment.apiUrl}/okr/key-result/${id}/add-attachments`;
      default:
        return `${environment.apiUrl}/tasks/${id}/attachments`;
    }
  }

  static getUploadParams(module: EditorModule) {
    switch (module) {
      case EditorModule.BLOG:
        return UploadParams.BLOG;
      case EditorModule.MARKDOWN:
        return UploadParams.MARKDOWN;
      case EditorModule.OKR:
        return UploadParams.OKR;
      case EditorModule.OKR_KEY_RESULT:
        return UploadParams.OKR_KEY_RESULT;
      default:
        return UploadParams.TASK;
    }
  }

  static replaceBlobUrl(htmlString: string, blobUrl: string, url: string, fileId: number) {
    if (fileFormatToPdfs.some(e => e === UtilService.getUrlExtension(url))) {
      htmlString = htmlString.replaceAll(`origin="${blobUrl}"`, `id="${fileId}"`);
    }
    return htmlString.replaceAll(blobUrl, url);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as queryString from 'query-string';
import { CookieService } from 'src/app/core/_services/cookie.service';
import { Logger } from 'src/app/core/models/logger';
import {
  AccountAuthResponse,
  AccountResponse,
  AuthRequest, AuthResponse, ConfirmSignupRequest, ConfirmSignupResponse, DomainToken, LoggedUserRole,
  ProviderAuthRequest, ResetPassword, SignUpRequest, AccountCompanyAuthRequest, SumObjectResponse,
  SystemAdminResponse, TenantIdentity, User, ExtendingTrial, CompanyAuthResponse, UserToken
} from 'src/app/shared/_models';
import {
  LEADER_PROJECTS_KEY, TIMESHEET_REPORT_FILTER_KEY, userRoles, USER_ACCESS_TOKEN_KEY, USER_DOMAIN_ACCESS_TOKEN,
  USER_LAST_URL,
  USER_PROFILE_KEY, USER_ROLE_PERMISSIONS_KEY, ROLE_PERMISSION_CACHE_KEY, USER_PERMISSIONS_KEY, LOCALHOST,
} from 'src/app/shared/_utils/consts';
import { IntegrationType, UserStatusKey, UserTokenType } from 'src/app/shared/_utils/enums';
import { I18nService } from 'src/app/site-management/_services';
import { UserSettingRequest } from 'src/app/site-management/_store/user-setting/user-setting.model';
import { UserSettingService } from 'src/app/site-management/_store/user-setting/user-setting.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedUser: User;
  isSystemAdmin: boolean = false;
  accessToken: string = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private i18nService: I18nService,
    private userSettingService: UserSettingService,
    private cookieService: CookieService,
  ) { }

  setLastUrl(url: string) {
    if (['/w', '/auth', '/home'].some(path => url.startsWith(path))) {
      return;
    }
    localStorage.setItem(USER_LAST_URL, url);
  }

  getLastUrl() {
    return localStorage.getItem(USER_LAST_URL);
  }

  clearLastUrl() {
    localStorage.removeItem(USER_LAST_URL);
  }

  clearAccessToken() {
    window.localStorage.removeItem(USER_ACCESS_TOKEN_KEY);
    this.accessToken = null;
  }

  setAccessToken(token: string) {
    const _token = token ?? '';
    window.localStorage.setItem(USER_ACCESS_TOKEN_KEY, _token);
    this.accessToken = _token;
  }

  getAccessToken(): string {
    if (!this.accessToken) {
      for (let index = 0; index < 5; index++) {
        this.accessToken = window.localStorage.getItem(USER_ACCESS_TOKEN_KEY) || null;
        if (this.accessToken) {
          if (index > 0) {
            const payload = {
              key: `ACCESS-TOKEN-ERROR`,
              value: JSON.stringify({index, accessToken: this.accessToken, time: moment().format()}),
            }
            this.saveUserSettingForApiTest(payload);
          }
          break;
        }
      }
    }
    return this.accessToken;
  }

  saveUserSettingForApiTest(payload: UserSettingRequest) {
    this.userSettingService.update(payload).subscribe();
  }

  setDomainToken(data: DomainToken) {
    localStorage.setItem(USER_DOMAIN_ACCESS_TOKEN, JSON.stringify(data));
  }

  getDomainToken(): DomainToken {
    return JSON.parse(localStorage.getItem(USER_DOMAIN_ACCESS_TOKEN) || null);
  }

  removeDomainToken() {
    localStorage.removeItem(USER_DOMAIN_ACCESS_TOKEN);
  }

  setLoggedUser(user: User) {
    this.loggedUser = user;
    localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));
  }

  getLoggedUser(): User {
    return this.loggedUser || JSON.parse(localStorage.getItem(USER_PROFILE_KEY) || null);
  }

  setSystemAdminFlg(data: SystemAdminResponse) {
    if (data) {
      this.isSystemAdmin = data?.systemAdmin;
    }
  }

  getSystemAdminFlg() {
    return this.isSystemAdmin;
  }

  getLoggedUserRole(): LoggedUserRole {
    const loggedUser = this.getLoggedUser();
    const roles = loggedUser.userRoles.map(role => role.roleCode);

    const isSuperAdmin = roles.includes(userRoles.superAdmin) || false;
    const isApprover = roles.includes(userRoles.approver);
    const isEmployee = roles.includes(userRoles.user);

    return { isSuperAdmin, isApprover, isEmployee };
  }

  isAuthenticated() {
    return this.getAccessToken() ? true : false;
  }

  isActiveUser() {
    return this.loggedUser?.status === UserStatusKey.ACTIVE;
  }

  isXpercUser() {
    return this.loggedUser?.email?.endsWith(`@xperc.com`);
  }

  signIn(payload: AuthRequest): Observable<AccountAuthResponse> {
    return this.http.post<AccountAuthResponse>(`${environment.apiUrl}/auth/login`, payload).pipe();
  }

  signInWithProvider(payload: ProviderAuthRequest): Observable<AccountAuthResponse> {
    return this.http.post<AccountAuthResponse>(`${environment.apiUrl}/auth/login`, payload).pipe();
  }

  signOut(): void {
    // localStorage.clear();
    this.loggedUser = null;
    // localStorage.removeItem(USER_ACCESS_TOKEN_KEY);
    this.clearAccessToken();
    localStorage.removeItem(USER_DOMAIN_ACCESS_TOKEN);
    localStorage.removeItem(USER_PROFILE_KEY);
    localStorage.removeItem(USER_ROLE_PERMISSIONS_KEY);
    localStorage.removeItem(TIMESHEET_REPORT_FILTER_KEY);
    localStorage.removeItem(LEADER_PROJECTS_KEY);
    localStorage.removeItem(ROLE_PERMISSION_CACHE_KEY);
    localStorage.removeItem(USER_PERMISSIONS_KEY);
  }

  // Company sign up
  signUp(payload: SignUpRequest): Observable<any> {
    const headers = {
      'accept-language': this.i18nService.getCurrentLang()
    };
    return this.http.post<any>(`${environment.apiUrl}/auth/signup`, payload, { headers }).pipe();
  }

  confirmSignup(payload: ConfirmSignupRequest): Observable<AccountAuthResponse> {
    return this.http.post<AccountAuthResponse>(`${environment.apiUrl}/auth/confirm-signup`, payload).pipe();
  }

  verifyExistDomain(text: string) {
    const domain = `${text}.${environment.mainDomain}`;
    return this.http.get<{exist: boolean}>(`${environment.apiUrl}/auth/verify-exist-domain?domain=${domain}`);
  }

  // Integration()
  getIntegrationOauthUrl(payload: { type: IntegrationType }): Observable<any> {
    const url = `${environment.apiUrl}/auth/integrate-oauth-url`;
    return this.http.post<any>(url, payload).pipe();
  }

  getIntegrationCallbackUrl(payload: { state: string, code: string, type: IntegrationType }): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/integrate-callback`, payload).pipe();
  }

  forgot(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/forgot-password`, email);
  }

  reset(payload: ResetPassword) {
    return this.http.post<any>(`${environment.apiUrl}/auth/reset-password`, payload);
  }

  active(payload: ResetPassword) {
    return this.http.post<AccountAuthResponse>(`${environment.apiUrl}/auth/activate-user`, payload);
  }

  activateExistAccount(payload: ResetPassword) {
    return this.http.post<void>(`${environment.apiUrl}/auth/activate-exist-account`, payload);
  }

  getTotalCompanies(payload: AccountCompanyAuthRequest) {
    const queryParams = queryString.stringify(payload);
    return this.http.get<SumObjectResponse>(`${environment.apiUrl}/auth/accounts/sum-companies?${queryParams}`);
  }

  extendTrial(payload: TenantIdentity) {
    return this.http
      .post(`${environment.apiUrl}/auth/extending-trial`, payload)
  }

  requiredExtendTrial(payload: ExtendingTrial) {
    return this.http.post(`${environment.apiUrl}/auth/required-extending-trial`, payload);
  }

  verifyExistAccount(params: TenantIdentity) {
    const queryParams = queryString.stringify(params);
    return this.http.get<AccountResponse>(`${environment.apiUrl}/auth/verify-exist-account?${queryParams}`);
  }

  verifyCompanyDomain(payload: AccountCompanyAuthRequest) {
    const queryParams = queryString.stringify(payload);
    return this.http.get<CompanyAuthResponse[]>(`${environment.apiUrl}/auth/accounts/company-domains?${queryParams}`);
  }

  navigateToSignInToken(authResponse: AuthResponse) {
    const url = `/auth/sign-in-with-token?domain=${authResponse.domain}`;
    this.setCookieAccessToken(authResponse.domain, authResponse.accessToken);
    this.router.navigateByUrl(url);
  }

  navigateToDomain(authResponse: AuthResponse, lastUrl: string = '') {
    let redirectUri = `https://${authResponse.domain}/auth/sign-in-with-token?lastUrl=${lastUrl}`;
    const cookieDomain = environment.env === 'local' ? LOCALHOST : authResponse.domain;
    this.setCookieAccessToken(cookieDomain, authResponse.accessToken);

    if (environment.env === 'local') {
      redirectUri = redirectUri.replace(`https://${authResponse.domain}`, `http://${LOCALHOST}`);
    }

    window.open(redirectUri, '_self');
  }

  navigateToSelectCompany() {
    const url = '/auth/select-company';
    this.router.navigateByUrl(url);
  }

  getAccessTokenKey(domain?: string): string {
    const email = this.getCurrentUserEmail();

    const accessToken: UserToken = {
      type: UserTokenType.ACCESS,
      env: environment.env,
      email,
      domain
    };

    return encodeURIComponent(queryString.stringify(accessToken));
  }

  getCookieAccessToken(domain: string) {
    const key = this.getAccessTokenKey(domain);
    return this.cookieService.getCookie(key);
  }

  setCookieAccessToken(domain: string, token: string) {
    const key = this.getAccessTokenKey(domain);
    this.cookieService.setCookie(key, token);
  }

  getCurrentUserEmail() {
    return this.cookieService.getCookie(`${environment.env}-currentEmail`);
  }

  setCurrentUserEmail(email: string) {
    this.cookieService.setCookie(`${environment.env}-currentEmail`, email);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pre-loader-assets',
  template: `
    <img class="disappear" src="/assets/img/loading/logo-fast.gif" />
  `,
  styles: [
    `
      .disappear {
        visibility: hidden;
        height: 0;
        width: 0;
        position: fixed;
      }
    `
  ]
})
export class PreloaderAssets {}

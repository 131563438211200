export enum LanguageCode {
  vi = 'vi',
  en = 'en',
}

export enum LanguageCodeUI {
  vi = 'VN',
  en = 'EN',
}

export enum LanguageKey {
  vi = 'VIETNAMESE',
  en = 'ENGLISH',
}

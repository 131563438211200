<div class="badge-wrapper" *ngIf="labels?.length > 0" [title]="getLabelsText()" [tooltip]="getLabelsText()" [isDisabled]="!displayTooltip">
  <ng-container *ngIf="!editable">
    <span class="badge badge-main badge-pill {{cssClass}} mr-1"
      *ngFor="let label of labels | slice : 0 : displayLabels"
      [ngStyle]="badgeStyle">
      {{label}}
    </span>

    <span class="badge badge-pill badge-suffix {{ suffixCssClass }}"
          *ngIf="suffix && labels?.length > displayLabels">+{{labels.length - displayLabels}}</span>
  </ng-container>

  <ng-container *ngIf="editable">
    <input
      stopEvent
      [type]="type" class="badge badge-main badge-input badge-pill {{cssClass}}"
      [ngStyle]="badgeStyle"
      [formControl]="formControl"
      (focusin)="onFocus()"
      (focusout)="onInputChange()"
      (keyup.enter)="onInputChange()">
  </ng-container>
</div>

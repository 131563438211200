import { Pipe, PipeTransform } from '@angular/core';
import { Comment, CommentState } from 'src/app/shared';
import { EditorModule } from 'src/app/shared/_components/x-editor/_models';

@Pipe({
  name: 'hasPreviousComment'
})

export class HasPreviousCommentPipe implements PipeTransform {
  transform(commentState: CommentState, commentId: number) {
    return commentState?.range?.startIndex > 0  && commentId
  }
}

@Pipe({
  name: 'remainCommentCount'
})

export class RemainCommentCountPipe implements PipeTransform {
  transform(commentState: CommentState) {
    const { range, pageable } = commentState;
    return range.startIndex * pageable.size || 0;
  }
}

@Pipe({
  name: 'nextReplyCommentCount'
})

export class NextReplyCommentCountPipe implements PipeTransform {
  transform(replyCommentState: CommentState) {
    const { range, pageable } = replyCommentState;
    return range.endIndex * pageable.size || 0;
  }
}


@Pipe({
  name: 'hasNextReplyComment'
})
export class HasNextReplyCommentPipe implements PipeTransform {
  transform(replyCommentState: CommentState, replyCommentId: number, parentComment: Comment, commentId: number) {
    const { pageable, position } = replyCommentState || {};
    return (
      replyCommentId && parentComment.id === commentId &&
      replyCommentState?.range?.endIndex <
        Math.ceil(position.totalElements / pageable.size)
    );
  }
}

@Pipe({
  name: 'previousReplyCommentCount'
})

export class PreviousReplyCommentCountCountPipe implements PipeTransform {
  transform(replyCommentState: CommentState) {
    const { range, pageable } = replyCommentState;
    return range.startIndex * pageable.size || 0;
  }
}

@Pipe({
  name: 'canReplyComment'
})

export class CanReplyCommentPipe implements PipeTransform {
  transform(module: EditorModule) {
    return [EditorModule.TASK].includes(module);
  }
}

export const COMMENT_PIPE = [
  HasPreviousCommentPipe,
  RemainCommentCountPipe,
  HasNextReplyCommentPipe,
  NextReplyCommentCountPipe,
  PreviousReplyCommentCountCountPipe,
  CanReplyCommentPipe,
];

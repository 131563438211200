<div class="user-report-history-wrapper" *ngIf="permissionChecker?.canView">
  <div class="row">
    <!-- <div class="col-12"> -->
    <!-- <div class="radio-buttons">
        <button *ngFor="let filter of filters" class="btn btn-sm btn-outline-secondary"
          [ngClass]="{'active': filter.value === selectedFilter}" (click)="changeFilter(filter.value)">
          {{filter.label}}
        </button>
      </div> -->
    <!-- </div> -->

    <div class="col-12 filter-list">
      <div class="filter-item">
        <div class="filter-label"><app-i18n [key]="'Period'"></app-i18n>
        </div>
        <ng-select placeholder="Date" [items]="filters" bindLabel="label" bindValue="value" [clearable]="false"
          [searchable]="false" [(ngModel)]="selectedFilter" (change)="changeFilter()">
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <app-i18n [key]="item['label']"></app-i18n>
          </ng-template>
        </ng-select>
      </div>
      <div class="filter-item date-input-group" *ngIf="selectedFilter === filterType.custom">
        <div class="item-group">
          <div class="filter-label"><app-i18n [key]="'From'"></app-i18n></div>
          <app-datepicker [value]="searchRequest.fromDate" [placeholder]="'From Date' | translate"
            (dpValueChange)="updateSearchDateParams($event)"></app-datepicker>
        </div>
        <div class="item-group">
          <div class="filter-label"><app-i18n [key]="'To'"></app-i18n></div>
          <app-datepicker [value]="searchRequest.toDate" [placeholder]="'To Date' | translate"
            (dpValueChange)="updateSearchDateParams($event, false)"></app-datepicker>
        </div>
      </div>
    </div>

    <ng-container *ngIf="chart">
      <div class="col-12 mt-3 chart-wrapper" *ngIf="chart.type === chartType.lineChart">
        <ng-container *ngIf="chart.data?.length > 0">
          <div class="status-list">
            <div class="status-block" *ngFor="let i of statusData" [tooltip]="i.tooltip" [placement]="'bottom'"
              containerClass="light-tooltip">
              <div class="header">
                <span class="value"> {{i.count}} ({{i.percent}})</span>
                <span class="diff" [ngClass]="i.isIncrease? 'increase' : 'decrease'">
                  <img class="increase-img" src="../../../../assets/icons/increase.png" alt="" *ngIf="i.isIncrease">
                  <img class="decrease-img" src="../../../../assets/icons/decrease.png" alt="" *ngIf="!i.isIncrease">
                  <span><app-i18n [key]="i.diff"></app-i18n></span>
                </span>
              </div>

              <div class="link">
                <app-i18n [key]="i.label"></app-i18n>
              </div>
            </div>
          </div>

          <div class="text-right">
            <div class="switch-wrapper">
              <span class="switch-button" [ngClass]="{'active': isNumdata}" (click)="switchLineChart(true)"><app-i18n [key]="'Num'"></app-i18n></span>
              <span class="switch-button" [ngClass]="{'active': !isNumdata}" (click)="switchLineChart(false)">%</span>
            </div>
          </div>

          <div class="line-chart-wrapper">
            <google-chart #googleChart [type]="chart.type" [data]="chart.data" [columns]="chart.columns"
              [options]="chart.options">
            </google-chart>

            <div class="chart-legend">
              <div class="status-detail" *ngFor="let i of statusData">
                <div class="point" [ngStyle]="{'background-color': i.color}"></div>
                <div class="info">
                  <span class="label"><app-i18n [key]="i.label"></app-i18n></span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="chart.data?.length <= 0">
          <app-i18n [key]="'No data to display'"></app-i18n>
        </div>
      </div>

      <div class="col-12 mt-3" *ngIf="chart.type === chartType.pieChart">
        <div class="pie-chart-wrapper" *ngIf="customData?.reportTotal > 0">
          <div class="chart-content">
            <div class="total-report">{{customData?.reportTotal}}</div>
            <google-chart #googleChart [type]="chart.type" [data]="chart.data" [columns]="chart.columns"
              [options]="chart.options">
            </google-chart>
          </div>

          <div class="chart-legend">
            <div class="month">{{customData?.yearMonth}}</div>
            <div class="status-detail" *ngFor="let i of statusData">
              <div class="point" [ngStyle]="{'background-color': i.color}"></div>
              <div class="info">
                <span class="label"><app-i18n [key]="i.label"></app-i18n> - </span>
                <span class="value"> {{i.count}} ({{i.percent}})</span>
                <span class="diff" [ngClass]="i.isIncrease? 'increase' : 'decrease'">
                  <img class="increase-img" src="../../../../assets/icons/increase.png" alt="" *ngIf="i.isIncrease">
                  <img class="decrease-img" src="../../../../assets/icons/decrease.png" alt="" *ngIf="!i.isIncrease">
                  <span><app-i18n [key]="i.diff"></app-i18n></span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="customData?.reportTotal <= 0">
          <app-i18n [key]="'No data to display'"></app-i18n>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-no-permission *ngIf="!permissionChecker?.canView"></app-no-permission>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountCompanyResponse, User } from '../..';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MultiCompanyService } from 'src/app/site-management/company/_services/multi-company.service';
import { Store } from '@ngrx/store';
import { AuthState, selectAccountCompanies } from 'src/app/core/store/auth';
import { cloneDeep } from 'lodash';
import { AccountsService } from 'src/app/authentication/_services/accounts.service';

@Component({
  selector: 'app-switch-company',
  templateUrl: './switch-company.component.html',
  styleUrls: ['./switch-company.component.scss']
})
export class SwitchCompanyComponent implements OnInit, OnDestroy {
  @Input() isMobileWrapper = false;
  @Input() isLandingPageScreen = false;

  companyLists: AccountCompanyResponse[] = [];
  loggedUser!: User;

  destroyed$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private accountService: AccountsService,
    private multiCompanyService: MultiCompanyService,
    private store: Store<AuthState>
  ) {}
// remove abstract component because permission is store sub domain due to error
  ngOnInit() {
    this.getLoggedUser();
    this.store.select(selectAccountCompanies)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(accounts => {
        if (accounts?.length) {
          const sortedLists = cloneDeep(accounts).sort((a, b) => a.id - b.id);
          this.companyLists = sortedLists;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getLoggedUser() {
    this.loggedUser = this.isLandingPageScreen
      ? this.accountService.getMainAccount()?.user
      : this.authService.getLoggedUser();
  }

  onSwitchCompany(event: Event, row: AccountCompanyResponse) {
    const currentCompanyId = this.loggedUser.company.id;
    if (currentCompanyId === row.company.id && !this.isLandingPageScreen) {
      return;
    }

    event.stopPropagation();
    this.multiCompanyService.signInCompany(row, true);
  }

  onSetDefault(event: Event, row: AccountCompanyResponse) {
    event.stopPropagation();
    this.multiCompanyService.setDefaultCompany(row, () => {
      this.companyLists.forEach(el => {
        el.defaultFlg = false;
      });
    });
  }
}

import { createFeature, createReducer, on } from '@ngrx/store';
import { UserState } from './user.models';
import { featureKey } from './user.selectors';
import { UserActions } from './user.actions';

const initialState: UserState = {
  users: [],
};

const userReducer = createReducer(
  initialState,
  on(UserActions.setUsers, (state, { users }) => ({ ...state, users }))
);

export const userFeature = createFeature({
  name: featureKey,
  reducer: userReducer
});

<app-search-overlay *ngIf="config" [config]="config" [canEdit]="canEdit" (open)="onOpen()" (change)="onChange($event); change.emit($event)">
</app-search-overlay>

<ng-template #selectedTemplate>
  <app-badge *ngIf="config.selecteds.length > 0"
    [labels]="config.selecteds | getOverlayLabels"
    [cssClass]="'badge-team-opacity'"
    [suffixCssClass]="'badge-team-opacity'"
    [displayLabels]="displayLabels"
    [suffix]="true"></app-badge>

  <div class="text-muted f-italic" *ngIf="config.selecteds.length == 0" [title]="'Set Team' | translate"><app-i18n
      [key]="'No Team'"></app-i18n></div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <span class="badge badge-team-opacity badge-main badge-pill pre-checkbox">{{item.name}}</span>
</ng-template>

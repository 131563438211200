

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { permissionCode } from '../../_utils';
import { PermissionChecker } from './permission-checker.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AppInjector } from 'src/app/app.module';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base',
  template: `<div>base component!</div>`,
})

export class BaseComponent implements OnInit, OnDestroy {
  visibility: { [key: string]: boolean } = {};
  permissionCode = permissionCode;
  actions: string[] = [];
  destroyed$ = new Subject<void>();
  permissionChecker: PermissionChecker;
  breakpointObserver: BreakpointObserver = AppInjector.get(BreakpointObserver);
  isWebLayout: boolean = true;

  constructor(
    public permissionCheckService?: PermissionCheckService
  ) {
    this.checkWindowSize();
  }

  ngOnInit() {
    this.visibility = this.permissionCheckService.getVisibility(this.actions);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  checkWindowSize() {
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.matches) {
          this.isWebLayout = true;
        } else {
          this.isWebLayout = false;
        }
      });
  }
}

import { createAction, props } from '@ngrx/store';
import { List2Res } from 'src/app/shared';
import { Event, EventSearchRequest, EventTimePayload, EventUpsert, LocationEvent, LocationEventRequest } from '../../work-calendar/models/work-calendar-event.model';

export const getEvents = createAction(
  '[Event] Get Events',
  props<{ payload: EventSearchRequest }>()
);

export const setEvents = createAction(
  '[Event] Set Events',
  props<{ events: Event[] }>()
);

export const createEvent = createAction(
  '[Event] Create Event',
  props<{ payload: EventUpsert }>()
);

export const createEventSuccess = createAction(
  '[Event] Create Event Success',
  props<{ event: Event }>()
);

export const createEventFail = createAction(
  '[Event] Create Event Fail',
  props<{ error: any }>()
);

export const updateEvent = createAction(
  '[Event] Update Event',
  props<{ eventId: number, payload: EventUpsert}>()
);

export const updateEventsSuccess = createAction(
  '[Event] Update Event Success',
  props<{ events: Event[]}>()
);

export const updateEventFail = createAction(
  '[Event] Update Event Fail',
  props<{ error: any }>()
);

export const deleteEvent = createAction(
  '[Event] Delete Event',
  props<{ id: number }>()
);

export const deleteEventSuccess = createAction(
  '[Event] Delete Event Success',
  props<{ event: Event }>()
);

export const deleteEventFail = createAction(
  '[Event] Delete Event Fail',
  props<{ error: any }>()
);

export const updateStartEndEvent = createAction(
  '[Event] Update Start End Event',
  props<{ eventId: number; payload: EventTimePayload }>()
);

export const getLocations = createAction(
  '[Event] Get Location Event',
  props<{ payload: LocationEventRequest }>()
);

export const setLocations = createAction(
  '[Event] Set Location Event',
  props<{ locations: List2Res<LocationEvent>}>()
);

export const createLocation = createAction(
  '[Event] Create Location Event',
  props<{ payload: Partial<LocationEvent>}>()
);

export const createLocationSuccess = createAction(
  '[Event] Create Location Event Success',
  props<{ location: LocationEvent }>()
);

export const createLocationFail = createAction(
  '[Event] Create Location Event Fail',
  props<{ error: any }>()
);

export const updateLocation = createAction(
  '[Event] Update Location Event',
  props<{ locationId: number, payload: Partial<LocationEvent>}>()
);

export const updateLocationSuccess = createAction(
  '[Event] Update Location Event Success',
  props<{ location: LocationEvent }>()
);

export const updateLocationFail = createAction(
  '[Event] Update Location Event Fail',
  props<{ error: any }>()
);

export const deleteLocation = createAction(
  '[Event] Delete Location Event',
  props<{ locationId: number }>()
);

export const deleteLocationSuccess = createAction(
  '[Event] Delete Location Event Success',
  props<{ locationId: number }>()
);

export const deleteLocationFail = createAction(
  '[Event] Delete Location Event Fail',
  props<{ error: any }>()
);

export const EventActions = {
  getEvents,
  setEvents,
  createEvent,
  createEventSuccess,
  createEventFail,
  updateEvent,
  updateEventsSuccess,
  updateEventFail,
  deleteEvent,
  deleteEventSuccess,
  deleteEventFail,
  updateStartEndEvent,
  getLocations,
  setLocations,
  createLocation,
  createLocationSuccess,
  createLocationFail,
  updateLocation,
  updateLocationSuccess,
  updateLocationFail,
  deleteLocation,
  deleteLocationSuccess,
  deleteLocationFail,
};

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { AbstractComponent } from '../_base-component';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'src/app/libs/froala/plugin';
import 'src/assets/js/image.min.js';
import {
  FROALA_DEFAULT_CONFIG,
  FROALA_MARKDOWN_CONFIG
} from 'src/app/libs/froala/froala';
import FroalaEditor from 'froala-editor';
import { EditorFormPayload } from '../editor-form/editor-form.component';
import { AttachmentType, EDITOR_PREVIEW_FILE, LanguageCode } from '../../_utils';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { FroalaEvent, FroalaUpload, MENTION_CLASS } from 'src/app/libs/froala/models';
import { JiraMarkService } from 'libs/jira-mark/src/public-api';
import { EditorAttachmentFile, TaskAttachmentAction } from '../..';
import { EditorModule } from './_models';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilePreview, FilePreviewComponent } from '../file-preview/file-preview.component';
import { EditorUtils } from 'src/app/libs/froala/models/utils.model';
import { BaseAttachment } from '../../_models/attachment.model';
import { EditorMention, TaskEditorFile } from './x-editor';
import { UtilService } from 'src/app/core';
import { AccountsService } from 'src/app/authentication/_services/accounts.service';
import { Store } from '@ngrx/store';
import { AppActions } from 'src/app/core/store/app';

@Component({
  selector: 'x-editor',
  templateUrl: './x-editor.component.html',
  styleUrls: ['./x-editor.component.scss']
})
export class XEditorComponent extends AbstractComponent {
  @Input() control: FormControl | AbstractControl = new FormControl();
  @Input() breadcrumbTemplate: TemplateRef<any>;
  @Input() title: FormControl;
  @Input() placeholderText = 'Add a description...';
  @Input() lazy: boolean = false;
  @Input() value: any;
  @Input() mode: 'edit' | 'view' = 'edit';
  @Input() projectId: number;
  @Input() taskUploadType: AttachmentType;
  @Input() customClass = '';
  @Input() showSaveBtn = true;
  @Input() placeholder: string;
  @Input() module: EditorModule = EditorModule.TASK;
  @Input() uploadId: number;
  @Input() hasChanged = false;
  @Input() attachments: BaseAttachment[] = [];
  @Input() focus = true;

  @Output() save = new EventEmitter<EditorFormPayload>();
  @Output() emitCancel = new EventEmitter();
  @Output() change = new EventEmitter<EditorFormPayload>();
  @Output() clickView = new EventEmitter();
  @Output() fileInserted = new EventEmitter<EditorAttachmentFile>();
  @Output() onSwitchView = new EventEmitter();
  @Output() onChanged = new EventEmitter();

  isSaving = false;
  editor: FroalaEditor;
  options = null;

  constructor(
    private view: ViewContainerRef,
    private authService: AuthService,
    private JiraMarkService: JiraMarkService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private accountsService: AccountsService,
    private store: Store
  ) {
    super(false);
    this.options = this.getOptions();
  }

  changes(simples) {
    this.options.autoUpload.lazy = simples.lazy?.currentValue;

    if ('mode' in simples) {
      if (this.editor && this.mode === 'edit') {
        setTimeout(() => {
          this.editor.events.focus();
        }, 100);
      }

      this.store.dispatch(AppActions.setEditorMode({mode: this.mode}))
    }
  }

  init() {
    this.setupInput();
    if (!this.value) {
      this.value = this.control.value || '';
    }

    if (this.value) {
      this.value = EditorUtils.clearLinkParams(this.value);
      this.control.setValue(this.getCleanValue());
    }    
  }

  setupInput() {
    const config =
      this.module === EditorModule.MARKDOWN
        ? FROALA_MARKDOWN_CONFIG
        : FROALA_DEFAULT_CONFIG;
    this.options = {
      ...this.options,
      ...config
    };
    this.options.placeholderText = this.placeholder;

    if (this.uploadId) {
      const url = FroalaUpload.getUploadUrl(this.uploadId, this.module);
      this.options.fileUploadURL = url;
      this.options.imageUploadURL = url;
      this.options.videoUploadURL = url;

      const params = FroalaUpload.getUploadParams(this.module);
      this.options.videoUploadParam = params;
      this.options.imageUploadParam = params;
      this.options.fileUploadParam = params;
      if (this.taskUploadType) {
        let params = { type: this.taskUploadType };
        if (this.taskUploadType === AttachmentType.description) {
          Object.assign(params, { action: TaskAttachmentAction.EDIT });
        }
        this.options.videoUploadParams = params;
        this.options.imageUploadParams = params;
        this.options.fileUploadParams =  params;
      }
    }

    if (this.module === EditorModule.BLOG) {
      const accessToken = this.accountsService.getMainAccount()?.authResponse?.accessToken;
      this.options.requestHeaders = {
        Authorization: `Bearer ${accessToken}`,
      }
      this.options.disableAttactmentProcessor = true;
    }
  }

  getOptions() {
    return {
      ...FROALA_DEFAULT_CONFIG,
      events: {
        [FroalaEvent.GET_INSTANCE]: (instance) => {
          this.editor = instance;
          if (this.focus) {
            this.editor.events.focus();
          }
          instance.integrator.setView(this.view);
          instance.integrator.setModule(this.module);
          // instance.imageProcessor.setValue(this.value);
          instance.mention.setProjectId(this.projectId);
          instance.events.trigger(FroalaEvent.HTML_INITIAL, [this.value]);

          if (this.breadcrumbTemplate) {
            instance.topContent.setBreadcrumb(this.breadcrumbTemplate);
          }

          if (this.title) {
            instance.topContent.setTitle(this.title);
          }
        },
        [FroalaEvent.ATTACHMENT_PUSHED]: (file) => {
          this.fileInserted.emit(file);
        },
        [FroalaEvent.HTML_UPLOADED]: (html) => {
          this.value = html;
        },
        // [FroalaEvent.MARKDOWN_SWITCH]: (val) => {
        //   this.onSwitchView.emit();
        // },
        [FroalaEvent.POPUPS_IMAGE_EDIT_SHOW]: () => {
          if (this.mode === 'view') {
            this.editor.image.exitEdit(true);
          }
        },
        [FroalaEvent.CONTENT_CHANGED]: () => {
          this.onChanged.emit(this.control.value);
          if (!this.hasChanged) {
            this.hasChanged = true;
            this.cdr.detectChanges();
          }
        },
        [FroalaEvent.INITIALIZED]: () => {
          this.setCursorToEnd();
        }

      },
      placeholderText: this.placeholder,
      autoUpload: {
        lazy: this.lazy
      },
      requestHeaders: {
        'Accept-Language': LanguageCode.en,
        Authorization: `Bearer ${this.authService.getAccessToken()}`,
        'company-secret':
          this.authService.getLoggedUser()?.company?.companySecret || ''
      },
      placeholder: this.placeholder
    };
  }

  getMentionList() {
    switch (this.module) {
      case EditorModule.MARKDOWN:
        return this.editor.mention.getMarkdownMentions();
      default:
        return this.editor.mention.getMentions();
    }
  }

  onSave() {
    this.hasChanged = false;
    const selectedUserMention = this.getMentionList();
    EditorMention.verifyIncomplete(this.control);
    let htmlString: string = this.control.value;
    this.value = this.control.value;
    htmlString = EditorUtils.clearLinkParams(htmlString);
    this.save.emit({ attachments: [], htmlString, selectedUserMention });
  }

  onCancel() {
    this.hasChanged = false;
    this.control.setValue(this.value);
    this.emitCancel.emit();
    if (this.value && this.module === EditorModule.TASK) {
      const data = this.replaceOldVideo(this.control.value);
      this.control.setValue(data, { emitEvent: false });
    }
  }

  onClickView(event) {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return;
    }

    if (event?.target?.nodeName === 'IMG') {
      event?.preventDefault();
      event?.stopPropagation();
      if (this.module === EditorModule.BLOG) {
        return;
      }

      let files: FilePreview[] = [{
        id: +event.target.id || undefined,  // fileId (case: upload file)
        name: event.target.name,
        url: '',
        objectUrl: '',
      }];
      // common image: clear param
      if (!event.target.currentSrc?.includes(EDITOR_PREVIEW_FILE)) {
        files[0].url = event.target.currentSrc?.split('?')?.[0];
      }
      else if (event.target.currentSrc?.split('file-url=')?.[1]?.includes('https')) {
        // url : assets/img/editor-coment-file.svg?file-url=https....&authentication
        files[0].url = event.target.currentSrc?.split('file-url=')?.[1].split('&')?.[0];
      }

      if (this.module === EditorModule.TASK) {
        files[0] = TaskEditorFile.getFilePreview(files, this.attachments);
      }

      this.modalService.show(FilePreviewComponent, {
        backdrop: 'static',
        class: 'modal-preview',
        initialState: {
          files,
        }
      });
    }
    else {
      // reset init status
      this.editor?.undo?.reset();

      // set position cursor to the end if has content in editor
      this.setCursorToEnd();
      this.clickView.emit(event);
    }
  }

  getCleanValue() {
    if (this.value) {
      let value = this.JiraMarkService.parse(this.value);
      if (this.module === EditorModule.TASK) {
        value = this.replaceOldVideo(value);
      }
      return value.replaceAll('src="', 'src="" origin="');
    }
  }

  replaceOldVideo(value) {
    var imgRegex = /<img\s+.*?src=".*?file-url=([^"]+)".*?>/g;
    return value?.replace(imgRegex,(match, src) => {
        if (UtilService.isVideoURL(src)) {
          return `<span class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable">
          <video src="${src}" style="width: 600px;" controls class="fr-draggable"><br></video>
          </span>`;

        } else {
          return match;
        }
    });
  }

  setCursorToEnd() {
    const content = this.editor.html.get();
    if (content) {
      this.editor.selection.setAtEnd(this.editor.el);
      this.editor.selection.restore();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  closeOverlay(event: KeyboardEvent) {
    if (this.mode === 'edit') {
      this.onCancel()
    }    
  }
}

import { createFeature, createReducer, MetaReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { SiteManagementState } from './site-management.models';
import { SiteActions } from './site-management.actions';

import * as SiteManagementUtils from './site-management.utils';

export const siteManagementFeatureKey = 'siteManagement';

export const initSiteManagementState: SiteManagementState = {
  loggedUser: null,
  currentProject: null,
  currentRelease: null,
  searchText: null,
  waitingRequest: null,
  permissions: [],
  rolePermissions: [],
  isIntegrationJiraStatus: null,
  groupMenus: [],
  projectRoles: {}
};

export const siteManagementReducer = createReducer(
  initSiteManagementState,
  on(
    SiteActions.updateLoggedUser,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        loggedUser: props.data
      };
    }
  ),
  on(
    SiteActions.updateCurrentProject,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        currentProject: props.data
      };
    }
  ),
  on(
    SiteActions.search,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        searchText: props.searchText
      };
    }
  ),
  on(
    SiteActions.searchSuccess,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        searchText: null
      };
    }
  ),
  on(
    SiteActions.loadWaitingRequestNumber,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state
      };
    }
  ),
  on(
    SiteActions.loadWaitingRequestNumberSuccess,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        waitingRequest: props.data
      };
    }
  ),
  on(
    SiteActions.updateCurrentRelease,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        currentRelease: props.data
      };
    }
  ),
  on(
    SiteActions.updatePermissions,
    (state: SiteManagementState, props): SiteManagementState => {
      SiteManagementUtils.setPermissions(props.data);
      return {
        ...state,
        permissions: props.data
      };
    }
  ),
  on(
    SiteActions.updateRolePermissions,
    (state: SiteManagementState, props): SiteManagementState => {
      SiteManagementUtils.setRolePermissions(props.data);
      return {
        ...state,
        rolePermissions: props.data
      };
    }
  ),
  on(
    SiteActions.setIntegrationJiraStatus,
    (state: SiteManagementState, props): SiteManagementState => {
      return {
        ...state,
        isIntegrationJiraStatus: props.isIntegrationJiraSuccess
      };
    }
  ),
  on(SiteActions.setGroupMenus, (state: SiteManagementState, props) => {
    return { ...state, groupMenus: props.menu };
  }),
  on(SiteActions.setProjectRoles, (state: SiteManagementState, { projectRoles }) => {
    const _projectRoles = projectRoles.reduce((acc, curr) => {
      acc[curr.projectId] = curr.roles;
      return acc;
    }, {});

    return { ...state, projectRoles: _projectRoles }
  })
);

export const metaReducers: MetaReducer<SiteManagementState>[] =
  !environment.production ? [] : [];

export const siteManagementFeature = createFeature({
  name: siteManagementFeatureKey,
  reducer: siteManagementReducer
});

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthState, selectAccountCompanies } from '../store/auth';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class SelectCompanyGuardService {
  constructor(
    private router: Router,
    private store: Store<AuthState>
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.select(selectAccountCompanies)
      .subscribe(accounts => {
        const isUndefined = Boolean(accounts.length > 0);

        if (!isUndefined) {
          this.router.navigateByUrl('/auth');
        }

        return isUndefined;
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { NotificationActionType, SystemNotificationTypes } from '../notification-system';
import { NotificationShort } from 'src/app/shared/_models';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSender } from 'src/app/shared/_utils';

@Pipe({
  name: 'isLockTimeSheetNotification'
})
export class IsLockTimeSheetNotificationPipe implements PipeTransform {
  transform(type: string): boolean {
    return type === NotificationActionType.LockTimeSheet;
  }
}

@Pipe({
  name: 'isSystemNotification'
})
export class IsSystemNotificationPipe implements PipeTransform {
  transform(type: string): boolean {
    return SystemNotificationTypes.includes(type as NotificationActionType);
  }
}

@Pipe({
  name: 'notificationSenderName'
})
export class NotificationSenderNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }
  transform(notification: NotificationShort): string {
    switch (notification.action) {
      case NotificationActionType.RemindOverDueTask:
        return NotificationSender.XCORP;
      case NotificationActionType.TaskReminder:
        return NotificationSender.XCORP;
      case NotificationActionType.LockTimeSheet:
        return this.translate.instant(NotificationSender.THE_TIMESHEET);
      default:
        return notification.sender?.firstName + ' ' + notification.sender?.lastName;
    }
  }
}

@Pipe({
  name: 'notificationLabelParams'
})
export class NotificationLabelParamsPipe implements PipeTransform {
  transform(notification: NotificationShort): any {
    const notificationData = notification?.data;
    if (notificationData?.dayRemaining > 0) {
      return { value: notificationData.dayRemaining };
    }

    if (notificationData?.from) {
      return {
        target: notificationData.targetKey,
        source: notificationData.from
      };
    }
    return null;
  }
}

@Pipe({
  name: 'notificationLabel'
})
export class NotificationLabelPipe implements PipeTransform {
  transform(notification: NotificationShort): any {
    let label = '';
    switch (notification.action) {
      case NotificationActionType.RemindOverDueTask:
        if (notification.data.dayRemaining > 0) {
          label = 'notification.remindOverDueTaskAfter';
        } else {
          label = 'notification.remindOverDueTaskToday';
        }
        break;
      case NotificationActionType.LockTimeSheet:
        if (notification.data.dayRemaining > 0) {
          label = 'notification.lockTimeSheet';
        } else {
          label = 'notification.lockTimeSheetToday';
        }
        break;
      default:
        label = `notification.${notification.action}`;
    }
    return label;
  }
}

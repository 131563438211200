import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements OnInit {
    @Input() fixed = false;

    constructor(private modalRef: BsModalRef) {}

    ngOnInit(): void {}

    hide() {
        this.modalRef.hide();
    }
}

<div class="task-detail-description">
  <div class="app-editor-html-view-wrapper" #html *ngIf="!showEditor" (click)="onClickComment($event, html)">
    <app-editor-html-view
      [innerHTML]="description"
      [placeholderHtml]="placeholder"
      (contentParsed)="onContentParsed($event.html, $event.awsImgs)">
    </app-editor-html-view>
  </div>

  <div *ngIf="showEditor">
    <app-editor-form
      [getUserPromise]="getUserPromise"
      [upload$]="upload$"
      [htmlString]="parsedHtml || description"
      [placeholder]="'Add a description...' | translate"
      (save)="onSave($event)"
      (emitCancel)="onCancel()">
    </app-editor-form>
  </div>
</div>
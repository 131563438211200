import { first } from "lodash";
import { TaskAttachment } from "../../_models";
import { FilePreview } from "../file-preview/file-preview.component";
import { AbstractControl, UntypedFormControl } from "@angular/forms";
import { MENTION_CLASS } from "src/app/libs/froala/models";

export type EditorMode = 'view' | 'edit';

export class TaskEditorFile {
  static getFilePreview(files: FilePreview[], attachments: TaskAttachment[]) {
    const file = first(files);
    const attachment = attachments?.find(attachment => attachment.url === file?.url);

    return {
      ...file,
      id: file?.id || attachment?.fileId,
    }
  }
}

export class EditorMention {
  static verifyIncomplete(control: AbstractControl | UntypedFormControl) {
    const controlHTML = $('<div></div>').append($.parseHTML(control.value));
    const incompleteMentions = $(controlHTML).find(`.${MENTION_CLASS}[complete!='true']`);

    if (incompleteMentions?.length > 0) {
      this.revertIncompleteToNormal(controlHTML);
      control.setValue($(controlHTML).html());
    }
  }

  static revertIncompleteToNormal(el: JQuery<Element>) {
    $(el).find(`.${MENTION_CLASS}[complete!="true"]`).each(function() {
      const elText = $(this).text();
      $(this).replaceWith(elText);
    });
  }
}

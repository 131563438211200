import { routerObject } from "./router-object.const";

export const productChilds = [
  {
    text: routerObject.productTaskManagement.text,
    routerLink: routerObject.productTaskManagement.fullPath,
    icon: 'task_mgmt'
  },
  {
    text: routerObject.productTimesheet.text,
    routerLink: routerObject.productTimesheet.fullPath,
    icon: 'calendar'
  },
  {
    text: routerObject.productWorkTracking.text,
    routerLink: routerObject.productWorkTracking.fullPath,
    icon: 'donut_small'
  },
  {
    text: routerObject.productLeaveManagement.text,
    routerLink: routerObject.productLeaveManagement.fullPath,
    icon: 'cottage'
  },
  {
    text: routerObject.productDocumentManagement.text,
    routerLink: routerObject.productDocumentManagement.fullPath,
    icon: 'menu_9'
  },
  {
    text: routerObject.productOkr.text,
    routerLink: routerObject.productOkr.fullPath,
    icon: 'space_dashboard'
  },
  {
    text: routerObject.productSlack.text,
    routerLink: routerObject.productSlack.fullPath,
    icon: 'slack'
  }
];

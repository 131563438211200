export enum NotificationSettingPayload {
  STATUS_ALL_FEATURES = 'statusAllFeatures',

  /** Task Management module */
  STATUS_TASK = 'statusTask',
  // ==>> Send mail
  STATUS_TASK_MAIL = 'statusTaskMail',
  TASK_SEND_MAIL_CREATED = 'taskSendMailCreated',
  TASK_SEND_MAIL_ASSIGNEE = 'taskSendMailAssignee',
  TASK_SEND_MAIL_CHANGE_STATUS = 'taskSendMailChangeStatus',
  TASK_SEND_MAIL_MENTION = 'taskSendMailMention',
  TASK_SEND_MAIL_REMINDER = 'taskSendMailReminder',
  // ==>> Send notification
  STATUS_TASK_NOTI = 'statusTaskNoti',
  TASK_OVER_DUE_DATE = 'taskOverDueDate',
  TASK_OVER_DUE_DATE_AFTER = 'taskOverDueDateAfter',
  RECEIVE_TASK_OVER_DUE_AFTER = 'receiveTaskOverDueAfter',
  TASK_NOTIFY_UPDATE = 'taskNotifyUpdate',
  TASK_NOTIFY_MENTION = 'taskNotifyMention',
  TASK_NOTIFY_REMINDER = 'taskNotifyReminder',
  TASK_NOTIFY_CREATED = 'taskNotifyCreated',

  /** Time Sheet module */
  STATUS_LOG_TIME = 'statusLogTime',
  // ==>> Send mail
  STATUS_LOG_TIME_MAIL = 'statusLogTimeMail',
  LOG_TIME_SEND_MAIL = 'logTimeSendMail',
  LOG_TIME_SEND_MAIL_BEFORE = 'logTimeSendMailBefore',
  // ==>> Send notification
  STATUS_LOG_TIME_NOTI = 'statusLogTimeNoti',
  LOG_TIME_SEND_NOTI = 'logTimeSendNoti',
  LOG_TIME_SEND_NOTI_BEFORE = 'logTimeSendNotiBefore'
}

export enum NotificationSettingType {
  NOTIFY = 'noti',
  EMAIL = 'email'
}

export enum NotificationFeatureKey {
  TASK = 'task',
  LOG_TIME = 'logtime',
}

export enum ListenSSEChannel {
  ImportProcess = 'importProcess',
  Error = 'error',
  UnreadNotification = 'unreadNotifications'
}

export enum NotificationSender {
  XCORP = 'xCorp',
  THE_TIMESHEET = 'The Timesheet',
}

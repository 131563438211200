import { TemplateRef } from '@angular/core';
import { NotificationFeatureKey } from '../_utils';
import { PageableModel } from './generic';

export interface Sender {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface Source {
  id: number;
  name?: string;
  key?: string;
}

export interface NotificationShort {
  id: number;
  sender: Sender;
  action: string;
  createdAt: string;
  read: boolean;
  data: NotificationTaskData;
}

export interface NotificationData {
  targetId: number;
  targetType: string;
  targetKey: string;
  targetName: string;
  sourceId?: string;
  sourceType?: string;
  message?: string;
}

export interface NotificationTaskData extends NotificationData {
  projectId: number;
  projectKey: string;
  projectName: string;
  dayRemaining?: number;
  deadline?: string;
  from?: string;
}

export interface NotificationRequest {
  field: string;
  value: boolean;
}

export class NotificationSearch extends PageableModel {
  read: boolean;
  receiverId: number;
  constructor(obj?: NotificationSearch) {
    super(obj);
  }
}

export interface NotificationSetting {
  statusAllFeatures?: boolean;

  /** Task Management Module */
  statusTask?: boolean;
  /** Send Mail */
  statusTaskMail?: boolean;
  taskSendMailCreated?: boolean;
  taskSendMailAssignee?: boolean;
  taskSendMailChangeStatus?: boolean;
  taskSendMailMention?: boolean;
  taskSendMailReminder?: boolean;
  /** Send Notification */
  statusTaskNoti?: boolean;
  taskOverDueDate?: boolean;
  taskOverDueDateAfter?: boolean;
  receiveTaskOverDueAfter?: number;
  taskNotifyUpdate?: boolean;
  taskNotifyMention?: boolean;
  taskNotifyReminder?: boolean;
  taskNotifyCreated?: boolean;

  /** Timesheet Module */
  statusLogTime?: boolean;
  /** Send Mail */
  statusLogTimeMail?: boolean;
  logTimeSendMail?: boolean;
  logTimeSendMailBefore?: number;
  /** Send Notification */
  statusLogTimeNoti?: boolean;
  logTimeSendNoti?: boolean;
  logTimeSendNotiBefore?: number;
}

export interface NotificationSettingRequest extends NotificationSetting {
  setAll?: boolean;
  setForAllTask?: boolean;
  turnOn?: boolean;
}

export interface NotificationSettingUI {
  label: string;
  id?: string;
  icon?: string;
  code: string;
  isCollapsed?: boolean;
  description?: string;
  child?: NotificationSettingUI[];
  additionalLabel?: string;
  additionalCode?: string;
  featureKey?: NotificationFeatureKey;
  templateRef?: TemplateRef<any>;
}

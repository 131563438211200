import { Component, Input } from '@angular/core';
import { BoardLabel } from 'src/app/shared';

@Component({
  selector: 'app-multi-label-label',
  template: `
    <div class="custom-option mr-1 mb-1" *ngFor="let item of items">
      <span
        class="badge-label text-ellipsis"
        [style.backgroundColor]="item.colorCode"
        >{{ item.name }}</span
      >
      <span
        class="material-icons btn-clear ng-value-icon"
        (click)="clear(item)"
      >
        clear
      </span>
    </div>
  `
})
export class MultiLabelComponent {
  @Input() items: BoardLabel[];
  @Input() clear: (data: any) => void;
}

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import {
  EditorConfig, MD_FOLDER_NAME, WikiItem, pageActions, selectContent, selectData, selectEditorConfigs,
  selectError, setContent, WikiMode, WikiEffects, activateWikiSuccess, topicActions, WikiType,
  TOPIC_FILE_NAME, setEditorConfigs, updateWikiItem
} from '../_store/wiki';
import { nonEmpty } from 'src/app/libs/rxjs/custom-operator.rxjs';
import { findWikiItem, getSamePage, updatedWikiItem } from '../_store/wiki/wiki.utils';
import { FileType } from 'src/app/shared';
import { Actions, ofType } from '@ngrx/effects';
import { DocumentDataService, TreeItem } from '../document/_services/document-data.service';
import { DocumentView } from '../document/_enums/document.enum';
import { cloneDeep } from 'lodash';

interface State {
  data: WikiItem[];
  selectedItem: WikiItem;
  content: string;
  isLoading: boolean;
  error: string | null;
  keyword: string;
  editorConfigs: EditorConfig;
  currentItem: WikiItem;
}

const initialState: State = {
  data: [],
  selectedItem: null,
  content: '',
  isLoading: false,
  error: null,
  keyword: null,
  editorConfigs: null,
  currentItem: null
};

@Injectable()
export class WikiStore extends ComponentStore<State> {
  constructor(
    private store: Store,
    private wikiEffects: WikiEffects,
    private actions$: Actions,
    private documentDataService: DocumentDataService
  ) {
    super(initialState);
    this.effects.getData(this.data$);
    this.effects.getContent(this.content$);
    this.effects.getEditorConfig(this.editorConfigs$);
    this.effects.getError(this.error$);
    this.effects.activatedSuccess(this.activateSuccess$);
    this.effects.createTopicSuccess(this.createTopicSuccess$);
    this.effects.createPageSuccess(this.createPageSuccess$);
    this.effects.changeActivatedTreeItem(this.activatedTreeItem$);
  }

  get data() {
    return this.get().data;
  }

  get isLoading() {
    return this.get().isLoading;
  }

  get error() {
    return this.get().error;
  }

  get keyword() {
    return this.get().keyword;
  }

  get content() {
    return this.get().content;
  }

  get selectedItem() {
    return this.get().selectedItem;
  }

  get editorConfigs() {
    return this.get().editorConfigs;
  }

  get currentItem() {
    return this.get().currentItem;
  }

  readonly data$ = this.store.select(selectData);
  readonly error$ = this.store.select(selectError);
  readonly content$ = this.store.select(selectContent);
  readonly editorConfigs$ = this.store.select(selectEditorConfigs);
  readonly selectedItem$: Observable<WikiItem> = this.select((state) => state.selectedItem);
  readonly currentItem$: Observable<WikiItem> = this.select((state) => state.currentItem);
  readonly activateSuccess$ = this.actions$.pipe(ofType(activateWikiSuccess));
  readonly createTopicSuccess$ = this.actions$.pipe(ofType(topicActions.createSuccess));
  readonly createPageSuccess$ = this.actions$.pipe(ofType(pageActions.createSuccess));
  readonly activatedTreeItem$ = this.documentDataService.getActiveTreeItem();

  readonly actions = {
    setData: (data: WikiItem[]) => {
      this.patchState({ data });
    },
    setSelectedItem: (item: WikiItem, canSync: boolean = true) => {
      this.patchState(() => {
        const fileContent = this.updateContent(item);

        if (fileContent && this.editorConfigs?.mode !== WikiMode.Create) {
          this.store.dispatch(pageActions.get({id: fileContent.id}));
          this.actions.setCurrentItem(item);
        }

        const selectedTreeItem = this.documentDataService.treeMap[item.id]
          ?? this.documentDataService.treeMap[item.uId];
        if (selectedTreeItem) {
          selectedTreeItem.value.isOpen = true;
        }
        if (canSync && item?.id !== this.documentDataService.getActiveTreeItemValue()?.id) {
          if (item.type === FileType.dir) {
            const updatedItemId = this.documentDataService.treeMap[item.id] ? item.id : item.uId;
            this.documentDataService.changeActiveTreeItem(updatedItemId);
          } else {
            this.documentDataService.changeActiveTreeItem(item.parentFile.id);
          }
        }

        /** If parent of this treeItem has not been opened -> open it */
        const parentTreeItem = this.documentDataService.treeMap[item?.parentFile?.id]?.value;
        if (parentTreeItem && !parentTreeItem.isOpen) {
          parentTreeItem.isOpen = true;
        }

        return { selectedItem: item };
      });
    },
    setCurrentItem: (currentItem: WikiItem) => {
      this.patchState({ currentItem });
    },
    setContent: (content: string) => {
      this.patchState({ content });
    },
    setKeyword: (keyword: string) => {
      this.patchState({ keyword });
    },
    onActiveTopicItem(item: WikiItem) {
      // todo
    },
    setEditorConfigs: (configs: EditorConfig) => {
      this.patchState({ editorConfigs: configs });
    },
    setError: (error: string) => {
      this.patchState({ error });
    }
  };

  readonly effects = {
    getData: this.effect((data$: Observable<WikiItem[]>) => {
      return data$.pipe(
        nonEmpty(),
        tap((data: WikiItem[]) => {
          this.actions.setData(data);

          const selectedId = this.selectedItem?.id > 0 ? this.selectedItem.id : this.selectedItem?.uId;
          const updatedSelected = findWikiItem(data, selectedId);
          if (this.selectedItem && updatedSelected && JSON.stringify(updatedSelected) !== JSON.stringify(this.selectedItem)) {
            this.actions.setSelectedItem(updatedSelected);
          }
        })
      );
    }),
    getContent: this.effect((content$: Observable<string>) => {
      return content$.pipe(
        nonEmpty(),
        tap((content: string) => {
          this.actions.setContent(content);
        })
      );
    }),
    getEditorConfig: this.effect((configs$: Observable<EditorConfig>) => {
      return configs$.pipe(
        nonEmpty(),
        tap((configs: EditorConfig) => {
          /** If the mode of editor was changed to Edit -> check loading draft file */
          if (configs.mode === WikiMode.Edit) {
            let mdFolder: WikiItem = null;
            let wikiFile: WikiItem = null;
            /** If selected is topic -> find MD folder in items
             *  else (page) -> find MD folder by parentFile.id
             */
            if (this.selectedItem && this.selectedItem.wikiType === WikiType.Topic) {
              mdFolder = this.selectedItem?.items?.find(item => item.name === MD_FOLDER_NAME);
              wikiFile = mdFolder?.items?.find(item => item.name === TOPIC_FILE_NAME);
            } else {
              mdFolder = findWikiItem(this.data, this.selectedItem?.parentFile?.id);
              wikiFile = this.selectedItem;
            }

            if (mdFolder) {
              const draftPage = getSamePage(mdFolder.items, wikiFile);
              if (draftPage) {
                this.store.dispatch(setContent({ data: null }));
                this.store.dispatch(pageActions.get({ id: draftPage.id }));
              }
            }
          }
          this.actions.setEditorConfigs(configs);
        })
      );
    }),
    getError: this.effect((error$: Observable<string>) => {
      return error$.pipe(
        nonEmpty(),
        tap((error: string) => {
          this.actions.setError(error);
        })
      );
    }),
    activatedSuccess: this.effect((item$: Observable<any>) => {
      return item$.pipe(
        nonEmpty(),
        tap((item: any) => {
          const topicFolder = item.data.parentFile.parentFile;
          const parent = findWikiItem(this.data, topicFolder.id);
          this.store.dispatch(setEditorConfigs({
            configs: {
              type: WikiType.Topic,
              mode: WikiMode.Edit
            }
          }));

          if (parent) {
            this.actions.setSelectedItem(parent);
          } else {
            const _topicFolder = { ...topicFolder };
            _topicFolder.wikiFlg = true;
            _topicFolder.wikiType = WikiType.Topic;
            const updatedWiki = updatedWikiItem(_topicFolder, this.data);
            this.store.dispatch(updateWikiItem({ item: updatedWiki }));
          }

          if (this.documentDataService.detailView === DocumentView.DOCUMENT) {
            this.documentDataService.detailView$.next(DocumentView.MARKDOWN);
          }
        })
      );
    }),
    createTopicSuccess: this.effect((item$: Observable<any>) => {
      return item$.pipe(
        nonEmpty(),
        tap((item: any) => {
          /** Reset content before loading new content of created topic */
          this.store.dispatch(setContent({ data: '' }));

          const parent = findWikiItem(this.data, item.topic.parentFile.parentFile.id);
          this.actions.setSelectedItem(parent);
        })
      );
    }),
    createPageSuccess: this.effect((item$: Observable<any>) => {
      return item$.pipe(
        nonEmpty(),
        tap((item: any) => {
          this.actions.setSelectedItem(item.page);
        })
      );
    }),
    changeActivatedTreeItem: this.effect((item$: Observable<TreeItem>) => {
      return item$.pipe(
        nonEmpty(),
        tap((activatedItem: TreeItem) => {
          if (activatedItem.id === this.selectedItem?.id) {
            return;
          }

          const wikiItem = findWikiItem(cloneDeep(this.data), activatedItem.id);
          if (wikiItem) {
            this.actions.setSelectedItem(wikiItem, false);
          }
        })
      );
    })
  };

  updateContent(selected: WikiItem) {
    if (selected?.wikiFlg && selected?.type === FileType.dir) {
      // Find MD folder
      const mdFolder = selected?.items?.find(el => el.name === MD_FOLDER_NAME);
      /** If existed published topic -> return published
       *  else return draft one
       */
      const topicFiles = mdFolder?.items?.filter(el => (el.name as string)?.includes(TOPIC_FILE_NAME));
      if (topicFiles && topicFiles?.length > 1) {
        return topicFiles.find(file => file.publishFlg);
      }
      return topicFiles?.[0];
    }

    return selected;
  }
}

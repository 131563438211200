export enum NotificationPatchType {
  read = 'read'
}

export enum NotificationTab {
  all = 'all',
  unread = 'unread',
}

export enum NotificationActionType {
  RemindOverDueTask = 'remindOverDueTaskAfter',
  TaskReminder = 'taskReminder',
  CloneData = 'cloneData',
  ShareTaskFilter = 'shareTaskFilter',
  FeedbackTaskComment = 'feedbackTaskComment',
  LockTimeSheet = 'lockTimeSheet',
  CloneTask = 'cloneTask',
  CloneEpic = 'cloneEpic',
  ImportJira = 'importJira'
}

export const SystemNotificationTypes = [
  NotificationActionType.RemindOverDueTask,
  NotificationActionType.TaskReminder,
  NotificationActionType.LockTimeSheet,
];

import { map } from 'lodash';
import { FilterItem, UserShort } from 'src/app/shared';
import { StatusChannel, Workspace } from './project-slack-integration.model';


export interface SlackIntegrationFilterProps {
  workspaceIds?: number[];
  connectors?: number[];
  connected?: boolean;
}

export enum SlackIntegrationFilterKey {
  Workspace = 'workspace',
  Connector = 'connector',
  Status = 'status',
  Project = 'project'
}

export const statusChannel: StatusChannel[] = [
  {
    name: 'Active',
    value: true
  },
  {
    name: 'Inactive',
    value: false
  }
];

export class SlackIntegrationFilter {
  workspace = new FilterItem<Workspace>({
    key: SlackIntegrationFilterKey.Workspace,
    label: 'Workspace',
    bindId: 'id',
    bindLabel: 'name',
    multiple: true
  });
  connector = new FilterItem<UserShort>({
    key: SlackIntegrationFilterKey.Connector,
    label: 'Connector',
    bindId: 'id',
    bindLabel: 'fullName',
    multiple: true
  });
  status = new FilterItem<any>({
    key: SlackIntegrationFilterKey.Status,
    label: 'Status',
    bindId: 'value',
    bindLabel: 'name',
    items: statusChannel,
    multiple: false
  });

  setWorkspaceItems(items: Workspace[]) {
    this.workspace.items = items;
  }

  setStatusItems(items: StatusChannel[]) {
    this.status.items = items;
  }

  setConnectorItems(items: UserShort[]) {
    this.connector.items = items;
  }

  getValues(): SlackIntegrationFilterProps {
    return {
      workspaceIds: map(this.workspace.selecteds, (e) => e.id),
      connectors: map(this.connector.selecteds, (e) => e.id),
      connected: this.status.selecteds?.[0]?.value
    };
  }

  getSelections() {
    return {
      workspace: [...this.workspace.selecteds],
      connector: [...this.connector.selecteds],
      status: [...this.status.selecteds],
    };
  }

  patch(obj) {
    obj.workspace && (this.workspace.selecteds = [...obj.workspace]);
    obj.connector && (this.connector.selecteds = [...obj.connector]);
    obj.status && (this.status.selecteds = [...obj.status]);
  }
}

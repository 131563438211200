import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOverlayLabels'
})
export class GetOverlayLabelsPipe implements PipeTransform {
  transform(value: any[]): any {
    return value?.map(e => e.name);
  }
}

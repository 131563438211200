import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.models';

export const featureKey = 'userData';

const selectFeature = createFeatureSelector<UserState>(featureKey);

export const selectAllUsers = createSelector(
  selectFeature,
  (state: UserState) => state.users
);

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Task } from 'src/app/shared';

@Component({
  standalone: true,
  selector: 'x-task-finder-item',
  imports: [CommonModule],
  template: `
    <div class="flex">
      <img class="mx-2" [src]="task.type.icon" alt="" />
      <a class="btn-link mr-2" [href]="'/task-mgmt/tasks/' + task.key"
        ><span>{{ task.key }}</span>
      </a>
      <div class="text-name text-ellipsis">
        {{ task.name }}
      </div>

      <div class="">
        <ng-container *ngFor="let item of task.versions">
          {{ item.name }}
        </ng-container>
      </div>
    </div>
  `
})
export class TaskFinderItemComponent implements OnInit {
  @Input() task: Task;

  constructor() {}

  ngOnInit() {
  }
}

export const GitTypes = [
  {
    name: 'Github',
    icon: 'assets/icons/git/github.svg',
  },
  {
    name: 'Gitlab',
    icon: 'assets/icons/git/gitlab.svg',
  },
  {
    name: 'Bitbucket',
    icon: 'assets/icons/git/bitbucket.svg',
  }
];

export const priorityIcons = [
  'assets/icons/project/priorities/blocker.svg',
  'assets/icons/project/priorities/critical.svg',
  'assets/icons/project/priorities/high_2.svg',
  'assets/icons/project/priorities/very_high_2.svg',
  'assets/icons/project/priorities/very_high.svg',
  'assets/icons/project/priorities/high.svg',
  'assets/icons/project/priorities/medium.svg',
  'assets/icons/project/priorities/major.svg',
  'assets/icons/project/priorities/medium_2.svg',
  'assets/icons/project/priorities/minor.svg',
  'assets/icons/project/priorities/very_low_2.svg',
  'assets/icons/project/priorities/low_2.svg',
  'assets/icons/project/priorities/very_low.svg',
  'assets/icons/project/priorities/low.svg',
  'assets/icons/project/priorities/default.svg',
];

export const PROJECT_KEY_LIMIT = 4;

export const TASK_LIST_WIDTH_LIMIT = 750;
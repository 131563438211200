import { SelectOption } from '..';
import { ProjectShort } from './project.model';

export enum SwimLaneType {
  NONE = 'None',
  ASSIGNEE = 'Assignees',
  EPIC = 'Epics',
  PRIORITY = 'Priority'
}

export enum SwimLaneName {
  NONE = 'None',
  ASSIGNEE = 'Assignees',
  EPIC = 'Epic',
  PRIORITY = 'Priority'
}

export class SwimLaneOptions {
  static get options(): SelectOption<SwimLaneType>[] {
    return Object.keys(SwimLaneType).map((key) => {
      return {
        name: SwimLaneName[key],
        value: SwimLaneType[key]
      };
    });
  }
}

export enum SwimlaneAssigneePosition {
  BELOW = 'Below',
  ABOVE = 'Above',
}
export enum SwimlaneAssigneePositionName {
  BELOW = 'Show below other swimlanes',
  ABOVE = 'Show above other swimlanes',
}

export class SwimLaneAssigneePositionOptions {
  static get options(): SelectOption<SwimlaneAssigneePosition>[] {
    return Object.keys(SwimlaneAssigneePosition).map((key) => {
      return {
        name: SwimlaneAssigneePositionName[key],
        value: SwimlaneAssigneePosition[key]
      };
    });
  }
}

export interface SwimlaneResponse {
  baseOn: SwimLaneType;
  unassigned?: SwimlaneAssigneePosition;
  project: ProjectShort;
}

export interface SwimlanePayload {
  baseOn: SwimLaneType;
  unassigned?: SwimlaneAssigneePosition;
}

import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox',
  templateUrl: './formly-checkbox.component.html',
  styleUrls: ['./formly-checkbox.component.scss']
})
export class CheckboxType extends FieldType<FieldTypeConfig> {

}

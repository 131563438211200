import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'x-i18n',
  templateUrl: './i18n.component.html',
  styleUrls: ['./i18n.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class I18nComponent implements OnInit {
  @Input() key = '';
  @Input() params = {};

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { routerObject } from 'src/app/shared/_utils';
import { IntegrationJiraToastService } from 'src/app/site-management/integration/_services/integration-jira.toast.service';

@Component({
  selector: 'app-integration-jira-toast',
  templateUrl: './integration-jira-toast.component.html',
  styleUrls: ['./integration-jira-toast.component.scss']
})
export class IntegrationJiraToastComponent {
  routerObject = routerObject;

  constructor(
    private router: Router,
    private nzNotification: NzNotificationService,
    private integrationJiraToastService: IntegrationJiraToastService
  ) {}

  onNavigateToJira() {
    const navigateUrl = this.routerObject.orgIntegrationJira.fullPath;
    this.router.navigateByUrl(navigateUrl);
    this.onCloseToast();
  }

  onCloseToast() {
    this.integrationJiraToastService.setVisible(false);
    this.nzNotification.remove();
  }
}

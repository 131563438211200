import { SlackIntegrationFilter, SlackIntegrationFilterKey, SlackIntegrationFilterProps } from "src/app/site-management/projects/project-slack-integration/_models";
import { IntegrationPlatform } from "../_utils";
import { CompanyLicenseResponse, User } from './user.model';
import { FilterItem } from './app';
import { Project } from './project.model';
import { map } from 'lodash';

export interface AccountLink {
  id: string;
  productName: string;
  domain: string;
  avatar: string;
}

export interface GitAccount {
  id: number;
  uuid: string;
  name: string;
  username: string;
  avatar: string;
  url: string;
  email: string;
  platform: string;
}

export interface GitAccountCompany {
  id: number;
  gitAccount: GitAccount;
  connected: boolean;
}

export interface GitAccountSearchRequest {
  keyword?: string;
}

export interface JiraConfigUser {
  id: number;
  displayName: string;
  domain: string;
  emailAddress: string;
  activeFlg: boolean;
  confirmFlg: boolean;
}

export interface JiraConfigUserPayload {
  requests: JiraConfigUser[];
}

export interface SyncJiraPayload {
  platform: IntegrationPlatform;
  projectId?: number;
}

export interface IntegrationJiraStatus {
  isPending: boolean;
}

export enum JiraDomainStatus {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum JiraApprovalAction {
  CANCEL = 'CANCEL',
  SUBMIT = 'SUBMIT'
}

export interface JiraDomainResponse {
  id: number;
  domain: string;
  status: JiraDomainStatus;
  company: CompanyLicenseResponse;
  user: User;
  owners: string[];
}

export interface JiraDomainApproveRequest {
  status: JiraDomainStatus;
  syncAll: boolean;
}

export interface JiraDomainApprovalRequest {
  action: JiraApprovalAction;
}

export interface SlackOrgIntegrationFilterProps extends SlackIntegrationFilterProps {
  projectIds?: number[];
}

export class SlackOrgIntegrationFilter extends SlackIntegrationFilter {
  project = new FilterItem<Project>({
    key: SlackIntegrationFilterKey.Project,
    label: 'Project',
    bindId: 'id',
    bindLabel: 'name',
    multiple: true
  });

  setProject(projects: Project[]) {
    this.project.items = projects;
  }

  getValues(): SlackOrgIntegrationFilterProps {
    return {
      workspaceIds: map(this.workspace.selecteds, (e) => e.id),
      connectors: map(this.connector.selecteds, (e) => e.id),
      connected: this.status.selecteds?.[0]?.value,
      projectIds: map(this.project.selecteds, (e) => e.id),
    };
  }

  getSelections() {
    return {
      workspace: [...this.workspace.selecteds],
      connector: [...this.connector.selecteds],
      status: [...this.status.selecteds],
      project: [...this.project.selecteds]
    };
  }

  patch(obj) {
    if (obj?.workspace) {
      this.workspace.selecteds = [...obj.workspace];
    }

    if (obj.connector) {
      this.connector.selecteds = [...obj.connector];
    }

    if (obj.status) {
      this.status.selecteds = [...obj.status];
    }

    if (obj.project) {
      this.project.selecteds = [...obj.project];
    }
  }
}

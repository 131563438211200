import FroalaEditor from 'froala-editor';
import { FroalaEvent } from '../models/events.model';
import { every, forEach, map } from 'lodash';
import { UtilService } from 'src/app/core';
import { EDITOR_PREVIEW_FILE } from 'src/app/shared';

FroalaEditor.PLUGINS.copyPasteFile = function (editor: FroalaEditor) {
  function _init() {
    editor.el.addEventListener(FroalaEvent.PASTE, onPaste);
  }

  function onPaste(event: ClipboardEvent) {
    const items = event.clipboardData.items;
    const isFile = every(items, (item) => {
      return item.kind === 'file';
    });

    if (!isFile) {
      return false;
    }

    editor.events.trigger(FroalaEvent.PASTE_FILE, [true], true);
    const files = map(items, (item) => item.getAsFile());

    if (!editor.opts.autoUpload?.lazy) {
      forEach(files, (file) => {
        editor.image.exitEdit(true);
        editor.selection.restore();
        editor.file.upload([file]);
      })
    } else {
      forEach(files, (file) => {
        fileToBlob(file, ({ blobUrl }) => {
          const isImage = UtilService.isImageURL(file.name);

          // Trigger attachmentPushed event
          editor.events.trigger(
            FroalaEvent.ATTACHMENT_PUSHED,
            [
              {
                file: file,
                previewUrl: isImage ? blobUrl : EDITOR_PREVIEW_FILE,
                blobUrl
              }
            ],
            true
          );

          if (isImage) {
            editor.image.insert(
              blobUrl,
              false,
              {
                name: file.name,
                origin: blobUrl
              },
              null
            );
          } else {
            editor.image.insert(
              `${EDITOR_PREVIEW_FILE}?file-url=${blobUrl}`,
              false,
              {
                name: file.name,
                origin: blobUrl
              },
              null
            );
          }
        });
      });
    }
  }

  function fileToBlob(file, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      const blobUrl = URL.createObjectURL(blob);
      callback({ blob, blobUrl });
    };

    reader.readAsArrayBuffer(file);
  }

  return {
    _init
  };
};

<div class="notification-list-wrapper p-2" *ngIf="notification" (click)="redirectToNotification(notification)">
  <div class="mr-2">
    <app-avatar
      [size]="'large'"
      [url]="!(notification.action | isSystemNotification) ? notification.sender?.avatar : defaultNotificationIcon"></app-avatar>
  </div>
  <div class="d-flex flex-column right-content-list notification-block">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="fw-bold">{{notification | notificationSenderName}}</span>&nbsp;
        <span class="noti-name">
          <app-i18n [key]="notification | notificationLabel" [params]="notification | notificationLabelParams"></app-i18n>
        </span>
      </div>
      <div class="mark-notification" *ngIf="!notification.read" (click)="markNotificationAsRead(notification); stopEvent($event)"></div>
    </div>

    <div class="title d-flex">
      <div class="text-primary mr-2" *ngIf="notification.data?.targetKey">{{notification.data?.targetKey}}</div>
      <div class="task-name">{{notification?.data?.targetName}}</div>
    </div>
    <div class="message text-ellipsis-2-line" *ngIf="showMessageReminder(notification)">
      <span><app-i18n [key]="'messageReminder'"></app-i18n>:&nbsp;</span>
      <span>"{{notification?.data?.message}}"</span>
    </div>
    <!-- TimeSheet notification -->
    <div class="message text-ellipsis-2-line" *ngIf="notification.action | isLockTimeSheetNotification">
      <span><app-i18n [key]="'messageLockTimeSheet'"></app-i18n>:&nbsp;</span>
      <span>{{notification?.data?.deadline | dateFormat: DATE_TIME_UI}}</span>
    </div>
    <div class="d-flex justify-content-between">
      <div class="text-primary" *ngIf="getTimeUI(notification.createdAt) as dateTime">
        <app-i18n [key]="dateTime.name" [params]="{value: dateTime.value}"></app-i18n>
      </div>
    </div>
  </div>

  <!-- <div dropdown placement="bottom right" class="btn-option">
    <button dropdownToggle type="button" class="btn btn-icon" id="button-alignment" (click)="onClickDropdown($event)">
      <span class="material-icons-outlined">more_horiz</span>
    </button>
    <div *ngIf="isShowBtnDropdown">
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right drop-menu-list">
        <div class="dropdown-item" (click)="markNotificationAsRead(notification); stopEvent($event)">
          <img src="../../../../../assets/icons/notification/notification_check.svg" alt="" class="mr-2">
          <app-i18n [key]="'markAsRead'"></app-i18n>
        </div>
        <div class="dropdown-item" (click)="removeNotification(notification); stopEvent($event)">
          <img src="../../../../../assets/icons/notification/notification_delete.svg" alt="" class="mr-2">
          <app-i18n [key]="'removeNotification'"></app-i18n>
        </div>
      </div>
    </div>
  </div> -->
</div>

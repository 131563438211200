import { HolidayType } from '../_utils/enums';

export interface Holiday {
  id?: number;
  holidayDate: string;
  name: string;
  description: string;
  type: HolidayType;
  overtimeRate: number;

  // FE
  countryCode?: string;
  isCompany?: boolean;
}

export interface HolidaySearchRequest {
  fromDate: string;
  toDate: string;
  countryCode?: string[];
}

export interface HolidayCountry {
  code: string;
  name: string;
  timeZones: string[];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  BoardStatus, BoardStatusCreateRequest, BoardStatusDeleteRequest, ChangeStatusOrderRequest, List2Res, StatusSearchParams
} from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class ProjectBoardStatusService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  get(projectId: number, param: StatusSearchParams): Observable<List2Res<BoardStatus>> {
    const params = queryString.stringify({...param});
    this.loading$.next(true);
    return this.http.get<List2Res<BoardStatus>>(`${environment.apiUrl}/projects/${projectId}/board-status/?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  add(projectId: number, payload: BoardStatusCreateRequest) {
    this.loading$.next(true);
    return this.http.post<BoardStatus>(`${environment.apiUrl}/projects/${projectId}/board-status/`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  updateStatus(projectId: number, statusId: number, payload: BoardStatusCreateRequest) {
    this.loading$.next(true);
    return this.http.put<BoardStatus>(`${environment.apiUrl}/projects/${projectId}/board-status/${statusId}`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  delete(projectId: number, statusId: number, payload: BoardStatusDeleteRequest) {
    this.loading$.next(true);
    return this.http.request<any>('delete', `${environment.apiUrl}/projects/${projectId}/board-status/${statusId}`, { body: payload }).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getListUnmappedStatus(projectId: number) {
    this.loading$.next(true);
    return this.http.get<BoardStatus[]>(`${environment.apiUrl}/projects/${projectId}/board-status/list-unmapped-status`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  editColumn(projectId: number, statusId: number, columnId: number) {
    this.loading$.next(true);
    return this.http.patch<any>(`${environment.apiUrl}/projects/${projectId}/board-status/${statusId}/edit-column`, { columnId }).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  changeStatusOrder(projectId: number, columnId: number, payload: ChangeStatusOrderRequest) {
    const apiUrl = `${environment.apiUrl}/projects/${projectId}/board-status/change-order-status/${columnId}`;
    return this.http.put<BoardStatus>(apiUrl, payload);
  }
}

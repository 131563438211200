import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { HOUR_MINUTE, TIME_FORMAT } from 'src/app/shared';

@Pipe({ name: 'getTimePointPosition' })
export class GetTimePointPositionPipe implements PipeTransform {
  transform(time: string, startTime: string, workingMinutes: number) {
    const position =  moment(time, TIME_FORMAT).diff(moment(startTime, TIME_FORMAT), 'minutes') / workingMinutes * 100 + '%';
    return `calc(${position})`;
  }
}

import { TaskPermissionState } from './_models';
import { ProjectSettingPermissionState } from './_models/project-setting';
export * from './_models';

export interface PermissionState {
  task: TaskPermissionState;
  backlogs: FeaturePermissionState;
  releaseDetails: FeaturePermissionState;
  taskFinder: FeaturePermissionState;
  storyline: FeaturePermissionState;
  projectSetting: ProjectSettingPermissionState;
  epic: FeaturePermissionState;
  taskTemplate: FeaturePermissionState;
  releases: FeaturePermissionState;
  activeBoard: FeaturePermissionState;
  releaseNote: FeaturePermissionState;
  sprint: FeaturePermissionState;
  statusGroup: FeaturePermissionState;
}

export interface FeaturePermissionState {
  view?: string[];
  edit?: string[];
  viewAnother?: string[];
  editAnother?: string[];
}

export interface ShareTarget {
  objectType: ShareTargetType;
  objectId: number;
  objectEmail: string;
  objectAvatar: string;
  objectName: string;
}

export interface SharingPayload {
  targets: ShareTarget[];
  permission?: string;
}

export interface SharedItem {
  target: ShareTarget;
  permission?: string;
}

export enum ShareTargetType {
  USER = 'USER',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
}

export const SharePermissions = ['View', 'Edit'];

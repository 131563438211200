
export enum OkrContributorType {
  PERSONAL= 'PERSONAL',
  COMPANY= 'COMPANY',
  GROUP= 'GROUP',
  TEAM= 'TEAM',
}

export enum OkrIndicator {
  LEADING = 'LEADING',
  LAGGING = 'LAGGING',
}


export enum OkrCategory {
  KPI = 'KPI',
  OBJECTIVE = 'OBJECTIVE',
}

export enum OkrProgressPercent {
  BEGIN = 25,
  LOW = 50,
  MEDIUM = 75,
  HIGH = 100,
}

export enum FragOkrDashBoard {
  GROUP = 'group',
  TEAM = 'team',
  USER = 'user',
}

export enum OkrDashboarDetailParams {
  userIds= 'userIds',
  teamIds= 'teamIds',
  groupIds= 'groupIds',
}

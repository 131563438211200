import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent implements OnInit {
  @Input() customClass = '';
  @Input() icon = 'more_horiz';
  @Input() transparent = true;
  @Input() isSetWidth  = true;
  @Input() placement = 'bottom right';
  @Input() dropdownClass = 'dropdown-menu-right';
  @Output() shown = new EventEmitter<void>();
  @Output() hidden = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onShowDropdown() {
    this.shown.emit();
  }

  onHideDropdown() {
    this.hidden.emit();
  }
}

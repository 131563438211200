import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DynamicFieldState } from './dynamic-field.models';
import { taskFieldSelector } from './dynamic-field.entity';

export const featureKey = 'dynamicField';

const selectFeature = createFeatureSelector<DynamicFieldState>(featureKey);

export const selectTaskDFields = createSelector(selectFeature, (state) =>
  taskFieldSelector.selectAll(state.taskFields)
);

export const selectProjectTaskDFields = (projectId: number) =>
  createSelector(selectFeature, (state) => {
    return state.taskFields.entities[projectId]?.fields;
  });

export const selectTaskDFieldsByProject = (projectId?: number) =>
  createSelector(selectTaskDFields, (state) => {
    const filteredState = projectId
      ? state.filter((field) => field.projectId === projectId)
      : state;

    return filteredState.reduce(
      (res, cur) => ({ ...res, [cur.projectId]: cur.fields }),
      {}
    );
  });

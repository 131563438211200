import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, isUndefined } from 'lodash';
import { exhaustMap, map, catchError, of, withLatestFrom } from 'rxjs';
import { FieldService } from '../../dynamic-field/_services/field-service';
import { DynamicFieldDataService } from '../../dynamic-field/_services/dynamic-field-data.service';
import { DynamicFieldHash } from 'src/app/shared';
import { ToastService } from 'src/app/core';
import { selectProjectTaskPermission } from '../permission';
import { ProjectsTaskDFields } from './dynamic-field.models';
import { DynamicFieldActions } from '.';

@Injectable()
export class DynamicFieldEffects {
  constructor(
    private actions$: Actions,
    private fieldService: FieldService,
    private toastService: ToastService,
    private store: Store,
    private dynamicFieldDataService: DynamicFieldDataService
  ) {}

  getTaskDFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DynamicFieldActions.getTaskDFields),
      withLatestFrom(this.store.select(selectProjectTaskPermission())),
      exhaustMap(([, projectPermission]) => {
        const tab = this.dynamicFieldDataService.getDynamicFieldByHash(
          DynamicFieldHash.PAGE_TASK_TABS
        );
        const section = this.dynamicFieldDataService.getDynamicFieldByHash(
          DynamicFieldHash.PAGE_TASK_SECTIONS
        );

        return this.fieldService.getAllByDFieldId(tab.id, section.id).pipe(
          map((fields) => {
            const result: ProjectsTaskDFields[] = projectPermission.reduce((prev, cur) => {
              const { projectId, permission } = cur;

              const _fields = cloneDeep(fields)
                .filter((e) => e.sectionId === section?.id)
                .map((e) => {
                  if (permission.canEditAnother) {
                    e.isVisible = true;
                    e.disabled = false;
                    
                    return { ...e, template: this[e.hash] };
                  }

                  /**
                   * Check permission on advance fields
                   */
                  if (e.isAdvanced && e?.isVisible) {
                    e.isVisible =
                      permission.canEditAdvance || permission.canViewAdvance;
                    e.disabled = !permission.canEditAdvance;

                    return { ...e, template: this[e.hash] };
                  }

                  const permissionMap = {
                    ASSIGNEE: permission.canEditAssignee,
                    REPORTER: permission.canEditReporter,
                    CC: permission.canEditCC
                  };

                  e.disabled = isUndefined(permissionMap[e.hash]) ? !permission.canEdit : !permissionMap[e.hash];

                  return { ...e, template: this[e.hash] };
                });

              prev.push({ projectId, fields: _fields });

              return prev;
            }, []);

            return DynamicFieldActions.setTaskDFields({ data: result });
          }),
          catchError((error) => {
            const httpError = JSON.parse(error);
            this.toastService.error(httpError?.message);
            return of(null);
          })
        );
      })
    );
  });
}

import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class IntegrationJiraToastService {
  private isVisible: boolean = false;

  constructor(
    private nzNotification: NzNotificationService
  ) {}

  setVisible(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  getVisible() {
    return this.isVisible;
  }

  closeToast() {
    this.nzNotification.remove();
  }
}

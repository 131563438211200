import { AwsImg } from '../_components/editor-content-editable/editor-html-view/editor-html-view.component';
import { StatusFeedback } from '../_utils';
import { CVShort, Okr, OkrKeyResult, Pageable, TaskSortResponse, User, UserShort } from './index';
import Quill from 'quill';

export class CommentUser {
  id: number;
  fullName: string;
  avatar: string;
}

export class Comment {
  id: number;
  user: UserShort;
  comment: string;
  jiraComment?: string; // adf format
  createdAt: string;
  modifiedAt: string;
  totalReply: number;
  userReply: CommentUser;

  isOpen?: boolean;
  html?: string;
  awsImgs?: AwsImg[];
  isSaving?: boolean;
  isDeleting?: boolean;
  editorInstance?: Quill;

  emoji?: EmojiComment;
  commentFeedback?: CommentFeedbackResponse;
  isShowFeedback?: boolean;

  // reply
  isExpandComments?: boolean;
  hasChildComment?: boolean;
  comments?: Comment[];
  isShowAddComment?: boolean;
  mentionUser?: UserShort;
  isAddReply?: boolean;
  isLoadReply?: boolean;

  canEdit?: boolean;
  canDelete?: boolean;
}

export interface TaskComment extends Comment {
  task: TaskSortResponse;
  comments?: TaskComment[];
}

export interface OkrComment extends Comment {
  okr: Okr;
}

export interface KrComment extends Comment {
  kr: OkrKeyResult;
}

export interface CVComment extends Comment {
  cv?: CVShort;
}

export interface EmojiRequest {
  emojiCode: string;
}

export interface EmojiComment {
  [emojiCode: string]: EmojiInfo;
}

export interface EmojiInfo {
  users: User[];
  totalUser: number;
}

export interface CommentFeedback {
  userIds?: number[];
  userRequestIds?: number[];
  dateFeedback: string;
  statusFeedback: StatusFeedback;
}

export interface CommentFeedbackResponse extends CommentFeedback {
  users: User[];
  usersRequest: User[];
}

export interface StatusFeedbackUI {
  name: string;
  value: StatusFeedback;
  icon: string;
}

export interface CommentPayload {
  parentId: number;
}

export interface CommentUpdatePayload {
  comment: string;
  parentId?: number;
  jiraComment?: string;
}

export interface PositionComment {
  position: number;
  totalElements: number;
}

export interface CommentIndexRange {
  startIndex: number;
  endIndex: number;
}

export class CommentState {
  range: CommentIndexRange;
  position: PositionComment;
  pageable: Pageable;

  constructor(obj) {
    this.range = obj?.range || {startIndex: 0, endIndex: 0};
    this.position = obj?.position || null;
    this.pageable = obj?.pageable || null;
  }
}

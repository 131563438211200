import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';
import { DATE_FORMAT_MONTH_NAME_DAY } from '../../_utils';

@Component({
  selector: 'app-task-date',
  templateUrl: './task-date.component.html',
  styleUrls: ['./task-date.component.scss']
})
export class TaskDateComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.control.setValue(moment(this.date).toDate(), { emitEvent: false });

    }
  }
  @Input() isReadOnly = false;
  @Input() date: Date | string;
  @Input() isDueDate = false;
  @Output() changeDate: EventEmitter<Date | string> = new EventEmitter<Date | string>();
  DATE_FORMAT = DATE_FORMAT_MONTH_NAME_DAY;
  control: UntypedFormControl = new UntypedFormControl('');
  @ViewChild('input') input: ElementRef;

  ngOnInit(): void {
    this.control.setValue(moment(this.date).toDate());
    this.control.valueChanges.subscribe((value: Date) => {
      this.onDateChange(value);
    })
  }



  onDateChange(date: Date) {
    if (!date) {
      return;
    }
    const dateFormatted = UtilService.getDateFormat(date);
    const curentDate = UtilService.getDateFormat(this.date);
    if (dateFormatted !== curentDate) {
      this.changeDate.emit(date);
    }
  }
}

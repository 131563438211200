import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import 'quill-mention';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService, UtilService } from 'src/app/core';
import { Attachment } from '../..';

// import module
import TableModule from 'quill1-table';
import Quill from 'quill';
import Delta from 'quill-delta';
import { JiraMarkService } from 'libs/jira-mark/src/public-api';
import { HttpClient } from '@angular/common/http';

// import formats
var Image = Quill.import('formats/image');
Image.sanitize = function(url) {
  return url;
};
Image.className = 'quill-img';
Quill.register(Image, true);

// register module
Quill.register('modules/table', TableModule);

export interface EditorUserMention {
  id: number;
  value: string;
}

export interface AttachmentFile {
  file: File;
  previewUrl: string;
}

export const IMAGE_COMMENT_FILE_LOADING = `assets/img/editor-coment-image.gif?key=`;
export const IMAGE_COMMENT_FILE = `assets/img/editor-coment-file.svg`;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit, OnDestroy {
  @ViewChild('quillEditorHTML') quillEditorHTML: ElementRef;
  @ViewChild('quillEditor') quillEditorComponent: QuillEditorComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() control: UntypedFormControl;
  @Input() placeholder = 'Insert text here ...';
  @Input() isMention = true;
  @Input() getUserPromise: (searchTerm: string) => Promise<EditorUserMention[]>;
  @Input() upload$: (file: File) => Observable<Attachment>;
  @Input() isLocalUpload = false;
  @Input() disabled = false;
  @Input() small = false;
  @Input() hideUpload = false;

  @Output() fileChanged: EventEmitter<AttachmentFile> = new EventEmitter<AttachmentFile>();
  @Output() onEditorCreated: EventEmitter<Quill> = new EventEmitter<Quill>();

  quill: Quill;
  modules: QuillModules = {};
  progressInfos: {
    [key: string]: {
      percent: number;
      error: string;
      fileAttachment: Attachment;
      subscription: Subscription;
    };
  } = {};
  selectedUserMention: EditorUserMention[] = [];
  destroyed$ = new Subject<void>();

  constructor(
    private toast: ToastService,
    private jiraMark: JiraMarkService,
    private http: HttpClient,
  ) { }

  get isUploading() {
    return Object.keys(this.progressInfos).some((key) => {
      return (
        this.progressInfos[key].percent < 100 &&
        this.progressInfos[key].error === '' &&
        (this.control?.value || '').includes(`key=${key}`)
      );
    });
  }

  ngOnInit(): void {
    const mentionModule = {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[a-zA-ZÀ-ỹ\s]*$/,
      showDenotationChar: false,
      spaceAfterInsert: false,
      onSelect: (item, insertItem) => {
        const editor = this.quillEditorComponent.quillEditor;

        insertItem(item);
        this.selectedUserMention.push(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: async (searchTerm, renderList) => {
        const matchedPeople = await this.getUserPromise(searchTerm);
        renderList(matchedPeople);
      },
      renderItem: (item, searchTerm) => {
        return `<img style=" width: 20px; height: 20px; border-radius: 20px; margin-right: 0.5rem; " src="${item.avatar || 'assets/icons/account_black.svg'}"><span>${item.value}</span>`;
      }
    };

    this.modules = {
      toolbar: this.disabled
        ? null
        : {
          container: [
            [
              {
                table: TableModule.tableOptions()
              },
              {
                table: [
                  'insert',
                  'append-row-above',
                  'append-row-below',
                  'append-col-before',
                  'append-col-after',
                  'remove-col',
                  'remove-row',
                  'remove-table',
                  'split-cell',
                  'merge-selection',
                  'remove-cell',
                  'remove-selection',
                  'undo',
                  'redo'
                ]
              }
            ],
            // [{ 'font': [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': 1 }, { 'header': 2 }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            this.hideUpload ? ['link'] : ['link', 'image'],

            ['clean'],
          ],
          handlers: {
            image: this.handleUploadFile,
          },
        },
      mention: null,
      table: true,
      keyboard: {
        // Since Quill’s default handlers are added at initialization, the only way to prevent them is to add yours in the configuration.
        bindings: {
          tab: {
            key: 'tab',
            handler: function (range, keycontext) {
              let outSideOfTable = TableModule.keyboardHandler(this.quill, 'tab', range, keycontext)
              if (outSideOfTable){ //for some reason when you return true as quill says it should hand it to the default like the other bindings... for tab it doesnt.
                this.quill.history.cutoff(); //mimic the exact same thing quill does
                let delta = new Delta().retain(range.index)
                                       .delete(range.length)
                                       .insert('\t');
                this.quill.updateContents(delta, Quill.sources.USER);
                this.quill.history.cutoff();
                this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
              }
            }
          },
          shiftTab: {
            key: 'tab',
            shiftKey: true,
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'shiftTab', range, keycontext);
            }
          },
          selectAll: {
            key: 'a',
            ctrlKey: true,
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'selectAll', range, keycontext);
            }
          },
          backspace: {
            key: 'backspace',
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'backspace', range, keycontext);
            }
          },
          delete: {
            key: 'delete',
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'delete', range, keycontext);
            }
          },
          undo: {
            ctrlKey: true,
            key: 'z',
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'undo', range, keycontext);
            }
          },
          redo: {
            ctrlKey: true,
            shiftKey: true,
            key: 'z',
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'redo', range, keycontext);
            }
          },
          copy: {
            ctrlKey: true,
            key: 'c',
            handler: function (range, keycontext) {
              return TableModule.keyboardHandler(this.quill, 'copy', range, keycontext);
            }
          }
        }
      }
    } as any;

    if (this.isMention && this.getUserPromise) {
      this.modules.mention = mentionModule;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getHtmlMentionIds(): string[] {
    const nodeHtml = this.quillEditorHTML.nativeElement?.getElementsByClassName(
      'mention'
    ) as HTMLCollection;
    const dataIds = nodeHtml ? Array.from(nodeHtml) : [];
    return dataIds.map((e) => e.getAttribute('data-id'));
  }

  getUserMentions() {
    return this.selectedUserMention
      .filter(
        (user, index) =>
          this.selectedUserMention.findIndex((e) => e.id === user.id) === index
      ) // Remove duplicates
      .filter((e) => this.getHtmlMentionIds().includes(`${e.id}`));
  }

  handleUploadFile = () => {
    this.fileInput?.nativeElement?.click();
  }

  setControlValue(html: string, options = {}) {
    this.control.setValue(html, options);
  }

  getEditorInstance(quill) {
    this.quill = quill;
    if (!this.quill) {
      return;
    }
    this.onEditorCreated.emit(quill);

    const html = this.jiraMark.parse(this.control.value);
    this.setControlValue(html);

    const length = this.quill.getLength();
    this.quill.setSelection(length);
    this.quill.focus();
  }

  setHtml(text: string) {
    const html = this.jiraMark.parse(text);
    this.setControlValue(html);
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    const files = Array.from(e.clipboardData.files || []);

    if (files.length > 0) {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
        this.onUploadFile(files);
      }, 500);
    } else {
      const text = e.clipboardData.getData('text');
      const urlRegex = /^(https?:\/\/|www\.|mailto:)[^\s]+$/i;
      if (urlRegex.test(text)) {
        this.onInsertLink(text, e);
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(e.clipboardData.getData('text/html'), 'text/html');
      const imgElements = doc.querySelectorAll('img');
      const imgSrc = Array.from(imgElements).map(img => img.getAttribute('src')).filter(e=> e.startsWith('blob:'));
      if (imgSrc?.length > 0) {
        this.fetchAndConvertBlob(imgSrc);
      }
    }
  }

  fetchAndConvertBlob(blobs: string[]) {
    blobs.forEach((blobSrc, index) => {
      fetch(blobSrc)
        .then(response => response.blob())
        .then(blob => {
          const fileName = `${new Date().getTime()}-${index}.${blob.type.split('/').pop().trim().toLocaleLowerCase()}`;
          const file = new File([blob], fileName, { type: blob.type });
          this.fileChanged.emit({ file, previewUrl: blobSrc });
        });
    })
  }

  onFileChanged(fileList: FileList) {
    if (!(this.upload$ || this.isLocalUpload)) {
      return;
    }

    const files = Array.from(fileList) || [];
    this.onUploadFile(files);
  }

  onInsertLink(text: string, e: ClipboardEvent) {
    e.preventDefault();
    this.quill.focus();
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, text, 'link', text, 'user');
    setTimeout(() => {
      this.setControlValue(this.quill?.root?.innerHTML || '');
      this.quill.setSelection(cursorPosition + text.length);
    }, 100);
  }

  onUploadFile(files: File[]) {
    if (!(this.upload$ || this.isLocalUpload) || files.length === 0) {
      return;
    }
    let quillIndex = this.quill.getSelection()?.index;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const key = `${new Date().getTime()}-${index}`;
      this.quill.insertEmbed(
        quillIndex,
        'image',
        `${IMAGE_COMMENT_FILE_LOADING}` + `${key}`
      );
      quillIndex += 1;

      this.uploadFile(file, key, () => {
        setTimeout(() => {
          this.quill.setSelection(quillIndex);
        }, 100);
      });
    }
    this.setControlValue(this.quill?.root?.innerHTML || '', { emitEvent: false });
    this.quill.setSelection(quillIndex);
  }

  uploadFile(file: File, key: string, successFn: () => void) {
    if (this.upload$) {
      this.progressInfos[key] = {
        percent: 0,
        error: '',
        fileAttachment: null,
        subscription: null,
      };

      this.progressInfos[key].subscription = this.upload$(file)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (event) => {
            const fileAttachment = event;
            this.progressInfos[key].percent = 100;
            this.progressInfos[key].fileAttachment = fileAttachment;
            this.updateImage(key, fileAttachment);

            successFn?.();
          },
          (error: string) => {
            const httpError = JSON.parse(error);
            this.toast.error(httpError?.message);
            this.progressInfos[key].error = httpError?.message;
          }
        );

      return;
    }

    if (this.isLocalUpload) {
      this.handleLocalImage(file, key);
      successFn?.();
    }
  }

  updateImage(key: string, file: Attachment) {
    const htmlText: string = this.control.value;
    let newText = '';
    if (UtilService.isImageURL(file.url)) {
      newText = htmlText.replace(
        `${IMAGE_COMMENT_FILE_LOADING}` + `${key}`,
        `${file.objectUrl}`
      );
    } else {
      newText = htmlText.replace(
        `${IMAGE_COMMENT_FILE_LOADING}` + `${key}`,
        `${IMAGE_COMMENT_FILE}?file-url=${file.url}`
      );
    }

    this.setControlValue(newText)
  }

  handleLocalImage(file: File, key: string) {
    let previewUrl;
    if (file.type.includes('image')) {
      const reader = new FileReader();

      reader.readAsDataURL(file); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        previewUrl = event.target.result;
        this.updateLocalImage(file, key, previewUrl);
      };
    } else {
      previewUrl = `${IMAGE_COMMENT_FILE}?key=${key}`;
      this.updateLocalImage(file, key, previewUrl);
    }
  }

  updateLocalImage(file: File, key: string, previewUrl: string) {
    this.fileChanged.emit({ file, previewUrl });
    const htmlText: string = this.quill.root.innerHTML;
    const newText = htmlText.replace(
      `${IMAGE_COMMENT_FILE_LOADING}` + `${key}`,
      previewUrl
    );

    this.setControlValue(newText);
  }

  getUploadAttachments(): Attachment[] {
    return Object.keys(this.progressInfos)
      .filter((e) => {
        return this.progressInfos[e].fileAttachment;
      })
      .map((e) => {
        return this.progressInfos[e].fileAttachment;
      });
  }

}

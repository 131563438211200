import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DocumentFolderIcons } from 'src/app/shared';
import { UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';

interface FolderSetting {
  icon: string;
  colorCode: string;
}

@Component({
  selector: 'app-document-folder-setting',
  templateUrl: './document-folder-setting.component.html',
  styleUrls: ['./document-folder-setting.component.scss']
})
export class DocumentFolderSettingComponent extends UpsertComponent<FolderSetting> implements OnInit  {
  colorCode = '';
  folders = DocumentFolderIcons;
  constructor(private formBuilder: UntypedFormBuilder) {
    super(formBuilder);
  }

  createForm() {
    this.form = this.fb.group({
      icon: [this.object.icon ? this.object.icon : this.folders[0].icon, [Validators.required]],
      colorCode: [this.object.colorCode ? this.object.colorCode : this.folders[0].colorCode, [Validators.required]]
    });
  }

  edit() {
    const formValue = this.form.getRawValue();
    this.objectResponse.next({...formValue});
  }
}

<div class="switch-company-wrapper"
     [ngClass]="{ 'mobile-wrapper': isMobileWrapper, 'company-list-wrapper': isLandingPageScreen }">
  <ng-container *ngFor="let item of companyLists">
    <div class="company-item" (click)="onSwitchCompany($event, item)">
      <div class="mr-2 company-logo">
        <div *ngIf="!item.company?.logo" class="default-logo">
          <span>{{item.company.name | getShortName}}</span>
        </div>
        <img *ngIf="item.company?.logo" [src]="item.company.logo" class="default-logo">
      </div>
      <div class="flex-fill text-ellipsis">
        <div class="fw-6 text-ellipsis">{{item.company.name}}</div>
        <div class="domain-company text-ellipsis">{{item.company.domain}}</div>
      </div>
      <div class="active bg-success mt-1 mr-2 active-pointer" *ngIf="item | isCurrentCompany : loggedUser"></div>
      <div class="default pointer" (click)="onSetDefault($event, item)">
        <mat-icon class="icon-sm" [svgIcon]="item?.defaultFlg ? 'star-filled' : 'star'"></mat-icon>
      </div>
    </div>
  </ng-container>
</div>

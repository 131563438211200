import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  getCookie(name: string) {
    var cookieName = name + "=";
    var cookieArray = document.cookie.split(';');

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }

    return null; // Cookie not found
  }

  setCookie(name: string, value: string, daysToExpire: number = 365) {
    var date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

    var expires = "expires=" + date.toUTCString();
    if (environment.env == 'local') {
      document.cookie = name + "=" + value + `;${expires}` + ";path=/";
    } else {
      document.cookie = name + "=" + value + `;${expires}` + `;domain=.${environment.mainDomain}` + ";path=/";
    }
  }

  clearCookie(name: string) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
}

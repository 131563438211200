
export enum Placement {
  auto = 'auto',
  top = 'top',
  bottom = 'bottom',
  right = 'right',
  left = 'left',
  topLeft = 'top left',
  topRight = 'top right',
  bottomLeft = 'bottom left',
  bottomRight = 'bottom right',
}

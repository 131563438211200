import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '../../models/loading';
import { EditorMode } from 'src/app/shared/_components/x-editor/x-editor';
import * as AppActions from './app.actions';

export const appFeatureKey = 'auth';

export interface AppState {
  loading?: LoadingState;
  defaultUrlPage?: string;
  editorMode: EditorMode;
  progressLoading: boolean;
}

export const initAppState: AppState = {
  editorMode: 'view',
  progressLoading: false
};

export const appReducer = createReducer(
  initAppState,
  on(AppActions.loading, (state, loading: LoadingState) => {
    return { ...state, loading };
  }),
  on(AppActions.setEditorMode, (state, { mode }) => {
    return { ...state, editorMode: mode };
  }),
  on(AppActions.progressLoading, (state, { loading }) => {
    return {...state, progressLoading: loading};
  })
);

import FroalaEditor from 'froala-editor';
import { head } from 'lodash';
import { AppInjector } from 'src/app/app.module';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { environment } from 'src/environments/environment';
import { FroalaEvent, FroalaUpload } from '../models';
import { USER_ACCESS_TOKEN_KEY } from 'src/app/shared/_utils/consts';

interface AttachmentHandler {
  lazy: () => void;
  eager: () => void;
}

FroalaEditor.PLUGINS.autoUploadImage = function (editor: FroalaEditor) {
  const fileService: FileService = AppInjector.get(FileService);
  let isPasting = false;
  let uploadQueue = [];

  function _init() {
    // listen event trigger copy paste files to prevent issue image types is invalid
    editor.events.on(FroalaEvent.PASTE_FILE, function(_isPasting: boolean) {
      isPasting = _isPasting;
    });

    if (!editor.opts.autoUpload?.lazy) {
      onBeforeUpload();
      onUploaded();
      onLoaded();
    }
  }

  function onBeforeUpload() {
    editor.events.on(FroalaEvent.IMAGE_BEFORE_UPLOAD, function (files: FileList) {
      if (isPasting) {
        return false;
      }

      if (files.length > 1) {
        const [_file, ..._files] = files;
        uploadQueue = _files;
      }
    });
  }

  function onUploaded() {
    editor.events.on(FroalaEvent.IMAGE_UPLOADED, function (response, placeholder: JQuery<Element>) {
      // if (!editor.opts.autoUpload?.lazy) {
        const files = JSON.parse(response);
        const file = FroalaUpload.getPreviewUrl(
          head(files),
          editor.integrator.module()
        );

        // Trigger attachmentPushed event
        editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [{ files }], true);

        if (file) {
          fileService.getAuthorization(file.url).subscribe({
            next: ([url]) => {
              editor.image.insert(
                url.fullUrl,
                environment.env == 'local' ? false : true,
                { name: file.name, origin: file.url },
                placeholder
              );
            }
          });
        }

        return false;
      // }
    });
  }

  function onLoaded() {
    editor.events.on(FroalaEvent.IMAGE_LOADED, function($img) {
      if (isPasting) {
        editor.image.remove($img);
        isPasting = false;
      }
      uploadInQueue($img);
    })
  }

  function uploadInQueue(image: JQuery<Element>) {
    if (uploadQueue.length > 0) {
      editor.image.exitEdit(true);
      editor.selection.setAfter(head(image));
      editor.selection.restore();
      const [_file, ..._files] = uploadQueue;
      editor.image.upload(uploadQueue);
      uploadQueue = _files;
    }
  }

  return {
    _init
  };
};

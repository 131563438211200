import FroalaEditor from 'froala-editor';
import { environment } from 'src/environments/environment';

FroalaEditor.DefineIcon('chatGPT', { NAME: 'help', SVG_KEY: 'help' });

FroalaEditor.RegisterCommand('chatGPT', {
  icon: 'chatGPT',
  title: 'Ask ChatGPT',
  plugin: 'chatGPT',
  callback: function () {
    this.chatGPT.ask();
  },
  undo: true,
  focus: false,
  refreshAfterCallback: false
});

FroalaEditor.PLUGINS.chatGPT = function (editor: FroalaEditor) {
  function _init() {}

  function ask() {
    const selection = editor.selection.text();

    const data = {
      model: environment.chatGpt.model,
      messages: [
        {
          role: "user",
          content: selection
        }
      ],
      
      max_tokens: environment.chatGpt.maxToken
    };

    fetch(environment.chatGpt.endpoint + '/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${environment.chatGpt.apiKey}`
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        const answer = data.choices[0].message.content;
        editor.html.insert(answer);
      });
  }

  return {
    _init: _init,
    ask
  };
};

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { defaultHexColorIcon } from 'src/app/shared';
import * as colorFn from 'src/app/shared/_utils/color';

@Pipe({ name: 'color' })

export class ColorPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(hex: string) {
    if (!hex) {
      return defaultHexColorIcon;
    }
    return colorFn.getColor(hex);
  }
}

export const VideoAllowedTypes = [
  'mp4',
  'webm',
  'ogg',
  'quicktime',
  'x-ms-wmv',
  'x-msvideo',
  'video/mpeg',
  'video/x-matroska',
  'audio/ogg'
];

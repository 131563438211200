import { FileResponse, FileType, Pageable } from 'src/app/shared';
import { GeneralConfirmComponent } from '../../_components';
import { DocumentFile } from './document-file';

export class DocumentTrashFile extends DocumentFile {
  baseType = 'DocumentTrashFile';
  pageable: Pageable = {
    page: 0,
    size: 100,
    sort: 'modifiedAt,desc'
  };
  constructor(
    file: FileResponse
  ) {
    super(file);
  }

  canDownload() {
    return true;
  }

  canForceDelete() {
    return true;
  }

  canRestore() {
    return true;
  }

  canAccessFolderChild() {
    return false;
  }

  canEmptyTrash() {
    return this.file?.path === 'trash/';
  }

  getChilds() {
    this.getTrashFiles({keyword: ''}, this.pageable);
  }

  getTrashFiles(payload: {keyword?: string}, pageable: Pageable) {
    this.getSubscription?.unsubscribe();
    this.getSubscription = this.fileService.getTrashFiles(payload, pageable).subscribe(
      (res) => {
        const items = res.content;
        this.items = items.map(e => {
          const item = new DocumentTrashFile(e);
          item.parent = this;
          return item;
        });
        this.delegate?.loadChildsDone(this, this.items);
      },
      (err) => {
        this.items = [];
        this.errorFn(err);
        this.delegate?.loadChildsDone(this, this.items);
      }
    );
  }

  onEmptyTrash() {
    const initialState = {
      title: 'Empty Recycle Bin',
      message: 'Do you want to empty recycle bin?',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.fileService.emptyRecycleBin().subscribe(
          () => {
            this.delegate?.loadChildsDone(this, []);
          },
          (err) => {
            this.errorFn(err);
          }
        );
      }
    });
  }

  search() {
    this.getTrashFiles({keyword: this.keyword}, this.pageable);
  }

  onSortChange(sort: string) {
    this.pageable.sort = sort;
    this.search();
  }

  onKeywordChange(keyword: string) {
    this.keyword = keyword;
    this.search();
  }
}

import { Component, Input } from '@angular/core';
import { ProjectMemberUser } from 'src/app/shared';

@Component({
  selector: 'app-user-option',
  template: `
    <div class="custom-option mr-1">
      <app-avatar [url]="item.avatar" [name]="item.fullName"></app-avatar>
      <span class="option-label">{{ item.fullName }}</span>
    </div>
  `
})
export class UserOptionComponent {
  @Input() item: ProjectMemberUser;
}

import { TemplateRef, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import FroalaEditor from 'froala-editor';
import { EditorBreadcrumbComponent } from 'src/app/shared/_components/x-editor/_components/editor-breadcrumb/editor-breadcrumb.component';
import { WikiTitleComponent } from 'src/app/site-management/wiki/_components/wiki-title/wiki-title.component';

// Define the plugin.
// The editor parameter is the current instance.
FroalaEditor.PLUGINS.topContent = function (editor: FroalaEditor) {

  function setBreadcrumb(breadcrumbTemplate: TemplateRef<any>) {
    const viewRef = editor.integrator.view() as ViewContainerRef;
    const breadcrumb = viewRef.createComponent(EditorBreadcrumbComponent);

    breadcrumb.setInput('breadcrumbTemplate', breadcrumbTemplate);
    breadcrumb.changeDetectorRef.detectChanges();

    const breadcrumbWrapper = $('<div>').addClass('breadcrumb-wrapper');
    $(breadcrumbWrapper).append($(breadcrumb.location.nativeElement));
    $(getTopBlock()).append(breadcrumbWrapper);

    breadcrumb.instance.onResizeEditor
      .subscribe((isExpand) => {
        if (isExpand) {
          editor.el.parentNode.classList.remove('medium-wrapper');
        }
        else {
          editor.el.parentNode.classList.add('medium-wrapper');
        }
      });
  }

  function setTitle(titleCtrl: UntypedFormControl) {
    const viewRef = editor.integrator.view() as ViewContainerRef;
    const title = viewRef.createComponent(WikiTitleComponent);
    title.setInput('control', titleCtrl);
    title.changeDetectorRef.detectChanges();
    const titleWrapper = $('<div>').addClass('title-wrapper');
    $(titleWrapper).append($(title.location.nativeElement));
    $(getTopBlock()).append(titleWrapper);
  }

  function getTopBlock() {
    const topBlock = $(editor.el.parentNode).find('.fr-top-editor-wrapper');
    if (topBlock?.length > 0) {
      return topBlock[0];
    }
    const topWrapper = $('<div>').addClass('fr-top-editor-wrapper');
    $(editor.el).before(topWrapper);

    // add markdown wrapper
    const contentWrapper = $('<div>').addClass('fr-markdown-content-wrapper');
    $(editor.el.parentNode).wrapAll(contentWrapper);
    //
    return topWrapper;
  }

  return {
    setTitle,
    setBreadcrumb,
  };
};

import { SprintTaskAnalysis } from "./sprint.model";

export interface BoardSprint {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  goal: string;
  status: BoardSprintStatus;
  deleted: boolean;
  duration: number;
}

export enum BoardSprintStatus {
  New = 'New',
  Active = 'Active',
  Completed = 'Completed',
}

export interface BoardSprintSearchRequest {
  keyword?: string;
  projectId: number;
}

export interface BoardSprintCompleteResponse {
  sprint: BoardSprint;
  numTaskDone: number;
  numTaskNotDone: number;
  countStatus: SprintTaskAnalysis;
  totalTask: number;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'func'
})

export class FuncPipe implements PipeTransform {
  transform(func: (...params) => any, ...params) {
    return func(...params);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { Group, GroupRequest, List2Res, PageInfo, Pageable, Team, User } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class GroupService extends EntityCollectionServiceBase<Group> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient
    ) {
    super('groups', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getGroups(query: GroupRequest , pageable: Pageable) {
    const params = queryString.stringify({ ...query, ...pageable});
    return this.http.get<List2Res<Group>>(`${environment.apiUrl}/groups/?${params}`);
  }

  addMembers(groupId: number, payload: {userIds: number[]}): Observable<User[]> {
    return this.http.post<User[]>(`${environment.apiUrl}/groups/${groupId}/members`, payload);
  }

  removeMembers(groupId: number, payload: {userIds: number[]}) {
    return this.http.request('delete', `${environment.apiUrl}/groups/${groupId}/members`, { body: payload });
  }

  getMembers(groupId: number, pageable: Pageable): Observable<List2Res<User>> {
    const params = queryString.stringify(pageable);
    return this.http.get<List2Res<User>>(`${environment.apiUrl}/groups/${groupId}/members?${params}`);
  }
}

export const MoreRichDefault = [
  'insertFile',
  'insertImage',
  'insertVideo',
  'insertTable',
  'insertLink',
  'emoticons',
  'fontAwesome',
  'specialCharacters',
  'embedly',
  'insertHR',
  'spellChecker',
  'selectAll',
  'html',
  'print',
  'help',
];

export const MarkdownMoreRich = [
  'formatOL',
  'formatUL',
  'lineHeight',
  'outdent',
  'indent',
];

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as queryString from 'query-string';
import { environment } from 'src/environments/environment';
import { AccountAuthResponse, AccountCompanyPatchRequest, AccountCompanyPatchType,
  AccountCompanyResponse, AuthResponse, UserToken, UserTokenType
} from 'src/app/shared';
import { CookieService, ToastService } from 'src/app/core';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { AccountsService } from 'src/app/authentication/_services/accounts.service';
import { AuthState, saveAccountCompanies } from 'src/app/core/store/auth';

@Injectable({
  providedIn: 'root'
})
export class MultiCompanyService {
  constructor(
    private store: Store<AuthState>,
    private accountsService: AccountsService,
    private authService: AuthService,
    private toast: ToastService,
    private cookieService: CookieService
  ) { }

  verifyAccountCompany(data: AccountAuthResponse, currentDomain = null) {
    this.store.dispatch(saveAccountCompanies({data: data.accountCompanies}));

    // Save current email to cookie
    const email = data.accountCompanies[0].account.email;
    this.authService.setCurrentUserEmail(email);

    this.setBaseToken(data.accountAccessToken);
    const activeCompany = this.getActiveCompany(data.accountCompanies, currentDomain);

    // Exist only one company || default company -> current login flow
    if (activeCompany) {
      this.signInCompany(activeCompany);
    } else {
      this.authService.navigateToSelectCompany();
    }
  }

  signInCompany(company: AccountCompanyResponse, signedIn = false) {
    this.accountsService.signInAccount(company.id)
      .subscribe({
        next: (response: AuthResponse) => {
          this.authService.setCurrentUserEmail(company.account.email);
          if (signedIn) {
            this.authService.navigateToDomain(response);
            return;
          }
          this.authService.navigateToSignInToken({...response, domain: environment.mainDomain});
        },
        error: (error: string) => this.errorFn(error)
      });
  }

  setDefaultCompany(data: AccountCompanyResponse, callback?: () => void) {
    const payload: AccountCompanyPatchRequest = {
      type: AccountCompanyPatchType.DEFAULT_FLG,
      value: !data.defaultFlg
    };
    this.accountsService.setAccountCompanyDefault(data.id, payload)
      .subscribe({
        next: (response: AccountCompanyResponse) => {
          if (callback) {
            callback();
          }
          data.defaultFlg = response.defaultFlg;
          this.toast.success('Set default company successfully!');
        },
        error: (error: string) => this.errorFn(error)
      });
  }

  getActiveCompany(companies: AccountCompanyResponse[], currentDomain = null) {
    return companies.length === 1
      ? companies[0]
      : currentDomain
        ? companies.find(item => item.company.domain === currentDomain)
        : companies.find(item => item?.defaultFlg);
  }

  errorFn(error: string) {
    const httpError = JSON.parse(error);
    this.toast.error(httpError?.message);
  }

  getBaseTokenKey(): string {
    const email = this.authService.getCurrentUserEmail();
    const baseToken: UserToken = {
      type: UserTokenType.BASE,
      env: environment.env,
      email
    };

    return encodeURIComponent(queryString.stringify(baseToken));
  }

  getBaseToken() {
    const key = this.getBaseTokenKey();
    return this.cookieService.getCookie(key);
  }

  setBaseToken(token: string) {
    const key = this.getBaseTokenKey();
    this.cookieService.setCookie(key, token);
  }
}

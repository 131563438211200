import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DATE_UI_FORMAT } from 'src/app/shared';

@Component({
  selector: 'app-formly-date-picker',
  templateUrl: './formly-date-picker.component.html',
  styleUrls: ['./formly-date-picker.component.scss']
})
export class DatePickerType extends FieldType<FieldTypeConfig> {
  @Input() bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    customTodayClass: 'bs-datepicker-today',
    isAnimated: true
  };
}

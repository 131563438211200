<div class="dialog-container fr-popup-position-left-70">
  <div class="dialog-header d-flex align-items-center">
    <button type="button" class="btn btn-white mr-2 d-md-none p-0" (click)="onCancel()">
      <span class="material-icons">close</span>
    </button>
    <h4 class="mb-0"><app-i18n [key]="(task ? 'Edit' : 'Create') + ' Task'"></app-i18n></h4>
  </div>
  <div class="dialog-inner-content">
    <div class="upsert-dialog content-container dropzone" appFileDropZone
      (onFileDropped)="onFilesDropped($event)">
    <!-- <div class="upsert-dialog content-container dropzone"> -->
      <form class="upsert-form" [formGroup]="form" #formUpsert>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label class="required"><app-i18n [key]="'Project'"></app-i18n></label>
              <ng-template #projectName>
                <ng-select
                  formControlName="projectId" placeholder=""
                  [items]="projects" bindLabel="name" [clearable]="false"
                  [searchable]="true"
                  [typeahead]="projectTypeahead$">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <app-i18n [key]="item['name']"></app-i18n>
                  </ng-template>
                </ng-select>
                <app-form-error [form]="form" [controlName]="'projectId'">
                </app-form-error>
              </ng-template>

              <input class="form-control custom" [value]="selectedProject.name" disabled
                *ngIf="hrBoardProject;else projectName"/>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="form-group">
              <label class="required"><app-i18n [key]="'Type'"></app-i18n></label>
              <ng-select
                formControlName="type" placeholder=""
                [items]="itemTypes" bindLabel="name" [clearable]="false"
                [searchable]="false"
                >
                <!-- <ng-template ng-label-tmp let-item="item">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.icon" />
                    <span class="option-label">{{item.name}}</span>
                  </div>
                </ng-template> -->
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.icon" />
                    <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'type'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isForceStatus">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label class="required"><app-i18n [key]="'Status'"></app-i18n></label>
              <ng-select
                formControlName="statusId" placeholder=""
                [items]="itemStatuses" bindLabel="name" [clearable]="false"
                [searchable]="false">
                <ng-template ng-label-tmp let-item="item">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.icon" />
                    <span class="option-label">{{item.name}}</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.icon" />
                    <span class="option-label">{{item.name}}</span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'statusId'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <div class="d-flex" *ngIf="taskType?.name === TaskTypeEnum.Epic || isForceEpic">
                <label class="required"><app-i18n [key]="'Epic Name'"></app-i18n></label>
                <label>
                  <mat-icon class="info-icon" [svgIcon]="'info-circle-fill'"
                    tooltip="Provide a short name to identify this epic.">
                  </mat-icon>
                </label>
              </div>
              <label class="required" *ngIf="!(taskType?.name === TaskTypeEnum.Epic || isForceEpic)"><app-i18n [key]="'Task Name'"></app-i18n></label>
              <input class="form-control custom" formControlName="name">
              <app-form-error [form]="form" [controlName]="'name'" [maxLengthMessage]="TASK_NAME_MAX_LENGTH_MESSAGE">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="taskType?.name === TaskTypeEnum.Epic || taskType?.name === TaskTypeEnum.Storyline || isForceEpic || isForceStoryline">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label class="required"><app-i18n [key]="'Color'"></app-i18n></label>
              <app-color-picker-form [control]="form.get('colorCode')" [size]="24"></app-color-picker-form>
              <app-form-error [form]="form" [controlName]="'colorCode'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Description'"></app-i18n></label>

              <x-editor
                [control]="form.controls['description']"
                [projectId]="selectedProjectId"
                [uploadId]="task?.id"
                [mode]="'edit'"
                [placeholder]="('Insert text here' | translate) + '...'"
                [lazy]="true"
                [showSaveBtn]="false"
                (emitCancel)="onCancel()"
                (fileInserted)="onDescriptionFileChanged($event.file, $event.previewUrl, $event.blobUrl)"
              ></x-editor>
              <app-form-error [form]="form" [controlName]="'description'"></app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">

            <app-task-upsert-files
              [files]="selectedFiles"
            ></app-task-upsert-files>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Priority'"></app-i18n></label>
              <ng-select
                formControlName="priorityId" placeholder=""
                [items]="priorities" bindLabel="name" [clearable]="false"
                [searchable]="false">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.icon" />
                    <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'priorityId'">
              </app-form-error>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Due Date'"></app-i18n></label>
              <div class="datepicker-input">
                <input class="form-control custom" placeholder=""
                  formControlName="dueDate" bsDatepicker [bsConfig]="bsConfig"
                  placement="bottom left">
              </div>
              <app-form-error [form]="form" [controlName]="'dueDate'">
              </app-form-error>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Assignee'"></app-i18n></label>
              <ng-select
                class="custom" formControlName="assigneeId"
                [items]="members" bindLabel="fullName"
                [typeahead]="memberTypeahead$"
                [clearable]="false" [searchable]="true">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="custom-option">
                    <app-avatar
                      [url]="item.avatar"
                      [name]="item.fullName"
                    ></app-avatar>
                    <span class="option-label">{{item.fullName}}</span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'assigneeId'">
              </app-form-error>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Reporter'"></app-i18n></label>
              <ng-select
                class="custom"
                formControlName="reporter"
                [items]="members"
                [clearable]="false"
                [searchable]="true"
                [typeahead]="memberTypeahead$">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="custom-option">
                    <app-avatar
                      [url]="item.avatar"
                      [name]="item.fullName"
                    ></app-avatar>
                    <span class="option-label">{{item.fullName}}</span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'reporter'">
              </app-form-error>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label class="checkbox-control">
                <input #input type="checkbox" formControlName="privateFlg" id="privateFlg">
                <label>
                  <app-i18n [key]="'Private'"></app-i18n>
                  <mat-icon class="info-icon" [svgIcon]="'info-circle-fill'" tooltip="{{'If checked then only Reporter, Assignee and CC can see this task.' | translate}}"></mat-icon>
                </label>
              </label>
              <app-form-error [form]="form" [controlName]="'privateFlg'">
              </app-form-error>
            </div>
          </div>
          <app-form-error [form]="form" [controlName]="'privateFlg'">
          </app-form-error>
        </div>
        <ng-container *ngIf="!isHideFields">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Cc'"></app-i18n></label>
              <ng-select
                class="custom" formControlName="ccIds"
                [items]="members" bindLabel="fullName"
                [clearable]="false" [searchable]="true" [multiple]="true">
              </ng-select>
              <app-form-error [form]="form" [controlName]="'ccIds'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-6">
            <div class="form-group">
              <label><app-i18n [key]="form.get('projectId').value | projectBoardName"></app-i18n></label>
              <ng-select
                formControlName="sprintId" placeholder=""
                [items]="sprints" bindLabel="name" [clearable]="true"
                [searchable]="true">
              </ng-select>
              <app-form-error [form]="form" [controlName]="'sprintId'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Estimate'"></app-i18n></label>
              <input type="number" class="form-control custom" min="0"
                max="9999999" formControlName="timeOriginalEstimate">
              <app-form-error [form]="form"
                [controlName]="'timeOriginalEstimate'">
              </app-form-error>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Story Point'"></app-i18n></label>
              <input type="number" class="form-control custom" min="0"
                max="9999999" formControlName="storyPoint">
              <app-form-error [form]="form" [controlName]="'storyPoint'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Metric'"></app-i18n></label>
              <div class="control">
                <ng-select
                  formControlName="metric"
                  [items]="metrics"
                  bindLabel="name"
                  [searchable]="true"
                  [clearable]="true"
                  [multiple]="false"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Metric Value'"></app-i18n></label>
              <input type="number" class="form-control" min="0" formControlName="metricValue">
              <app-form-error [form]="form" [controlName]="'metricValue'"></app-form-error>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!(taskType?.name === TaskTypeEnum.Epic || isForceEpic)">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Epic link'"></app-i18n></label>
              <ng-select
                formControlName="epicId" placeholder="" [items]="epics"
                bindLabel="name" [clearable]="false" [searchable]="true">
              </ng-select>
              <app-form-error [form]="form" [controlName]="'epicId'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="canSetPlatform()">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Platform'"></app-i18n></label>
              <ng-select
                formControlName="platform"
                placeholder=""
                [items]="availablePlatforms"
                [searchable]="false"
                [clearable]="clearablePlatform()"
                [multiple]="false"
                [requiredIf]="isRequiredPlatform()">

                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="custom-option">
                    <app-icon [icon]="item"></app-icon>
                    <span class="ml-2">{{item.name}}</span>
                  </div>
                </ng-template>
              </ng-select>
              <app-form-error [form]="form" [controlName]="'platform'"></app-form-error>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="taskType?.name !== TaskTypeEnum.Storyline">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Storyline'"></app-i18n></label>
              <ng-select
                formControlName="storyline"
                placeholder=""
                [items]="storylines"
                bindLabel="name"
                [searchable]="true"
                [clearable]="true"
                [multiple]="false"
                [typeahead]="storylineTypeahead$">

                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  <div class="custom-option">
                    <img class="option-icon" [src]="item.type?.icon" />
                    <span class="option-label"><span class="text-primary mr-1">{{item.key}}</span>{{item.name}}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Label'"></app-i18n></label>
              <ng-select
                 class="custom" formControlName="labelIds"
                [items]="labels" bindLabel="name"
                [clearable]="true" [searchable]="true" [closeOnSelect]="true"
                [multiple]="true" [hideSelected]="false"
                [typeahead]="labelInput$">
              </ng-select>
              <app-form-error [form]="form" [controlName]="'labelIds'">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label><app-i18n [key]="'Releases'"></app-i18n></label>
              <ng-select
                class="custom" formControlName="versions"
                [items]="releases" bindLabel="name"
                [clearable]="true" [searchable]="true" [closeOnSelect]="true"
                [multiple]="true" [hideSelected]="false"
                [typeahead]="versionInput$">
              </ng-select>
              <app-form-error [form]="form" [controlName]="'versions'">
              </app-form-error>
            </div>
          </div>
        </div>
        </ng-container>
        <div class="row">
          <div class="col-12"><button class="btn btn-link btn-flex" (click)="isHideFields = !isHideFields"><app-i18n [key]="isHideFields ? 'Show' : 'Hide'"></app-i18n></button></div>
        </div>
      </form>
      <div class="drop-zone-drag-over-mask"></div>
    </div>
  </div>
  <div class="actions text-right dialog-footer d-none d-md-block">
    <button type="button" class="btn btn-white mr-3"
      (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary"
      [disabled]="form.pristine || loading"
      (click)="onSubmit()">
      <span class="spinner-border spinner-border-sm text-white mr-1" *ngIf="loading"></span>
      <app-i18n [key]="'Save'"></app-i18n></button>
  </div>
  <div class="actions dialog-footer d-flex d-md-none row">
    <div class="col-6 pr-2">
      <button type="button" class="btn btn-outline-primary col"
        (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n>
      </button>
    </div>
    <div class="col-6 pl-2">
      <button type="submit" class="btn btn-primary col"
        [disabled]="form.pristine || loading"
        (click)="onSubmit()">
        <span class="spinner-border spinner-border-sm text-white mr-1" *ngIf="loading"></span>
        <app-i18n [key]="'Save'"></app-i18n>
      </button>
    </div>
  </div>
</div>

<div class="d-flex align-items-center">
  <div class="editor-breadcrumb">
    <ng-container *ngTemplateOutlet="breadcrumbTemplate"></ng-container>
  </div>
  <button class="ml-auto btn btn-flex"
          (click)="onResize()">
    <mat-icon svgIcon="expand_horizontal" class="icon-md"
              *ngIf="!isExpand"></mat-icon>
    <mat-icon svgIcon="collapse_horizontal" class="icon-md"
              *ngIf="isExpand"></mat-icon>
  </button>
</div>

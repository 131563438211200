<div class="backlog-content-menu-wrapper">
  <div class="children-container d-flex flex-column">
    <ng-container *ngFor="let item of backlogContextMenu">
      <ng-container *ngTemplateOutlet="template; context: {item : item, absolute: true }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #template let-item="item" let-absolute="absolute">
  <div class="menu-item" (click)="onClickMenu(item); stopEvent($event)" *ngIf="!item?.disabled">
    <div class="children-hover" *ngIf="item?.children">
      <div class="children-container">
        <ng-container *ngFor="let children of item.children">
          <ng-container *ngTemplateOutlet="template; context: {item : children}"></ng-container>
        </ng-container>
      </div>
    </div>
    <div class="item d-flex justify-content-between"
      [ngClass]="{'hover-item': absolute}">
      <div class="label"><app-i18n [key]="item?.name"></app-i18n></div>
      <span class="material-icons icon" *ngIf="item?.children?.length">arrow_right</span>
    </div>
  </div>
</ng-template>


import { Component, Input } from '@angular/core';
import { ProjectMemberUser } from 'src/app/shared';

@Component({
  selector: 'app-multi-user-label',
  template: `
    <div class="custom-option mr-2 mb-1" *ngFor="let item of items">
      <app-avatar [url]="item.avatar" [name]="item.fullName" [size]="'very-small'"></app-avatar>
      <span class="option-label">{{ item.fullName }}</span>
      <span
        *ngIf="!disabled"
        class="material-icons btn-clear ng-value-icon"
        (click)="clear(item)"
      >
        clear
      </span>
    </div>
  `,
  styles: [`
    .custom-option {
      background: var(--x-filter-item-bg);
      padding: 2px 5px;
      border-radius: 3px;
    }
  `]
})
export class MultiUserLabelComponent {
  @Input() items: ProjectMemberUser[];
  @Input() disabled: boolean;
  @Input() clear: (data: any) => void;
}

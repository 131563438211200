import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ProjectPlatform } from 'src/app/shared';
import { ProjectsService } from './projects.service';

@Injectable({
  providedIn: 'root'
})

export class ProjectsMasterDataService {
  private cachePlatforms = new Map<number, ProjectPlatform[]>();
  public platforms$ = new Subject<ProjectPlatform[]>();
  isLoadingPlatform = false;
  constructor(
    private projectsService: ProjectsService,
  ) {
  }

  getPlatFormsByProject(projectId: number, force: boolean = false): Observable<ProjectPlatform[]> {
    const hasKey = this.cachePlatforms.has(projectId);
    if (!hasKey || force) {
      this.getPlatforms(projectId);
      return this.platforms$;
    }

    return of(this.cachePlatforms.get(projectId));
  }

  setPlatFormsToProject(projectId: number, value: ProjectPlatform[] = []) {
    this.cachePlatforms.set(projectId, value);
  }

  private getPlatforms(projectId: number) {
    if (this.isLoadingPlatform || !projectId) {
      return;
    }

    this.isLoadingPlatform = true;
    this.projectsService.getPlatforms(projectId, {}, { page: 0, size: 100000 })
      .subscribe(res => {
        const platforms = res.content;
        this.setPlatFormsToProject(projectId, platforms);
        this.platforms$.next(platforms);
        this.isLoadingPlatform = false;
      },
      (error) => {
        const platforms = [];
        this.setPlatFormsToProject(projectId, platforms);
        this.platforms$.next(platforms);
        this.isLoadingPlatform = false;
      });
  }
}

import { Component, Input } from '@angular/core';
import { ProjectEpicModel } from 'src/app/shared';

@Component({
  selector: 'app-epic-option',
  template: `
    <div class="custom-option">
      <span
        class="badge-label text-ellipsis"
        [ngStyle]="{ 'background-color': item.colorCode }"
        >{{ item.name }}</span
      >
    </div>
  `
})
export class EpicOptionComponent {
  @Input() item: ProjectEpicModel;
}

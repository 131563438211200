<div class="circle-progress-container">
  <div
    class="circle-progress"
    [ngClass]="{ done: progress >= 100 }"
    [ngStyle]="{
        'background': 'radial-gradient(closest-side, white 80%, transparent 80%), conic-gradient(' + color + ' ' + (progress || 0) +'%, '+ borderColor +' 0)',
        'width.px': size,
        'height.px': size,
        'font-size.px': titleSize
      }"
  >
    <span class="title" [ngClass]="[titleClass]" *ngIf="showTitle">
      {{ progress || 0 }}{{ suffix }}
    </span>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RenameUpsert } from 'src/app/shared';

@Component({
  selector: 'app-document-rename-upsert',
  templateUrl: './document-rename-upsert.component.html',
  styleUrls: ['./document-rename-upsert.component.scss']
})
export class DocumentRenameUpsertComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<RenameUpsert>();

  form: UntypedFormGroup;
  name: string;

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {

    this.form = this.fb.group({
      name: [this.name, [
        Validators.required,
      ]],
    });
  }

  onSubmit() {
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formValue = this.form.getRawValue();

    this.formSubmit.next({ ...formValue });
  }

  onCancel() {
    this.formSubmit.next(null);
  }
}
import { CheckInObjectType } from "./okr.model";
import { User } from "./user.model";

export interface OkrCheckInRequest {
  objectId: number;
  objectType: CheckInObjectType,
  timelineId: number;
}

export interface OkrCheckIn {
  value: number;
  valueType: OkrValueType,
  note: string,
  createdAt: string,
  createdBy: User;
}

export interface OkrCheckInPayload {
  objectId: number;
  objectType: CheckInObjectType;
  timelineId: number;
  valueType: OkrValueType;
  value: number;
  note: string;
}

export enum OkrValueType {
  JUSTIFY = 'JUSTIFY',
  START = 'START',
  EXPECTED = 'EXPECTED',
  CURRENT = 'CURRENT',
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() displayLabels = 1;
  @Input() editable = false;
  @Input() type = 'number';
  @Input() labels: string[] = [];
  @Input() color = '';
  @Input() bgColor = '';
  @Input() cssClass = 'badge-light';
  @Input() suffixCssClass = 'badge-light';
  @Input() suffix = false;
  @Input() displayTooltip = false;
  @Output() valueChange = new EventEmitter<any>();

  formControl: UntypedFormControl;
  badgeStyle = {};

  constructor() {}

  ngOnInit(): void {
    if (this.editable) {
      this.formControl = new UntypedFormControl(this.label);
    }

    if (this.color) {
      this.badgeStyle['color'] = this.color;
    }
    if (this.bgColor) {
      this.badgeStyle['background-color'] = this.bgColor;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label) {
      this.labels.splice(0, this.labels.length);
      this.labels.push(`${this.label}`);
    }
  }

  getLabelsText() {
    return this.labels.join(', ');
  }

  onInputChange() {
    if (`${this.formControl.value}` && `${this.formControl.value}` !== `${this.label}`) {
      this.valueChange.emit(this.formControl.value);
    } else {
      this.formControl.setValue(this.label, { emitEvent: false });
    }
  }

  onFocus() {
    if (this.editable) {
      this.formControl.setValue('');
    }
  }
}

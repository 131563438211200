import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DATE_UI_FORMAT, User, UserShort } from 'src/app/shared';
import { List2Res, Pageable, PageInfo } from 'src/app/shared/_models/generic/list-2-res.model';
import { LeaveReport, LeaveReportSearch } from 'src/app/shared/_models/leave-report.model';
import { CountRequestDay, FilterRequestLeaveResponse, LeaveRequest, LeaveRequestSearch, LeaveRequestUpsert, RequestGroup, RequestLeaveApproveRequest } from 'src/app/shared/_models/leave-request.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class LeaveRequestService extends EntityCollectionServiceBase<LeaveRequest> {
  public page$: Observable<PageInfo>;
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
  ) {
    super('leave-requests', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  searchRequest(payload: LeaveRequestSearch, pageable: Pageable): Observable<List2Res<LeaveRequest>> {
    const params = queryString.stringify({...payload, ...pageable});

    this.setLoading(true);
    return this.http.get<List2Res<LeaveRequest>>(`${environment.apiUrl}/leave-requests/?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  listApproverInLastRequest(): Observable<UserShort[]> {
    this.setLoading(true);
    return this.http.get<UserShort[]>(`${environment.apiUrl}/leave-requests/list-approvers-in-last-request`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  listCcInLastRequest(): Observable<UserShort[]> {
    this.setLoading(true);
    return this.http.get<UserShort[]>(`${environment.apiUrl}/leave-requests/list-cc-in-last-request`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  updateRequest(id: number, payload: LeaveRequestUpsert): Observable<LeaveRequest> {
    this.setLoading(true);
    return this.http.put<LeaveRequest>(`${environment.apiUrl}/leave-requests/${id}`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  cancelRequest(id: number): Observable<LeaveRequest> {
    this.setLoading(true);
    return this.http.get<LeaveRequest>(`${environment.apiUrl}/leave-requests/${id}/cancel`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  approveRequest(payload: RequestLeaveApproveRequest): Observable<LeaveRequest[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    this.setLoading(true);
    return this.http
      .post<LeaveRequest[]>(`${environment.apiUrl}/leave-requests/approve`, JSON.stringify(payload), { headers })
      .pipe(finalize(() => this.setLoading(false)));
  }

  countRequestDays(payload: CountRequestDay): Observable<number> {
    this.setLoading(true);
    return this.http.post<number>(`${environment.apiUrl}/leave-requests/count-request-days`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  countWaitingApprovalRequest(payload: LeaveRequestSearch): Observable<number> {
    const params = queryString.stringify(payload);

    this.setLoading(true);
    return this.http.get<number>(`${environment.apiUrl}/leave-requests/count-request?${params}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getApprovers(keyword?: string): Observable<List2Res<User>> {
    this.setLoading(true);
    return this.http.get<List2Res<User>>(`${environment.apiUrl}/leave-requests/list-approvers?keyword=${keyword || ''}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getTeamMembers(queryParams?: string): Observable<List2Res<User>> {
    this.setLoading(true);
    return this.http.get<List2Res<User>>(`${environment.apiUrl}/leave-requests/list-team-members?${queryParams}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  searchReport(payload: LeaveReportSearch, pageable?: Pageable): Observable<List2Res<LeaveReport>> {
    const queryParams = queryString.stringify({...payload, ...pageable});

    this.setLoading(true);
    return this.http.get<List2Res<LeaveReport>>(`${environment.apiUrl}/leave-requests/report?${queryParams}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  exportReport(payload: LeaveReportSearch): Observable<HttpResponse<Blob>> {
    let queryParams = `startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}&locationId=${payload.locationId || ''}&paidFlag=${payload.paidFlag !== null ? payload.paidFlag : ''}`;
    queryParams += `&exportType=${payload.exportType}`;

    for (const id of payload.teamIds) {
      queryParams += `&teamIds=${id}`;
    }

    for (const id of payload.employeeIds) {
      queryParams += `&employeeIds=${id}`;
    }

    this.setLoading(true);
    return this.http.get<Blob>(`${environment.apiUrl}/leave-requests/export?${queryParams}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(finalize(() => this.setLoading(false)));
  }

  getDayInfo(request: LeaveRequest) {
    return request.requestDays;
  }

  getAbsencePeriod(request: RequestGroup) {
    const fromDate = moment(request.fromDate).format(DATE_UI_FORMAT);
    const toDate = moment(request.toDate).format(DATE_UI_FORMAT);
    const shiftText = ` (${request.shift?.alias ?? 'Full Day'})`;

    if (request.fromDate === request.toDate) {
      return fromDate + shiftText;
    }
    return fromDate + ' - ' + toDate + shiftText;
  }

  cancelApproved(id: number): Observable<LeaveRequest> {
    this.setLoading(true);
    return this.http.put<LeaveRequest>(`${environment.apiUrl}/leave-requests/${id}/cancel-approved`, {})
      .pipe(finalize(() => this.setLoading(false)));
  }

  getFilter() {
    return this.http.get<FilterRequestLeaveResponse>(`${environment.apiUrl}/leave-requests/get-filter`);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { CustomUserReportTimeline, TIME_FORMAT } from 'src/app/shared';

@Pipe({ name: 'getCurrentTimePos' })
export class GetCurrentTimePosPipe implements PipeTransform {
  transform(currentTime: string, timeline: CustomUserReportTimeline[]) {
    let foundRange = null;
    const timeToCheck = moment(currentTime, TIME_FORMAT);
    for (let i = 0; i < timeline.length - 1; i++) {
      const startTime = timeline[i].time;
      const endTime = timeline[i + 1].time;

      if (timeToCheck.isSameOrAfter(startTime) && timeToCheck.isSameOrBefore(endTime)) {
        foundRange = [timeline[i], timeline[i + 1]];
        break;
      }
    }

    if (foundRange) {
      const rate = foundRange[0].time?.diff(timeToCheck) / foundRange[0].time?.diff(foundRange[1].time);
      return `calc(${foundRange[0]?.pos + rate * (foundRange[1]?.pos - foundRange[0]?.pos)}px)`;
    }
    return `calc(${0}%)`;
  }
}

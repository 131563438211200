import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subject } from 'rxjs';
import { AbstractComponent, FileBaseModel, PageInfo, Pageable, TreeFolder } from 'src/app/shared';

export interface DocumentFileDelegate<T = any> {
  createChildDone?: (parent: DocumentFileBase<T>, item: DocumentFileBase<T>) => void;
  deleteChildsDone?: (parent: DocumentFileBase<T>, items: DocumentFileBase<T>[]) => void;
  loadChildsDone?: (parent: DocumentFileBase<T>, items: DocumentFileBase<T>[]) => void;
}

export interface DocumentFileSection<T> {
  code?: string;
  header?: {
    title?: string;
    icon?: string;
  };
  content?: {
    items?: DocumentFileBase<T>[];
  };
}

export interface DocumentLoadMoreConfig {
  pageable?: Pageable;
  pageInfo?: PageInfo;
  callback?: () => void;
}

export class DocumentFileBase<T extends FileBaseModel = any> extends AbstractComponent {
  file: T;
  parent?: DocumentFileBase<T>;
  items: DocumentFileBase<T>[] = [];
  delegate?: DocumentFileDelegate<T>;
  isDocumentFileBase = true;
  sections: DocumentFileSection<T>[];
  isSelected = false;
  keyword = '';
  pageable: Pageable = {
    page: 0,
    size: 100,
    sort: '',
  };
  pageInfo: PageInfo;
  isLoading$ = new Subject<boolean>();

  constructor(file: T) {
    super();
    this.file = file;
    // Object.keys(file).forEach(key => {
    //   if (key !== 'id') {
    //     this[key] = file[key];
    //   }
    // });
  }

  get id(): string {
    return this.file.path;
  }

  getDefaultTimeSection(): DocumentFileSection<T>[] {
    return [
      {
        code: 'Today',
        header: {
          icon: '',
          title: 'Today',
        },
        content: {
          items: [],
        }
      },
      {
        code: 'This week',
        header: {
          icon: '',
          title: 'This week',
        },
        content: {
          items: [],
        }
      },
      {
        code: 'This month',
        header: {
          icon: '',
          title: 'This month',
        },
        content: {
          items: [],
        }
      },
      {
        code: 'This year',
        header: {
          icon: '',
          title: 'This year',
        },
        content: {
          items: [],
        }
      },
    ];
  }

  get selecteds(): DocumentFileBase<T>[] {
    return this.items.filter(e => e.isSelected);
  }

  canAddFile() {
    return false;
  }

  canAddFolder() {
    return false;
  }

  canRename() {
    return false;
  }

  canShare() {
    return false;
  }

  canSetting() {
    return false;
  }

  canUpload() {
    return false;
  }

  canDownload() {
    return false;
  }

  canDowloadChilds() {
    return this.selecteds.every(e => e.canDownload());
  }

  canDelete() {
    return false;
  }

  canDeleteChilds() {
    return this.selecteds.every(e => e.parent && e.canDelete());
  }


  canForceDelete() {
    return false;
  }

  canForceDeleteChilds() {
    return this.selecteds.every(e => e.parent && e.canForceDelete());
  }

  canOpenNewTab() {
    return false;
  }

  canAddShortcut() {
    return false;
  }

  canRefresh() {
    return true;
  }

  canLink() {
    return false;
  }

  canRestore() {
    return false;
  }

  canRestoreChilds() {
    return this.selecteds.every(e => e.canRestore());
  }

  canAccessFolderChild() {
    return true;
  }

  canEmptyTrash() {
    return false;
  }

  canLoadMore(pageable: Pageable, pageInfo: PageInfo) {
    return pageable.page + 1 < pageInfo.totalPages;
  }

  onLoadMore(viewport: CdkVirtualScrollViewport, config: DocumentLoadMoreConfig) {
    const end = viewport.getRenderedRange().end;
    const total = viewport.getDataLength();
    const canLoad = this.canLoadMore(config.pageable, config.pageInfo);

    // Starting load more progress
    if (end === total && canLoad) {
      this.pageable.page++;
      this.isLoading$.next(true);
      config.callback();
    }
  }

  getChilds() {}

  onUploadTreeFolder(treeFolder: TreeFolder) {}
  onUploadFile(files: File[]) {}
  onCreateFolder(payload) {}
  onAddShortcut() {}
  onRename() {}

  onDeleteChilds() {}
  onForceDeleteChilds() {}
  onRestoreChilds() {}
  onDownloadChilds() {}
  onShareChilds() {}
  onLinkChilds() {}
  onEmptyTrash() {}

  onSortChange(sort: string) {}
  onKeywordChange(keyword: string) {}
}

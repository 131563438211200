import { EDITOR_PREVIEW_FILE, EDITOR_SYNC_FILE } from "src/app/shared";


export class EditorUtils {
  static clearLinkParams(htmlString = '') {
    return htmlString.replaceAll(/(src=")([^?"]*)(\?(?!file-url=)[^"]*)?([^"]*")/g, '$1$2$4')
      .replaceAll(/(src="[^?]*\?file-url=[^"&]*)[^"]*"/g, '$1"')
      .replaceAll(/origin="[^"]*"/g, '')
      .replaceAll(EDITOR_SYNC_FILE, EDITOR_PREVIEW_FILE);
  }
}

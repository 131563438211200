import { FroalaOptions } from 'froala-editor';
import { MarkdownMoreText, MarkdownMoreTextStyle, MoreTextDefault } from './constants/moreText';
import { MarkdownMoreParagraph, MoreParagraphDefault } from './constants/moreParagraph';
import { MarkdownMoreRich, MoreRichDefault } from './constants/moreRich';
import { MarkdownMoreMisc, MoreMiscDefault } from './constants/moreMisc';
import { HtmlAllowedTagsDefault } from './constants/htmlAllowedTags';
import { HtmlAllowedAttrs } from './constants/htmlAllowedAttrs';
import { VideoAllowedTypes } from './constants/videoAllowedTypes';
import { environment } from 'src/environments/environment';
import { maximumFileSize } from 'src/app/shared';

export const FROALA_DEFAULT_CONFIG: FroalaOptions | any = {
  key: environment.froalaKey,
  quickInsertEnabled: false,
  documentReady: false,
  toolbarSticky: false,
  imageInsertButtons: ['imageBack', '|', 'imageUpload'],
  toolbarButtons: {
    moreText: {
      buttons: MoreTextDefault,
      buttonsVisible: 2
    },

    moreParagraph: {
      buttons: MoreParagraphDefault,
      buttonsVisible: 3
    },

    moreRich: {
      buttons: MoreRichDefault,
      buttonsVisible: 3
    },

    moreMisc: {
      buttons: MoreMiscDefault,
      align: 'right',
      buttonsVisible: 2
    }
  },
  videoInsertButtons: [
    'videoBack',
    '|',
    'videoByURL',
    'videoEmbed',
    'videoUpload'
  ],
  fileInsertButtons: ['fileBack', '|'],
  htmlAllowedTags: HtmlAllowedTagsDefault,
  htmlAllowedAttrs: HtmlAllowedAttrs,
  placeholderText: 'Add a description',
  videoMaxSize: maximumFileSize,
  videoAllowedTypes: VideoAllowedTypes,
  autosave: 0,
  imageDefaultWidth: 200,
  fontFamily: {
    'Lato,sans-serif': 'Lato',
    'Arial,Helvetica,sans-serif': 'Arial',
    'Georgia,serif': 'Georgia',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Times New Roman',Times,serif": 'Times New Roman',
    'Verdana,Geneva,sans-serif': 'Verdana'
  }
};


export const FROALA_MARKDOWN_CONFIG = {
  ...FROALA_DEFAULT_CONFIG,
  paragraphFormatSelection: true,
  fontSizeSelection: true,
  fontFamilySelection: true,
  toolbarButtons: {
    moreMisc: {
      buttons: MarkdownMoreMisc,
    },
    moreParagraphFormat: {
      buttons: ['paragraphFormat'],
    },
    moreFontFamily: {
      buttons: ['fontFamily']
    },
    moreFontSize: {
      buttons: ['fontSize'],
    },
    moreText: {
      buttons: MarkdownMoreText,
    },
    moreTextStyle: {
      buttons: MarkdownMoreTextStyle,
    },
    moreParagraph: {
      buttons: MarkdownMoreParagraph,
      buttonsVisible: 0
    },
    moreRich: {
      buttons: MarkdownMoreRich,
      buttonsVisible: 5
    },
  },
};

<div class="editor-title-wrapper">
  <span contenteditable
        #input
        cdkOverlayOrigin
        (focus)="onOpenDropdown()"
        (focusout)="onCloseDropdown()"
        #trigger="cdkOverlayOrigin"
        [attr.wikiTitlePlaceholder]="placeholder | translate"
        class="editor-title content-editable"
        [ngClass]="className"
        cdkObserveContent
        [formControl]="control"></span>

</div>

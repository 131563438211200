<div
  class="progress-group-wrapper"
  [placement]="'bottom'"
  [tooltip]="items | progressTooltip"
>
  <ng-container *ngFor="let item of items; let index = index">
    <div
      *ngIf="item.count > 0"
      class="status"
      [ngStyle]="item | progressGroupColor : index : total : items : height"
    ></div>
  </ng-container>
</div>

<form class="document-share-wrapper c-modal" [formGroup]="form">
  <div class="mb-3">
    <h4><app-i18n [key]="'shareWithOthers'"></app-i18n></h4>
  </div>

  <div class="row">
    <div class="col-lg-12 col-12">
      <div class="share-content">
        <!-- Share User -->
        <div class="share-row share-target-row">
          <div class="item share-target">
            <ng-select
              class="w-100"
              formControlName="target"
              [placeholder]="'addOthers' | translate"
              [items]="targets"
              [searchable]="true"
              [multiple]="true"
              [typeahead]="userTypeahead$">

              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                <div class="custom-option">
                  <app-avatar [url]="item.objectAvatar" [name]="item.objectName" *ngIf="item.objectType === 'USER'"></app-avatar>
                  <img class="option-icon" src="../../../../assets/icons/documents/ic_team_blue.png" alt="" *ngIf="item.objectType === 'TEAM'">
                  <img class="option-icon" src="../../../../assets/icons/documents/ic_group_blue.png" alt="" *ngIf="item.objectType === 'GROUP'">

                  <span class="option-label">{{item.objectName}}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <ng-select
            class="item share-type"
            formControlName="permission"
            [items]="currentPermission | shareType"
            [searchable]="false"
            [clearable]="false"
            [requiredIf]="hasOptions('target')">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <app-i18n [key]="item"></app-i18n>
            </ng-template>
          </ng-select>

          <div class="item action">
            <button type="submit" class="btn btn-icon btn-primary" [tooltip]="'add' | translate"  [placement]="'top'" [disabled]="form.invalid" appRestrictMultiSubmit (multiClick)="onSubmit()">
              <span class="material-icons-outlined">
                add
              </span>
            </button>
          </div>
        </div>

        <!-- Shared List -->
        <div class="share-list">
          <div class="d-flex-center jc_sb mb-3" *ngIf="rows.length > 0">
            <span class="font-semibold"><app-i18n [key]="'Shared For'"></app-i18n></span>
            <div class="d-flex-center">
              <span class="mr-2"><app-i18n [key]="'Delete All'"></app-i18n></span>
              <button type="submit"
                class="btn btn-icon btn-secondary text-white"
                [tooltip]="'unshare' | translate" [placement]="'bottom'"
                (click)="onDeleteAll()">
                <span class="material-icons-outlined"> clear </span>
              </button>
            </div>
          </div>
          <div class="share-row" *ngFor="let row of rows; let index = index">
            <div class="item share-target">
              <ng-container *ngIf="row.user">
                <app-avatar [size]="'large'" [url]="row.user?.avatar" [name]="row.user?.fullName"></app-avatar>

                <div class="target-info">
                  <div class="name">{{row.user?.firstName}}</div>
                  <div class="email">{{row.user?.email}}</div>
                </div>
              </ng-container>

              <ng-container *ngIf="row.team">
                <img class="icon-lg" src="../../../../assets/icons/documents/ic_team_blue.png" alt="">

                <div class="target-info">
                  <div class="name">{{row.team?.name}}</div>
                </div>
              </ng-container>

              <ng-container *ngIf="row.group">
                <img class="icon-lg" src="../../../../assets/icons/documents/ic_group_blue.png" alt="">

                <div class="target-info">
                  <div class="name">{{row.group?.name}}</div>
                </div>
              </ng-container>
            </div>

            <ng-select
              class="item share-type"
              [(ngModel)]="row.mergePermission"
              [ngModelOptions]="{standalone: true}"
              [items]="currentPermission | shareType"
              [searchable]="false"
              [clearable]="false"
              [requiredIf]="hasOptions('target')"
              [appendTo]="'body'"
              [disabled]="(currentPermission | isView) || row.disable"
              (change)="onEdit($event, row)">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                <app-i18n [key]="item"></app-i18n>
              </ng-template>
            </ng-select>

            <div class="item action">
              <button *ngIf="!((currentPermission | isView) || row.disable)" type="submit" class="btn btn-icon btn-secondary" [tooltip]="'unshare' | translate" [placement]="'top'" (click)="onDelete(row, index)">
                <span class="material-icons-outlined">
                  clear
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="link?.links.length > 0">
    <div class="notify">
      <input type="checkbox" formControlName="notify" class="form-check-input" id="notify">
      <label class="form-check-label" for="notify"><app-i18n [key]="'notifyPeople'"></app-i18n></label>
    </div>

    <textarea class="form-control" placeholder="{{'enterMessage' | translate}}" formControlName="message" rows="3"></textarea>
  </ng-container>

  <div class="flex align-content-center flex-wrap my-3">
    <div class="flex align-content-center border px-2 py-1 mr-2 mb-2 rounded-sm" *ngFor="let item of selectedFiles">
      <img class="file-icon icon-md" [src]="'../../../../assets/icons/documents/folder_default.svg'" alt="" *ngIf="item.type === 'Dir'">
      <file-icon class="file-icon" [set]="'square-o'" [size]="'sm'" [type]="getUrlExtension(item.path)" *ngIf="item.type === 'File'"></file-icon>
      <span class="px-2">{{item.name}}</span>
    </div>
  </div>

  <div class="actions text-right pt-3">
    <button class="btn btn-white mr-2" (click)="onCancel()"><app-i18n [key]="'Close'"></app-i18n></button>
    <button *ngIf="isNotify" class="btn btn-primary" (click)="onSend(link)"><app-i18n [key]="'Save'"></app-i18n></button>
  </div>
</form>


<div class="app-document-link c-modal mt-2">
  <app-document-link #link [fileIds]="fileIds" [isShowCloseBtn]="false"></app-document-link>
</div>

import { EntityState } from '@ngrx/entity';
import { DynamicField, Field } from 'src/app/shared';

export interface DynamicFieldState {
  fields: DynamicField[];
  taskFields: EntityState<ProjectsTaskDFields>;
}

export interface ProjectsTaskDFields {
  projectId: number;
  fields: Field[];
}

export type TaskField = {
  [key in TaskFieldKey]?: TaskFieldStatus;
}

export interface TaskFieldStatus {
  visible: boolean;
  disabled: boolean;
}

export enum TaskFieldKey {
  REMINDER = 'TD_REMINDER',
  PLATFORM = 'TD_PLATFORM',
  EXPECT_METRIC_VALUE = 'TD_EXPECTED_METRIC_VALUE',
  PROGRESS = 'TD_PROGRESS',
  START_DATE = 'TD_START_DATE',
  END_DATE = 'TD_END_DATE',
  CURRENT_METRIC_VALUE = 'TD_CURRENT_METRIC_VALUE',
  DUE_DATE = 'DUE_DATE',
  ASSIGNEE = 'ASSIGNEE',
  SPRINT = 'SPRINT',
  TYPE = 'TYPE',
  PRIORITY = 'PRIORITY',
  EPIC = 'EPIC_LINK',
  ESTIMATE = 'ESTIMATE',
  STORY_POINT = 'STORY_POINT',
  LABEL = 'LABELS',
  RELEASE = 'RELEASE',
  FOLLOW_UP = 'FOLLOW_UP',
  REPORTER = 'REPORTER',
  STATUS = 'STATUS',
  CC = 'CC',
  METRIC = 'METRIC',
  STORY_LINE = 'STORY_LINE'
};

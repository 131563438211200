<div #mentionList
     class="mention-list-container">
  <div class="mention-all-wrapper" [ngSwitch]="currentMode">
    <ng-container *ngSwitchCase="mentionMode.PROJECT_MEMBER">
      <div class="mention-list-wrapper">
        <ng-container *ngTemplateOutlet="memberList"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="mentionMode.ALL">
      <ng-container *ngTemplateOutlet="allMode"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="detailMode"></ng-container>
    </ng-container>
  </div>
</div>


<ng-template #detailMode>
  <div class="mention-list-wrapper single">
    <div class="d-flex align-item-center mention-title btn btn-flex"
         (click)="changeMode(mentionMode.ALL)">
      <div class="mention-arrow mr-2">
        <i class="fa collapse-icon fa-angle-left"></i>
      </div>
      <div>{{currentMode}}
        <ng-container [ngSwitch]="currentMode">
          <ng-container *ngSwitchCase="mentionMode.USER"> ({{userRes?.length || 0}})</ng-container>
          <ng-container *ngSwitchCase="mentionMode.TEAM"> ({{teamRes?.length || 0}})</ng-container>
          <ng-container *ngSwitchCase="mentionMode.GROUP"> ({{groupRes?.length || 0}})</ng-container>
        </ng-container>
      </div>
    </div>
    <ng-container [ngSwitch]="currentMode">
      <ng-container *ngSwitchCase="mentionMode.USER">
        <ng-container *ngTemplateOutlet="userList; context: { loadAll: true }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="mentionMode.TEAM">
        <ng-container *ngTemplateOutlet="teamList; context: { loadAll: true }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="mentionMode.GROUP">
        <ng-container *ngTemplateOutlet="groupList; context: { loadAll: true }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #allMode>
  <div class="mention-list-wrapper">
    <div class="d-flex align-item-center mention-title btn btn-flex"
         (click)="changeMode(mentionMode.USER)">
      <div>{{mentionMode.USER}} ({{ userRes?.length || 0 }})</div>
      <div class="ml-auto mention-arrow">
        <i class="fa collapse-icon fa-angle-right"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="userList"></ng-container>
  </div>
  <div class="mention-list-wrapper"
       *ngIf="teamRes?.length">
    <div class="d-flex align-item-center mention-title btn btn-flex"
         (click)="changeMode(mentionMode.TEAM)">
      <div>{{mentionMode.TEAM}} ({{ teamRes?.length || 0 }})</div>
      <div class="ml-auto mention-arrow">
        <i class="fa collapse-icon fa-angle-right"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="teamList"></ng-container>
  </div>
  <div class="mention-list-wrapper"
       *ngIf="groupRes?.length">
    <div class="d-flex align-item-center mention-title btn btn-flex"
         (click)="changeMode(mentionMode.GROUP)">
      <div>{{mentionMode.GROUP}} ({{ groupRes?.length || 0 }})</div>
      <div class="ml-auto mention-arrow">
        <i class="fa collapse-icon fa-angle-right"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="groupList"></ng-container>
  </div>
</ng-template>

<ng-template #memberList>
  <div class="mention-list"
    #dropdownList=dropdownList
    dropdownList
    [items]="members"
    (itemSelected)="onMemberClick($event)"
  >
    <div class="mention-item"
         [ngClass]="{ 'selected-item': activeIndex === index }"
         (click)="onMemberClick(item)"
         *ngFor="let item of members; let index = index">
      <app-avatar [url]="item.avatar"
                  [name]="item.fullName"></app-avatar>
      <div class="mention-item-name text-ellipsis">
        {{ item.fullName }}
      </div>
    </div>
    <ng-container *ngIf="!members?.length">
      <div class="mention-item">
        <app-avatar></app-avatar>
        <span class="mention-item-name text-ellipsis"><app-i18n key="This user does not exist in your project"></app-i18n></span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #userList
             let-loadAll="loadAll">
  <div class="mention-list">
    <div class="mention-item"
         (click)="onClick(item)"
         *ngFor="let item of (loadAll ? userRes : userRes | slice: 0: 5) ; let index = index">
      <app-avatar [url]="item.objectAvatar"
                  [name]="item.objectName"
                  nzIcon="user"></app-avatar>
      <div class="mention-item-name text-ellipsis">
        <div class="text-ellipsis">{{ item.objectName }}</div>
        <div class="item-second text-ellipsis">{{ item.objectEmail }}</div>
      </div>
    </div>
    <ng-container *ngIf="!userRes?.length">
      <div class="mention-item">
        <app-avatar></app-avatar>
        <span class="mention-item-name text-ellipsis"><app-i18n key="None"></app-i18n></span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #groupList
             let-loadAll="loadAll">
  <div class="mention-list">
    <div class="mention-item"
         (click)="onClick(item)"
         *ngFor="let item of (loadAll ? groupRes : groupRes | slice: 0: 5); let index = index">
      <app-avatar [url]="item.objectAvatar"
                  [name]="item.objectName"
                  nzIcon="user"></app-avatar>
      <div class="mention-item-name text-ellipsis">
        <div class="text-ellipsis">{{ item.objectName }}</div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #teamList
             let-loadAll="loadAll">
  <div class="mention-list">
    <div class="mention-item"
         (click)="onClick(item)"
         *ngFor="let item of (loadAll ? teamRes : teamRes | slice: 0: 5); let index = index">
      <app-avatar [url]="item.objectAvatar"
                  [name]="item.objectName"
                  nzIcon="user"></app-avatar>
      <div class="mention-item-name text-ellipsis">
        <div class="text-ellipsis">{{ item.objectName }}</div>
      </div>
    </div>
  </div>
</ng-template>

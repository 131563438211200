import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractComponent, FileParent, PageableModel } from 'src/app/shared';
import { WikiStore } from '../../wiki.store';
import { MD_FOLDER_NAME, WikiItem } from 'src/app/site-management/_store/wiki';

@Component({
  selector: 'app-wiki-title',
  templateUrl: './wiki-title.component.html',
  styleUrls: ['./wiki-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class WikiTitleComponent extends AbstractComponent {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = 'Give this page a title';
  @Input() className = 'form-control';
  @Input() editable = true;
  @Output() onSubmit = new EventEmitter<string>();
  @ViewChild('input') input: ElementRef;

  isOpen = false;
  pageable: PageableModel = new PageableModel();

  inactiveTopic: WikiItem[] = [];
  currentTopic: FileParent;

  constructor(
    private cdr: ChangeDetectorRef,
    private wikiStore: WikiStore,
  ) {
    super();
  }

  init() {
    setTimeout(() => {
      if (this.input && this.input.nativeElement && !this.control.value) {
        this.input.nativeElement.focus();
      }
    }, 100);
  }

  getCurrentTopic(item: FileParent) {
    if (!item) {
      return;
    }
    if (item.name === MD_FOLDER_NAME) {
      this.currentTopic = item.parentFile || item;
      return;
    }
    return this.getCurrentTopic(item.parentFile);
  }

  onOpenDropdown() {
    this.isOpen = true;
    this.cdr.detectChanges();
  }

  onCloseDropdown() {
    this.isOpen = false;
    this.cdr.detectChanges();
  }

  onActiveTopicItem(item: WikiItem) {
    this.control.setValue(item.wikiName || item.name);
    this.wikiStore.actions.onActiveTopicItem(item);
  }
}

export const MoreTextDefault = [
  'bold',
  'italic',
  'chatGPT',
  'task-finder',
  'underline',
  'fontFamily',
  'fontSize',
  'strikeThrough',
  'textColor',
  'backgroundColor',
  'inlineClass',
  'inlineStyle',
  'subscript',
  'superscript',
  'clearFormatting'
];

export const MarkdownMoreText = [
  'bold',
  'italic',
  'underline'
];

export const MarkdownMoreTextStyle = [
  'textColor',
  'backgroundColor',
  'clearFormatting'
];


<div class="time-picker-wrapper">
  <input
    #input
    [placeholder]="'None' | translate"
    class="form-control"
    bsTimePicker
    [control]="control"
    [formControl]="control"
    [defaultValue]="defaultValue"
  />

  <button class="btn btn-clear-date" *ngIf="showClear && control?.value && !control?.disabled">
    <span
      class="material-icons-outlined h-100 d-block"
      [title]="'Clear all' | translate"
      (click)="clearControlValue()"
    >
      close
    </span>
  </button>
</div>

import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { WikiItem } from './wiki.models';
import { FileItem, FileType } from 'src/app/shared';
import { AppInjector } from 'src/app/app.module';
import { DocumentDataService, TreeItem } from '../../document/_services/document-data.service';

/**
 * Find WikiItem in WikiTree
 * @param items Array of WikiItem being searched
 * @param id: Id of the topic/page being searched
 * @returns A topic/page if exists
 */
export const findWikiItem = (items: WikiItem[], id: number): WikiItem => {
  for (const item of items) {
    if (item.id === id || item?.uId === id) {
      return item;
    }
    if (item?.items) {
      const foundItem = findWikiItem(item.items, id);
      if (foundItem) {
        return foundItem;
      }
    }
  }

  return null;
};

export const filterWikiItem = (items: WikiItem[]): WikiItem[] => {
  /** Find pair of items {draft, published} topic/sub-topic/page
   * - If length of this pair is 2, then add attribute <hidden> to draft one
   */
  const clonedItems = cloneDeep(items);
  if (!items.length) {
    return [];
  }

  return clonedItems.map(item => {
    if (item.type === FileType.file) {
      // Find draft or published item in the same level
      const samePage = getSamePage(clonedItems, item);
      if (samePage) {
        if (item.publishFlg) {
          samePage.hidden = true;
        } else {
          item.hidden = true;
        }
      }
    }
    return item;
  });
};

export const getFileName = (item: WikiItem): string => {
  const fileName = item?.path?.split('/').at(-1);
  if (fileName?.[0] === '.') {
    // Get only file name without dot
    return fileName.substring(1);
  }

  return fileName;
};

export const getSamePage = (items: WikiItem[], page: WikiItem): WikiItem => {
  const fileName = getFileName(page);

  if (!fileName) {
    return null;
  }

  return items.find(item => item.id !== page.id && item.path.includes(fileName));
};

/**
 * Get content of a topic/page
 * @param objectURL The URL representing the file object
 * @param callback Function for continuous handling
 */
export const getWikiContent = (objectURL: string, callback: (result: string) => void) => {
  if (!objectURL) {
    callback(null);
    return;
  }

  fetch(objectURL)
    .then(res => res.blob())
    .then(blob => {
      const fr = new FileReader();

      fr.readAsText(blob);
      fr.addEventListener('load', () => {
        callback(fr.result as string);
      });
    });
};

export const canEditWiki = (item: WikiItem) => {
  return item.editable && !item.defaultFlg;
};

export const mappingTreeItemToWiki = (treeItem: TreeItem | FileItem): WikiItem => {
  const wikiItem = {} as WikiItem;
  Object.keys(treeItem).forEach(key => {
    wikiItem[key] = JSON.parse(JSON.stringify(treeItem[key]));
  });
  return wikiItem;
};

export const updateTreeMap = (wikiItem: WikiItem, items: WikiItem[]) => {
  const documentDataService = AppInjector.get(DocumentDataService);
  const topicTreeItem = documentDataService.treeMap[wikiItem.id];

  if (items.length > 0) {
    items.forEach(item => {
      documentDataService.treeMap[item.id] = new BehaviorSubject<TreeItem>(cloneDeep(item));
      documentDataService.treeMap[item.id].value.parentUI = documentDataService.treeMap[wikiItem.id].value;
    });
    topicTreeItem.value.items = cloneDeep(items);
  }
};

/**
 * Get the WikiItem different from TreeMap that can be updated
 * @param item WikiItem being search
 * @param items Array of WikiItem being searched
 * @returns WikiItem can be updated
 */
export const updatedWikiItem = (item: WikiItem, items: WikiItem[]): WikiItem => {
  const documentDataService = AppInjector.get(DocumentDataService);
  const currentTreeItem = documentDataService.treeMap[item.id].value;
  const existed = findWikiItem(cloneDeep(items), item.id);

  if (existed) {
    item.items.forEach(el => {
      existed.items.push(el);
    });
    return mappingTreeItemToWiki(existed);
  }

  const draftData = mappingTreeItemToWiki(
    documentDataService.treeMap[currentTreeItem?.parentFile?.id ?? currentTreeItem.parentUI.id].value
  );

  draftData.items = [
    mappingTreeItemToWiki({...currentTreeItem, ...item})
  ];
  return updatedWikiItem(draftData, items);
};

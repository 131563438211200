import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'joinText'})
export class JoinTextPipe implements PipeTransform {
  transform(value: any[], field: string, divide = ', '): string {
    if (field) {
      return value.map(item => item[field]).join(divide);
    }
    return value.join(divide);
  }
}

<span class="get-link" [ngClass]="{ show: isShow }">
  <mat-icon
    class="icon-sm"
    [boundariesElement]="'window'"
    [tooltip]="tooltipText | translate"
    [tooltipAppendToBody]="true"
    (click)="onCopyLinkToClipboard()"
    [svgIcon]="'action-bar-link'"
  ></mat-icon>
</span>

import { createAction, props } from "@ngrx/store";
import { Pageable, User, UserSearchRequest } from "src/app/shared";

export const getUsers = createAction(
  '[User] Get List Users',
  props<{ payload: UserSearchRequest; pageable: Pageable }>()
);

export const setUsers = createAction(
  '[User] Set List Users',
  props<{ users: User[] }>()
)

export const UserActions = {
  getUsers,
  setUsers,
};

import { Directive, ElementRef, HostListener, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[dropdown-overflow]'
})
export class DropdownPositionDirective implements OnInit, OnDestroy {
  yPosition: number;
  xPosition: number;
  sub: Subscription;

  constructor(
    private zone: NgZone,
    private bsDropdown: BsDropdownDirective
  ) {}

  ngOnInit(): void {
    this.sub = this.bsDropdown.onShown.pipe(debounceTime(200)).subscribe((container: any) => {
      this.zone.runOutsideAngular(() => {
      const elementRef = container._element as ElementRef;

      const menu = elementRef.nativeElement.querySelector('.dropdown-menu');

      // get window width, height
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // get menu width, height
      const menuWidth = menu.offsetWidth;
      const menuHeight = menu.offsetHeight;

      const clientY = menu.getBoundingClientRect().y;
      const clientX = menu.getBoundingClientRect().x;
      // calculate menu width show out of window
      const totalHeight = windowHeight - (menuHeight + clientY);
      const totalWidth = windowWidth -  (menuWidth + clientX);

      const translateX = totalWidth <= 0 ? (totalWidth - 10) : 0;
      const translateY = totalHeight <= 0 ? (totalHeight - 10) : 0;

      setTimeout(() => {
        menu.style.transform = `translate3d(${translateX}px, ${translateY}px, 0px)`;
        }, 100);
      });

    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

import { AppInjector } from 'src/app/app.module';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { permissionCode } from '../../_utils';
import { Role } from '../../_models';

export interface RolePermissionCodes {
  viewCodes?: string[];
  editCodes?: string[];
  viewAnotherCodes?: string[];
  editAnotherCodes?: string[];
}
export class PermissionChecker {
  permissionDataService: PermissionCheckService = AppInjector.get(
    PermissionCheckService
  );
  visibility: { [key: string]: boolean } = {};
  permissionCode = permissionCode;
  actions: string[] = [];

  canView = true;
  canEdit = true;
  canViewAnother = true;
  canEditAnother = true;

  constructor(codes: RolePermissionCodes, roles?: Role[]) {
    this.actions = Object.values(codes).reduce((list, cur)=> [...list, ...cur], []);
    this.visibility = this.permissionDataService.getVisibility(this.actions, roles);
    if (codes.editCodes) {
      this.canEdit = codes.editCodes.some(v=> this.visibility[v]);
    }
    if (codes.viewCodes) {
      this.canView = (codes.editCodes && this.canEdit) || codes.viewCodes.some(v=> this.visibility[v]);
    }
    if (codes.editAnotherCodes) {
      this.canEditAnother = codes.editAnotherCodes.some(v => this.visibility[v]);
    }
    if (codes.viewAnotherCodes) {
      this.canViewAnother = (codes.editAnotherCodes && this.canEditAnother) || codes.viewAnotherCodes.some(v => this.visibility[v]);
    }
  }

  checkViewAnother() {}
  checkEditAnother() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import * as pluralize from 'pluralize';

@Pipe({ name: 'pluralize' })
export class PluralizePipe implements PipeTransform {
  transform(count: number, noun: string): string {
    const pluralNoun = pluralize(noun, count);
    return `${count} ${pluralNoun}`;
  }
}

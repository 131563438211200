import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzToastType, ToastType } from '../models/toast';
import { UNKNOWN_MESSAGE } from '../constants/message';

const DEFAULT_CONFIG: NzNotificationDataOptions = {
  nzPlacement: 'bottomRight',
  nzPauseOnHover: true
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private nzNotification: NzNotificationService, private translate: TranslateService) {}

  success(content: string, config?: NzNotificationDataOptions) {
    const title = this.translate.instant(ToastType.Success);
    content = this.translate.instant(content || UNKNOWN_MESSAGE);
    return this.nzNotification.success(title, content, this.getConfig(config));
  }

  warning(content: string, config?: NzNotificationDataOptions) {
    const title = this.translate.instant(ToastType.Warning);
    content = this.translate.instant(content || UNKNOWN_MESSAGE);
    return this.nzNotification.warning(title, content, this.getConfig(config));
  }

  error(content: string, config?: NzNotificationDataOptions) {
    const title = this.translate.instant(ToastType.Error);
    content = this.translate.instant(content || UNKNOWN_MESSAGE);
    return this.nzNotification.error(title, content, this.getConfig(config));
  }

  info(content: string, config?: NzNotificationDataOptions) {
    const title = this.translate.instant(ToastType.Info);
    content = this.translate.instant(content || UNKNOWN_MESSAGE);
    return this.nzNotification.info(title, content, this.getConfig(config));
  }

  create(type: NzToastType, title: string, content: string, config?: NzNotificationDataOptions) {
    return this.nzNotification.create(type, title, content, this.getConfig(config));
  }

  template(template: TemplateRef<any>, config?: NzNotificationDataOptions) {
    return this.nzNotification.template(template, this.getConfig(config));
  }

  getConfig(config: NzNotificationDataOptions) {
    return { ...DEFAULT_CONFIG, ...config };
  }

  notifyTrialExpired() {
    this.create(NzToastType.Info, 'Your 30-day trial has ended', 'Please contact Administrator go to Billing to process Payment.');
  }
}

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as moment from 'moment';
import { DATE_TIME_UI_12_HOURS_FORMAT } from 'src/app/shared';

@Component({
  selector: 'formly-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerType
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

  ngAfterViewInit(): void {
    const value = this.form.get(this.field.key as string)?.value;
    if (value) {
      const formatText = moment(value).format(DATE_TIME_UI_12_HOURS_FORMAT);
      this.input.nativeElement.value = formatText;
    }
  }
}

import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppInjector } from 'src/app/app.module';
import { AppActions } from '../store/app';

export const DeactiveLoadingGuard: CanActivateFn = () => {
  const store = AppInjector.get(Store);

  store.dispatch(AppActions.loading({ visible: false }));

  return true;
};

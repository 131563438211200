import { Component, Input } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { AbstractComponent, FileResponse, GetLinkResponse, routerObject } from 'src/app/shared';
import { FileService } from '../_services/file.service';

@Component({
  selector: 'app-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss']
})
export class DocumentLinkComponent extends AbstractComponent {
  @Input() files: FileResponse[] = [];
  @Input() fileIds: number[] = [];
  @Input() isShowCloseBtn = true;

  links: GetLinkResponse[] = [];
  linkParsed = '';
  shortcutLinks: string[] = [];
  isCopied = false;

  constructor(
    private bsModalRef: BsModalRef,
    private fileService: FileService,
    private clipboardService: ClipboardService,
  ) {
    super();
  }

  init(): void {
    if (this.files.length > 0) {
      this.fileIds = [];
      this.shortcutLinks = [];
      this.files.forEach(file => {
        if (file.shortcut) {
          if (file.shortcutLink) {
            this.shortcutLinks.push(file.shortcutLink);

          }
          return;
        }

        this.fileIds.push(file.id);
      });
    }
    this.getFileShareInfo();
  }

  getFileShareInfo() {
    this.fileService.createLinkShare(this.fileIds).subscribe(
      (links) => {
        this.links = links;
        this.parseLink();
      },
      (error: string) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      }
    );
  }

  parseLink() {
    this.linkParsed = [
      ...this.links.map(e => {
        return `${window.location.origin + routerObject.document.fullPath}?code=${e.code}`;
      }),
      ...this.shortcutLinks
    ].join('; ');
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  copyLink() {
    this.clipboardService.copy(this.linkParsed);
    this.isCopied = true;
  }

}

import {
  trigger,
  transition,
  style,
  animate,
  keyframes
} from '@angular/animations';

export const FADE = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(300, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(300, style({ opacity: 0 }))
  ])
]);

export const FADE_FAST = trigger('fadeFast', [
  transition(':enter', [style({ opacity: 1 })]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(200, style({ opacity: 0 }))
  ])
]);

export const SLIDE_LEFT = trigger('slideInBlurredLeft', [
  transition(':enter', [
    style({
      transform: 'translateX(-1000px) scaleX(2.5) scaleY(0.2)',
      transformOrigin: '100% 50%',
      filter: 'blur(40px)',
      opacity: 0
    }),
    animate(
      '0.3s', // Adjust the duration as needed
      style({
        transform: 'translateX(0) scaleX(1) scaleY(1)',
        transformOrigin: '50% 50%',
        filter: 'blur(0)',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0) scaleX(1) scaleY(1)',
      transformOrigin: '50% 50%',
      filter: 'blur(0)',
      opacity: 1
    }),
    animate(
      '0.3s', // Adjust the duration as needed
      style({
        transform: 'translateX(-1000px) scaleX(2) scaleY(0.2)',
        transformOrigin: '100% 50%',
        filter: 'blur(40px)',
        opacity: 0
      })
    )
  ])
]);

export const SLIDE_RIGHT = trigger('slideInBlurredRight', [
  transition(':enter', [
    style({
      transform: 'translateX(1000px) scaleX(2.5) scaleY(0.2)',
      transformOrigin: '0% 50%',
      filter: 'blur(40px)',
      opacity: 0
    }),
    animate(
      '0.3s', // Adjust the duration as needed
      style({
        transform: 'translateX(0) scaleX(1) scaleY(1)',
        transformOrigin: '50% 50%',
        filter: 'blur(0)',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(0) scaleX(1) scaleY(1)',
      transformOrigin: '50% 50%',
      filter: 'blur(0)',
      opacity: 1
    }),
    animate(
      '0.3s', // Adjust the duration as needed
      style({
        transform: 'translateX(1000px) scaleX(2) scaleY(0.2)',
        transformOrigin: '0% 50%',
        filter: 'blur(40px)',
        opacity: 0
      })
    )
  ])
]);

export const SLIDE_CENTER = trigger('slideCenter', [
  transition(':enter', [
    animate(
      '0.1s', // Adjust the duration as needed
      keyframes([style({ width: '0%' }), style({ width: '100%' })])
    )
  ]),
  transition(':leave', [
    animate(
      '0.1s', // Adjust the duration as needed
      keyframes([style({ width: '100%' }), style({ width: '0%' })])
    )
  ])
]);

export const FADE_LEFT = trigger('fadeLeft', [
  transition(':enter', [
    animate(
      '0.2s ease-out',
      keyframes([
        style({ transform: 'translateX(-5px)', opacity: 0, offset: 0 }),
        style({ transform: 'translateX(0)', opacity: 1, offset: 1 })
      ])
    )
  ]),
  transition(':leave', [
    animate(
      '0.2s ease-out',
      keyframes([
        style({ transform: 'translateX(0)', opacity: 1, offset: 0 }),
        style({ transform: 'translateX(-5px)', opacity: 0, offset: 1 })
      ])
    )
  ])
]);

export const FADE_RIGHT = trigger('fadeRight', [
  transition(':enter', [
    animate(
      '0.2s ease-out',
      keyframes([
        style({ transform: 'translateX(5px)', opacity: 0, offset: 0 }),
        style({ transform: 'translateX(0)', opacity: 1, offset: 1 })
      ])
    )
  ]),
  transition(':leave', [
    animate(
      '0.2s ease-out',
      keyframes([
        style({ transform: 'translateX(0)', opacity: 1, offset: 0 }),
        style({ transform: 'translateX(5px)', opacity: 0, offset: 1 })
      ])
    )
  ])
]);

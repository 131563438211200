import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, catchError, map, tap, switchMap, concatMap } from 'rxjs/operators';
import * as WikiActions from './wiki.actions';
import { ToastService } from 'src/app/core';
import { MD_FOLDER_NAME, WikiGroup, WikiMode, WikiState, WikiType } from './wiki.models';
import { WikiService } from '../../_services';
import { FileService } from '../../document/_services/file.service';
import { MoveFileRequest, PageSize, Pageable } from 'src/app/shared';
import { DocumentDataService } from '../../document/_services/document-data.service';
import { updateTreeMap } from './wiki.utils';

@Injectable()
export class WikiEffects {
  constructor(
    private actions$: Actions,
    private wikiStore: Store<WikiState>,
    private wikiService: WikiService,
    private toast: ToastService,
    private fileService: FileService,
    private documentDataService: DocumentDataService,
  ) { }

  getTopicItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.getTopicItems),
      exhaustMap(props => {
        let action$ = null;
        switch (props.topic?.wikiGroup) {
          case WikiGroup.PROJECT:
            action$ = WikiActions.getProjectTopicItems(props);
            break;
          case WikiGroup.COMPANY_DOCUMENT:
            action$ = WikiActions.getCompanyTopicItems(props);
            break;
        }
        return of(action$);
      })
    );
  });

  getProjectTopicItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.getProjectTopicItems),
      concatMap(props => {
        const pageable = new Pageable();
        const id = (props.topic?.parentUI || props.topic?.parentFile) ? props.topic.id : '';
        const syncDocument = props?.options?.syncDocument ?? false;

        this.documentDataService.syncTreeItemById(props.topic.id, {}, syncDocument);
        return this.wikiService.getProjectWikis(pageable, id).pipe(
          switchMap(response => {
            const wikiItems = response.content.map(el => ({ ...el, wikiGroup: WikiGroup.PROJECT }));
            const mdFolder = wikiItems.find(item => item.name === MD_FOLDER_NAME);

            if (props?.options?.updateTreeMap) {
              updateTreeMap(props.topic, wikiItems);
            }

            if (mdFolder) {
              this.wikiStore.dispatch(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
              return of(WikiActions.getProjectTopicItems({
                topic: mdFolder,
                options: props.options
              }));
            }

            const hasMdLoaded = props.topic.name === MD_FOLDER_NAME;
            if (hasMdLoaded && props?.options?.moveWiki) {
              this.wikiStore.dispatch(WikiActions.moveWikiItem({
                payload: {
                  ...props.options.moveWiki,
                  destination: props.topic,
                }
              }));
            }

            if (props?.options?.callback) {
              this.wikiStore.dispatch(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
              return of(props.options.callback());
            }
            return of(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
          }),
          catchError(error => of(WikiActions.getTopicItemsFailed({ error })))
        );
      })
    );
  });

  getCompanyTopicItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.getCompanyTopicItems),
      concatMap(props => {
        const pageable = new Pageable();
        const id = (props.topic?.parentUI || props.topic?.parentFile) ? props.topic.id : '';
        const syncDocument = props?.options?.syncDocument ?? false;

        this.documentDataService.syncTreeItemById(props.topic.id, {}, syncDocument);
        return this.wikiService.getCompanyDocumentWikis(pageable, id).pipe(
          switchMap(response => {
            const wikiItems = response.content.map(el => ({ ...el, wikiGroup: WikiGroup.COMPANY_DOCUMENT }));
            const mdFolder = wikiItems.find(item => item.name === MD_FOLDER_NAME);

            if (props?.options?.updateTreeMap) {
              updateTreeMap(props.topic, wikiItems);
            }

            if (mdFolder) {
              this.wikiStore.dispatch(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
              return of(WikiActions.getCompanyTopicItems({
                topic: mdFolder,
                options: props.options
              }));
            }

            const hasMdLoaded = props.topic.name === MD_FOLDER_NAME;
            if (hasMdLoaded && props?.options?.moveWiki) {
              this.wikiStore.dispatch(WikiActions.moveWikiItem({
                payload: {
                  ...props.options.moveWiki,
                  destination: props.topic,
                }
              }));
            }

            if (props?.options?.callback) {
              this.wikiStore.dispatch(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
              return of(props.options.callback());
            }
            return of(WikiActions.setData({ id: props.topic.id, data: wikiItems }));
          }),
          catchError(error => of(WikiActions.getTopicItemsFailed({ error })))
        );
      })
    );
  });

  getTopicItemsFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.getTopicItemsFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  getContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.getContent),
      exhaustMap(props =>
        this.fileService.getAuthenticatedUrl(props.item.webViewLink).pipe(
          map(blob => WikiActions.setContent({ data: blob })),
          catchError(error => of(WikiActions.getContentFailed({ error })))
        )
      )
    );
  });

  activateWiki$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.activateWiki),
      exhaustMap(props => {
        /** System folder -> Get id from children.parentFile */
        const wikiId = props.folder.id > 0
          ? props.folder.id
          : props.folder?.items[0]?.parentFile?.id;

        return this.wikiService.activateWiki(wikiId, props.payload).pipe(
          map(data => WikiActions.activateWikiSuccess({ data })),
          catchError(error => of(WikiActions.activateWikiFailed({ error })))
        );
      })
    );
  });

  activateWikiSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.activateWikiSuccess),
        tap(() => {
          this.toast.success('Activated topic successfully!');
        })
      );
    },
    { dispatch: false }
  );

  activateWikiFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.activateWikiFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  moveWikiItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.moveWikiItem),
      concatMap(props => {
        const source = props.payload.source;
        const destination = props.payload.destination;
        const mdFolder = destination.name === MD_FOLDER_NAME
          ? destination
          : destination?.items?.find(el => el.name === MD_FOLDER_NAME);

        if (!mdFolder) {
          return of(WikiActions.getTopicItems({
            topic: destination,
            options: {
              syncDocument: true,
              moveWiki: props.payload
            }
          }));
        } else {
          const request: MoveFileRequest = {
            sourceFileId: [source.items[props.payload.sourceIndex].id],
            desFileId: mdFolder.id
          };
          return this.fileService.moveFile(request).pipe(
            map(item => {
              this.wikiStore.dispatch(WikiActions.getTopicItems({ topic: mdFolder }));
              return WikiActions.moveWikiItemSuccess({ payload: { ...props.payload, destination: mdFolder } });
            }),
            catchError(error => of(WikiActions.moveWikiItemFailed({ error })))
          );
        }
      })
    );
  });

  moveWikiItemSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.moveWikiItemSuccess),
        tap(data => {
          const movedItem = data.payload.source.items[data.payload.sourceIndex];
          const wikiType = movedItem.wikiType === WikiType.Topic
            ? WikiType.Topic : WikiType.Page;
          this.toast.success(`Moved ${wikiType} Successfully!`);
        })
      );
    },
    { dispatch: false }
  );

  moveWikiItemFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.moveWikiItemFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  /** Topic Effects */
  createTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.topicActions.create),
      exhaustMap(props =>
        this.wikiService.createTopic(props.topic.id, props.payload).pipe(
          map(topic => {
            topic.wikiGroup = props.topic.wikiGroup;
            this.wikiStore.dispatch(WikiActions.setEditorConfigs({configs: { mode: WikiMode.View, type: WikiType.Topic }}));

            return WikiActions.getTopicItems({
              topic: props.topic,
              options: {
                updateTreeMap: true,
                callback: () => WikiActions.topicActions.createSuccess({ topic })
              }
            });
          }),
          catchError(error => of(WikiActions.topicActions.createFailed({ error })))
        )
      )
    );
  });

  createTopicSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.topicActions.createSuccess),
        tap(props => {
          const msg = props.topic?.publishFlg
            ? 'Published successfully!'
            : 'Saved draft successfully!';
          this.toast.success(msg);
        })
      );
    },
    { dispatch: false }
  );

  createTopicFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.topicActions.createFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  editTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.topicActions.edit),
      exhaustMap(props =>
        this.wikiService.editTopic(props.id, props.payload).pipe(
          map(topic => {
            this.wikiStore.dispatch(WikiActions.setEditorConfigs({ configs: {
              mode: WikiMode.View,
              type: WikiType.Topic
            }}));
            return WikiActions.topicActions.editSuccess({ topic });
          }),
          catchError(error => of(WikiActions.topicActions.editFailed({ error })))
        )
      )
    );
  });

  editTopicSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.topicActions.editSuccess),
        tap(props => {
          const msg = props.topic?.publishFlg
            ? 'Published successfully!'
            : 'Saved draft successfully!';
          this.toast.success(msg);
        })
      );
    },
    { dispatch: false }
  );

  editTopicFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.topicActions.editFailed),
      tap(({ error }) => {
        const httpError = JSON.parse(error);
        this.toast.error(httpError?.message);
      })
    ); },
    { dispatch: false }
  );

  deleteTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.topicActions.delete),
      exhaustMap(props =>
        this.wikiService.deleteTopic(props.topic?.id, props.payload).pipe(
          map(() => {
            if (props.payload?.deleteFolder) {
              this.documentDataService.deleteSelected([props.topic]);
            }
            return WikiActions.topicActions.deleteSuccess(props);
          }),
          catchError(error => of(WikiActions.topicActions.deleteFailed({ error })))
        )
      )
    );
  });

  deleteTopicSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.topicActions.deleteSuccess),
        tap(() => {
          this.toast.success('Deleted topic successfully!');
        })
      );
    },
    { dispatch: false }
  );

  deleteTopicFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.topicActions.deleteFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );


  /** Page Effects */
  getPages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.pageActions.get),
      switchMap(props =>
        this.wikiService.getPage(props.id).pipe(
          map(item => WikiActions.getContent({ item })),
          catchError(error => of(WikiActions.pageActions.getFailed({ error })))
        )
      )
    );
  });

  createPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.pageActions.create),
      exhaustMap(props =>
        this.wikiService.createPage(props.id, props.payload).pipe(
          map(page => {
            this.wikiStore.dispatch(WikiActions.setEditorConfigs({configs: {
              mode: WikiMode.View,
              type: WikiType.Page
            }}));
            return WikiActions.pageActions.createSuccess({ page });
          }),
          catchError(error => of(WikiActions.pageActions.createFailed({ error })))
        )
      )
    );
  });

  createPageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.createSuccess),
        tap(props => {
          const msg = props.page?.publishFlg
            ? 'Published successfully!'
            : 'Saved draft successfully!';
          this.toast.success(msg);
        })
      );
    },
    { dispatch: false }
  );

  createPageFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.createFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  editPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.pageActions.edit),
      exhaustMap(props =>
        this.wikiService.editPage(props.id, props.payload).pipe(
          map(page => {
            this.wikiStore.dispatch(WikiActions.setEditorConfigs({configs: { mode: WikiMode.View, type: WikiType.Page }}));
            return WikiActions.pageActions.editSuccess({ page });
          }),
          catchError(error => of(WikiActions.pageActions.editFailed({ error })))
        )
      )
    );
  });

  editPageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.editSuccess),
        tap(props => {
          const msg = props.page?.publishFlg
            ? 'Published successfully!'
            : 'Saved draft successfully!';
          this.toast.success(msg);
        })
      );
    },
    { dispatch: false }
  );

  editPageFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.editFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  deletePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.pageActions.delete),
      exhaustMap(props =>
        this.wikiService.deletePage(props.page.id).pipe(
          map(() => WikiActions.pageActions.deleteSuccess({ page: props.page })),
          catchError(error => of(WikiActions.pageActions.deleteFailed({ error })))
        )
      )
    );
  });

  deletePageSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.deleteSuccess),
        tap(() => {
          this.toast.success('Deleted page successfully!');
        })
      );
    },
    { dispatch: false }
  );

  deletePageFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WikiActions.pageActions.deleteFailed),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  getMention$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.getMentionMembers),
      switchMap((props) => {
        return this.wikiService.getMemberList(props.request, {size: PageSize.loadAll }).pipe(
          map((data) => {
            return WikiActions.setMentionMembers({
              mentionList: data.content
            });
          }),
          catchError((error) => of(WikiActions.setMentionMembers({ mentionList: null })))
        );
      }),
    );
  });

  mentionMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WikiActions.mentionMembers),
      switchMap((props) => {
        return this.wikiService.mentionMember(props.id, props.payload).pipe(
          map((data) => {
            return WikiActions.mentionMembersSuccess({ mention: data });
          }),
          catchError((error) => of(WikiActions.mentionMembersFailed({ error })))
        );
      }),
    );
  });
}

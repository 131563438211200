import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserService } from '../../user/_services/user.service';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUsers),
      exhaustMap((props) =>
        this.userService.getUsers(props.payload, props.pageable).pipe(
          map((res) => UserActions.setUsers({ users: res.content })),
          catchError((error) => of(UserActions.setUsers({ users: [] })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}
}

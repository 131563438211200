import * as moment from 'moment';
import { FORMAT_12_HOURS, TIME_FORMAT } from '../..';

export const numMaxDayInMonth = 31;
export const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY= 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export const DefaultPickerPlaceholder = 'Date';

export const DATE_NO_TIME_ZONE = 'YYYY-MM-DDTHH:mm:ss.SSS';

export function getDurationHours(startTime: string, endTime: string) {
  // Create Moment.js objects for start time and end time
  const startMoment = moment(startTime, 'HH:mm:ss');
  const endMoment = moment(endTime, 'HH:mm:ss');

  // Calculate the difference between end time and start time
  const duration = moment.duration(endMoment.diff(startMoment));

  // Get the difference in hours, minutes, and seconds
  const hours = Math.floor(duration.asHours());
  const minutes = moment.utc(duration.asMilliseconds()).format('mm');
  const seconds = moment.utc(duration.asMilliseconds()).format('ss');

  return `${hours}:${minutes}:${seconds}`;
}

export function setTime(date: moment.Moment, time: string) {
  const [hours, minutes, seconds] = time.split(':');
  return moment(date).set({ hour: +hours, minute: +minutes, second: +seconds });
}

export const getUtcTimeFromLocalTime = (localTime: string, format: string = TIME_FORMAT) => {
  return moment(localTime, [TIME_FORMAT, FORMAT_12_HOURS]).utc().format(format);
}

export const getLocalTimeFromUtcTime = (utcTime: string, format: string = TIME_FORMAT) => {
  return moment.utc(utcTime, TIME_FORMAT).local().format(format);
}

export const toISOString = (isoDate: string, currentTime: string) => {
  const [hours, minutes, seconds] = currentTime?.split(':');
  return moment(isoDate)
    .set({ hour: +hours, minute: +minutes, second: +seconds })
    .toISOString();
}

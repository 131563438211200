import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-editor-breadcrumb',
  templateUrl: './editor-breadcrumb.component.html',
  styleUrls: ['./editor-breadcrumb.component.scss']
})
export class EditorBreadcrumbComponent {
  @Input() breadcrumbTemplate: TemplateRef<any>;
  @Output() onResizeEditor = new EventEmitter<boolean>();
  isExpand = true;

  constructor(private cdr: ChangeDetectorRef) {
  }

  onResize() {
    this.isExpand = !this.isExpand;
    this.cdr.detectChanges();
    this.onResizeEditor.emit(this.isExpand);
  }
}

import { Group } from './group.model';
import { Team } from './team.model';
import { User } from './user.model';

export interface OkrWorkspace {
  id: number;
  name: string;
  key: string;
  privateFlg: boolean;
  manager: OkrWorkspaceMember;
  justifiable: boolean;
  isEditable?: boolean;
}

export interface OkrWorkspaceUpsertRequest {
  name: string;
  key: string;
  privateFlg: boolean;
  managerId: number;
  justifiable: boolean;
}

export interface OkrWorkspaceMember {
  id: number;
  user: User;
  group: Group;
  team: Team;
  role: OkrWorkspaceRole;
}

export interface OkrWorkspaceMemberSearchRequest {
  keyword?: string;
  groupIds?: number[];
  teamIds?: number[];
}

export interface OkrWorkspaceRole {
  id: number;
  roleName: string;
  roleCode: string;
  description: string;
}


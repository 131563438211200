import { AbstractControl, ValidationErrors } from '@angular/forms';
import { chain, filter } from 'lodash';

export enum EmailValidatorKey {
  isDuplicated = 'isDuplicated'
}

export class emailValidator {
  static emailExists(formCtrlName = 'email') {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasDuplicateEmail = chain(filter(control.value, (v) => v[formCtrlName]))
        .countBy(formCtrlName)
        .values()
        .some(count => count > 1)
        .value();

      return hasDuplicateEmail ? { [EmailValidatorKey.isDuplicated]: true } : null;
    };
  }
}

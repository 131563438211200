import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ProjectsService } from 'src/app/site-management/projects/_services';
import { AbstractComponent } from '../_base-component';
import { UserFilter } from 'src/app/site-management/projects/filter/project-member-filter/project-member-filter.component';
import { CommentFeedback, CommentFeedbackResponse, DATE_FORMAT, DATE_UI_FORMAT, Project, ProjectMemberUser,
  StatusFeedback, StatusFeedbackUI, StatusFeedbacks, User, UserFeedbackType } from '../..';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/authentication/_services/auth.service';

@Component({
  selector: 'app-comment-feedback',
  templateUrl: './comment-feedback.component.html',
  styleUrls: ['./comment-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentFeedbackComponent extends AbstractComponent {
  @Input() feedback!: CommentFeedbackResponse;
  @Input() project!: Project;
  @Output() addFeedback = new EventEmitter<CommentFeedback>();
  @Output() updateFeedback = new EventEmitter<CommentFeedback>();
  @Output() updateStatus = new EventEmitter<StatusFeedback>();
  @Output() deletedFeedback = new EventEmitter();
  @Output() openedFeedback = new EventEmitter<boolean>();
  @ViewChild('statusDropdown') statusDropdown: NgSelectComponent;
  @ViewChild('datePicker') datePicker: ElementRef;
  @ViewChild('userRequestDropdown') userRequestDropdown: ElementRef;
  @ViewChild('userDropdown') userDropdown: ElementRef;
  feedbackOpened = false;
  currentProjectId!: number;
  members: UserFilter[] = [];
  requestMembers: UserFilter[] = [];
  dropdownMembers: UserFilter[] = [];
  dropdownRequestMembers: UserFilter[] = [];
  selectedDateValues = '';
  selectedStatusFeedback!: StatusFeedbackUI;
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    rangeInputFormat: DATE_UI_FORMAT,
    adaptivePosition: true
  };
  DATE_UI_FORMAT = DATE_UI_FORMAT;
  StatusFeedback = StatusFeedback;
  statusFeedbacks = StatusFeedbacks;
  isFirstTimeLoading = true;
  dateControl = new UntypedFormControl();
  loggedUser: User;
  showUserRequestList = false;
  showUserList = false;
  UserFeedbackType = UserFeedbackType;
  keyword = '';

  constructor(
    private projectService: ProjectsService,
    private authService: AuthService) {
    super();
  }

  get selectedMembers() {
    return this.members.filter(member => member?.isSelected);
  }

  get selectedRequestMembers() {
    return this.requestMembers.filter(member => member?.isSelected);
  }

  init(): void {
    this.loggedUser = this.authService.getLoggedUser();
    this.getMembers();
    this.setupData();
  }

  getMembers(type: UserFeedbackType = null) {
    this.projectService.getProjectMembers(this.project.id, this.keyword)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        if (type) {
          this.getMembersWithType(res, type);
          return;
        }
        this.members = this.setupMemberDropdown(res, UserFeedbackType.FEEDBACK);
        this.requestMembers = this.setupMemberDropdown(res, UserFeedbackType.NEED_FEEDBACK);
      });
  }

  setupData() {
    if (this.feedback) {
      this.dateControl.setValue(this.feedback.dateFeedback);
      this.selectedStatusFeedback = this.statusFeedbacks.find(status => status?.value === this.feedback.statusFeedback);
    }
    this.dateControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (this.feedback) {
        this.submitFeedback();
      }
    });
  }

  openCalendar() {
    this.datePicker.nativeElement.click();
  }

  disabledSaveFeedback() {
    return this.selectedMembers.length === 0 || this.selectedRequestMembers.length === 0 || !this.dateControl.value;
  }

  toggleMember(member: UserFilter, type: UserFeedbackType) {
    member.isSelected = !member.isSelected;
    let selectedMember!: UserFilter;

    if (type === UserFeedbackType.NEED_FEEDBACK) {
      selectedMember =  this.requestMembers.find(el => el.id === member.id);
    } else {
      selectedMember =  this.members.find(el => el.id === member.id);
    }

    if (selectedMember) {
      selectedMember.isSelected = member.isSelected;
    }

    if (this.feedback) {
      this.submitFeedback();
    }
  }

  submitFeedback() {
    const userIds = this.members.filter(member => member?.isSelected).map(member => member?.id);
    const userRequestIds = this.requestMembers.filter(member => member?.isSelected).map(member => member?.id);
    const payload: CommentFeedback = {
      userIds,
      userRequestIds,
      dateFeedback: moment(this.dateControl.value).format(DATE_FORMAT),
      statusFeedback: StatusFeedback.WAITING
    };
    if (this.feedback) {
      payload.statusFeedback = this.feedback.statusFeedback;
      this.updateFeedback.next(payload);
    } else {
      this.addFeedback.next(payload);
    }
    this.selectedStatusFeedback = StatusFeedbacks.find(status => status?.value === payload.statusFeedback);
  }

  openStatusDropdown() {
    this.statusDropdown?.toggle();
  }

  onDeleteFeedback() {
    this.deletedFeedback.next(null);
    this.dateControl.setValue('');
    this.selectedStatusFeedback = null;
    this.feedback = null;
    this.requestMembers = this.requestMembers.map(member => ({...member, isSelected: false}));
    this.members = this.members.map(member => ({...member, isSelected: false}));
    // this.requestMembers.find(member => member.id === this.loggedUser.id).isSelected = true;
    const member = this.requestMembers.find(member => member.id === this.loggedUser.id)
    if (member) {
      member.isSelected = true;
    }
    this.dropdownMembers = this.members;
    this.dropdownRequestMembers = this.requestMembers;
  }

  onStatusChanged() {
    this.updateStatus.next(this.selectedStatusFeedback.value);
  }

  toggleFeedback(value: boolean) {
    this.feedbackOpened = value;
    this.openedFeedback.next(value);
  }

  onSearchMembers(keyword: string, type: UserFeedbackType) {
    this.keyword = keyword;
    this.getMembers(type);
  }

  getMembersWithType(members: ProjectMemberUser[], type: UserFeedbackType) {
    if (type === UserFeedbackType.NEED_FEEDBACK) {
      this.dropdownRequestMembers = this.setupMemberDropdown(members, type);
    } else {
      this.dropdownMembers = this.setupMemberDropdown(members, type);
    }
  }

  setupMemberDropdown(members: ProjectMemberUser[], type: UserFeedbackType) {
    let results!: UserFilter[];
    if (type === UserFeedbackType.NEED_FEEDBACK) {
      results = members.map(member => ({
        ...member,
        isSelected: this.feedback ? this.feedback.usersRequest.some(user => user.id === member.id) : false
      }));
      const member =  results.find(member => member.id === this.loggedUser.id);
      if (member) {
        member.isSelected = true;
      }
    } else {
      results = members.map(member => ({
        ...member,
        isSelected: this.feedback ? this.feedback.users.some(user => user.id === member.id) : false
      }));
    }

    return results;
  }
}

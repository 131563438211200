import { AUDIT_ACTION } from '../_utils';

export interface AuditLog {
  id: number;
  field: string;
  createdAt: string;
  newValue: string;
  oldValue: string;
  author: Author;
  mainFeatureType: string;
  action: AUDIT_ACTION;
  targetObject: TargetObject;
}

export interface TargetObject {
  id: number;
  key: string;
  name: string;
  avatar: string;
  typeOfLeave: any;
}

export interface Author {
  id: number;
  fullName: string;
  avatar: string;
}

export class AuditLogSearch {
  objectId?: number;
  authorIds?: number[];
  searchText?: string;
  fromTime?: string;
  toTime?: string;
  featureTypes?: string[];
  actions?: AUDIT_ACTION[];
  projectId?: number;

  constructor(data?: AuditLogSearch) {
    this.objectId = data?.objectId;
    this.authorIds = data?.authorIds || [];
    this.searchText = data?.searchText || '';
    this.fromTime = data?.fromTime || '';
    this.toTime = data?.toTime || '';
    this.featureTypes = data?.featureTypes || [];
    this.actions = data?.actions || [];
  }
}

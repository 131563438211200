import { Pipe, PipeTransform } from '@angular/core';
import { AppInjector } from 'src/app/app.module';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { PermissionChecker } from 'src/app/shared';

@Pipe({
  name: 'canEditTeam'
})
export class CanEditTeamPipe implements PipeTransform {
  authService = AppInjector.get(AuthService);

  transform(team: any, permissionChecker: PermissionChecker): boolean {
    const loggedUser = this.authService.getLoggedUser();
    const isLeader = loggedUser.id === team?.leaderId;
    const isMember = loggedUser.teams?.find(el => el?.id === team.id);

    const canEditAsMember = permissionChecker?.canEdit && !!isMember;
    const canEditOthers = permissionChecker?.canEditAnother && !isMember;
    const canEditBoth = permissionChecker?.canEdit && permissionChecker?.canEditAnother;

    return canEditAsMember || canEditOthers || canEditBoth || isLeader;
  }
}


export const closingTags = [
  "</a>",
  "</abbr>",
  "</address>",
  "</article>",
  "</aside>",
  "</blockquote>",
  "</body>",
  "</button>",
  "</caption>",
  "</cite>",
  "</code>",
  "</colgroup>",
  "</datalist>",
  "</dd>",
  "</del>",
  "</details>",
  "</dfn>",
  "</dialog>",
  "</div>",
  "</dl>",
  "</dt>",
  "</em>",
  "</fieldset>",
  "</figcaption>",
  "</figure>",
  "</footer>",
  "</form>",
  "</h1>",
  "</h2>",
  "</h3>",
  "</h4>",
  "</h5>",
  "</h6>",
  "</header>",
  "</html>",
  "</i>",
  "</iframe>",
  "</ins>",
  "</kbd>",
  "</label>",
  "</legend>",
  "</li>",
  "</main>",
  "</map>",
  "</mark>",
  "</menu>",
  "</nav>",
  "</noscript>",
  "</object>",
  "</ol>",
  "</optgroup>",
  "</option>",
  "</output>",
  "</p>",
  "</pre>",
  "</progress>",
  "</q>",
  "</rp>",
  "</rt>",
  "</ruby>",
  "</s>",
  "</samp>",
  "</section>",
  "</select>",
  "</small>",
  "</span>",
  "</strong>",
  "</sub>",
  "</summary>",
  "</sup>",
  "</table>",
  "</tbody>",
  "</td>",
  "</tfoot>",
  "</th>",
  "</thead>",
  "</time>",
  "</title>",
  "</tr>",
  "</u>",
  "</ul>",
  "</var>",
  "</video>",
  "</wbr>"
];


import { HttpResponse } from '@angular/common/http';
import { split } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.module';
import { UtilService } from 'src/app/core';
import { FileResponse, FileSearchRequest, FileType, Pageable, RenameUpsert, ShortCutUpsert } from 'src/app/shared';
import { GeneralConfirmComponent } from '../../_components';
import { DocumentFolderSettingComponent } from '../document-folder-setting/document-folder-setting.component';
import { DocumentLinkComponent } from '../document-link/document-link.component';
import { DocumentRenameUpsertComponent } from '../document-rename-upsert/document-rename-upsert.component';
import { DocumentShareComponent } from '../document-share/document-share.component';
import { DocumentShortcutUpsertComponent } from '../document-shortcut-upsert/document-shortcut-upsert.component';
import { DocumentDataService } from '../_services/document-data.service';
import { FileService } from '../_services/file.service';
import { DocumentFileBase } from './document-file-base';

export class DocumentFile extends DocumentFileBase<FileResponse> {
  fileService: FileService = AppInjector.get(FileService);
  modalService: BsModalService = AppInjector.get(BsModalService);
  baseType = 'DocumentFile';
  getSubscription: Subscription;

  constructor(
    file: FileResponse,
  ) {
    super(file);
  }

  getChilds() {
    const payload: FileSearchRequest = { displayInTreeFolder: true, keyword: '' };
    const pageable = new Pageable({ page: 0, size: 100 });

    this.getSubscription?.unsubscribe();
    this.getSubscription = this.fileService.getChildFiles(this.file.id, payload, pageable).subscribe(
      (res) => {
        const items = res.content;
        this.items = items.map(e => {
          const item = new DocumentFile(e);
          item.parent = this;
          return item;
        });
        this.delegate?.loadChildsDone(this, this.items);
      },
      (err) => {
        this.items = [];
        this.errorFn(err);
        this.delegate?.loadChildsDone(this, this.items);
      }
    );
  }

  canRefresh() {
    return this.file.type === FileType.dir;
  }

  onRestoreChilds() {
    const ids = this.selecteds.map(e => e.file.id);
    this.fileService.restoreFile(ids).subscribe(
      (res) => {
        this.getChilds();
      },
      (err) => {
        this.errorFn(err);
      }
    );
  }

  onDeleteChilds() {
    const initialState = {
      title: 'Delete',
      message: 'Do you want to delete selected items?',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        const ids = this.selecteds.map(e => e.file.id);
        this.fileService.delete(ids).subscribe(
          (res) => {
            this.getChilds();
          },
          (err) => {
            this.errorFn(err);
          }
        );
      }
    });
  }

  onForceDeleteChilds() {
    const initialState = {
      title: 'Permanently Delete',
      message: 'Do you want to permanently delete selected items?',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        const ids = this.selecteds.map(e => e.file.id);
        this.fileService.permanentlyDelete(ids).subscribe(
          (res) => {
            this.getChilds();
          },
          (err) => {
            this.errorFn(err);
          }
        );
      }
    });
  }

  onDownloadChilds() {
    const ids = this.selecteds.map(e => e.file.id);
    this.fileService.download(ids)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (event) => {
          if (event instanceof HttpResponse && event.body) {
            UtilService.saveServerFile(event);
          }
        },
        (err: string) => {
          this.errorFn(err);
        }
      );
  }

  onShareChilds() {
    const modalRef = this.modalService.show(DocumentShareComponent, {
      backdrop: 'static',
      class: 'modal-document-share',
      initialState: {
        fileIds: this.selecteds.map(e => e.file.id),
        selectedFiles: this.selecteds.map(e => e.file),
      },
    });
  }

  onLinkChilds() {
    const modalRef = this.modalService.show(DocumentLinkComponent, {
      backdrop: 'static',
      initialState: {
        files: this.selecteds.map(e => e.file),
      },
    });
  }

  onSetting() {
    const modalRef = this.modalService.show(DocumentFolderSettingComponent, {
      backdrop: 'static',
      initialState: {
        object: this.file
      },
    });
    modalRef.content.objectResponse.subscribe(data => {
      if (data) {
        this.fileService.settingFolder(this.file.id, data).subscribe(
          (res: FileResponse) => {
            this.toast.success('Created Successfully!');
            this.file.icon = res.icon;
            modalRef.hide();
          },
          (err: string) => {
            this.errorFn?.(err);
          }
        );
      } else {
        modalRef.hide();
      }
    });
  }

  onAddShortcut() {
    const modalRef = this.modalService.show(DocumentShortcutUpsertComponent, {
      backdrop: 'static',
      initialState: {},
    });

    modalRef.content.formSubmit.subscribe((data: ShortCutUpsert) => {
      if (data) {
        this.fileService.createShortcut(this.file.id, data).subscribe(
          (file) => {
            this.toast.success('Created Successfully!');
            modalRef.hide();
          },
          (err: string) => {
            this.errorFn?.(err);
          }
        );
      } else {
        modalRef.hide();
      }
    });
  }

  onRename() {
    // const [name, extension] = split(this.file.name, '.');
    const [name, extension] = UtilService.getFileNameAndExtension(this.file.name);

    const modalRef = this.modalService.show(DocumentRenameUpsertComponent, {
      backdrop: 'static',
      initialState: {
        name,
      },
    });

    modalRef.content.formSubmit.subscribe((data: RenameUpsert) => {
      if (data) {
        const newName = data.name + (extension ? `.${extension}` : '');
        this.fileService.rename(this.file.id, newName).subscribe(
          () => {
            this.toast.success('Rename successfully!');
            this.file.name = newName;
            modalRef.hide();
          },
          (err: string) => {
            this.errorFn?.(err);
          });
      } else {
        modalRef.hide();
      }
    });
  }
}

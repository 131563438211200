import { Pipe, PipeTransform } from '@angular/core';
import { Project, ProjectBoardName, ProjectType } from 'src/app/shared';

@Pipe({ name: 'projectBoardName' })
export class ProjectBoardNamePipe implements PipeTransform {
  transform(project: Project) {
    let boardName = ProjectBoardName.SPRINT;

    if (project?.type === ProjectType.KANBAN) {
      boardName = ProjectBoardName.KANBAN;
    }
    return boardName;
  }
}

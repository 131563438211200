import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[custom-dropdown]',
})
export class CustomDropdownDirective {
  @Output() hide = new EventEmitter<any>();
  constructor(
    private element: ElementRef,
  ) {}

  @HostListener('click', ['$event'])
  onMouseEnter(event) {
    const clickedElement = event.target as HTMLElement;
    if (clickedElement.closest('[dropdown-item]')) {
      this.hide.emit();
    }

  }
}

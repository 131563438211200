import { TaskAttachment, TaskCustomField } from './task.model';

export interface TaskUpsert {
  name?: string;
  description?: string;
  attachments?: TaskAttachment[];
  dueDate?: Date;
  priorityId?: number;
  assigneeId?: number;
  reporterId?: number;
  ccIds?: number[];
  typeId?: number;
  projectId?: number;
  sprintId?: number;
  timeOriginalEstimate?: number;
  storyPoint?: number;
  epicId?: number;
  labelIds?: number[];
  versionIds?: number[];
  privateFlg?: boolean;
  statusId?: number;
  customFields?: TaskCustomField[];
  colorCode?: string;
  storylineEstimates?: TaskStorylineEstimateRequest[];
  storylineId?: number;
  platformId?: number;
  metricId?: number;
  metricValue?: number;
  startDate?: Date;
  endDate?: Date;
  startTime?: string;
  endTime?: string;
  taskTemplate?: boolean;
}

export interface TaskStorylineEstimateRequest {
  id?: number;
  value: number;
  platformId: number;
  taskId?: number;
}

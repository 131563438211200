import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventState } from './event.models';

export const featureKey = 'event';

const selectFeature = createFeatureSelector<EventState>(featureKey);

export const selectEvents = createSelector(
  selectFeature,
  (state: EventState) => state.events
);

export const selectEventCreated = createSelector(
  selectFeature,
  (state: EventState) => state.eventCreated
);

export const selectEventsUpdated = createSelector(
  selectFeature,
  (state: EventState) => state.eventsUpdated
);

export const selectEventDeleted = createSelector(
  selectFeature,
  (state: EventState) => state.eventDeleted
);

export const selectLocationEvent = createSelector(
  selectFeature,
  (state: EventState) => state.locations
);

export const selectLocationEventPageInfo = createSelector(
  selectFeature,
  (state: EventState) => state.locationPageInfo
);

<div class="p-4">
  <h4 class="c-modal-header">
    <span><app-i18n [key]="title" [params]="titleParams"></app-i18n></span>
  </h4>

  <div class="c-content" *ngIf="message">
    <app-i18n [key]="message" [params]="params"></app-i18n>
  </div>
  <div class="c-content" *ngIf="templateRef">
    <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
  </div>

  <div class="actions text-right pt-3" *ngIf="canAction">
    <button type="button" class="btn btn-white" (click)="onNoClick()"><app-i18n
        [key]="cancelBtnName"></app-i18n></button>
    <button type="button" class="btn btn-primary ml-2" (click)="onYesClick()"><app-i18n
        [key]="saveBtnName"></app-i18n></button>
  </div>
</div>

import FroalaEditor from 'froala-editor';
import { forEach, head } from 'lodash';
import * as uuid from 'uuid';
import { FroalaEvent } from '../models';

FroalaEditor.PLUGINS.lazyUploadImage = function (editor: FroalaEditor) {
  const _files: { file: File; blobUrl: string }[] = [];
  let isPasting = false;

  function files() {
    return _files;
  }

  function _init() {
    editor.events.on(FroalaEvent.PASTE_FILE, function(_isPasting: boolean) {
      isPasting = _isPasting;
    });

    if (editor.opts.autoUpload?.lazy) {
      onBeforeUpload();
      onLoaded();
    }
  }

  function onBeforeUpload() {
    editor.events.on('image.beforeUpload', function (files: FileList, placeholder: JQuery<Element>) {
      if (isPasting) {
        return false;
      }

      forEach(files, (file) => {
        fileToBlob(file, ({ blobUrl, blob }) => {
          // Generate file name if exist
          if (!file.name) {
            file = new File([blob], `${uuid.v4()}.${getExtension(file.type)}`, { type: file.type });
          }

          // Trigger attachmentPushed event
          editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [
            { file, previewUrl: blobUrl, blobUrl }
          ], true);

          editor.image.insert(
            blobUrl,
            false,
            {
              name: file.name,
              origin: blobUrl
            },
            placeholder || null
          );

          // Add file to current plugin
          _files.push({ file, blobUrl });
        });
      })

      return false;
    });
  }

  function onLoaded() {
    editor.events.on(FroalaEvent.IMAGE_LOADED, function($img) {
      if (isPasting) {
        editor.image.remove($img);
        isPasting = false;
      }
    });
  }

  function fileToBlob(file, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      const blobUrl = URL.createObjectURL(blob);

      callback({ blob, blobUrl });
    };

    reader.readAsArrayBuffer(file);
  }

  function getExtension(type) {
    // Use regex to extract the image format
    const format = type.match(/image\/([a-z]+)/i);
    // Check if the match was successful and extract the format
    return format ? format[1].toLowerCase() : null;
  }

  return {
    _init,
    files
  };
};

import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SseService {
  sseUrl: string;
  eventSource: EventSource;

  constructor(private zone: NgZone) {}

  createSSE(url: string) {
    // this.sseUrl = url;
    // this.eventSource = new EventSource(url);
  }

  listenSSE(channel: string, callback: (event) => void) {
    // this.eventSource.addEventListener(channel, callback);
  }

  getServerSentEvent(url: string): Observable<MessageEvent> {
    return new Observable(observer => {
      const eventSource = this.getEventSource(url);
      eventSource.onopen = (ev) => {
      };
      eventSource.onerror = (ev) => {
      };
      eventSource.addEventListener('message', event => {
        this.zone.run(() => {
          observer.next(event);
        });
      });
    });
  }
  private getEventSource(url: string): EventSource {
    if (this.eventSource) {
      this.eventSource.close();
    }
    this.eventSource = new EventSource(url);
    return this.eventSource;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { LoggedUserRole, permissionCode, PositionOverlay, User } from 'src/app/shared';
import { UtilService } from 'src/app/core';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { ProfileOverlayService } from 'src/app/site-management/_services/profile-overlay.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { TooltipOverlayConfig } from 'src/app/site-management/_services/token';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() user: User;
  @Input() isBig = false;
  @Input() showFullName = true;
  @Input() showConfirm = false;
  @Input() unassignedTitle = 'Unassigned';
  @Input() hideTooltip = false;
  @Input() disabled = false;

  @Output() showUser = new EventEmitter();
  @Output() clickedUser = new EventEmitter();
  @ViewChild('avatar', { read: ViewContainerRef, static: true }) appAvatar: ViewContainerRef; // container component

  loggedUserRole: LoggedUserRole;
  visibility: { [key: string]: boolean } = {};
  permissionCode = permissionCode;
  confirmFlag = false;
  actions = [
    permissionCode.ORGANIZATION_USER_VIEW,
  ];
  isWebLayout = true;
  tooltipOverlayConfig: TooltipOverlayConfig = {
    position: [
      PositionOverlay.bottomLeft,
      PositionOverlay.topLeft,
    ]
  }
  constructor(
    private authService: AuthService,
    private permissionCheckService: PermissionCheckService,
    private profileOverlay: ProfileOverlayService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.visibility = this.permissionCheckService.getVisibility(this.actions);
  }

  ngOnInit(): void {
    this.loggedUserRole = this.authService.getLoggedUserRole();
    this.checkWindowSize();
  }

  checkWindowSize() {
    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe(result => {
      if (result.matches) {
        this.isWebLayout = true;
      } else {
        this.isWebLayout = false;
      }
    });
  }

  onShowUser(event: ElementRef) {
    if (this.user && !this.disabled) {
      this.profileOverlay.open(event, this.user);
    }
  }

  onConfirmShowUser(e: Event) {
    if (this.showFullName) {
      e.stopPropagation();
      this.showConfirm = !this.showConfirm;
      this.isBig = !this.isBig;
      this.clickedUser.emit(this.showConfirm ? this.user.id : null);
    }
  }

  onShowUserProfile(e: Event) {
    e.stopPropagation();
    this.router.navigate([`/organization/users/${this.user?.id}`]);
  }
}

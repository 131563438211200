import { Group, ProjectMemberUser, Team, User } from "src/app/shared";
import { WikiMentionMember } from "src/app/site-management/_store/wiki";



export const SPACE = 'Space';
export const BACKSPACE = 'Backspace';
export const AT_SIGN = '@';
export const DIGIT_2 = 'Digit2';
export const EMPTY = '​';
export const MENTION_CLASS = 'mention';

export interface SelectedMember {
  member?: ProjectMemberUser;
  markdownMember?: WikiMentionMember;
  id: string;
}

export enum MentionMode {
  USER = 'USER',
  GROUP = 'GROUP',
  TEAM = 'TEAM',
  ALL = 'ALL',

  PROJECT_MEMBER = 'PROJECT_MEMBER'
}

import { BoardReleaseDetail, GroupMenu, PermissionCache, Permisson, Project, ProjectRoles, Role, RolePermission, User } from 'src/app/shared';

export interface SiteManagementState {
  loggedUser: User;
  currentProject: Project;
  currentRelease: BoardReleaseDetail;
  searchText: string;
  waitingRequest: number;
  permissions: Permisson[];
  rolePermissions: RolePermission[];
  isIntegrationJiraStatus: boolean;
  groupMenus: GroupMenu[];
  projectRoles: Record<number, Role[]>;
}

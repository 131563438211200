import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ProgressImportJiraColor, routerObject } from 'src/app/shared/_utils';
import { SyncJiraStatus } from 'src/app/site-management/projects/project-sync-jira-data/_models';
import { ProjectSyncJiraDataService } from 'src/app/site-management/projects/project-sync-jira-data/_services/project-sync-jira-data.service';

@Component({
  selector: 'app-sync-jira-data-toast',
  templateUrl: './sync-jira-data-toast.component.html',
  styleUrls: ['./sync-jira-data-toast.component.scss']
})
export class SyncJiraDataToastComponent implements OnInit {
  progressItems = [];
  syncStatus!: SyncJiraStatus;

  constructor(
    private router: Router,
    private nzNotification: NzNotificationService,
    private projectSyncJiraDataService: ProjectSyncJiraDataService
  ) { }

  ngOnInit() {
    this.projectSyncJiraDataService.syncJiraStatus$
      .subscribe(status => {
        if (!status) {
          return;
        }
        this.syncStatus = status;
        const doneProgress = status?.progress;
        const undoneProgress = +Number(100 - doneProgress).toFixed(2);
        this.progressItems = [
          {label: '',  color: ProgressImportJiraColor.Done, count: doneProgress},
          {label: '',  color: ProgressImportJiraColor.ToDo, count: undoneProgress},
        ];
      });
  }

  onViewDetail() {
    this.router.navigateByUrl(routerObject.taskMgmtProject.fullPath);
    this.nzNotification.remove();
  }
}

import { Component, Input } from '@angular/core';
import { Team } from 'src/app/shared/_models';
import { TeamService } from 'src/app/site-management/team/_services/team.service';
import { SearchOverlayBaseComponent } from '../search-overlay-base.component';

@Component({
  selector: 'app-search-overlay-team',
  templateUrl: './search-overlay-team.component.html',
  styleUrls: ['./search-overlay-team.component.scss']
})
export class SearchOverlayTeamComponent extends SearchOverlayBaseComponent<Team> {
  @Input() displayLabels = 2;

  constructor(
    private teamService: TeamService,
  ) {
    super();
    this.config.title = 'Team';
    this.config.multiple = true;
  }

  getSearchFn() {
    return (keyword?: string) => {
      this.config.searchSubscription?.unsubscribe();
      this.config.loading = true;
      const query = `page=0&size=20&keyword=${keyword}`;
      this.config.searchSubscription = this.teamService.getWithQuery(query).subscribe(
        (res) => {
          this.config.items = res;
          this.config.loading = false;
        },
        (error) => {
          this.config.items = [];
          this.config.loading = false;
        }
      );
    };
  }

  getTeamLabels() {
    return this.config.selecteds.map(e => e.name);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  SYNCING_SUCCESS, SyncJiraFileLog, SyncJiraJobStatus, SyncJiraLinkTaskLog, SyncJiraStatus, SyncJiraTaskLog,
  SyncingJiraDataProps, JiraRetryRequest
} from '../_models';
import { isEmpty } from 'lodash';
import { ToastService } from 'src/app/core';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { User } from 'src/app/shared';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class ProjectSyncJiraDataService {
  syncJiraStatus$ = new BehaviorSubject<SyncJiraStatus>(null);
  loggedUser!: User;
  companyId!: number;
  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private authService: AuthService,
    private nzNotification: NzNotificationService,
  ) {
    this.loggedUser = authService.getLoggedUser();
    this.companyId = authService.loggedUser?.company?.id;
  }

  getProgress() {
    return this.http.get<SyncJiraTaskLog[]>(`${environment.apiUrl}/batch-progress`);
  }

  getTaskLinkProgress() {
    return this.http.get<SyncJiraLinkTaskLog[]>(`${environment.apiUrl}/batch-progress/task-link`);
  }

  getFileProgress() {
    return this.http.get<SyncJiraFileLog[]>(`${environment.apiUrl}/batch-progress/file`);
  }

  getSyncJobStatus() {
    return this.http.get<SyncJiraJobStatus[]>(`${environment.apiUrl}/batch-progress/status`);
  }

  changeDisplayStatus(companyId: number, displayJiraFlg: boolean) {
    const payload = { displayJiraFlg };
    return this.http.put(`${environment.apiUrl}/companies/${companyId}/change-display-jira`, payload);
  }

  retrySyncTask(progressId: number, payload: JiraRetryRequest) {
    return this.http.post(`${environment.apiUrl}/batch-progress/retries/${progressId}/tasks`, payload);
  }

  retrySyncLinkTask(progressId: number, payload: JiraRetryRequest) {
    return this.http.post(`${environment.apiUrl}/batch-progress/retries/${progressId}/link-tasks`, payload);
  }

  resyncFile(progressId: number) {
    return this.http.post(`${environment.apiUrl}/batch-progress/retries/${progressId}/files`, {});
  }

  getSyncingDataLogs(props: SyncingJiraDataProps) {
    const status = this.syncJiraStatus$.getValue();
    const canSync = (log, forceSync) => !log?.finishFlg || forceSync;

    const syncTasks = canSync(status?.taskLogs, props?.forceSyncTask) ? this.getProgress() : of(null);
    const syncLinkTasks = canSync(status?.linkTaskLogs, props?.forceSyncTask) ? this.getTaskLinkProgress() : of(null);
    const syncFiles = canSync(status?.fileLogs, props?.forceSyncFile) ? this.getFileProgress() : of(null);
    const jobStatuses = this.getSyncJobStatus();

    forkJoin([syncTasks, syncFiles, syncLinkTasks, jobStatuses])
      .pipe(takeUntil(props.destroyed$))
      .subscribe({
        next: ([taskProgress, fileProgress, linkTaskProgress, jobStatus]) => {
          const taskLogs = this.getStatusItem(taskProgress, jobStatus) ?? status?.taskLogs;
          const linkTaskLogs = this.getStatusItem(linkTaskProgress, jobStatus) ?? status?.linkTaskLogs;
          const fileLogs = this.getStatusItem(fileProgress, jobStatus) ?? status?.fileLogs;

          if (!taskLogs && !linkTaskLogs && !fileLogs) {
            props.pendingCallback();
            return;
          }

          const syncStatus = new SyncJiraStatus({ taskLogs, linkTaskLogs, fileLogs });
          this.syncJiraStatus$.next(syncStatus);

          const canShow = props.callback(syncStatus);
          if (props?.showToast && canShow && syncStatus.displayStatus) {
            const syncToast = this.toast.template(props.toastTemplate, { nzDuration: 0 });
            // Listen close toast event
            syncToast.onClose.subscribe(() => {
              if (syncStatus.progress === SYNCING_SUCCESS) {
                this.toggleDisplayStatus(false);
              }
            });
          }
        }
      });
  }

  toggleDisplayStatus(value?: boolean) {
    const currentStatus = this.syncJiraStatus$.getValue();
    const nextDisplayStatus = value ?? !currentStatus.displayStatus;

    this.changeDisplayStatus(this.companyId, nextDisplayStatus)
      .subscribe({
        next: () => {
          this.loggedUser.company.displayJiraFlg = nextDisplayStatus;
          this.authService.setLoggedUser(this.loggedUser);
          const nextStatus = new SyncJiraStatus({
            displayStatus: nextDisplayStatus,
            taskLogs: currentStatus.taskLogs,
            linkTaskLogs: currentStatus.linkTaskLogs,
            fileLogs: currentStatus.fileLogs
          });
          this.syncJiraStatus$.next(nextStatus);
        }
      });
  }

  getStatusItem(logs: any[], jobStatuses: SyncJiraJobStatus[]) {
    if (isEmpty(logs)) {
      return null;
    }
    const sortedLogs = this.sortLogs(logs);
    const total = sortedLogs.reduce((acc, cur) =>
      acc + (cur?.total ?? cur?.totalTask ?? cur?.totalFiles), 0
    );
    const current = sortedLogs.reduce((acc, cur) =>
      acc + (cur?.current ?? cur?.currentTask ?? cur?.currentFiles), 0
    );
    const finishFlg = sortedLogs.every(log => log?.finishFlg);
    const jobId = this.getStatusJobId(logs);
    const jobStatus = jobStatuses.find(job => job.jobId === jobId)?.status;

    return {
      total,
      current,
      finishFlg,
      logs: sortedLogs,
      status: jobStatus
    };
  }

  sortLogs(logs: any[]) {
    return logs.sort((a, b) => {
      const current = el => el?.currentTask ?? el?.current ?? el?.currentFiles;
      const total = el => el?.totalTask ?? el?.total ?? el?.totalFiles;

      const aFinished = current(a) >= total(a);
      const bFinished = current(b) >= total(b);

      if (aFinished && bFinished) { return 0; }
      if (aFinished) { return -1; }
      if (bFinished) { return 1; }

      return 0;
    });
  }

  removeToast() {
    this.nzNotification.remove();
  }

  getStatusJobId(statuses: any) {
    return statuses[0]?.jobId ?? statuses[0]?.job?.id;
  }
}



export enum FroalaEvent {
  // Custom events
  MARKDOWN_SWITCH = 'markdown.switch',
  HTML_UPLOADED = 'html.updated',
  ATTACHMENT_PUSHED = 'attachment.pushed',
  GET_INSTANCE = 'getInstance',
  HTML_INITIAL = 'html.initial',

  // Image events
  IMAGE_BEFORE_UPLOAD = 'image.beforeUpload',
  IMAGE_UPLOADED = 'image.uploaded',
  IMAGE_LOADED = 'image.loaded',

  // File events
  FILE_BEFORE_UPLOAD = 'file.beforeUpload',
  FILE_UPLOADED = 'file.uploaded',

  // Popup events
  POPUPS_IMAGE_EDIT_SHOW = 'popups.show.image.edit',
  POPUPS_IMAGE_INSERT = 'popups.show.image.insert',
  POPUPS_FILE_INSERT = 'popups.show.file.insert',

  // Error events
  VIDEO_ERROR = 'video.error',
  IMAGE_ERROR = 'image.error',
  FILE_ERROR = 'file.error',

  // Editor events
  CONTENT_CHANGED = 'contentChanged',
  DESTROY = 'destroy',
  INITIALIZED = 'initialized',

  // Paste
  PASTE = 'paste',
  PASTE_FILE = 'pasteFile',
}

import { Component, Input } from '@angular/core';
import { ProjectPlatform } from 'src/app/shared';

@Component({
  selector: 'app-platform-label',
  template: `
    <div class="custom-option">
      <app-icon [icon]="item"></app-icon>
      <span class="ml-2">{{ item.name }}</span>
    </div>
  `
})
export class PlatformLabelComponent {
  @Input() item: ProjectPlatform;
}

import { Component, Input } from '@angular/core';
import { Project } from 'src/app/shared';

@Component({
  selector: 'app-project-option',
  template: `
    <div class="custom-option">
    <img class="option-icon mr-2" [src]="item.avatar" *ngIf="item?.avatar" />
      <span class="ml-2">{{ item.name }}</span>
    </div>
  `
})
export class ProjectOptionComponent {
  @Input() item: Project;
}

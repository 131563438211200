import { CALENDAR_SETTING, SelectOption, HolidayCountry ,Task } from "src/app/shared";

export enum WorkCalendarType {
  Story = 'Story',
  Task = 'Task',
  Bug = 'Bug',
  Event = 'Event',
  Holiday = 'Holiday',
  Leave = 'Leave'
}

export enum WorkCalendarPrefixId {
  EVENT= 'EVENT',
  TASK = 'TASK',
  HOLIDAY = 'HOLIDAY',
  LEAVE = 'LEAVE'
}

export enum WorkCalendarHolidayType {
  Company= 'Company',
  All= 'All'
}

export enum EventIcon {
  Task = 'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/b4282d1c85b4466ddc4c3f5a78921807/Task.svg',
  Story = 'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/e063104751e63f97f1d51aecaf8b3d14/Story.svg',
  Bug = 'https://xcorp-upload.s3.ap-southeast-1.amazonaws.com/resources/7c3d92f98165a5b7a00387f72dde4750/Bug.svg',
}

export enum WorkCalendarMode {
  List,
  Calendar
}

export interface CalendarAction {
  createdDate?: boolean;
  startEndDate?: boolean;
  dueDate?: boolean;
}

export const defaultCalendarAction: CalendarAction = {
  createdDate: false,
  startEndDate: false,
  dueDate: false,
};

export interface CalendarColorItem {
  name: string;
  color: string;
  show: boolean;
  entity?: {
    holidayType?: WorkCalendarHolidayType;
    [WorkCalendarType.Holiday]?: HolidayCountry;
  }
}
export interface CalendarColor {
  [key: string]: CalendarColorItem
}

export interface CalendarSetting {
  actions: CalendarAction;
  colors: CalendarColor;
}

export interface TaskCalendar extends Task {
  backgroundColor?: string;
  taskEventId?: string;
  action?: CalendarAction;
}

export enum DisplayEventType {
  Auto = 'auto',
  Block = 'block',
  None = 'none'
}

export const getCalendarActionSetting = () => {
  return JSON.parse(localStorage.getItem(CALENDAR_SETTING)) || defaultCalendarAction;
};

export const defaultColors = ['#dce5ff', '#e4f2e4', '#fbdbdd', '#6df5ff', '#fbdbdd'];
export const defaultNameColors = ['Task', 'Story', 'Bug', 'Event', 'Leave'];

export const calendarSettingColorDefault = () => {
  const calendarColors = {};
  defaultNameColors.forEach((e, idx) => {
    calendarColors[e] = { name: e, color: defaultColors[idx], show: true };
  });

  return calendarColors;
};

export enum NotificationTypeEnum {
  EMAIL = 'EMAIL',
  NOTIFICATION = 'NOTIFICATION',
}

export const notifications: SelectOption[] = [
  {
    name: 'Notification',
    value: NotificationTypeEnum.NOTIFICATION
  },
  {
    name: 'Email',
    value: NotificationTypeEnum.EMAIL
  }
];

export enum StatusParticipationEvent {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING'
}

export const eventParticipationOptions: SelectOption[] = [
  {
    name: 'Yes',
    value: StatusParticipationEvent.APPROVED
  },
  {
    name: 'No',
    value: StatusParticipationEvent.REJECTED
  },
  {
    name: 'Maybe',
    value: StatusParticipationEvent.WAITING
  }
];

import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProjectsService } from 'src/app/site-management/projects/_services';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { Project, ProjectMemberUser, Task, TaskPatchType, UserShort } from '../../_models';

@Component({
  selector: 'app-task-assignee',
  templateUrl: './task-assignee.component.html',
  styleUrls: ['./task-assignee.component.scss'],
})
export class TaskAssigneeComponent implements OnInit, OnDestroy {
  @Input() taskId: number;
  @Input() assignee: UserShort;
  @Input() projectId: number;
  @Input() detect = false;
  @Output() selectedAssignee: EventEmitter<Task> = new EventEmitter<Task>();

  isLoadingAssignee = false;
  assigneeSub: Subscription;
  patchSub: Subscription;
  assignees: ProjectMemberUser[] = [];
  keyword = '';

  constructor(
    public projectsService: ProjectsService,
    public taskService: TaskService,
    protected store: Store,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.assigneeSub?.unsubscribe();
    this.patchSub?.unsubscribe();
  }

  ngOnInit(): void {

  }

  searchAssignees(keyword: string = '') {
    this.isLoadingAssignee = true;
    this.assigneeSub?.unsubscribe();
    this.assigneeSub = this.projectsService
      .getProjectMembers(this.projectId, keyword)
      .subscribe(
        (res) => {
          this.assignees = res;
        },
        (error) => {
          this.assignees = [];
        },
        () => {
          this.isLoadingAssignee = false;
          this.onDetech();
        }
      );
  }

  selectAssignee(user: ProjectMemberUser) {
    if (this.assignee?.id !== user?.id || !user) {
      this.patchSub?.unsubscribe();
      this.patchSub = this.taskService
        .patch(this.taskId, {
          type: TaskPatchType.assigneeId,
          value: user?.id
        })
        .subscribe((task) => {
          this.selectedAssignee.emit(task);
        });
    }
  }

  onDetech() {
    if (!this.detect) {
      return;
    }
    this.cdr.detectChanges();
  }
}

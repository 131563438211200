import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[dropdownList]',
  exportAs: 'dropdownList',
})
export class DropdownListDirective {
  @Input() items: any[] = [];
  @Input() optionSelector = '.mention-item';
  @Input() selectedClass= 'selected-item';
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();


  private selectedIndex: number = -1;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.selectedIndex = -1;

      if (this.items.length > 0) {
        this.navigate(1);
      }
    }
  }

  ngOnDestroy() {

  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        this.navigate(-1);
        break;
      case 'ArrowDown':
        this.navigate(1);
        break;
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        this.emitSelected();
        break;
    }
  }

  keydown(event) {
    this.onKeyDown(event);
  }

  private navigate(delta: number): void {
    this.selectedIndex = (this.selectedIndex + delta + this.items.length) % this.items.length;
    this.scrollToSelected();
    this.highlightSelected();
  }

  private scrollToSelected(): void {
    const item = this.el.nativeElement.querySelectorAll(this.optionSelector)[this.selectedIndex];
    if (item) {
      item.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
    }
  }

  private highlightSelected(): void {
    const items = this.el.nativeElement.querySelectorAll(this.optionSelector);
    items.forEach((item, index) => {
      item.classList.toggle(this.selectedClass, index === this.selectedIndex);
    });
  }

  private emitSelected(): void {
    if (this.selectedIndex >= 0 && this.selectedIndex < this.items.length) {
      const selectedItem = this.items[this.selectedIndex];
      this.itemSelected.emit(selectedItem);
    } else {
      this.itemSelected.emit(null);
    }
  }
}

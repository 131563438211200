import FroalaEditor from 'froala-editor';
import { AppInjector } from 'src/app/app.module';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { FroalaEvent } from '../models';
import { UtilService } from 'src/app/core';
import { EDITOR_SYNC_FILE } from 'src/app/shared';

FroalaEditor.PLUGINS.attachmentProcessor = function (editor: FroalaEditor) {
  const fileService: FileService = AppInjector.get(FileService);

  function _init() {
    editor.events.on(FroalaEvent.HTML_INITIAL, function () {
      const el = $('x-editor').find('[src]');
      const urls = [];

      el.each(function (index, element) {
        const src = $(element).attr('src');
        let origin = $(element).attr('origin');

        if (origin) {
          origin = decodeURIComponent(origin);
        }

        // Migrate src to origin
        if (!origin) {
          $(element).attr('origin', src);
        }

        if (origin && !src) {
          $(element).attr('src', origin);
        }

        urls.push(origin || src);
      });

      if(editor?.opts?.disableAttactmentProcessor) {
        return;
      }

      fileService.getAuthorizations(urls).subscribe(res => {
        res.forEach((u, i) => {
          const fullUrl = u.fullUrl || '';
          const url = fullUrl.split(`?file-url=`)?.[1] || ''

          if (url && !UtilService.isValidUrl(url)) {
            $(el[i]).attr('src', `${EDITOR_SYNC_FILE}?file-url=${url}`);
          } else {
            $(el[i]).attr('src', u.fullUrl);
          }
        });

        editor.events.trigger(FroalaEvent.HTML_UPLOADED, [editor.html.get()])
      })
    });
  }

  return {
    _init
  };
};

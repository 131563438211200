import { Injectable } from '@angular/core';
import { jiraMarkupToHTML } from './jiramark';
import { closingTags } from './jira-mark.const';
import * as j2m from 'jira2md';
import * as showdown from 'showdown';
import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer';
import { EDITOR_PREVIEW_FILE } from 'src/app/shared/_utils/_consts/file.const';
import { JiraTableProcessor } from './jira-table-processor';

@Injectable({
  providedIn: 'root'
})
export class JiraMarkService {
  closingTags = closingTags;

  constructor() {}

  isContainHtml(html: string): boolean {
    const regexString = this.closingTags
      .map((tag) => tag.replace(/<\/(\w+)>/, '\\<$1\\>'))
      .join('|');
    const regex = new RegExp(regexString);

    return regex.test(html);
  }

  parse(value: string, option?: any) {
    if (value) {
      if (typeof value === 'object') { // check if value is jira adf json format
        return this.adfToHtml(value);
      }

      if (!this.isContainHtml(value)) { // check if value is jira markup format
        let html = jiraMarkupToHTML(value, option);
        html = this.decodeHtml(html);
        return html;
      }
    }
    // value is null or html
    return value;
  }

  htmlToAdf(html) {
    const jsonObj = this.html2AdfObject(html);
    this.removePrivateProp(jsonObj);
    this.replaceLineBreaks(jsonObj);
    this.replaceFileId(jsonObj);
    this.replaceMention(jsonObj);
    this.replaceTable(jsonObj, html);

    return jsonObj;
  }

  adfToMarkup(adf) {
    const transformer = new WikiMarkupTransformer();
    const node = transformer['schema'].nodeFromJSON(adf);
    return transformer.encode(node);
  }

  adfToHtml(adf) {
    this.replaceMention(adf, false);
    const markup = this.adfToMarkup(adf);
    const html = jiraMarkupToHTML(markup, {});
    return this.decodeHtml(html);
  }

  private removePrivateProp(obj) {
    if (!obj) {
      return;
    }
    if (obj['attrs'] && obj['type']==='paragraph') {
      delete obj['attrs'];
    }

    for (let key in obj) {
      if (key.startsWith('_') || key === `localId`) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.removePrivateProp(obj[key]);
      }
    }
  }

  private replaceHtmlToMarkdown(html: string) {
    let _html = html;
    _html = html
            .replaceAll(/<figcaption\b[^>]*>(.*?)<\/figcaption>/g, '<p>$1</p>')   // figcaption tag
            .replaceAll(/<a[^>]*\s+href="([^"]*)"[^>]*>/g, '<a href="$1">') //  clear attribute a tag
            .replaceAll(/<span.*?><iframe src="(.*?)".*?<\/iframe><\/span>/g, (match, src) =>  '<a href="' + src + '">' + src + '</a>') // replace iframe tag to a tag
            .replaceAll(/<span.*?><video.*?src="(.*?)".*?<\/video><\/span>/g, `<img src="assets/img/editor-coment-file.svg?file-url=$1" alt="">`); // replace video
    return _html;
  }

  private replaceMarkdownToAdf(md) {
    let _md = md;
    _md = md.replaceAll(/(?<!\!)\[([^\]]+)\]\(<([^>]+)>\)/g, '[$1|$2]');  // fix convert link
    return _md;
  }

  private replaceMention(obj: any, toJira = true): void {
    if (!obj?.content) {
      return;
    }
    obj.content.forEach((e, index) => {
      if (toJira && e.type === "text" && e.text.includes('class="mention"')) {
        const dataId = /data-id="([^"]+)"/.exec(e.text)?.[1];
        const dataValue = /data-value="([^"]+)"/.exec(e.text)?.[1];
        const textBefore = /(.*?)\[span.*?\]/.exec(e.text)?.[1];

        if (dataId && dataValue) {
          const mentionObj = {
            type: 'mention',
            attrs: { text: `<span class="mention" data-id="${dataId}" data-value="${dataValue}">@${dataValue}</span>` }
          };
          if (textBefore) {
            e.text = textBefore;
            obj.content.splice(index + 1, 0, mentionObj);
          } else {
            e.type = mentionObj.type;
            e.attrs = mentionObj.attrs;
            delete e.text;
          }
        }
      }
      if (!toJira && e.type === "mention") {
        e.type = 'text';
        e.text = e.attrs?.text;
      }
      this.replaceMention(e, toJira);
    });
    if (toJira) {
      obj.content = obj.content.filter((e, index)=> !index || obj.content[index - 1].type !== "mention");
    }
  }

  private replaceLineBreaks(obj: any): void {
    if (obj.type === "text" && obj.text === "[br]") {
      obj.type = "hardBreak";
      delete obj.text;  // Remove the 'text' property
    } else if (obj.content) {
      obj.content.forEach(e => this.replaceLineBreaks(e));
    }
  }

  private replaceFileId(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.replaceFileId(obj[key]);
      } else if (key === 'id' && typeof obj[key] === 'string') {
        let originalString = obj[key];
        const regex = /\[.*?file-url=([^&\]]*)/;
        const match = regex.exec(originalString);
        const url = match ? match[1] : '';

        if (url) {
          obj[key] = `[${decodeURIComponent(url)}]`; // replace "[assets/img/editor-coment-file.svg?file-url=${s3URL + params}" to "[${s3URL}]]""
        } else {
          obj[key] = decodeURIComponent(originalString.replace(/\?.*?(?=\])/, '')); // remove query param
        }
      }
    }
  }

  private isJSONStr(text: string) {
    if (typeof text !== 'string') {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  // decodeHtml(text: string) { // Hàm này để check và convert html entity
  //   const doc = new DOMParser().parseFromString(text, 'text/html');
  //   return doc.documentElement.textContent;
  // }

  decodeHtml(text: string) { // Hàm này để check và convert html entity\
    var e = document.createElement('textarea');
    e.innerHTML = text;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  md2html(text: string) {
    const converter = new showdown.Converter();
    return converter.makeHtml(text);
  }

  html2AdfObject(html: string) {
    const converter = new showdown.Converter();
    const md = converter.makeMarkdown(this.replaceHtmlToMarkdown(html));
    const jira = j2m.to_jira(this.replaceMarkdownToAdf(md));
    const object = new WikiMarkupTransformer();
    return object.parse(jira).toJSON();
  }

  replaceTable(obj: any, html: string) {
    // 1. parse html string to html
    const divEl = document.createElement('div');
    divEl.innerHTML = html;

    // 2. find all table elements
    const tableEls = divEl.querySelectorAll('table');

    // 3. loop object content to find objects index with table type
    const tableAdfNodesIdx = obj.content
      .map((cur, index) => (cur.type === 'table' ? index : -1))
      .filter(index => index !== -1);

    // 4. override each object with the corresponding table element
    const jiraTable = new JiraTableProcessor();
    tableEls.forEach((el, index) => {
      obj.content[tableAdfNodesIdx[index]] = jiraTable.convertToAdf(el, this.html2AdfObject.bind(this));
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavbarActionService {
  private isCompanySwitchSubject = new BehaviorSubject<boolean>(false);
  isCompanySwitch$ = this.isCompanySwitchSubject.asObservable();

  constructor() { }

  setNavbarMode(isCompanySwitch: boolean) {
    this.isCompanySwitchSubject.next(isCompanySwitch);
  }
}

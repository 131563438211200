import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MentionMode } from 'src/app/libs/froala/models';
import { Group, List2Res, ProjectMemberUser, Team, User } from 'src/app/shared';
import { WikiMemberType, WikiMentionMember } from 'src/app/site-management/_store/wiki';
import { DropdownListDirective } from '../../../../_directives/dropdown-list.directive';

@Component({
  selector: 'app-mention-list',
  templateUrl: './mention-list.component.html',
  styleUrls: ['./mention-list.component.scss']
})
export class MentionListComponent implements OnChanges {
  @Input() members: ProjectMemberUser[];
  @Input() keyword: string = '';
  @Input() markdownMembers: WikiMentionMember[];
  @Input() currentMode = MentionMode.ALL;
  @Output() clickMember = new EventEmitter<ProjectMemberUser>();
  @Output() clickMarkdownMember = new EventEmitter<WikiMentionMember>();
  @Output() focus = new EventEmitter<boolean>();
  @ViewChild('dropdownList') dropdownList: DropdownListDirective;

  constructor(private cdr: ChangeDetectorRef) {}

  activeIndex: number = 0;
  mentionMode = MentionMode;

  projectMembers: ProjectMemberUser[];
  userRes: WikiMentionMember[] = [];
  teamRes: WikiMentionMember[] = [];
  groupRes: WikiMentionMember[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.keyword || changes.members || changes.markdownMembers) {
      this.setMembers();
    }
  }

  setMembers() {
    if (this.markdownMembers?.length) {
      const members = this.markdownMembers.filter(e => e.objectName.toLocaleLowerCase().includes(this.keyword));
      this.userRes = members?.filter(e => e.objectType === WikiMemberType.USER) || [];
      this.teamRes = members?.filter(e => e.objectType === WikiMemberType.TEAM) || [];
      this.groupRes = members?.filter(e => e.objectType === WikiMemberType.GROUP) || [];
      return;
    }
    this.projectMembers = this.members?.filter(e => e.fullName.toLocaleLowerCase().includes(this.keyword));
    this.cdr.detectChanges();
  }

  onMemberClick(member: ProjectMemberUser) {
    this.clickMember.emit(member);
  }

  onClick(member: WikiMentionMember) {
    this.clickMarkdownMember.emit(member);
  }

  changeMode(mode: MentionMode) {
    this.currentMode = mode;
  }

  onKeyDown(event: KeyboardEvent) {
    this.dropdownList.keydown(event);
  }
}

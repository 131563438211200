import { FieldType } from '../_enums';

export const DynamicFieldHash = {
  PAGE_MY_PROFILE_BANK_SECTIONS: 'PAGE_MY_PROFILE_BANK_SECTIONS',
  OR_MY_PROFILE_BANK_SECTIONS: 'OR_MY_PROFILE_BANK_SECTIONS',
  USER_DASHBOARD_MENU_LEVEL_1: 'USER_DASHBOARD_MENU_LEVEL_1',
  LEAVE: 'LEAVE',
  WORK_TRACKING: 'WORK_TRACKING',
  TIMESHEET: 'TIMESHEET',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  WORK_CALENDAR_MENULEVEL1: 'WORK_CALENDAR_MENULEVEL1',
  WORK_CALENDAR_MENULEVEL2: 'WORK_CALENDAR_MENULEVEL2',
  WORK_CALENDAR_TABS: 'WORK_CALENDAR_TABS',
  WORK_CALENDAR_SECTIONS: 'WORK_CALENDAR_SECTIONS',
  DOCUMENT: 'DOCUMENT',
  OKR_BOARD: 'OKR_BOARD',
  HR: 'HR',
  ORGANIZATION: 'ORGANIZATION',
  LEAVE_DASHBOARD: 'LEAVE_DASHBOARD',
  LEAVE_SETTING_CATEGORY_TAB: 'LEAVE_SETTING_CATEGORY_TAB',
  LEAVE_DASHBOARD_SECTIONS: 'LEAVE_DASHBOARD_SECTIONS',
  LEAVE_MY_REQUESTS_SECTIONS: 'LEAVE_MY_REQUESTS_SECTIONS',
  LEAVE_REPORT_SECTIONS: 'LEAVE_REPORT_SECTIONS',
  LEAVE_BALANCE_SECTIONS: 'LEAVE_BALANCE_SECTIONS',
  LEAVE_APPROVAL_SECTIONS: 'LEAVE_APPROVAL_SECTIONS',
  LEAVE_SETTING_CATEGORY_SECTIONS: 'LEAVE_SETTING_CATEGORY_SECTIONS',
  LEAVE_SETTING_TYPE_SECTIONS: 'LEAVE_SETTING_TYPE_SECTIONS',
  LEAVE_DASHBOARD_TAB: 'LEAVE_DASHBOARD_TAB',
  LEAVE_MY_REQUEST_TAB: 'LEAVE_MY_REQUEST_TAB',
  LEAVE_REPORT_TAB: 'LEAVE_REPORT_TAB',
  LEAVE_BALANCE_TAB: 'LEAVE_BALANCE_TAB',
  LEAVE_APPROVAL_TAB: 'LEAVE_APPROVAL_TAB',
  MY_REQUESTS: 'MY_REQUESTS',
  LEAVE_SETTING_TYPE_TAB: 'LEAVE_SETTING_TYPE_TAB',
  LEAVE_SETTING_CARRY_OVER: 'LEAVE_SETTING_CARRY_OVER',
  LEAVE_DASHBOARD_WFH_TODAY_SECTIONS: 'LEAVE_DASHBOARD_WFH_TODAY_SECTIONS',
  LEAVE_DASHBOARD_OFF_TODAY_SECTIONS: 'LEAVE_DASHBOARD_OFF_TODAY_SECTIONS',
  REPORT: 'REPORT',
  LEAVE_DASHBOARD_BALANCE_DETAILS_SECTIONS:
    'LEAVE_DASHBOARD_BALANCE_DETAILS_SECTIONS',
  BALANCE: 'BALANCE',
  APPROVAL: 'APPROVAL',
  LEAVE_SETTING: 'LEAVE_SETTING',
  MY_TRACKING: 'MY_TRACKING',
  WT_MY_TRACKING_SECTIONS: 'WT_MY_TRACKING_SECTIONS',
  WT_DASHBOARD_SECTIONS: 'WT_DASHBOARD_SECTIONS',
  WT_SETTINGS_LEAVE_TYPE_SECTIONS: 'WT_SETTINGS_LEAVE_TYPE_SECTIONS',
  WT_MY_TRACKING_TAB: 'WT_MY_TRACKING_TAB',
  WT_DASHBOARD_TAB: 'WT_DASHBOARD_TAB',
  WT_SETTINGS_TAB: 'WT_SETTINGS_TAB',
  WORK_TRACKING_DASHBOARD: 'WORK_TRACKING_DASHBOARD',
  WT_SETTINGS_SHIFT_PROFILE_SECTIONS: 'WT_SETTINGS_SHIFT_PROFILE_SECTIONS',
  WORK_TRACKING_SETTING: 'WORK_TRACKING_SETTING',
  MY_LOGWORK: 'MY_LOGWORK',
  TS_MYLOGWORK_TAB: 'TS_MYLOGWORK_TAB',
  TS_REPORTS_TAB: 'TS_REPORTS_TAB',
  TS_MY_LOGWORK_SECTIONS: 'TS_MY_LOGWORK_SECTIONS',
  TS_REPORTS_SECTIONS: 'TS_REPORTS_SECTIONS',
  TIMESHEET_REPORT: 'TIMESHEET_REPORT',
  TIME_SHEET_SETTING_MENULEVEL2: 'TIME_SHEET_SETTING_MENULEVEL2',
  TM_ACTIVE_SPRINT_SECTIONS: 'TM_ACTIVE_SPRINT_SECTIONS',
  TM_BACKLOG_SECTIONS: 'TM_BACKLOG_SECTIONS',
  TM_EPIC_SECTIONS: 'TM_EPIC_SECTIONS',
  TM_RELEASE_SECTIONS: 'TM_RELEASE_SECTIONS',
  TM_PS_LABEL_SECTIONS: 'TM_PS_LABEL_SECTIONS',
  TM_PS_STORYLINE_SECTIONS: 'TM_PS_STORYLINE_SECTIONS',
  TM_PS_PRIORITY_SECTIONS: 'TM_PS_PRIORITY_SECTIONS',
  TM_PROJECTS_TAB: 'TM_PROJECTS_TAB',
  TM_ACTIVE_SPRINT_TAB: 'TM_ACTIVE_SPRINT_TAB',
  TM_RELEASE_TAB: 'TM_RELEASE_TAB',
  TM_BACKLOG_TAB: 'TM_BACKLOG_TAB',
  TM_EPIC_TAB: 'TM_EPIC_TAB',
  TM_STORYLINE_TAB: 'TM_STORYLINE_TAB',
  TM_PS_GENERAL_TAB: 'TM_PS_GENERAL_TAB',
  PROJECTS: 'PROJECTS',
  TM_PS_BOARD_SETTINGS_SECTIONS: 'TM_PS_BOARD_SETTINGS_SECTIONS',
  TM_PS_PROJECT_MEMBERS_SECTIONS: 'TM_PS_PROJECT_MEMBERS_SECTIONS',
  TM_PS_GENERAL_SECTIONS: 'TM_PS_GENERAL_SECTIONS',
  TM_STORYLINE_SECTIONS: 'TM_STORYLINE_SECTIONS',
  TM_PROJECTS_SECTIONS: 'TM_PROJECTS_SECTIONS',
  TM_TASK_TAB: 'TM_TASK_TAB',
  TM_ACTIVE_SPRINT_TASK_DETAIL: 'TM_ACTIVE_SPRINT_TASK_DETAIL',
  ACTIVE_SPRINT: 'ACTIVE_SPRINT',
  TM_PS_PROJECT_MEMBER_TAB: 'TM_PS_PROJECT_MEMBER_TAB',
  TM_TASK: 'TM_TASK',
  RELEASE: 'RELEASE',
  TM_PS_BOARD_SETTINGS_TAB: 'TM_PS_BOARD_SETTINGS_TAB',
  TM_PS_BOARD_STATUS_GROUP: 'TM_PS_BOARD_STATUS_GROUP',
  BACKLOG: 'BACKLOG',
  TM_PS_PRIORITY_TAB: 'TM_PS_PRIORITY_TAB',
  TM_PS_STORYLINE_TAB: 'TM_PS_STORYLINE_TAB',
  EPIC: 'EPIC',
  TM_PS_LABEL_TAB: 'TM_PS_LABEL_TAB',
  STORYLINE: 'STORYLINE',
  PROJECT_SETTINGS: 'PROJECT_SETTINGS',
  DOCUMENT_PROJECT: 'DOCUMENT_PROJECT',
  DC_PROJECTS_TAB: 'DC_PROJECTS_TAB',
  DC_COMPANY_DOCUMENTS_TAB: 'DC_COMPANY_DOCUMENTS_TAB',
  DC_PROJECTS_SECTIONS: 'DC_PROJECTS_SECTIONS',
  DC_COMPANY_DOCUMENTS_SECTIONS: 'DC_COMPANY_DOCUMENTS_SECTIONS',
  COMPANY_DOCUMENTS: 'COMPANY_DOCUMENTS',
  OKR: 'OKR',
  OKR_SETTINGS_SPACES_SECTIONS: 'OKR_SETTINGS_SPACES_SECTIONS',
  OKR_SETTINGS_TIMELINES_SECTIONS: 'OKR_SETTINGS_TIMELINES_SECTIONS',
  OKR_SETTINGS_METRICS_SECTIONS: 'OKR_SETTINGS_METRICS_SECTIONS',
  OKR_OKR_TAB: 'OKR_OKR_TAB',
  OKR_SETTINGS_SPACES_TAB: 'OKR_SETTINGS_SPACES_TAB',
  OKR_OKR_SECTIONS: 'OKR_OKR_SECTIONS',
  OKR_SETTINGS: 'OKR_SETTINGS',
  OKR_SETTINGS_TIMELINE_TAB: 'OKR_SETTINGS_TIMELINE_TAB',
  OKR_SETTINGS_METRICS_TAB: 'OKR_SETTINGS_METRICS_TAB',
  HR_HIRING_BOARD_MENULEVEL2: 'HR_HIRING_BOARD_MENULEVEL2',
  HR_POLICY_POLICY_TAB: 'HR_POLICY_POLICY_TAB',
  HR_POLICY_SECTIONS: 'HR_POLICY_SECTIONS',
  HR_BENEFIT_SECTIONS: 'HR_BENEFIT_SECTIONS',
  HR_HIRING_BOARD_SECTIONS: 'HR_HIRING_BOARD_SECTIONS',
  POLICY: 'POLICY',
  HR_HIRING_BOARD_TABS: 'HR_HIRING_BOARD_TABS',
  HR_POLICY_BENEFIT_TAB: 'HR_POLICY_BENEFIT_TAB',
  OR_USER_SECTIONS: 'OR_USER_SECTIONS',
  OR_MY_PRO_PERSONAL_TAB: 'OR_MY_PRO_PERSONAL_TAB',
  OR_ROLE_PERMISSION_TAB: 'OR_ROLE_PERMISSION_TAB',
  OR_HOLIDAY_TAB: 'OR_HOLIDAY_TAB',
  OR_BILLING_TAB: 'OR_BILLING_TAB',
  OR_INTEGRATION_TAB: 'OR_INTEGRATION_TAB',
  OR_MY_PROFILE_PERSONAL_SECTIONS: 'OR_MY_PROFILE_PERSONAL_SECTIONS',
  OR_MY_PROFILE_CONTACT_SECTIONS: 'OR_MY_PROFILE_CONTACT_SECTIONS',
  OR_MY_PROFILE_WORK_INFO_SECTIONS: 'OR_MY_PROFILE_WORK_INFO_SECTIONS',
  OR_MY_PROFILE_LEAVE_HISTORY_SECTIONS: 'OR_MY_PROFILE_LEAVE_HISTORY_SECTIONS',
  OR_MY_PROFILE_REPORT_HISTORY_SECTIONS:
    'OR_MY_PROFILE_REPORT_HISTORY_SECTIONS',
  OR_MY_PROFILE_ONBOARD_MISSION_SECTIONS:
    'OR_MY_PROFILE_ONBOARD_MISSION_SECTIONS',
  OR_ROLE_PERMISSION_SECTIONS: 'OR_ROLE_PERMISSION_SECTIONS',
  OR_MY_PROFILE_HOLIDAY_SECTIONS: 'OR_MY_PROFILE_HOLIDAY_SECTIONS',
  OR_BILLING_CURRENT_PLAN_SECTIONS: 'OR_BILLING_CURRENT_PLAN_SECTIONS',
  OR_INTEGRATION_SLACK_SECTIONS: 'OR_INTEGRATION_SLACK_SECTIONS',
  OR_LOCATION_SECTIONS: 'OR_LOCATION_SECTIONS',
  OR_SHIFT_SHIFT_SECTIONS: 'OR_SHIFT_SHIFT_SECTIONS',
  OR_SHIFT_PROFILE_SECTIONS: 'OR_SHIFT_PROFILE_SECTIONS',
  OR_TEAM_SECTIONS: 'OR_TEAM_SECTIONS',
  OR_GROUP_SECTIONS: 'OR_GROUP_SECTIONS',
  LOCATIONS: 'LOCATIONS',
  OR_LOCATION_TAB: 'OR_LOCATION_TAB',
  OR_SHIFT_TAB: 'OR_SHIFT_TAB',
  OR_TEAM_TAB: 'OR_TEAM_TAB',
  OR_GROUP_TAB: 'OR_GROUP_TAB',
  OR_USER_TAB: 'OR_USER_TAB',
  SHIFT: 'SHIFT',
  OR_BILLING_NCE_SECTIONS: 'OR_BILLING_NCE_SECTIONS',
  OR_MY_PRO_WORK_INFO_TAB: 'OR_MY_PRO_WORK_INFO_TAB',
  OR_INTEGRATION_JIRA_SECTIONS: 'OR_INTEGRATION_JIRA_SECTIONS',
  OR_SHIFT_PROFILE_TAB: 'OR_SHIFT_PROFILE_TAB',
  OR_BILLING_INFORMATION_SECTIONS: 'OR_BILLING_INFORMATION_SECTIONS',
  TEAM: 'TEAM',
  OR_MY_PRO_LEAVE_HISTORY_TAB: 'OR_MY_PRO_LEAVE_HISTORY_TAB',
  OR_BILLING_HISTORY_SECTIONS: 'OR_BILLING_HISTORY_SECTIONS',
  OR_MY_PRO_REPORT_HISTORY_TAB: 'OR_MY_PRO_REPORT_HISTORY_TAB',
  GROUP: 'GROUP',
  OR_MY_PRO_ONBOARD_MISSIONS_TAB: 'OR_MY_PRO_ONBOARD_MISSIONS_TAB',
  OR_BILLING_PAYMENT_SECTIONS: 'OR_BILLING_PAYMENT_SECTIONS',
  USER: 'USER',
  OR_BILLING_BILLING_SECTIONS: 'OR_BILLING_BILLING_SECTIONS',
  MY_PROFILE: 'MY_PROFILE',
  ROLE_PERMISSION: 'ROLE_PERMISSION',
  HOLIDAY: 'HOLIDAY',
  BILLING: 'BILLING',
  INTEGRATION: 'INTEGRATION',
  GANTT: 'GANTT',
  GANTT_TAB: 'GANTT_TAB',
  GANTT_SECTIONS: 'GANTT_SECTIONS',
  ORG_TREE: 'ORG_TREE',
  ORG_TREE_TAB: 'ORG_TREE_TAB',
  ORG_TREE_SECTIONS: 'ORG_TREE_SECTIONS',
  OR_WORK_INFO_LOCATION_GROUP_CONTACT_SECTIONS:
    'OR_WORK_INFO_LOCATION_GROUP_CONTACT_SECTIONS',
  OR_WORK_INFO_JOB_DATES_SECTIONS: 'OR_WORK_INFO_JOB_DATES_SECTIONS',
  PAGE: 'PAGE',
  ONBOARD_MANAGEMENT_LV2: 'ONBOARD_MANAGEMENT_LV2',
  HR_SETTINGS_LV2: 'HR_SETTINGS_LV2',
  AUDIT_LOG_MENU_LEVEL_1: 'AUDIT_LOG_MENU_LEVEL_1',
  SETTINGS: 'SETTINGS',
  SETTINGS_DYNAMIC_FIELD: 'SETTINGS_DYNAMIC_FIELD',
  SETTINGS_DYNAMIC_FIELD_TABS: 'SETTINGS_DYNAMIC_FIELD_TABS',
  SETTINGS_DYNAMIC_FIELD_SECTIONS: 'SETTINGS_DYNAMIC_FIELD_SECTIONS',
  SETTINGS_LANGUAGE: 'SETTINGS_LANGUAGE',
  SETTINGS_NOTIFICATIONS: 'SETTINGS_NOTIFICATIONS',
  SETTINGS_DOCUMENT_TEMPLATES: 'SETTINGS_DOCUMENT_TEMPLATES',
  SETTINGS_LOCATION: 'SETTINGS_LOCATION',
  CUSTOMER_SETTING: 'CUSTOMER_SETTING',
  OKR_DASHBOARD: 'OKR_DASHBOARD',
  PAGE_MY_PROFILE: 'PAGE_MY_PROFILE',
  OR_MY_PRO_NOTIFICATION_TAB: 'OR_MY_PRO_NOTIFICATION_TAB',
  OR_MY_PRO_POLICIES_TAB: 'OR_MY_PRO_POLICIES_TAB',
  PAGE_TASK_TABS: 'PAGE_TASK_TABS',
  PAGE_TASK_SECTIONS: 'PAGE_TASK_SECTIONS',
  SALARY_PROFILE_LV2: 'SALARY_PROFILE_LV2',
  PAYROLL_LV2: 'PAYROLL_LV2',
  PAY_SCHEDULE_LV2: 'PAY_SCHEDULE_LV2',
  PAGE_MY_PROFILE_TABS: 'PAGE_MY_PROFILE_TABS',
  PAGE_MY_PROFILE_SECTIONS: 'PAGE_MY_PROFILE_SECTIONS',
  PAGE_MY_PROFILE_CONTACT_SECTIONS: 'PAGE_MY_PROFILE_CONTACT_SECTIONS',
  PAGE_MY_PROFILE_WORK_INFO_SECTIONS: 'PAGE_MY_PROFILE_WORK_INFO_SECTIONS',
  MY_PROFILE_LOCATION_GROUP_TEAM: 'MY_PROFILE_LOCATION_GROUP_TEAM',
  MY_PROFILE_JOB_DATES: 'MY_PROFILE_JOB_DATES',
  TASK_MANAGEMENT_PROJECT_SETTINGS_TASK_TYPE:
    'TASK_MANAGEMENT_PROJECT_SETTINGS_TASK_TYPE',
  TASK_MANAGEMENT_PROJECT_SETTINGS_HISTORY:
    'TASK_MANAGEMENT_PROJECT_SETTINGS_HISTORY',
  HR_MEMBER_SETTING_TAB: 'HR_MEMBER_SETTING_TAB',
  HR_ONBOARD_MEMBER_SETTING_TAB: 'HR_ONBOARD_MEMBER_SETTING_TAB',
  HR_ONBOARD_MANAGEMENT_TAB: 'HR_ONBOARD_MANAGEMENT_TAB',
  HR_HIRING_BOARD_SETTING_TAB: 'HR_HIRING_BOARD_SETTING_TAB',
  ORG_TREE_COMPANY_STRUCTURE: 'ORG_TREE_COMPANY_STRUCTURE',
  ORG_TREE_JOB_TITLE: 'ORG_TREE_JOB_TITLE',
  SETTING_CARRY_OVER: 'SETTING_CARRY_OVER',
  SALARY_SETTING_TABS: 'SALARY_SETTING_TABS',
  MY_OKR_MENULEVEL2: 'MY_OKR_MENULEVEL2',
  MY_OKR_TABS: 'MY_OKR_TABS',
  MY_OKR_SECTIONS: 'MY_OKR_SECTIONS',
  TASK_TEMPLATE_MENULEVEL2: 'TASK_TEMPLATE_MENULEVEL2'
};

export const FieldHash = {
  MY_PF_EMPLOYEE_ID: 'MY_PF_EMPLOYEE_ID',
  MY_PF_USERNAME: 'MY_PF_USERNAME',
  MY_PF_FULLNAME: 'MY_PF_FULLNAME',
  MY_PF_EMAIL: 'MY_PF_EMAIL',
  MY_PF_LOCATION: 'MY_PF_LOCATION',
  MY_PF_GROUPS: 'MY_PF_GROUPS',
  MY_PF_SHIFT_PROFILE: 'MY_PF_SHIFT_PROFILE',
  MY_PF_ROLE: 'MY_PF_ROLE',
  MY_PF_JOB_TITLE: 'MY_PF_JOB_TITLE',
  MY_PF_TEAM: 'MY_PF_TEAM',
  MY_PF_PHONE: 'MY_PF_PHONE',
  MY_PF_BACKUP_PHONE: 'MY_PF_BACKUP_PHONE',
  MY_PF_SKYPE: 'MY_PF_SKYPE',
  MY_PF_BIRTHDAY: 'MY_PF_BIRTHDAY',
  MY_PF_FIRST_WORK_DATE: 'MY_PF_FIRST_WORK_DATE',
  MY_PF_LAPTOP_BUY_DATE: 'MY_PF_LAPTOP_BUY_DATE',
  MY_PF_PART_TIME_EMPLOYEE: 'MY_PF_PART_TIME_EMPLOYEE',
  MY_PF_UPLOAD_AVATAR: 'MY_PF_UPLOAD_AVATAR',
  MY_PF_NOTE: 'MY_PF_NOTE',
  MY_PF_HIRE_DATE: 'MY_PF_HIRE_DATE',
  MY_PF_PASS_PROBATION_DATE: 'MY_PF_PASS_PROBATION_DATE',
  MY_PF_START_CONTRACT_DATE: 'MY_PF_START_CONTRACT_DATE',
  MY_PF_START_CONTRACT_END_DATE: 'MY_PF_START_CONTRACT_END_DATE'
};

export const FieldTypes = [
  {
    type: FieldType.text,
    icon: '../../../../assets/icons/dynamic-fields/field-types/text.svg',
    name: 'Text',
    desc: 'Text fields allow users to enter text.'
  },
  // {
  //   type: FieldType.richText,
  //   icon: '../../../../assets/icons/dynamic-fields/field-types/text.svg',
  //   name: 'Richtext',
  //   desc: 'Text fields allow users to enter text.',
  // },
  {
    type: FieldType.number,
    icon: '../../../../assets/icons/dynamic-fields/field-types/float.svg',
    name: 'Number',
    desc: 'A number field which contains of Real Numbers and can use a decimal place.'
  },
  {
    type: FieldType.datePicker,
    icon: '../../../../assets/icons/dynamic-fields/field-types/date-picker.svg',
    name: 'Datepicker',
    desc: 'This field only accepts dates and provides a drop down calendar for picking the date.'
  },
  {
    type: FieldType.select,
    icon: '../../../../assets/icons/dynamic-fields/field-types/dropdown.svg',
    name: 'Dropdown',
    desc: 'A single select list with a configurable list of options.'
  },
  {
    type: FieldType.multiSelect,
    icon: '../../../../assets/icons/dynamic-fields/field-types/label.svg',
    name: 'Multiselect Dropdown',
    desc: 'This is a drop down list of items. Use this when you have a preset list of items and only one option must be chosen.'
  },
  {
    type: FieldType.file,
    icon: '../../../../assets/icons/dynamic-fields/field-types/file.svg',
    name: 'File',
    desc: 'Allow users to upload files'
  },
  {
    type: FieldType.table,
    icon: '../../../../assets/icons/dynamic-fields/field-types/table.svg',
    name: 'Table',
    desc: 'Each column of the table is a piece of data you want collected.'
  },
  {
    type: FieldType.function,
    icon: '../../../../assets/icons/dynamic-fields/field-types/function.svg',
    name: 'Function',
    desc: 'Allow users to establish function'
  }
];


export enum UserMaritalValue {
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE'
}

export enum UserGenderValue {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum UserStatusKey {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum UserNotifySetting {
  TURN_ON = 'turnOn',
  SET_FOR_ALL_TASK = 'setForAllTask',
  TASK_SEND_MAIL_CREATED = 'taskSendMailCreated',
  TASK_SEND_MAIL_ASSIGNEE = 'taskSendMailAssignee',
  TASK_SEND_MAIL_MENTION = 'taskSendMailMention',
  TASK_SEND_MAIL_CHANGE_STATUS = 'taskSendMailChangeStatus',
  TASK_REMINDER = 'taskSendMailReminder',
  SET_ALL = 'setAll',
  TASK_NOTIFY_UPDATE = 'taskNotifyUpdate',
  TASK_NOTIFY_MENTION = 'taskNotifyMention',
  TASK_OVER_DUE_AFTER = 'taskOverDueDateAfter',
  RECEIVER_TASK_OVER_DUE_AFTER = 'receiveTaskOverDueAfter',
  TASK_NOTIFY_REMINDER = 'taskNotifyReminder'
}

export enum UserNotifyType {
  NOTIFY = 'notify',
  EMAIL = 'email'
}

export enum SalaryPatchType {
  UNIT= 'UNIT',
  SALARY= 'SALARY',
  CONTRACT_SALARY= 'CONTRACT_SALARY',
}

export enum UserTokenType {
  BASE = 'baseToken',
  ACCESS = 'accessToken'
}

import { TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AppInjector } from 'src/app/app.module';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { Project } from 'src/app/shared';

export interface SyncJiraStatusObject {
  taskLogs: SyncJiraItem<SyncJiraTaskLog>;
  fileLogs: SyncJiraItem<SyncJiraFileLog>;
  linkTaskLogs: SyncJiraItem<SyncJiraLinkTaskLog>;
  displayStatus?: boolean;
}

export class SyncJiraStatus {
  taskLogs: SyncJiraItem<SyncJiraTaskLog>;
  fileLogs: SyncJiraItem<SyncJiraFileLog>;
  linkTaskLogs: SyncJiraItem<SyncJiraLinkTaskLog>;
  displayStatus: boolean;
  readonly progress: number;
  private authService = AppInjector.get(AuthService);

  constructor(obj: SyncJiraStatusObject) {
    this.taskLogs = obj.taskLogs;
    this.linkTaskLogs = obj.linkTaskLogs;
    this.fileLogs = obj.fileLogs;
    this.progress = +this.getProgress().toFixed(2);
    this.displayStatus = obj.displayStatus ?? this.authService?.loggedUser?.company?.displayJiraFlg;
  }

  getProgress() {
    /** Each logs { task, link-task, file } have equal percent */
    const eachLogPercent = 100 / 3;
    const syncLists = [this.taskLogs, this.linkTaskLogs, this.fileLogs];

    const result = syncLists.reduce((prev, syncItem) => {
      if (!syncItem || !syncItem.total) {
        if (prev.finish) {
          prev.progress += eachLogPercent;
        }

        return prev;
      }

      if (syncItem.finishFlg) {
        prev.finish = true;
        prev.progress += eachLogPercent;
      } else {
        prev.progress += (syncItem.current / syncItem.total) * eachLogPercent;
      }

      return prev;
    }, { progress: 0, finish: false });

    return result.progress;
  }
}

export interface SyncJiraTaskLog {
  id: number;
  projectName: string;
  projectKey: string;
  currentTask: number;
  totalTask: number;
  finishFlg: boolean;
  job: SyncJiraDataJob;
}

export interface SyncJiraLinkTaskLog {
  id: number;
  projectName: string;
  projectKey: string;
  total: number;
  current: number;
  finishFlg: boolean;
  jobId: number;
}

export interface SyncJiraFileLog {
  project: Project;
  currentFiles: number;
  totalFiles: number;
  jobId: number;
}

export interface SyncJiraJobStatus {
  jobId: number;
  status: JiraJobStatus;
}

export interface SyncJiraDataJob {
  id: number;
  domain?: string;
  status: string;
  errorMessage: string;
}

export interface JiraRetryRequest {
  projectKey: string;
  taskOffset: number;
}

export interface SyncJiraItem<T> {
  current: number;
  total: number;
  logs: T[];
  finishFlg: boolean;
  status?: JiraJobStatus;
}

export interface SyncingJiraDataProps {
  showToast?: boolean;
  destroyed$: Subject<void>;
  toastTemplate?: TemplateRef<any>;
  pendingCallback: () => void;
  callback: (val: SyncJiraStatus) => boolean;
  forceSyncTask?: boolean;
  forceSyncLinkTask?: boolean;
  forceSyncFile?: boolean;
}

export const SYNCING_TIME = 120000;
export const SYNCING_SUCCESS = 100;

export enum JiraJobStatus {
  WAITING = 'Waiting',
  RUNNING = 'Running',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  CONTINUE = 'Continue',
  PENDING = 'Pending',
  UN_APPROVED = 'UnApproved'
}

export enum JiraSyncType {
  TASK = 'Task',
  LINK_TASK = 'LinkTask',
  FILE = 'File'
}


export enum HttpCode {
  NOT_ACCEPTABLE = 406,
  PAYMENT_REQUIRED = 402,
  UNAUTHORIZED = 401
}

export const JIRA_ACCOUNT_MESSAGE_ERROR = "Default data is not set: Jira Account";
export const FREE_LICENSE_PROJECT_CODE_ERROR = "ERR_LIC_0001";
export const defaultErrorMessage = 'An unknown error occurred';

interface HttpError {
  code: string;
  message: string;
}

enum HttpErrorKey {
  DELETE_CONSTRAINT_METRIC = 'DELETE_CONSTRAINT_METRIC',
  ROLE_HAS_CHANGED = 'ROLE_HAS_CHANGED'
}

export const HTTP_ERROR: Record<HttpErrorKey, HttpError> = {
  /** Metric errors */
  DELETE_CONSTRAINT_METRIC: {
    code: 'ERR_MET_0001',
    message: 'This metric is currently in use. Please remove it from any objects that are using it.'
  },
  /** Permission errors */
  ROLE_HAS_CHANGED: {
    code: 'ERR_ACC_0014',
    message: 'Your permissions have been updated. Please log in again to continue.'
  }
};

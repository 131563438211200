import { Component, Input } from '@angular/core';
import { BoardLabel } from 'src/app/shared';

@Component({
  selector: 'app-label-option',
  template: `
    <div class="custom-option mr-1">
      <span
        class="badge-label text-ellipsis"
        [style.backgroundColor]="item.colorCode"
        >{{ item.name }}</span
      >
    </div>
  `
})
export class LabelOptionComponent {
  @Input() item: BoardLabel;
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppInjector } from 'src/app/app.module';
import {
  DATE_FORMAT,
  DATE_MONTH_YEAR_SHORT_UI_FORMAT,
  DATE_QUARTER_YEAR_SHORT_UI_FORMAT,
  DateUIType,
  LanguageCode
} from 'src/app/shared';
import { I18nService } from '../_services';
import { VietnameseLocaleObject } from 'src/app/shared/_utils/_consts/moment.const';

@Pipe({
  name: 'dateUI'
})
export class DateUIPipe implements PipeTransform {
  i18Service = AppInjector.get(I18nService);
  language!: LanguageCode;

  transform(date: string | moment.Moment, type: DateUIType): string {
    this.language = this.i18Service.getCurrentLang();

    switch (type) {
      case DateUIType.QUARTER_YEAR:
        return this.transformQuaterYear(date);
      case DateUIType.MONTH_YEAR:
        return this.transformMonthYear(date);
      case DateUIType.SHORT_MONTH:
        return this.transformShortMonth(date);
      case DateUIType.LONG_MONTH:
        return this.transformLongMonth(date);
      case DateUIType.SHORT_WEEK_DAY:
        return this.transformShortWeekDay(date);
      case DateUIType.MIN_WEEK_DAY:
        return this.transformMinWeekDay(date);
      default:
        return moment(date).format(DATE_FORMAT);
    }
  }

  private transformQuaterYear(date: string | moment.Moment): string {
    const dateMoment = moment(date);

    switch (this.language) {
      case LanguageCode.vi:
        const months = VietnameseLocaleObject.months;
        return `Quý ${months[dateMoment.quarter()]}, ${dateMoment.year()}`;
      // Add more language here...
      default:
        // Default is En
        return moment(date).format(DATE_QUARTER_YEAR_SHORT_UI_FORMAT);
    }
  }

  private transformMonthYear(date: string | moment.Moment): string {
    const dateMoment = moment(date);

    switch (this.language) {
      case LanguageCode.vi:
        const months = VietnameseLocaleObject.months;
        return `${months[dateMoment.month()]}, ${dateMoment.year()}`;
      // Add more language here...
      default:
        // Default is En
        return moment(date).format(DATE_MONTH_YEAR_SHORT_UI_FORMAT);
    }
  }

  private transformShortMonth(date: string | moment.Moment): string {
    switch (this.language) {
      case LanguageCode.vi:
        const months = VietnameseLocaleObject.monthsShort;
        return months[moment(date).month()];
      // Add more language here...
      default:
        // Default is En
        return moment(date).format('MMM');
    }
  }

  private transformLongMonth(date: string | moment.Moment): string {
    switch (this.language) {
      case LanguageCode.vi:
        const months = VietnameseLocaleObject.monthsShort;
        return months[moment(date).month()];
      // Add more language here...
      default:
        // Default is En
        return moment(date).format('MMMM');
    }
  }

  private transformMinWeekDay(date: string | moment.Moment): string {
    switch (this.language) {
      case LanguageCode.vi:
        const weekDays = VietnameseLocaleObject.weekdaysMin;
        return weekDays[moment(date).day()];
      // Add more language here...
      default:
        // Default is En
        return moment(date).format('dd')[0];
    }
  }

  private transformShortWeekDay(date: string | moment.Moment): string {
    switch (this.language) {
      case LanguageCode.vi:
        const weekDays = VietnameseLocaleObject.weekdaysShort;
        return weekDays[moment(date).day()];
      // Add more language here...
      default:
        // Default is En
        return moment(date).format('dd').toUpperCase();
    }
  }
}

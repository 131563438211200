import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'x-loading-vertical',
  template: `
    <div class="loader">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
  `,
  styleUrls: ['./loading-vertical.component.scss'],
})
export class LoadingVertical {
  constructor() {}
}

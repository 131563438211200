import FroalaEditor from 'froala-editor';
import { head } from 'lodash';
import { FroalaEvent, FroalaUpload } from '../models';
import { environment } from 'src/environments/environment';
import { EDITOR_LOADING_FILE, EDITOR_PREVIEW_FILE } from 'src/app/shared';
import { UtilService } from 'src/app/core';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { AppInjector } from 'src/app/app.module';

FroalaEditor.PLUGINS.autoUploadFile = function (editor: FroalaEditor) {
  const fileService: FileService = AppInjector.get(FileService);

  let uploadQueue = [];

  function _init() {
    if (!editor.opts.autoUpload?.lazy) {
      onBeforeUpload();
      onUploaded();
      onLoaded();
    }
  }

  function onLoaded() {
    editor.events.on(FroalaEvent.IMAGE_LOADED, function($img) {
      uploadInQueue($img);
    })
  }

  function onBeforeUpload() {
    editor.events.on(FroalaEvent.FILE_BEFORE_UPLOAD, function (files: FileList) {
      if (files.length > 1) {
        const [_file, ..._files] = files;
        uploadQueue = _files;
      }
    });
  }

  function onUploaded() {
    editor.events.on(FroalaEvent.FILE_UPLOADED, function (response) {
      // if (!editor.opts.autoUpload?.lazy) {
        const files = JSON.parse(response);
        const file = FroalaUpload.getPreviewUrl(head(files), editor.integrator.module());

        // Trigger attachmentPushed event
        editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [{ files }], true);

        if (file) {
          const isImage = UtilService.isImageURL(file.name);
          if (isImage) {
            handleUploadImage(file);

            return false;
          }

          const isVideo = UtilService.isVideoURL(file.name);
          if (isVideo) {
            handleUploadVideo(file);

            return false;
          }


          editor.image.insert(
            `${EDITOR_PREVIEW_FILE}?file-url=${decodeURIComponent(file.url)}`,
            environment.env == 'local' ? false : true,
            { name: file.name, id: file.id }, null);
        }

        return false;
      // }
    });
  }

  function handleUploadVideo(file) {
    fileService.getAuthorization(file.url).subscribe({
      next: ([url]) => {
        editor.video.insert(`
        <video src="${url.fullUrl}" origin="${file.url}" style="width: 600px;" controls class="fr-draggable"></video>
        `);
      }
    });
  }

  function handleUploadImage(file) {

    fileService.getAuthorization(file.url).subscribe({
      next: ([url]) => {
        editor.image.insert(
          url.fullUrl,
          environment.env == 'local' ? false : true,
          {
            name: file.name,
            origin: url.sourceUrl
          },
          null
        );
      }
    });
  }

  function uploadInQueue(image: JQuery<Element>) {
    if (uploadQueue.length > 0) {
      editor.image.exitEdit(true);
      editor.selection.setAfter(head(image));
      editor.selection.restore();
      const [_file, ..._files] = uploadQueue;
      editor.file.upload(uploadQueue);
      uploadQueue = _files;
    }
  }

  return {
    _init
  };
};

import { Task, TaskType } from 'src/app/shared';

export interface PreviousWorkType {
  projectId: number;
  workItemType: TaskType;
}

export interface TaskState {
  preWorkType: PreviousWorkType | null;
  taskTypes: TaskType[];
  taskCreated: Task | null;
  taskUpdated: Task | null;
  workCalendarTasks: Task[];
  taskDeleted: Task | null;
}

export interface Location {
  id?: number;
  city?: string;
  state?: string;
  postalCode?: string;
  companyId: number;
  name: string;
  address: string;
  phone: string;
  fax: string;
  establishedDate: string;
  countryCode: string;
  timezone: string;
  foundation: string;
  totalMember?: number;
}

export interface LocationCountry {
  countryCode: string;
  name: string;
}

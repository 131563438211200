import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'domainName' })
export class DomainNamePipe implements PipeTransform {
  transform(domain: string): string {
    if (!domain) {
      return '';
    }

    const url = new URL(domain);
    return url.host;
  }
}

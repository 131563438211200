import { Sprint } from './sprint.model';
import { TaskType } from './task.model';
import * as queryString from 'query-string';
import { SortDirection, UnassignedId } from '../_utils';
import { ProgressGroupItem } from '../_components/progress-group/progress-group.component';
import { every } from 'lodash';
import { Pageable } from './generic';
import { User } from './user.model';
import { BoardReleaseListResponse } from './board-release.model';
import { Project, ProjectPriority } from './project.model';
import { PageSize } from '../_utils/_enums/table.enum';

export interface ProjectEpicModel {
  id: number;
  key?: string;
  name: string;
  dueDate: string;
  status: EpicTaskStatus;
  sprints: Sprint[];
  countStatus: EpicTaskStatus[];
  existTaskInBacklog: boolean;
  countStoryPoint: number;
  colorCode: string;
  deleted?: boolean;
  type: TaskType;
  versions: BoardReleaseListResponse[];
  assignee: User;
  priority: ProjectPriority;
  projectId: number;
  project?: Project;
}

export interface EpicTaskStatus {
    id: number;
    name: string; // Task Status
    colorCode: string;
    defaultFlg: boolean;
    icon: string;
    deleted: boolean;
    count: number;
}
export class EpicSearchParam extends Pageable {
    projectId?: number;
    keyword?: string;
    taskTypeIds?: number[];
    taskIds?: number[];
    taskKey?: number[];
    epicIds?: number[];
    userIds?: number[];
    reporterIds?: number[];
    priorityIds?: number[];
    taskStatusIds?: number[];
    sprintIds?: number[];
    releaseIds?: number[];
    metricIds?: number[];
    labelIds?: number[];
    fromDate?: string;
    toDate?: string;
    privateFlg?: boolean | null;
    includeUnassigned?: boolean;
    taskBacklogFlg?: boolean;

    constructor(obj?: EpicSearchParam) {
        super();
        this.keyword = obj?.keyword || '';
        this.taskTypeIds = obj?.taskTypeIds || [];
        this.taskIds = obj?.taskIds || [];
        this.epicIds = obj?.epicIds || [];
        this.priorityIds = obj?.priorityIds || [];
        this.taskStatusIds = obj?.taskStatusIds || [];
        this.userIds = obj?.userIds || [];
        this.sprintIds = obj?.sprintIds || [];
        this.releaseIds = obj?.releaseIds || [];
        this.metricIds = obj?.metricIds || [];
        this.labelIds = obj?.labelIds || [];
        this.sort = obj?.sort || [];
        this.size = obj?.size || PageSize.loadAll;
    }

    public toQueryString(): string {
        let query = `keyword=${this.keyword || ''}&page=${this.page || ''}&size=${this.size || ''}`;

        if (this.sort.length > 0) {
          query += '&' + queryString.stringify({ sort: this.sort });
        }

        if (this.taskTypeIds.length > 0) {
          query += '&' + queryString.stringify({ typeIds: this.taskTypeIds });
        }

        if (this.taskIds.length > 0) {
          query += '&' + queryString.stringify({ taskIds: this.taskIds });
        }

        if (this.epicIds.length > 0) {
          query += '&' + queryString.stringify({ epicIds: this.epicIds });
        }

        if (this.priorityIds.length > 0) {
          query += '&' + queryString.stringify({ priorityIds: this.priorityIds });
        }

        if (this.taskStatusIds.length > 0) {
          query += '&' + queryString.stringify({ statusIds: this.taskStatusIds });
        }

        if (this.userIds.length > 0) {
          query += '&' + queryString.stringify({ userIds: this.userIds.filter(i => i !== UnassignedId) });
          if (this.userIds.some(i => i === UnassignedId)) {
            query += '&includeUnassigned=true';
          }
        }

        if (this.sprintIds.length > 0) {
          query += '&' + queryString.stringify({ sprintIds: this.sprintIds });
        }

        if (this.releaseIds.length > 0) {
          query += '&' + queryString.stringify({ releaseIds: this.releaseIds });
        }

        if (this.fromDate) {
            query += `&fromDate=${this.fromDate}`;
        }

        if (this.toDate) {
            query += `&toDate=${this.toDate}`;
        }

        if (this.reporterIds) {
          query += '&' + queryString.stringify({ reporterIds: this.reporterIds });
        }

        if (this.labelIds) {
          query += '&' + queryString.stringify({ labelIds: this.labelIds });
        }

        if (this.metricIds) {
          query += '&' + queryString.stringify({ metricIds: this.metricIds });
        }

        query += `&taskBacklogFlg=${this.taskBacklogFlg || false}`;

        return query;
    }

    isDefault() {
      return every(
        [
          this.taskTypeIds,
          this.taskIds,
          this.epicIds,
          this.priorityIds,
          this.taskStatusIds,
          this.userIds,
          this.sprintIds,
          this.releaseIds,
          this.metricIds,
          this.labelIds
        ],
        (e) => e.length === 0
      );
    }
}

export interface EpicCountStatus {
  progressItems: ProgressGroupItem[];
  totalCount: number;
}

export const pagingSize = 20;

<form class="p-4" [formGroup]="form">
  <div class="mb-3">
    <h4><app-i18n [key]="'Rename'"></app-i18n></h4>
  </div>

  <div class="row">
    <div class="col-lg-12 col-12">
      <div class="form-group">
        <label><app-i18n [key]="'Name'"></app-i18n></label>
        <input class="form-control custom" formControlName="name">
        <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
      </div>
    </div>
  </div>

  <div class="actions text-right pt-3">
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary" (click)="onSubmit()"><app-i18n [key]="'Save'"></app-i18n></button>
  </div>
</form>
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { appDefaultLang, routerObject, User } from 'src/app/shared';
import { ToastService } from '../_services';
import * as queryString from 'query-string';
import { SKIP_NAVIGATE } from '../constants/error';
import { FREE_LICENSE_PROJECT_CODE_ERROR, HTTP_ERROR, HttpCode, JIRA_ACCOUNT_MESSAGE_ERROR } from '../models/http-error';
import { environment } from 'src/environments/environment';
import { Logger } from '../models/logger';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  user: User;
  ignoredUrls: string[] = [
    `${environment.apiUrl}/files/get-authorization`,
  ];
  private cancel$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastService,
    private translateService: TranslateService,
  ) {
    this.user = this.authService.getLoggedUser();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        takeUntil(this.cancel$),
        catchError((error: HttpErrorResponse) => {
          if (!request.url.startsWith(environment.apiUrl) || this.ignoredUrls.includes(request.url)) {
            return throwError(JSON.stringify(error.error));
          }

          if (error.status === HttpCode.UNAUTHORIZED) {
            console.error(`401 request: ${request.url}`);
            this.gotoSignIn();
            error[SKIP_NAVIGATE] = true;
            return throwError(() => error);
          }

          if (error.status === HttpCode.PAYMENT_REQUIRED) { // Payment required
            this.toast.notifyTrialExpired();
            return throwError(null);
          }

          if (error.status === HttpCode.NOT_ACCEPTABLE) {
            if (error.message === 'Invalid Company Secret') {
              this.gotoSignIn();
              return throwError(null);
            }
            if (error?.error?.code === HTTP_ERROR.ROLE_HAS_CHANGED.code) {
              this.onPermissionChanged();
              return throwError(null);
            }
            return throwError(JSON.stringify({ ...error.error, status: HttpCode.NOT_ACCEPTABLE }));
          }

          if (error?.error?.message === JIRA_ACCOUNT_MESSAGE_ERROR) {
            return throwError(JSON.stringify({ ...error.error, message: this.getJiraAccountMessage() }));
          }

          if (this.isFreeLicenseError(error)) {
            return throwError(null);
          }

          return throwError(JSON.stringify(error.error));
        })
      );
  }

  isFreeLicenseError(error: HttpErrorResponse) {
    const isError = [FREE_LICENSE_PROJECT_CODE_ERROR].some(e=> e === error?.error?.code);
    if (!isError) {
      return false;
    }

    if (error?.error?.code === FREE_LICENSE_PROJECT_CODE_ERROR) {
      const _require = this.translateService.instant('Allowed maximum projects');
      const upgradeNotify = this.translateService.instant('Upgrade to a paid plan');
      const linkText = this.translateService.instant('Click on Billing to proceed', { link: `<a href="${routerObject.orgBilling.fullPath}">${this.translateService.instant(routerObject.orgBilling.text)}</a>` });
      const message =  `${_require} ${upgradeNotify} <div>${linkText}</div>`;
      this.toast.error(message, { nzDuration: 8000 });
    }

    return true;

  }

  getJiraAccountMessage() {
    const _text = this.translateService.instant('Cannot create data from xCorp because you have not confirmed to sync data to Jira. To integrate with Jira, please click on the link below.');
    const message =  `${_text} <div><a href="${routerObject.orgMyProfile.fullPath}?tab=Integration">Link</a></div>`;
    return message;
  }

  gotoSignIn(config = null) {
    this.authService.signOut();
    const lastUrl = window.location.pathname + window.location.search;
    this.authService.setLastUrl(lastUrl);
    let params: Params = this.user?.company && this.user?.email
      ? { 'signout-domain': this.user.company.domain, 'signout-email': this.user.email } : {};

    const language = this.translateService.currentLang || appDefaultLang;

    if (config?.params) {
      params = { ...params, ...config.params };
    }

    // FUA: use query string for navigate by url
    const queryParams = queryString.stringify(params);

    this.router.navigateByUrl(`${language}/auth/sign-in?${queryParams}`);
  }

  onPermissionChanged() {
    const config = {
      params: { 'permission-error': true }
    };
    this.gotoSignIn(config);
    this.cancel$.next(null);
    this.cancel$.complete();
  }
}

import { Component, Input } from '@angular/core';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';

@Component({
  selector: 'app-storyline-option',
  template: `
    <div class="custom-option">
      <img class="option-icon" [src]="item.type?.icon" />
      <span class="option-label"
        ><span class="text-primary mr-1">{{ item.key }}</span
        >{{ item.name }}</span
      >
    </div>
  `
})
export class StorylineOptionComponent {
  @Input() item: BoardStoryline;
}

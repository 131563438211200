import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WikiState } from './wiki.models';
export const featureKey = 'wiki';

const selectFeature = createFeatureSelector<WikiState>(featureKey);

export const selectData = createSelector(
  selectFeature,
  (state: WikiState) => state.data
);

export const selectContent = createSelector(
  selectFeature,
  (state: WikiState) => state.content
);

export const selectError = createSelector(
  selectFeature,
  (state: WikiState) => state.error
);

export const selectEditorConfigs = createSelector(
  selectFeature,
  (state: WikiState) => state.editorConfigs
);

export const selectMentionMembers = createSelector(
  selectFeature,
  (state) => state.mentionList
);

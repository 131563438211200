import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { HOUR_MINUTE, TIME_FORMAT, userTrackingReportStatus } from 'src/app/shared';

@Pipe({ name: 'getUserTrackingStatusText' })
export class GetUserTrackingStatusTextPipe implements PipeTransform {
  transform(currentTime: string, status: string = '', time: string, limitBefore: number = 0, limitAfter: number = 0): string {
    const timeBefore = moment(time, TIME_FORMAT).subtract(limitBefore, 'm').format(TIME_FORMAT);
    const timeAfter = moment(time, TIME_FORMAT).add(limitAfter, 'm').format(TIME_FORMAT);

    if (status === userTrackingReportStatus.waiting) {
      if (currentTime < timeBefore) {
        return 'Future Report';
      }
      if (timeBefore <= currentTime && currentTime <= timeAfter) {
        return 'Ongoing Report';
      }

      return 'Missed Report';
    }

    if (status === userTrackingReportStatus.completed) {
      return 'Reported At Time';
    }
    if (status === userTrackingReportStatus.missed) {
      return 'Missed Report';
    }
    if (status === userTrackingReportStatus.lately) {
      return 'Reported Late';
    }
    if (status === userTrackingReportStatus.early) {
      return 'Reported Soon';
    }
    return `Needless Report`;
  }
}

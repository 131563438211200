import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Attachment } from '../..';
import { AttachmentFile, EditorComponent, EditorUserMention } from '../editor/editor.component';
import Quill from 'quill';

export interface EditorFormPayload {
  htmlString: string;
  attachments: Attachment[];
  selectedUserMention?: any[];
}

@Component({
  selector: 'app-editor-form',
  templateUrl: './editor-form.component.html',
  styleUrls: ['./editor-form.component.scss']
})
export class EditorFormComponent implements OnInit {
  @Input() htmlString = '';
  @Input() placeholder = 'Insert text here...';
  @Input() isSaving = false;
  @Input() hasDraft = false;
  @Input() getUserPromise: (searchTerm: string) => Promise<EditorUserMention[]>;
  @Input() upload$: (file: File) => Observable<Attachment>;
  @Output() save: EventEmitter<EditorFormPayload> = new EventEmitter();
  @Output() change: EventEmitter<EditorFormPayload> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() onEditorCreated: EventEmitter<Quill> = new EventEmitter<Quill>();

  @ViewChild('appEditor') editorComponent: EditorComponent;
  control: UntypedFormControl = new UntypedFormControl('');
  isDisabled = true;
  selectedFiles: AttachmentFile[] = [];

  constructor() { }

  ngOnInit(): void {
    this.control.setValue(this.htmlString);

    this.control.valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => {
        this.change.emit(this.setupPayload());
        if (this.hasDraft) {
          this.isDisabled = false;
          return;
        }
        if (value && value !== this.htmlString) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      });
  }

  onSave() {
    const payload = this.setupPayload();
    let htmlString = payload.htmlString;
    const uploadFiles = this.selectedFiles?.filter(e=> htmlString.includes(e.previewUrl) && e.file);
    if (uploadFiles?.length > 0) {
      this.reBuildUrlImg(uploadFiles, (res) => {
        res.filter(e=> e.attachment).forEach(result => {
          htmlString = htmlString.replace(
            result.previewUrl,
            `${result.attachment.url}`
          );
          payload.attachments.push(result.attachment);
        });
        payload.htmlString = htmlString;
        this.save.emit(payload);
        this.selectedFiles = [];
      });
    }
    else {
      this.save.emit(payload);
      this.selectedFiles = [];
    }
  }

  setupPayload(): EditorFormPayload {
    const attachments: Attachment[] = this.editorComponent.getUploadAttachments();
    let htmlString: string = this.control.value;

    attachments.forEach(e => {
      htmlString = htmlString.replace(e.objectUrl, e.url);
    });
    const selectedUserMention = this.editorComponent?.getUserMentions();

    return { attachments, htmlString, selectedUserMention };
  }

  onCancel() {
    this.cancel.emit();
  }

  getEditorInstance(quill) {
    this.onEditorCreated.emit(quill);
  }

  fileChanged(file: AttachmentFile) {
    this.selectedFiles.push(file);
  }

  reBuildUrlImg(files: AttachmentFile[], success: (payload: { attachment: Attachment, previewUrl: string }[]) => void) {
    const results: { attachment: Attachment, previewUrl: string }[] = [];
    files.forEach((file) => {
      this.upload$(file.file)
      .subscribe(
        (event) => {
          results.push({ attachment: event, previewUrl: file.previewUrl });
          if (results.length === files.length) {
            success?.(results);
          }
        },
        (error: string) => {
          results.push({ attachment: null, previewUrl: file.previewUrl });
          if (results.length === files.length) {
            success?.(results);
          }
        }
      );
    })

  }
}

import { NotificationSettingUI } from '../../_models/notification.model';
import { NotificationFeatureKey, NotificationSettingPayload, NotificationSettingType } from '../_enums';

export const NotificationSettingStructure: NotificationSettingUI[] = [
  {
    icon: 'assets/icons/time_sheet.svg',
    label: 'Timesheet',
    code: NotificationSettingPayload.STATUS_LOG_TIME,
    isCollapsed: false,
    featureKey: NotificationFeatureKey.LOG_TIME,
    child: [
      {
        label: 'All email notifications',
        id: NotificationSettingType.EMAIL,
        code: NotificationSettingPayload.STATUS_LOG_TIME_MAIL,
        featureKey: NotificationFeatureKey.LOG_TIME,
        child: [
          {
            label: 'Send an email notification when a timesheet is due to be locked in the next',
            code: NotificationSettingPayload.LOG_TIME_SEND_MAIL,
            additionalLabel: 'day(s)',
            additionalCode: NotificationSettingPayload.LOG_TIME_SEND_MAIL_BEFORE,
          },
        ]
      },
      {
        label: 'All notifications',
        id: NotificationSettingType.NOTIFY,
        code: NotificationSettingPayload.STATUS_LOG_TIME_NOTI,
        featureKey: NotificationFeatureKey.LOG_TIME,
        child: [
          {
            label: 'Send a notification when a timesheet is due to be locked in the next',
            code: NotificationSettingPayload.LOG_TIME_SEND_NOTI,
            additionalLabel: 'day(s)',
            additionalCode: NotificationSettingPayload.LOG_TIME_SEND_NOTI_BEFORE,
          },
        ]
      }
    ]
  },
  {
    icon: 'assets/icons/task-management.svg',
    label: 'Task Management',
    code: NotificationSettingPayload.STATUS_TASK,
    isCollapsed: false,
    description: 'subtitleTaskManagement',
    featureKey: NotificationFeatureKey.TASK,
    child: [
      {
        label: 'All email notifications',
        id: NotificationSettingType.EMAIL,
        code: NotificationSettingPayload.STATUS_TASK_MAIL,
        featureKey: NotificationFeatureKey.TASK,
        child: [
          {
            label: 'Create a new task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_CREATED
          },
          {
            label: 'Assignee task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_ASSIGNEE,
          },
          {
            label: 'Mention on comment',
            code: NotificationSettingPayload.TASK_SEND_MAIL_MENTION
          },
          {
            label: 'Update task',
            code: NotificationSettingPayload.TASK_SEND_MAIL_CHANGE_STATUS
          },
          {
            label: 'Task Reminder',
            code: NotificationSettingPayload.TASK_SEND_MAIL_REMINDER
          }
        ]
      },
      {
        label: 'All notifications',
        id: NotificationSettingType.NOTIFY,
        code: NotificationSettingPayload.STATUS_TASK_NOTI,
        featureKey: NotificationFeatureKey.TASK,
        child: [
          {
            label: 'Create a new task',
            code: NotificationSettingPayload.TASK_NOTIFY_CREATED,
          },
          {
            label: 'Show notification when task is updated',
            code: NotificationSettingPayload.TASK_NOTIFY_UPDATE,
          },
          {
            label: 'Show notification when has mention in a task',
            code: NotificationSettingPayload.TASK_NOTIFY_MENTION,
          },
          {
            label: 'Show notification when task is upcoming to due date in next',
            code: NotificationSettingPayload.TASK_OVER_DUE_DATE_AFTER,
            additionalLabel: 'day(s)',
            additionalCode: NotificationSettingPayload.RECEIVE_TASK_OVER_DUE_AFTER
          },
          {
            label: 'Task Reminder',
            code: NotificationSettingPayload.TASK_NOTIFY_REMINDER,
          }
        ]
      }
    ]
  }
];

export const NotifyBeforeDays = [0, 1, 2, 3, 4, 5];
export const NotifyBeforeSettings = [
  NotificationSettingPayload.LOG_TIME_SEND_MAIL,
  NotificationSettingPayload.LOG_TIME_SEND_NOTI,
  NotificationSettingPayload.TASK_OVER_DUE_DATE_AFTER
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationJiraStatus } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class IntegrationJiraService {
  constructor(
    private http: HttpClient,
  ) {}

  getIntegrationJiraStatus() {
    const apiUrl = `${environment.apiUrl}/integrations/pending-jobs`;
    return this.http.get<IntegrationJiraStatus>(apiUrl);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { delay, delayWhen, forkJoin, MonoTypeOperatorFunction, Observable, of, pipe, retry, retryWhen, take, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { PermissionCache, PermissionCacheRequest, PermissionCodeCache, Permisson, ProjectCache, ROLE_PERMISSION_CACHE_KEY,
  RolePermission, USER_PERMISSIONS_KEY, USER_ROLE_PERMISSIONS_KEY, UserStatusKey, routerObject, updateFormatByLanguage } from 'src/app/shared';
import { CustomerService } from 'src/app/site-management/customer/_services/customer.service';
import { DynamicFieldDataService } from 'src/app/site-management/dynamic-field/_services/dynamic-field-data.service';
import { DynamicFieldService } from 'src/app/site-management/dynamic-field/_services/dynamic-field-service';
import { PermissionService } from 'src/app/site-management/role-permission/_services/permisson.service';
import { UserService } from 'src/app/site-management/user/_services/user.service';
import { I18nService } from 'src/app/site-management/_services';
import { SiteActions, SiteManagementState, updatePermissions, updateRolePermissions } from 'src/app/site-management/_store/site-management';
import { Store } from '@ngrx/store';
import { Logger } from '../models/logger';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ProjectsService } from 'src/app/site-management/projects/_services';
import { DynamicFieldActions } from 'src/app/site-management/_store/dynamic-field';
import { RoleService } from 'src/app/site-management/role/_services/role.service';
import { AppActions } from '../store/app';


@Injectable({
  providedIn: 'root'
})
export class SiteManagementGuardService  {
  isLoaded = false;
  maxRetryCount = 5;
  retryInterval = 1000;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private authService: AuthService,
    private userService: UserService,
    private projectsService: ProjectsService,
    private customService: CustomerService,
    private dynamicFieldService: DynamicFieldService,
    private dynamicFieldDataService: DynamicFieldDataService,
    private i18nService: I18nService,
    private store: Store<SiteManagementState>,
    private roleService: RoleService,
  ) { }

  catchApiError<T>(apiName: string): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((err) => {
        return throwError(() => Logger.debug('Site Management Guard', apiName, { err }));
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const languageCode = this.i18nService.getCurrentLang();
    updateFormatByLanguage(languageCode);

    if (this.isLoaded) {
      return true;
    }
    const cacheProject = this.projectsService.getCacheProject();
    ProjectCache.shared.set(cacheProject);
    const obs: any[] = [
      this.getRolePermissions().pipe(this.catchApiError('getRolePermissions')),
      this.userService.getProfile().pipe(this.catchApiError('userService.getProfile()')),
      this.dynamicFieldService.getAll().pipe(this.catchApiError('dynamicFieldService.getAll()')),
      this.customService.getSystemAdmin().pipe(this.catchApiError('customService.getSystemAdmin()')),
      this.roleService.getUserRolesOnProjects().pipe(this.catchApiError('roleService.getUserRolesOnProjects'))
    ];

    const patterns = [/\/task-mgmt\/projects\/([^\/]+)\//, /\/task-mgmt\/tasks\/([^\/-]+)-/]; // get projectKey from url
    let projectKey = null;
    for (let index = 0; index < patterns.length; index++) {
      const pattern = patterns[index];
      const _key = state.url.match(pattern)?.[1];
      if (_key) {
        projectKey = _key;
      }
    }

    if (projectKey) {
      obs.push(this.projectsService.getByProjectKey(projectKey));
    }

    return forkJoin(obs).pipe(
      retryWhen((errors) =>
      errors.pipe(
        switchMap((error, index) => {
          this.store.dispatch(AppActions.loading({ visible: true }));
          if (index < this.maxRetryCount - 1) {
            return of(error).pipe(delay(this.retryInterval));
          }
          return throwError(() => error); // Throw an error to stop retrying
        }),
        take(this.maxRetryCount)
      )
    ),
      map((res: any[]) => {
        const permissionObject = res[0];
        const user = res[1];
        const fields = res[2];
        const systemAdmin = res[3];
        const projectRoles = res[4];
        const project = res[5];

        this.isLoaded = true;
        this.store.dispatch(updateRolePermissions({ data: permissionObject.rolePermissions }));
        this.store.dispatch(updatePermissions({ data: permissionObject.permissions }));
        this.permissionService.permissions = permissionObject.permissions;
        this.authService.setLoggedUser(user);
        this.authService.setSystemAdminFlg(systemAdmin);
        this.dynamicFieldDataService.storeFields(fields);
        this.store.dispatch(DynamicFieldActions.setFields({ fields: cloneDeep(fields) }));
        this.store.dispatch(AppActions.loading({ visible: false }));
        this.store.dispatch(SiteActions.setProjectRoles({ projectRoles }));

        if (project) {
          ProjectCache.shared.set(project);
        }

        this.store.dispatch(SiteActions.updateCurrentProject({ data: ProjectCache.shared.get() }));
        if (user?.status !== UserStatusKey.ACTIVE) {
          this.router.navigate([routerObject.authSignIn.fullPath]);
          return false
        }
        return true;
      }),
      catchError((err) => {
        this.store.dispatch(AppActions.loading({ visible: false }));
        this.router.navigate(['/error'], {queryParams: {relativeUrl: location.href}})
        return of(false);
      })
    );
  }

  getRolePermissions(): Observable<{rolePermissions: RolePermission[], permissions: Permisson[]}> {
    const data: PermissionCacheRequest = {
      codes: [PermissionCodeCache.PERMISSION, PermissionCodeCache.ROLE_PERMISSION]
    };

    return this.permissionService.getCache(data).pipe(
      switchMap((caches) => {
        const clientCaches = JSON.parse(localStorage.getItem(ROLE_PERMISSION_CACHE_KEY)) ?? [];
        const clientPermissions = JSON.parse(localStorage.getItem(USER_PERMISSIONS_KEY)) || [];
        const clientRolePermissions = JSON.parse(localStorage.getItem(USER_ROLE_PERMISSIONS_KEY)) ?? [];

        localStorage.setItem(ROLE_PERMISSION_CACHE_KEY, JSON.stringify(caches));

        const clientPermissionCache = this.getCacheByCode(clientCaches, PermissionCodeCache.PERMISSION);
        const clientRolePermissionCache = this.getCacheByCode(clientCaches, PermissionCodeCache.ROLE_PERMISSION);

        const serverPermissionCache = this.getCacheByCode(caches, PermissionCodeCache.PERMISSION);
        const serverRolePermissionCache = this.getCacheByCode(caches, PermissionCodeCache.ROLE_PERMISSION);

        const isPermissionChanged = clientPermissionCache?.version !== serverPermissionCache?.version;
        const isRolePermissionChanged = clientRolePermissionCache?.version !== serverRolePermissionCache?.version;

        return forkJoin([
          isRolePermissionChanged ? this.permissionService.getRolePermission() : of(clientRolePermissions),
          isPermissionChanged ? this.permissionService.getAll() : of(clientPermissions)
        ]).pipe(map(([rolePermissions, permissions]) => ({ rolePermissions, permissions })));
      })
    );
  }

  getCacheByCode(caches: PermissionCache[], code: PermissionCodeCache): PermissionCache {
    return caches.find(cache => cache?.code === code);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { NgxSelectTemplate } from 'src/app/site-management/work-calendar/models/work-calendar-overlay.formly';

@Pipe({
  name: 'isMultiLabel'
})

export class IsMultiLabelPipe implements PipeTransform {
  transform(type: NgxSelectTemplate) {
    return [
      NgxSelectTemplate.MultiUserTemplate,
      NgxSelectTemplate.LabelTemplate,
      NgxSelectTemplate.ReleasesTemplate
    ].some((t) => t == type);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { USER_PROFILE_KEY, User } from 'src/app/shared';

@Pipe({ name: 'jiraConnectedFlg' })

export class JiraConnectedFlgPipe implements PipeTransform {
  transform(value: any) {
    const user: User = JSON.parse(localStorage.getItem(USER_PROFILE_KEY));
    return user?.company?.jiraConnectedFlg;
  }
}

import FroalaEditor from 'froala-editor';

// Icon templates
FroalaEditor.DefineIconTemplate('font-awesome', '<i class="fa fa-[icon]"></i>');
FroalaEditor.DefineIconTemplate('mat-icon', '<span class="[class]" style="font-size: [fontSize]px">[icon]</span>');
FroalaEditor.DefineIconTemplate('svg-icon', '<img class="m-2" style="width: 21px" src=[src] />');

// Icon templates
FroalaEditor.DefineIcon('task', {
  icon: 'task_alt',
  template: 'mat-icon',
  class: "material-icons-outlined text-center",
  fontSize: 16
});

FroalaEditor.DefineIcon('document-switch', {
  template: 'svg-icon',
  src: 'assets/icons/document-switch.svg',
});

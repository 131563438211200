import { createFeatureSelector, createSelector } from '@ngrx/store';
import { siteManagementFeatureKey } from './site-management.reducers';
import { SiteManagementState } from './site-management.models';

export const selectSiteManagement = createFeatureSelector<SiteManagementState>(
  siteManagementFeatureKey
);

export const selectLoggedUser = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.loggedUser
);

export const selectCurrentProject = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.currentProject
);

export const selectCurrentRelease = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.currentRelease
);

export const selectSearchText = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.searchText
);

export const selectWaitingRequest = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.waitingRequest
);

export const selectRolePermissions = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.rolePermissions
);

export const selectPermissions = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.permissions
);

export const selectIntegrationJiraStatus = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.isIntegrationJiraStatus
);

export const selectGroupMenus = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.groupMenus
);

export const selectProjectRoles = createSelector(
  selectSiteManagement,
  (state: SiteManagementState) => state.projectRoles
);

export const SiteManagementSelectors = {
  selectLoggedUser,
  selectCurrentProject,
  selectCurrentRelease,
  selectSearchText,
  selectWaitingRequest,
  selectRolePermissions,
  selectPermissions,
  selectIntegrationJiraStatus,
  selectGroupMenus
};

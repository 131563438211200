import { permissionCode } from "src/app/shared";
import { FeaturePermissionState } from "../permission.models";

export interface TaskPermissionState extends FeaturePermissionState {
  create: string[];
  delete: string[];
  createComment: string[];
  editComment: string[];
  viewAnotherComment: string[];
  editAnotherComment: string[];
  deleteComment: string[];
  editAssignee: string[];
  editEditReporter: string[];
  editCC: string[];
  editAdvance: string[];
  viewAdvance: string[];
  editAfterDueDate: string[];
  editPrivateFlg: string[];
}

export const taskPermissionState: TaskPermissionState = {
  edit: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT],
  editAnother: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_OTHER],
  create: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_CREATE],
  delete: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_DELETE],
  createComment: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_CREATE],
  editComment: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT],
  viewAnotherComment: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_VIEW_OTHER],
  editAnotherComment: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT_OTHER],
  deleteComment: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_DELETE],
  editAssignee: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_ASSIGNEE],
  editEditReporter: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_REPORTER],
  editCC: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_CC],
  editAdvance: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_ADVANCE],
  viewAdvance: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_VIEW_ADVANCE],
  editAfterDueDate: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_AFTER_DUE_DATE],
  editPrivateFlg: [permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_PRIVATE_FLAG],
};

import { Pipe, PipeTransform } from '@angular/core';
import { NgxSelectTemplate } from 'src/app/site-management/work-calendar/models/work-calendar-overlay.formly';

@Pipe({
  name: 'getOptionUser'
})

export class GetOptionUserPipe implements PipeTransform {
  transform(type: NgxSelectTemplate) {
    return [NgxSelectTemplate.UserTemplate, NgxSelectTemplate.MultiUserTemplate].find(t => t === type);
  }
}

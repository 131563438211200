import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { AppActions } from '../store/app';
import { environment } from 'src/environments/environment';
import { LOCALHOST, routerObject } from 'src/app/shared';
import { getAccountCompanies } from '../store/auth/auth.actions';
import { LOGIN_SUCCESS } from '../constants/message';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentDomain = window.location.host;
    const isValidDomain = currentDomain !== environment.mainDomain || currentDomain === LOCALHOST;

    if (this.authService.isAuthenticated() && isValidDomain) {
      this.store.dispatch(AppActions.loading({ text: LOGIN_SUCCESS, visible: false, level: "info" }));
      const loggedUser = this.authService.getLoggedUser();
      if (loggedUser) {
        this.store.dispatch(getAccountCompanies({ accountId: loggedUser.account.id }));
      }
      return true;
    }

    this.authService.setLastUrl(state.url);

    this.router.navigate([routerObject.landingPage.fullPath]);
    return false;
  }
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';
import { Store } from '@ngrx/store';
import FroalaEditor from 'froala-editor';
import { debounce, flatMap, groupBy, mapValues, remove, trim, uniqBy } from 'lodash';
import { AppInjector } from 'src/app/app.module';
import {
  ProjectActions,
  ProjectSelectors
} from 'src/app/site-management/_store/project';
import { PositionOverlay } from 'src/app/shared';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as uuid from 'uuid';
import { AT_SIGN, BACKSPACE, DIGIT_2, EMPTY, MENTION_CLASS, MentionMode, SPACE, SelectedMember } from '../models';
import { WikiMemberListRequest, WikiType, getMentionMembers, selectMentionMembers } from 'src/app/site-management/_store/wiki';
import { MentionListComponent } from 'src/app/shared/_components/x-editor/_components/mention-list/mention-list.component';

FroalaEditor.PLUGINS.mention = function (editor: FroalaEditor) {
  const destroyed$ = new Subject();
  const store = AppInjector.get(Store);
  const overlay = AppInjector.get(Overlay);
  let mentionList: ComponentPortal<any> = new ComponentPortal(MentionListComponent);
  let removedId = null;
  let overlayRef: OverlayRef;
  let mentionListRef: ComponentRef<MentionListComponent>;
  let selectedMembers: SelectedMember[] = [];
  let projectId = null;
  let searchKeyword = null;

  function _init() {
    onKeyup();
    onKeydown();
    onInput();
    onDestroy();
  }

  function getMentions() {
    return uniqBy(
      selectedMembers.filter(e => $(editor.el).find(`#${e.id}`).length).map((e) => e.member),
      'id'
    );
  }

  function getMarkdownMentions() {
    return flatMap(mapValues(groupBy(
      selectedMembers.filter(e => $(editor.el).find(`#${e.id}`).length)
        .map((e) => e.markdownMember), 'objectId'),
        group => uniqBy(group, 'objectId')))
  }

  function setProjectId(_projectId) {
    if (_projectId) {
      projectId = _projectId;
    }
  }

  function onKeyup() {
    const customKeyUp = function (e) {
      const code = e.originalEvent.code;
      const innerText = editor.selection.element().innerText;

      if (code === BACKSPACE && removedId) {
        remove(selectedMembers, (e) => e.id == removedId);
        removedId = null;
      }

      if (code === SPACE && isMentionIncomplete()) {
        revertToNormal();
        return;
      }

      if (
        code === BACKSPACE &&
        isMentionIncomplete() &&
        trim(innerText) === EMPTY
      ) {
        hide(true);
        $(editor.selection.element()).remove();
        editor.html.insert('');
        return;
      }

      if (!isMention()) {
        hide(true);
        revertIncompleteToNormal();
      }

      if (isMentionIncomplete()) {
        const keyword = editor.selection.element().innerText?.toLocaleLowerCase().substr(1);
        mentionListRef?.setInput('keyword', keyword);
        getProjectAllMembers(keyword);
      }
    }

    editor.events.on('keyup', debounce(customKeyUp, 200));
  }

  function onInput() {
    editor.events.on('input', function (e) {
      if (e.originalEvent.data == AT_SIGN) {
        const mentionId = uuid.v4();
        editor.cursor.backspace();
        editor.html.insert(`<span id="${mentionId}" class="${MENTION_CLASS}">@</span>`);

        createOverlay();
        observerStore(mentionId);
      }
    });
  }

  function observerStore(mentionId: string) {
    if (projectId) {
      handleBaseEditor(mentionId);
      return;
    }

    handleMarkdownEditor(mentionId);
  }

  function handleBaseEditor(mentionId: string) {
    store
      .select(ProjectSelectors.selectProjectMembers)
      .pipe(takeUntil(destroyed$), debounceTime(100))
      .subscribe((members) => {
        if (!editor.integrator.isFocused()) {
          return;
        }
        if (overlayRef && !overlayRef.hasAttached()) {
          mentionListRef = overlayRef.attach(mentionList);
        }
        mentionListRef.setInput('currentMode', MentionMode.PROJECT_MEMBER);
        mentionListRef.setInput('members', members);

        mentionListRef.instance.clickMember
          .pipe(takeUntil(destroyed$))
          .subscribe((member) => {
            if (member) {
              selectedMembers.push({ id: mentionId, member });
              $(`#${mentionId}`)
                .attr('data-id', member?.id)
                .attr('data-value', member?.fullName)
                .text(`@${member?.fullName}`);
              onCompleteMention(mentionId)
            } else {
              onCompleteMention(mentionId, true);
            }
          });
      });
    // get All
    getProjectAllMembers('');
  }

  function positionStrategy() {
    const position = [PositionOverlay.bottomLeft, PositionOverlay.topLeft];
    const bound = editor.selection.element().getBoundingClientRect();
    if (bound.top > window.innerHeight - bound.bottom) {
      position.reverse();
    }
    return overlay
      .position()
      .flexibleConnectedTo(editor.selection.element())
      .withPositions(position)
  }

  function getProjectAllMembers(keyword: string = '') {
    if (searchKeyword != keyword) {
      searchKeyword = keyword;
      store.dispatch(
        ProjectActions.getProjectAllMembers({
          projectId,
          keyword,
          force: true
        }));
    }
  }

  function handleMarkdownEditor(mentionId: string) {
    store
      .select(selectMentionMembers)
      .pipe(takeUntil(destroyed$), debounceTime(100))
      .subscribe((members) => {
        if (!editor.integrator.isFocused()) {
          return;
        }
        if (overlayRef && !overlayRef.hasAttached()) {
          mentionListRef = overlayRef.attach(mentionList);
        }
        mentionListRef.setInput('markdownMembers', members || []);

        mentionListRef.instance.clickMarkdownMember
          .pipe(takeUntil(destroyed$))
          .subscribe((markdownMember) => {
            selectedMembers.push({ id: mentionId, markdownMember });
            $(`#${mentionId}`).text(`@${markdownMember?.objectName}`);
            onCompleteMention(mentionId);
          });
      });

    // get All
    const request: WikiMemberListRequest = {
      keyword: "",
      wikiFlg: true,
      type: WikiType.Page
    }
    store.dispatch(
      getMentionMembers({ request })
    );
  }

  function onCompleteMention(mentionId: string, withoutMention?: boolean) {
    const node = $(`#${mentionId}`)

    if (!withoutMention) {
      node.attr('complete', 'true');
    } else {
      node.removeClass('mention');
    }

    editor.selection.setAfter(node.get(0));
    editor.selection.restore();
    editor.html.insert(' ');
    hide(true, true);
  }

  function onKeydown() {
    editor.events.on('keydown', function (e) {
      if ([
          FroalaEditor.KEYCODE.ENTER,
          FroalaEditor.KEYCODE.ARROW_DOWN,
          FroalaEditor.KEYCODE.ARROW_UP,
          FroalaEditor.KEYCODE.TAB
        ].includes(e.which)
        && overlayRef?.hasAttached()) {
        e.stopPropagation();
        e.preventDefault();
        mentionListRef?.instance?.onKeyDown(e);
        return false;
      }
      const code = e.originalEvent.code;
      if (code === BACKSPACE && isMentionComplete()) {
        removedId = $(editor.selection.element()).attr('id');
        $(editor.selection.element()).remove();
      }

      if (code === DIGIT_2 && isMention()) {
        hide(true);
        const keyword = editor.selection.element().innerText;
        $(editor.selection.element()).remove();
        editor.html.insert(keyword);
        return;
      }
    }, true);
  }

  function revertIncompleteToNormal(el = editor.el) {
    $(el).find(`.${MENTION_CLASS}[complete!="true"]`).each(function () {
      const element = $(this).text();
      $(this).replaceWith(element);
    })
  }

  function isMention() {
    return $(editor.selection.element()).hasClass(MENTION_CLASS);
  }

  function isMentionIncomplete() {
    return (
      $(editor.selection.element()).hasClass(MENTION_CLASS) &&
      $(editor.selection.element()).attr('complete') !== 'true'
    );
  }

  function isMentionComplete() {
    return (
      $(editor.selection.element()).hasClass(MENTION_CLASS) &&
      $(editor.selection.element()).attr('complete') === 'true'
    );
  }

  function revertToNormal() {
    hide(true);
    const keyword = editor.selection.element().innerText;
    $(editor.selection.element()).remove();
    editor.html.insert(keyword);
  }

  function createOverlay() {
    hide(true);
    overlayRef = overlay.create({
      width: 'auto',
      height: 'auto',
      positionStrategy: positionStrategy()
    });

    overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(destroyed$))
      .subscribe(() => {
        hide();
        revertIncompleteToNormal();
      });
  }

  function hide(destroy = false, clean: boolean = false) {
    overlayRef?.detach();

    if (clean) {
      overlayRef?.dispose?.();
      overlayRef = null;
    }

    if (destroy) {
      destroyed$.next(null);
    }
  }

  function onDestroy() {
    editor.events.on('destroy', function () {
      overlayRef?.dispose?.();
      overlayRef = null;
    });
  }

  return {
    _init,
    getMentions,
    setProjectId,
    getMarkdownMentions,
    revertIncompleteToNormal
  };
};

import { UserStatusKey } from '../_utils';

export enum AccountCompanyPatchType {
  DEFAULT_FLG = 'defaultFlg'
}

export enum ExistedField {
  EMAIL = 'email',
  USERNAME = 'username'
}

export interface AccountAuthResponse {
  accountAccessToken: string;
  accountCompanies: AccountCompanyResponse[];
}

export interface AccountCompanyResponse {
  id: number;
  account: AccountResponse;
  company: CompanyAuthResponse;
  defaultFlg: boolean;
}

export interface AccountResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  status: UserStatusKey;
}

export interface CompanyAuthResponse {
  id?: number;
  name?: string;
  alias?: string;
  logo?: string;
  domain?: string;
  activeFlg?: boolean;
}

export interface AccountCompanyPatchRequest {
  type: AccountCompanyPatchType;
  value: string | boolean;
}

export interface VerifyExistRequest {
  field: ExistedField;
  value: string;
}

export interface VerifyExistResponse {
  exist: boolean;
}

export interface ExtendCompanyTrialState {
  email: string;
  companies: CompanyAuthResponse[];
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/core';
import { EventService } from '../../_services/work-calendar-event.service';
import { EventActions } from './event.actions';
@Injectable()
export class EventEffects {
  constructor(
    private actions$: Actions,
    private eventService: EventService,
    private toast: ToastService
  ) {}

  getEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.getEvents),
      exhaustMap((props) =>
        this.eventService.getEvent(props.payload).pipe(
          map((events) => EventActions.setEvents({ events })),
          catchError((error) => of(EventActions.setEvents({ events: [] })))
        )
      )
    );
  });

  createEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.createEvent),
      exhaustMap((props) =>
        this.eventService.createEvent(props.payload).pipe(
          map((event) => EventActions.createEventSuccess({ event })),
          catchError((error) => of(EventActions.createEventFail({ error })))
        )
      )
    );
  });

  createEventSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.createEventSuccess),
        tap(() => {
          this.toast.success('Created event successfully');
        })
      );
    },
    { dispatch: false }
  );

  createEventFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.createEventFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  updateEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.updateEvent),
      exhaustMap((props) =>
        this.eventService.updateEvent(props.eventId, props.payload).pipe(
          map((events) => EventActions.updateEventsSuccess({ events })),
          catchError((error) => of(EventActions.updateEventFail({ error })))
        )
      )
    );
  });

  updateEventsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.updateEventsSuccess),
        tap(() => {
          this.toast.success('Updated event successfully');
        })
      );
    },
    { dispatch: false }
  );

  updateEventFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.updateEventFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );

  deleteEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.deleteEvent),
      exhaustMap((payload) =>
        this.eventService.deleteEvent(payload.id).pipe(
          map((event) => EventActions.deleteEventSuccess({ event })),
          catchError((error) => of(EventActions.deleteEventFail({ error })))
        )
      )
    );
  });

  deleteEventSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.deleteEventSuccess),
        tap(() => {
          this.toast.success('Deleted event successfully');
        })
      );
    },
    { dispatch: false }
  );

  deleteEventFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventActions.createEventFail),
        tap(({ error }) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        })
      );
    },
    { dispatch: false }
  );
  // temporary hide drag drop, out of scope release 19/1/2024
  // updateStartEndEvent$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EventActions.updateStartEndEvent),
  //     exhaustMap((props) =>
  //       // Update start time
  //       this.eventService.updateTimeEvent(props.eventId, props.payload).pipe(
  //         map((event) => EventActions.updateEventSuccess({ event })),
  //         catchError((error) => of(EventActions.updateEventFail({ error })))
  //       )
  //     )
  //   );
  // });

  getLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.getLocations),
      exhaustMap((props) =>
        this.eventService.getLocations(props.payload).pipe(
          map((res) => EventActions.setLocations({ locations: res })),
          catchError((error) => of(EventActions.setLocations({ locations: null })))
        )
      )
    );
  });

  createLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.createLocation),
      exhaustMap((props) =>
        this.eventService.createLocation(props.payload).pipe(
          map((location) => EventActions.createLocationSuccess({ location })),
          catchError((error) => of(EventActions.createEventFail({ error })))
        )
      )
    );
  });

  updateLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.updateLocation),
      exhaustMap((props) =>
        this.eventService.updateLocation(props.locationId, props.payload).pipe(
          map((location) => EventActions.updateLocationSuccess({ location })),
          catchError((error) => of(EventActions.updateLocationFail({ error })))
        )
      )
    );
  });

  deleteLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventActions.deleteLocation),
      exhaustMap((props) =>
        this.eventService.deleteLocation(props.locationId).pipe(
          map(() => EventActions.deleteLocationSuccess({ locationId: props.locationId })),
          catchError((error) => of(EventActions.updateLocationFail({ error })))
        )
      )
    );
  });
}

import { LanguageKey } from "../_utils";
import { SelectOption } from "./utils.model";

export interface Company {
  id?: number;
  name: string;
  alias: string;
  logo: string;
  domain: string;
  language: LanguageKey;
  establishmentDate: string;
  slackConnectedFlg: boolean;
  jiraConnectedFlg: boolean;
  gitConnected: boolean;
  taxId?: number;
}

export interface PayLoadPatchCompany {
  type: string;
  value: boolean | string;
}

export class ConnectSlack {
  slackConnectedFlg?: boolean;
  code?: string;
}

export class CompanyStorage {
  total: number;
  used: number;
}

export enum CompanyType {
  COMPANY = 'COMPANY',
  SCHOOL = 'SCHOOL',
}

export const companyTypes: SelectOption<CompanyType>[] = [
  {
    name: 'Company',
    value: CompanyType.COMPANY,
  },
  {
    name: 'School',
    value: CompanyType.SCHOOL,
  },
];

import { createAction, emptyProps, props } from '@ngrx/store';
import { DynamicField, Field } from 'src/app/shared';
import { ProjectsTaskDFields } from './dynamic-field.models';

export const setFields = createAction(
  '[Dynamic Field] Set Fields',
  props<{ fields: DynamicField[] }>()
);

export const getTaskDFields = createAction('[Dynamic Field] Get Task Dynamic Fields', emptyProps);
export const setTaskDFields = createAction(
  '[Dynamic Field] Set Task Dynamic Field',
  props<{data: ProjectsTaskDFields[]}>()
);
export const addTaskDField = createAction('[Dynamic Field] Add Task Dynamic Field', props<{ field: ProjectsTaskDFields }>());

export enum Month {
  Jan = 'January',
  Feb = 'February',
  Mar = 'March',
  Apr = 'April',
  May = 'May',
  Jun = 'June',
  Jul = 'July',
  Aug = 'August',
  Sep = 'September',
  Oct = 'October',
  Nov = 'November',
  Dec = 'December'
}

export enum UnitOfTime {
  days = 'days',
  months = 'months',
  years = 'years',
  minutes = 'minutes',
  hours = 'hours',
  periods = 'periods'
}

export enum PeriodOfTimeAgo {
  oneMinuteAgo = 'oneMinuteAgo',
  oneHourAgo = 'oneHourAgo',
  oneDayAgo = 'oneDayAgo',
  oneWeekAgo = 'oneWeekAgo',
  oneMonthAgo = 'oneMonthAgo',
  oneYearAgo = 'oneYearAgo',
  minutesAgo = 'minutesAgo',
  hoursAgo = 'hoursAgo',
  daysAgo = 'daysAgo',
  weekAgo = 'weekAgo',
  monthsAgo = 'monthsAgo',
  yearsAgo = 'yearsAgo',
  lastWeek = 'lastWeek',
  now = 'now',
  today = 'today',
}

export enum DatePickerPlacement {
  auto = 'auto',
  topLeft = 'top left',
  topRight = 'top right',
  bottomLeft = 'bottom left',
  bottomRight = 'bottom right',
}

export enum dateFiltersValue {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  CUSTOM = 'CUSTOM',
}

export enum DateUIType {
  QUARTER_YEAR = 'quarterYear',
  MONTH_YEAR = 'monthYear',
  SHORT_MONTH = 'shortMonth',
  LONG_MONTH = 'longMonth',
  MIN_WEEK_DAY = 'minWeekDay',
  SHORT_WEEK_DAY = 'shortWeekDay'
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { CurrencyUnit, DocumentTemplateDownloadRequest, Group, List2Res, PageInfo, Pageable, Team } from 'src/app/shared';
import { EmployeeSalary, UserInvitePayLoad, SalaryPayLoad, SalaryProfilePayLoad, User, UserCreateRequest,
  UserReport, UserReportSearchRequest, UserUpdateStatusRequest, SetPasswordRequest, UserSearchRequest } from 'src/app/shared/_models/user.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class UserService extends EntityCollectionServiceBase<User> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
  ) {
    super('users', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getUsers(payload: UserSearchRequest, pageable?: Pageable) {
    const params = queryString.stringify({ ...payload, ...pageable });
    return this.http.get<List2Res<User>>(`${environment.apiUrl}/users/?${params}`);
  }

  createUser(payload: UserCreateRequest): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/users/create`, payload);
  }

  inviteUser(payload: UserInvitePayLoad) {
    return this.http.post<any>(`${environment.apiUrl}/users/invite`, payload);
  }

  importUser(payload: FormData): Observable<User[]> {
    return this.http.post<User[]>(
      `${environment.apiUrl}/users/import-users`,
      payload
    );
  }

  getProfile(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/profile`);
  }

  getProfileOfToken(token: string): Observable<User> {
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return this.http.get<User>(`${environment.apiUrl}/users/profile`, { headers });
  }

  getPrimaryContact(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/primary-contact`);
  }

  updateStatus(payload: UserUpdateStatusRequest): Observable<User> {
    return this.http.patch<User>(`${environment.apiUrl}/users/update-status`, payload);
  }

  getReport(payload: UserReportSearchRequest): Observable<UserReport> {
    const params = `userId=${payload.userId}&preFromDate=${payload.preFromDate}&preToDate=${payload.preToDate}`
      + `&fromDate=${payload.fromDate}&toDate=${payload.toDate}`;
    return this.http.get<UserReport>(`${environment.apiUrl}/users/user-report?${params}`);
  }

  changePassword(payload: {currentPassword: string, newPassword: string}, userId: number) {
    return this.http.post(`${environment.apiUrl}/users/${userId}/change-password` , payload);
  }

  changeAvatar(payload: {avatar: File, avatarLarge: File}, userId: number): Observable<User> {
    const formData = new FormData();
    formData.append('avatar', payload.avatar);
    formData.append('avatarLarge', payload.avatarLarge);
    return this.http.patch<User>(`${environment.apiUrl}/users/${userId}/change-avatar`, formData);
  }

  generatePayslip(userId: number, payrollId: number) {
    const payload = {};
    return this.http.post(`${environment.apiUrl}/users/${userId}/payslips/${payrollId}`, payload);
  }

  downloadPayslip(userId: number, payrollId: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${environment.apiUrl}/users/${userId}/payslips/${payrollId}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  downloadContract(payload: DocumentTemplateDownloadRequest): Observable<HttpResponse<Blob>> {
    const params = `userId=${payload.userId}&templateId=${payload.templateId}`;
    return this.http.get<Blob>(`${environment.apiUrl}/users/${payload.userId}/contracts?${params}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getSalaryProfile(id: number): Observable<EmployeeSalary> {
    return this.http.get<EmployeeSalary>(`${environment.apiUrl}/users/${id}/salary-profiles`);
  }

  updateSalarySetting(id: number, payload: SalaryPayLoad): Observable<EmployeeSalary> {
    return this.http.patch<EmployeeSalary>(`${environment.apiUrl}/users/${id}/salary-setting`, payload);
  }

  updateSalaryProfile(id: number, payload: SalaryProfilePayLoad): Observable<EmployeeSalary> {
    return this.http.patch<EmployeeSalary>(`${environment.apiUrl}/users/${id}/salary-profiles`, payload);
  }

  getUnit(): Observable<CurrencyUnit> {
    return this.http.get<CurrencyUnit>(`${environment.apiUrl}/users/salary-unit`);
  }

  setPassword(payload: SetPasswordRequest) {
    return this.http.post(`${environment.apiUrl}/users/set-password`, payload);
  }

  getUserTeams(id: number) {
    return this.http.get<Team[]>(`${environment.apiUrl}/users/${id}/teams`);
  }

  getUserGroups(id: number) {
    return this.http.get<Group[]>(`${environment.apiUrl}/users/${id}/groups`);
  }
}

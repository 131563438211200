import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountLink } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JiraAccountService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<AccountLink[]> {
    this.loading$.next(true);
    return this.http.get<AccountLink[]>(`${environment.apiUrl}/jira-accounts/`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  delete(id: string): Observable<any> {
    this.loading$.next(true);
    return this.http.delete<any>(`${environment.apiUrl}/jira-accounts/${id}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  unlink(): Observable<any> {
    this.loading$.next(true);
    return this.http.post<any>(`${environment.apiUrl}/jira-accounts/unlink`, {}).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  unlinkUser() {
    return this.http.post(`${environment.apiUrl}/jira-accounts/unlink-user`, {});
  }
}

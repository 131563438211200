<x-dropdown [buttonRef]="buttonRef">
  <div class="header">
    <div class="title"><app-i18n [key]="'Member'"></app-i18n></div>

    <app-search-input (search)="searchAssignees($event)"></app-search-input>
  </div>
  <div class="option-list">
    <div
      class="custom-option"
      dropdown-item
      *ngIf="assignee"
      (click)="selectAssignee()"
    >
      <span class="material-icons icon-md unassigned-avatar">
        account_circle
      </span>
      <span class="option-label"
        ><app-i18n [key]="'Unassigned'"></app-i18n
      ></span>
    </div>
    <div
      class="custom-option"
      [ngClass]="{ selected: item.id === assignee?.id }"
      *ngFor="let item of assignees"
      dropdown-item
      (click)="selectAssignee(item)"
    >
      <app-avatar [url]="item.avatar" [name]="item.fullName"></app-avatar>
      <span class="option-label">{{ item.fullName }}</span>
    </div>

    <div
      class="text-center"
      *ngIf="!isLoadingAssignee && assignees.length === 0"
    >
      <app-i18n key="No options"></app-i18n>
    </div>
  </div>
</x-dropdown>

<ng-template #buttonRef>
  <app-avatar
    [url]="assignee?.avatar"
    [name]="assignee?.fullName"
    (click)="searchAssignees()"
  ></app-avatar>
</ng-template>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/core';
import {
  AbstractComponent,
  appDefaultLang,
  AppLanguage,
  appLanguages,
  CURRENT_LANGUAGE,
  LanguageCode
} from 'src/app/shared';
import { I18nService } from 'src/app/site-management/_services';

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent extends AbstractComponent {
  @Input() placement = 'bottom';
  @Input() style: 'icon' | 'inline' | 'landing' = 'inline';

  appDefaultLang = appDefaultLang;
  appLanguagues = appLanguages;
  LanguageCode = LanguageCode;
  currentLanguage: AppLanguage;
  languaguesDisplay = appLanguages;

  currentYear = (new Date()).getFullYear();

  constructor(
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {
    super(false);
  }



  init(): void {
    const languageCode = localStorage.getItem(CURRENT_LANGUAGE) || this.appDefaultLang;
    this.currentLanguage = this.appLanguagues.find(e=> e.code === languageCode);
    this.checkWindowSize();
  }

  switchLanguage(language: AppLanguage) {
    if (this.currentLanguage.code === language.code) {
      return;
    }
    this.currentLanguage = language;
    localStorage.setItem(CURRENT_LANGUAGE, language.code);
    this.translateService.use(language.code).subscribe();
    this.i18nService.changeLanguage(language.code);
    const pathname = window.location.pathname;

    if ([`/${LanguageCode.en}/`, `/${LanguageCode.vi}/`].some(e => pathname.startsWith(e))) {
      const newPath = `/${language.code}/` + pathname.slice(4);
      window.location.pathname = newPath;
    } else {
      window.location.reload();
    }
  }

  onSearchText(keyword: string) {
    this.languaguesDisplay = this.appLanguagues.filter(
      (e) =>
        e.code.includes(keyword) || UtilService.isSubString(e.name, keyword)
    );
  }
}

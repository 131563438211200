import { createReducer, on } from '@ngrx/store';
import * as UserSettingAction from './user-setting.actions';
import { UserSettingState, defaultUserSettingState } from './user-setting.model';

export const featureKey = 'userSetting';

const initialState: UserSettingState = {};

export const userSettingReducer = createReducer(
  initialState,
  on(UserSettingAction.storeUserSetting, (state, props) => {
   const newState =  { ...state };
   if (props.payload.settingKey) {
    newState[props.payload.settingKey] = props.payload.settingValue;
   }
   return newState;
  }),
  on(UserSettingAction.getUserSetting, (state, props) => {
   const newState = { ...state };
   newState[props.payload] = newState[props.payload] ?? defaultUserSettingState[props.payload];
   return newState;
  }),
);

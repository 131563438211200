import { AppInjector } from 'src/app/app.module';
import { UtilService } from 'src/app/core';
import { DatePeriod, FileResponse, FileType } from 'src/app/shared';
import { FileService } from '../_services/file.service';
import { DocumentFile } from './document-file';
import { DocumentFileBase } from './document-file-base';

export class DocumentRecentFile extends DocumentFile {
  baseType = 'DocumentRecentFile';
  constructor(
    file: FileResponse,
  ) {
    super(file);
  }

  getChilds() {
    this.getSubscription?.unsubscribe();
    this.getSubscription = this.fileService.getRecentFiles({keyword: ''}, this.pageable).subscribe(
      (res) => {
        const items = res.content.map(e => e.file);
        this.items = items.map(e => {
          const item = new DocumentRecentFile(e);
          item.parent = this;
          return item;
        });
        this.buildSection();
        this.delegate?.loadChildsDone(this, this.items);
      },
      (err) => {
        this.items = [];
        this.buildSection();
        this.errorFn(err);
        this.delegate?.loadChildsDone(this, this.items);
      }
    );
  }

  buildSection() {
    this.sections = this.getDefaultTimeSection();

    this.items.forEach(item => {
      if (!item.file.lastAction) {
        return;
      }

      if (UtilService.isBelongToPeriod(item.file.lastAction, DatePeriod.TODAY)) {
        this.sections[0].content.items.push(item);
        return;
      }

      if (UtilService.isBelongToPeriod(item.file.lastAction, DatePeriod.THIS_WEEK)) {
        this.sections[1].content.items.push(item);
        return;
      }

      if (UtilService.isBelongToPeriod(item.file.lastAction, DatePeriod.THIS_MONTH)) {
        this.sections[2].content.items.push(item);
        return;
      }

      // if (UtilService.isBelongToPeriod(item.file.lastAction, DatePeriod.THIS_YEAR)) {
      //   this.sections[3].content.items.push(item);
      //   return;
      // }
      this.sections[3].content.items.push(item);
    });
  }

  canLink() {
    return true;
  }

  canShare() {
    return true;
  }

  canDelete() {
    return this.file.editable;
  }

  canAddShortcut() {
    // return this.file.editable;
    return false;
  }

  canRename() {
    return this.file.editable;
  }

  canDownload() {
    return true;
  }

  canSetting() {
    return this.file.editable && this.file.type === FileType.dir;
  }

  canAccessFolderChild() {
    return false;
  }
}

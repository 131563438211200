import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AdvanceExpFilter, AdvanceFilter, DATE_FORMAT, EmptyString, LogicOperator, MultiOperatorFields, QuotationMark, UTCRegex } from 'src/app/shared';
import { SymbolicName, TokenGroupType, TokenLeaf } from 'src/app/shared/_components/antlr/xql-editor/_models/xql.model';

@Injectable({
  providedIn: 'root'
})

export class AdvanceFilterService {
  initExpr: AdvanceExpFilter = { lhs: null, operator: null, rhs: null };

  convertToAdvance(advanceData: AdvanceFilter, tokens: TokenLeaf[], startTokenIdx: number = 0) {
    let i = startTokenIdx;
    let isMultiField = false;

    for (; i < tokens.length; i++) {
      const token = tokens[i];

      if (token.symbolicName === SymbolicName.CLOSE_PAR) {
        if (isMultiField) {
          isMultiField = false;
          continue;
        }
        if (!advanceData.logicOperator) {
          advanceData.logicOperator = LogicOperator.AND;
        }
        return i;
      }

      if (token.symbolicName === SymbolicName.OPEN_PAR && !isMultiField) {
        if (!advanceData?.child) {
          advanceData.child = [];
        }
        advanceData.child.push({ logicOperator: null, expr: [] });
        const lastChildIdx = advanceData.child.length - 1;
        i = this.convertToAdvance(advanceData.child[lastChildIdx], tokens, i + 1);
        continue;
      }

      const expr = advanceData.expr;
      let lastExprIdx = expr.length - 1;
      const lastExpr = expr[lastExprIdx];
      if (expr.length === 0 || (this.isFullyExpr(lastExpr) && this.isNotLastElement(i + 1, tokens.length) && !isMultiField)) {
        expr.push({...this.initExpr});
        lastExprIdx++;
      }

      switch (token.groupType) {
        case TokenGroupType.field:
          expr[lastExprIdx].lhs = token.text;
          break;

        case TokenGroupType.operator:
          expr[lastExprIdx].operator = token.text;
          if (MultiOperatorFields.includes(token.text)) {
            isMultiField = true;
            expr[lastExprIdx].rhs = [];
          }
          if (token.symbolicName === SymbolicName.NOT_) {
            advanceData.logicOperator = LogicOperator.NOT;
          }
          break;

        case TokenGroupType.operand:
          const text = this.removeExtraCharacter(token.text, QuotationMark);
          if (isMultiField) {
            (expr[lastExprIdx].rhs as string[]).push(text);
          } else {
            if (text.match(UTCRegex)) {
              expr[lastExprIdx].rhs = moment.utc(text).local().format(DATE_FORMAT);
            } else {
              expr[lastExprIdx].rhs = text;
            }
          }
          break;

        case TokenGroupType.keyword:
          advanceData.logicOperator = token.text;
          break;
        }
      }
  }

  isFullyExpr(expr: AdvanceExpFilter) {
    return expr?.lhs && expr?.operator && expr?.rhs;
  }

  isNotLastElement(index: number, length: number) {
    return index !== length;
  }

  removeEmptyRow(advanceFilter: AdvanceFilter) {
    advanceFilter.expr = advanceFilter.expr.filter(condition => (condition?.lhs || condition?.rhs));

    if (!advanceFilter?.child) { return; }
    advanceFilter.child.forEach(el => this.removeEmptyRow(el));
  }

  removeExtraCharacter(token: string, character: string) {
    return token.replaceAll(character, EmptyString);
  }
}

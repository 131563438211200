import { Task, TaskTypeEnum, User } from "src/app/shared";

export const isNormalTask = (task: Task): boolean => {
  const specialTypes = [
    TaskTypeEnum.Epic.toUpperCase(),
    TaskTypeEnum.Storyline.toUpperCase(),
  ];
  return !(task?.taskTemplate || specialTypes.includes(task.type.code as TaskTypeEnum));
};

export const isTaskBelongedToUser = (task: Task, user: User) => {
  return task.assignee?.id === user.id ||
    task.reporter?.id === user.id ||
    task.cc?.some(_cc => _cc?.id === user.id) ||
    task.createdBy?.id === user.id;
}

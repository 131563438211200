import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgProgress } from 'ngx-progressbar';
import { tap } from 'rxjs';
import { AppActions } from '.';

@Injectable()
export class AppEffects {
  constructor(private _actions$: Actions, private _ngProgress: NgProgress) {}

  $progressLoading = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.progressLoading),
        tap(({ loading }) => {
          const ref = this._ngProgress.ref();

          if (loading) {
            ref.start();
          } else {
            ref.complete();
          }
        })
      );
    },
    { dispatch: false }
  );
}

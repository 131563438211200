import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { XDirectivesModule } from 'src/@xcorp/directives/x-directives.module';
import { MatIconModule } from '@angular/material/icon';
import { I18nModule } from '../i18n/i18n.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownMenuComponent,
  ],
  imports: [
    CommonModule,
    XDirectivesModule,
    MatIconModule,
    MatTooltipModule,
    I18nModule,
    TranslateModule,
  ],
  exports: [
    DropdownComponent,
  ]
})
export class DropdownModule { }

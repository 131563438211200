import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-option',
  template: `
    <div class="d-flex">
      <img class="option-icon mr-2" [src]="item.icon" *ngIf="item?.icon" />
      <div class="option-label" [ngClass]="{ 'ml-0': !item?.icon }">
        {{ item.name }}
      </div>
    </div>
  `
})
export class CommonOptionComponent {
  @Input() item: any;
}

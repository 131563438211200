<div class="switch-date-wrapper">
  <div
    class="switch-date-group"
    [ngClass]="{ mobile: screenView === screenSize.small }"
  >
    <button
      class="btn btn-flex btn-icon-circle previous"
      (click)="changeDateStep(-1)"
    >
      <span class="material-icons"> navigate_before </span>
    </button>

    <div class="date-info">
      <input
        type="text"
        placeholder="Daterangepicker"
        class="form-control"
        bsDaterangepicker
        [placement]="switchDatePlacement()"
        [(ngModel)]="datesUI"
        (bsValueChange)="changeDates($event)"
        [bsConfig]="bsConfig"
        (onHidden)="onDatePickerHidden()"
      />
    </div>

    <button
      class="btn btn-flex btn-icon-circle next"
      (click)="changeDateStep(1)"
    >
      <span class="material-icons"> navigate_next </span>
    </button>
  </div>
  <button
    class="btn btn-today ml-2"
    *ngIf="showThisPeriodBtn"
    [ngClass]="(period | periodActive : firstAndlast) ? 'btn-primary' : ''"
    (click)="changeToThisPeriod()"
  >
    {{ getThisPriodLabel() | translate }}
  </button>
</div>

<div class="epic-list-dropdown-wrapper">
  <div class="header">
    <div class="d-flex-center justify-content-between mb-1">
      <div class="mb-0"><app-i18n [key]="'Assign Epic'"></app-i18n></div>
      <button class="btn btn-icon" (click)="onFocus()" [tooltip]="'Create Epic' | translate"><span class="material-icons-outlined">add</span></button>
    </div>
    <app-search-input (search)="handleKeywordChange($event)"></app-search-input>
  </div>
  <div class="option-list epic-list">
    <div class="custom-option" *ngIf="selectedEpic" (click)="selectEpic()"
      [ngClass]="{'text-primary': selectedEpic?.id}">
      <app-i18n [key]="'Unlink'"></app-i18n>
    </div>
    <div class="custom-option d-flex" *ngIf="isCreateEpic">
      <div class="d-flex-center">
        <app-epic-inline-task [projectId]="projectId" (objectResponse)="addEpic($event)"></app-epic-inline-task>
      </div>
    </div>
    <div class="custom-option" nz-menu-item [ngClass]="{'selected': item.id === selectedEpic?.id}" *ngFor="let item of epics"
      (click)="selectEpic(item)">
      <div class="badge-label text-ellipsis w-100 d-flex justify-content-between align-items-center"
        [ngStyle]="{'background-color': item.colorCode}">
        <span class="label-option" *ngIf="item?.name as nameEpic" [title]="nameEpic"> {{ nameEpic }} </span>
        <span class="material-icons-round text-white" *ngIf="item.id === selectedEpic?.id">
          done
        </span>
      </div>
      <button *ngIf="canViewDetailEpic" class="btn btn-flex" [tooltip]="'View Detail' | translate"
        [placement]="'bottom'" (click)="goToDetailEpic(item)">
        <mat-icon [svgIcon]="'navigate'" class="navigate-icon ml-3"></mat-icon>
      </button>
    </div>
    <div class="text-center py-2" *ngIf="epics?.length === 0"><app-i18n [key]="'No options'"></app-i18n></div>
  </div>
</div>

import { Sprint } from "src/app/shared";

export interface BacklogContextMenuItem {
  id?: number;
  sprint?: Sprint;
  name: string;
  code: string;
  children?: BacklogContextMenuItem[];
  disabled?: boolean;
}

export enum ContextMenuCode {
  MOVE_TO = 'MOVE_TO',
  DELETE_TASK = 'DELETE_TASK',
  SET_TASK_TEMPLATE = 'SET_TASK_TEMPLATE',
  BULK_CHANGE = 'BULK_CHANGE',
  MOVE_TO_SPRINT = 'MOVE_TO_SPRINT',
  MOVE_TO_TOP_BACKLOG = 'MOVE_TO_TOP_BACKLOG',
  MOVE_TO_BOTTOM_BACKLOG = 'MOVE_TO_BOTTOM_BACKLOG',
  CLONE_TASKS = 'CLONE_TASKS'
}

export enum LabelSubMenu {
  nextSprint = 'Next Sprint',
  topOfBacklog = 'Top of Backlog',
  bottomOfBacklog = 'Bottom of Backlog'
}

export const BacklogContextMenuStructure: BacklogContextMenuItem[] = [
  {
    name: 'Move To',
    code: ContextMenuCode.MOVE_TO,
    children: [
      {
        name: 'Top of Backlog',
        code: ContextMenuCode.MOVE_TO_TOP_BACKLOG
      },
      {
        name: 'Bottom of Backlog',
        code: ContextMenuCode.MOVE_TO_BOTTOM_BACKLOG
      }
    ]
  },
  {
    name: 'Clone Task',
    code: ContextMenuCode.CLONE_TASKS,
  },
  {
    name: 'Delete Task',
    code: ContextMenuCode.DELETE_TASK
  },
  // {
  //   name: 'Set to Task Templates',
  //   code: ContextMenuCode.SET_TASK_TEMPLATE
  // },
  {
    name: 'Bulk Change',
    code: ContextMenuCode.BULK_CHANGE
  }
];

export const TaskDetailFields = [
  {
    name: 'Status',
    value: 'status',
    type: 'statusId',
    template: 'statusTemplate',
  },
  {
    name: 'Assignee',
    value: 'assignee',
    type: 'assigneeId',
    template: 'assigneeTemplate',
  },
  {
    name: 'Reporter',
    value: 'reporter',
    type: 'reporterId',
    template: 'reporterTemplate',
  },
  {
    name: 'CC',
    value: 'cc',
    type: 'ccIds',
    template: 'ccTemplate',
  },
  {
    name: 'Type',
    value: 'type',
    type: 'typeId',
    template: 'typeTemplate'
  },
  {
    name: 'Priority',
    value: 'priority',
    type: 'priorityId',
    template: 'priorityTemplate',
  },
  {
    name: 'Epic',
    value: 'epic',
    type: 'epicId',
    template: 'epicTemplate',
  },
  {
    name: 'Releases',
    value: 'releases',
    type: 'versionIds',
    template: 'releasesTemplate',
  },
  {
    name: 'Sprint',
    value: 'sprint',
    type: 'sprintId',
    template: 'sprintTemplate',
  },
  {
    name: 'Label',
    value: 'label',
    type: 'labelIds',
    template: 'labelTemplate',
  },
  {
    name: 'Due Date',
    value: 'dueDate',
    type: 'dueDate',
    template: 'dateTemplate',
  },
  {
    name: 'End Date',
    value: 'endDate',
    type: 'endDate',
    template: 'dateTemplate',
  },
  {
    name: 'Start Date',
    value: 'startDate',
    type: 'startDate',
    template: 'dateTemplate',
  },
  {
    name: 'Platform',
    value: 'platform',
    type: 'platformId',
    template: 'platformTemplate'
  },
  {
    name: 'Storyline',
    value: 'storyline',
    type: 'storylineId',
    template: 'storylineTemplate'
  },
  {
    name: 'Follow Up',
    value: 'followUp',
    type: 'followUp',
    template: 'followUpTemplate'
  }
];

export const followUpItems = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false'}
];



import { TaskSortResponse } from './index';
import { Pageable } from './generic';
import { Group } from './group.model';
import { Project } from './project.model';
import { Shift, ShiftProfile } from './shift.model';
import { Task } from './task.model';
import { TypeOfLeave } from './type-of-leave.model';
import { LogTimeResponse } from './time-log.model';

export interface WorkTrackingSetting {
  id: number;
  includedUserOffice: boolean;
  deleted: boolean;
  leaveTypes: TypeOfLeave[];
  shiftProfiles: ShiftProfileTrackingSetting[];
}
export interface ShiftProfileTrackingSetting {
  id?: number;
  shiftProfile?: ShiftProfile;
  shiftProfileId?: number;
  trackingSettingShifts?: WorkTrackingSettingDetail[];
  defaultFlg?: boolean;
}

export interface WorkTrackingSettingDetail {
  id?: number;
  deleted?: boolean;
  reportStartFlg: boolean;
  reportStartLimitBefore: number;
  reportStartLimitAfter: number;
  reportEndFlg: boolean;
  reportEndLimitBefore: number;
  reportEndLimitAfter: number;
  shift?: Shift;
  shiftId?: number;
  shiftProfileShiftId?: number;
}

export interface WorkTrackingSettingUpsert {
  includedUserOffice?: boolean;
  leaveTypeIds?: number[];
  shiftProfiles: ShiftProfileTrackingSetting[];
}

export interface UserReportTime {
  settingDetailId: number;
  shift: Shift;
  reportStartFlg: boolean;
  reportStartLimitBefore: number;
  reportStartLimitAfter: number;
  reportStartTracking: UserTrackingReport;
  reportStartStatus: string;
  reportEndFlg: boolean;
  reportEndLimitBefore: number;
  reportEndLimitAfter: number;
  reportEndTracking: UserTrackingReport;
  reportEndStatus: string;
}

export interface UserTrackingReport {
  id: number;
  settingDetailId: number;
  reportType: string;
  date: string;
  time: Time;
  status: string;
  details: UserTrackingReportDetail[];
}

export interface UserTrackingReportUpsert {
  settingDetailId: number;
  reportType: string;
  details: UserTrackingReportDetail[];
}

export interface Time {
  hour: number;
  minute: number;
  second: number;
  nano: number;
}

export interface UserTrackingReportDetail {
  id: number;
  project?: Project;
  projectId?: number;
  task?: TaskSortResponse;
  taskId?: number;
  progress: number;
  description: string;
  updateTask: boolean;
}

export interface UserTrackingSummarySearchRequest {
  fromDate: string;
  toDate: string;
  userIds: number[];
  groupIds: number[];
  teamIds: number[];
  userStatus: string;
}

export interface UserTrackingSummary {
  userId: number;
  fullName: string;
  avatar: string;
  details: UserTrackingSummaryDetail[];
  summary: Summary[];
}

export interface UserTrackingSummaryDetail {
  date: string;
  shiftDetails: UserTrackingSummaryShiftDetail[];
  includeComment: boolean;
}

export interface UserTrackingSummaryShiftDetail {
  shiftId: number;
  startStatus: string;
  endStatus: string;
}

export interface Summary {
  status: string;
  count?: number;
  countString?: string;
}

export interface TrackingSummaryDate {
  user: string;
  avatar: string;
  date: string;
  shifts: UserReportTrackingShift[];
  details: TrackingSummaryTaskDate[];
  generalComment: string;
}

export interface UserReportTrackingShift {
  shift: Shift;
  shiftStatuses: { reportType: string, status: string, time: string }[];
}

export interface TrackingSummaryTaskDate {
  project: Project;
  task: TaskSortResponse;
  description: string;
  progressShifts: TaskShiftProgress[];
  logTimes: LogTimeResponse[];
  allLogTimesAsHours: number;
  currentLogTimes: LogTimeResponse[];
  currentLogTimesAsHours: number;
}

export interface TrackingSummaryTaskDateMobile extends TrackingSummaryTaskDate {
  tasks: TrackingSummaryTaskDate[];
}

export interface TaskShiftProgress {
  settingShift: WorkTrackingSettingDetail;
  reportType: string;
  date: string;
  time: string;
  status: string;
  progress: number;
  description: string;
}

export interface UserReportCommentDto {
  userId: number;
  date: string;
  comment: string;
}

export interface CustomUserReportTime {
  before: number;
  after: number;
  position: number;
}

export interface CustomUserReportTimeline {
  time: moment.Moment;
  pos: number;
}

import { RequestStatus } from '../_utils';
import { RequestLeaveApprover } from './request-leave-approver.model';
import { Shift } from './shift.model';
import { TypeOfLeave } from './type-of-leave.model';
import { SwipeUI } from './ui';
import { User } from './user.model';

export class LeaveRequest {
  id: number;
  status: string;
  comment: string;
  user: User;
  typeOfLeave: TypeOfLeave;
  requestLeaveApprovers: RequestLeaveApprover[];
  cc: User[];
  requestDays: number;
  approvedBy: User;
  approvedTime: string;
  requestGroupListResponse: RequestGroup[];
  groupId: number;
  submittedTime?: string;
}

export interface RequestGroup {
  id: number;
  shift: Shift;
  fromDate: string;
  toDate: string;
  shiftFromTime: string;
  shiftToTime: string;
}

export class LeaveRequestUI extends LeaveRequest implements SwipeUI {
  dataUI: {
    didSwipe: boolean;
  };
}

export class LeaveRequestUpsert {
  id?: number;
  typeOfLeaveId: number;
  approverIds: number[];
  cc: number[];
  comment: string;
  requestGroupList: RequestGroupUpsert[];
}

export class RequestGroupUpsert {
  id?: number;
  shiftId?: number;
  fromDate: string;
  toDate: string;
  shiftFromTime?: string;
  shiftToTime?: string;
}

export class LeaveRequestSearch {
  fromDate?: string;
  toDate?: string;
  userType?: string;
  employeeIds?: number[];
  status?: RequestStatus[];
  typeOfLeaveIds?: number[];
  userId?: number;
  userStatus?: string;

  constructor(data?: any) {
    this.fromDate = data?.fromDate || '';
    this.toDate = data?.toDate || '';
    this.userType = data?.userType || '';
    this.employeeIds = data?.employeeIds || [];
    this.status = data?.status || [];
    this.typeOfLeaveIds = data?.typeOfLeaveIds || [];
    this.userId = data?.userId || null;
    this.userStatus = data?.userStatus || '';
  }
}

export class RequestLeaveApproveRequest {
  requestLeaveStatus: RequestStatus;
  ids?: number[];
  allFlag?: boolean;
  fromDate?: string;
  toDate?: string;
  groupIds?: number[];
}

export class CountRequestDay {
  listRequestDays: GetRequestDay[];
}

export class GetRequestDay {
  shiftId: number;
  fromDate: string;
  toDate: string;
}

export class FilterRequestLeaveResponse {
  periodType: string;
  fromDate: string;
  toDate: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_MONTH_NAME_DAY } from 'src/app/shared';


@Pipe({
  name: 'dueDateString'
})
export class DueDateStringPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) { }

  transform(_dueDate: string) {
    let text = '';
    if (!_dueDate) {
      text = this.translateService.instant('Due');
      return {
        dueDateString: `<span class="text-muted f-italic">${text}</span>`,
        dueDateDescription: this.translateService.instant('Set due date'),
        color: 'var(--x-light-gray)',
        text
      };
    }
    const dueDate = moment(_dueDate, DATE_FORMAT);
    const today = moment(moment().format(DATE_FORMAT));
    const diffDate = today.diff(dueDate, 'days');
    if (diffDate === 0) {
      text = this.translateService.instant('Today');
      return {
        dueDateString: `<span class="text-warning due-date-label">${text}</span>`,
        dueDateDescription: this.translateService.instant('Due Today'),
        color: '#f2a403',
        text
      };
    } else if (diffDate > 0) {
      return {
        dueDateString: `<span class="text-danger due-date-label">${diffDate}d</span>`,
        dueDateDescription: `Late ${diffDate + (diffDate > 1 ? ' days' : 'day')}`,
        color: '#ec4d57',
        text: `${diffDate}d`
      };
    } else {
      text = moment(_dueDate).format(DATE_FORMAT_MONTH_NAME_DAY);
      return {
        dueDateString: `<span class="text-dodgerblue due-date-label">${text}</span>`,
        dueDateDescription: `${this.translateService.instant('Due')} ${text}`,
        color: 'dodgerblue',
        text: text
      };
    }
  }
}


export const DATE_CONTROL_PIPE = [
  DueDateStringPipe,
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountCompanyPatchRequest, AccountCompanyResponse, AuthResponse,
  VerifyExistRequest, VerifyExistResponse, XcorpAccount } from 'src/app/shared/_models';
import { XCORP_ACCOUNTS } from 'src/app/shared/_utils/consts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(private http: HttpClient) { }

  getMainAccount() {
    return this.getXcorpAccounts().find(e => e.isMain);
  }

  getSubAccounts() {
    return this.getXcorpAccounts().filter(e => !e.isMain);
  }

  getXcorpAccounts(): XcorpAccount[] {
    return JSON.parse(localStorage.getItem(XCORP_ACCOUNTS) || null) || [];
  }

  setXcorpAccounts(accs: XcorpAccount[]) {
    return localStorage.setItem(XCORP_ACCOUNTS, JSON.stringify(accs));
  }

  setMainXcorpAccount(newAcc: XcorpAccount) {
    const accounts = this.getXcorpAccounts();
    const index = accounts.findIndex(e => e.user.id === newAcc.user.id);
    accounts.forEach(e => e.isMain = false);
    newAcc.isMain = true;

    if (index === -1) {
      accounts.push(newAcc);
    } else {
      accounts[index] = newAcc;
    }
    this.setXcorpAccounts(accounts);
  }

  validateUserSignOut(email: string, domain: string) {
    const accounts = this.getXcorpAccounts();
    const index = accounts.findIndex(e => e.user.email === email && e.user.company.domain === domain);
    if (index === -1) {
      return;
    }

    accounts[index].expired = true;
    accounts[index].isMain = false;
    this.setXcorpAccounts(accounts);
  }

  signInAccount(accountId: number) {
    return this.http.post<AuthResponse>(`${environment.apiUrl}/account-companies/${accountId}/login`, {});
  }

  getAccountCompanies(accountId: number) {
    return this.http.get<AccountCompanyResponse[]>(`${environment.apiUrl}/accounts/${accountId}/account-companies`);
  }

  setAccountCompanyDefault(accountCompanyId: number, payload: AccountCompanyPatchRequest) {
    const url = `${environment.apiUrl}/account-companies/${accountCompanyId}`;
    return this.http.patch<AccountCompanyResponse>(url, payload);
  }

  verifyExist(payload: VerifyExistRequest) {
    return this.http.post<VerifyExistResponse>(`${environment.apiUrl}/accounts/verify-exist`, payload);
  }
}

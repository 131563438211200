<div class="h-100" [ngClass]="{ 'd-none': mode !== 'edit', preventUpload: mode === 'edit' }">
  <div
    [class]="'h-100 edit ' + customClass"
    [froalaEditor]="options"
    [formControl]="control"
    [ngClass]="{ 'pb-3': !showSaveBtn }"
  ></div>

  <div class="text-right toolbar-action" *ngIf="showSaveBtn">
    <button
      type="button"
      class="btn btn-white"
      (click)="onCancel()"
      [disabled]="isSaving"
    >
      <app-i18n [key]="'Cancel'"></app-i18n>
    </button>

    <button type="button" class="btn btn-primary ml-2" [disabled]="!hasChanged" (click)="onSave()">
      <app-i18n [key]="'Save'"></app-i18n>
    </button>
  </div>
</div>

<div *ngIf="mode !== 'edit'">
  <div
    class="view"
    quillMentionEvent
    [ngClass]="{ placeholder: !control.value }"
    [froalaView]="control.value || placeholder || ''"
    (click)="onClickView($event)"
  ></div>
</div>

export enum SprintStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum ProjectBoardName {
  SPRINT = 'Sprint',
  KANBAN = 'Kanban'
}

export enum ProjectReportViewMode {
  CHART = 'Chart',
  TASK = 'task'
}

<div class="p-4 import-file-container">
  <h4 class="c-modal-header">
    <span><app-i18n [key]="title"></app-i18n></span>
  </h4>

  <div class="c-content" *ngIf="fileLink">
    <div>
      <app-i18n [key]="'You can download the template here'"></app-i18n>
    </div>
    <div class="example-file my-2">
      <a [href]="fileLink" target="_blank">
        <span class="material-icons"> save_alt </span>&nbsp;
        <app-i18n [key]="'Example'"></app-i18n>
      </a>
    </div>
  </div>
  <div class="">
    <input
      type="file"
      (change)="onFileChanged($event.target.files)"
      #fileInput
      (click)="$event.target.value = null"
      hidden="true"
      [attr.multiple]="multiple"
    />

    <div
      class="py-3 my-3 import-drag text-center"
      appFileDropZone
      (onFileDropped)="filesDropped($event)"
      (click)="fileInput.click()"
    >
      <div class="fw-bold"><app-i18n [key]="'Upload file'"></app-i18n></div>
      <div>
        <app-i18n key="Please select file to import" [params]="{ validFile: validFile }"></app-i18n>
      </div>
    </div>

    <div class="d-flex flex-column my-1 files">
      <div *ngFor="let file of files; let idx = index" class="file px-3">
        <file-icon
          class="file-icon"
          [set]="'square-o'"
          [size]="'sm'"
          [type]="getExtension(file.type)"
        >
        </file-icon>

        <div class="file-name mx-1" [title]="file.name">{{ file.name }}</div>
        <button class="btn btn-close btn-light ml-auto" (click)="deleteItem(idx)">
          x
        </button>
      </div>
    </div>
  </div>

  <div class="actions text-right pt-3">
    <button type="button" class="btn btn-white" (click)="Cancel()">
      <app-i18n [key]="cancelBtnName"></app-i18n>
    </button>
    <button type="button" class="btn btn-primary ml-2" appRestrictMultiSubmit (multiClick)="onSubmmit()">
      <app-i18n [key]="saveBtnName"></app-i18n>
    </button>
  </div>
</div>

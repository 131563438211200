export const MoreParagraphDefault = [
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'formatOLSimple',
  'formatOL',
  'formatUL',
  'paragraphFormat',
  'paragraphStyle',
  'lineHeight',
  'outdent',
  'indent',
  'quote'
];

export const MarkdownMoreParagraph = [
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify'
];

import { createFeature, createReducer, on } from '@ngrx/store';
import { EventState } from './event.models';
import * as EventActions from './event.actions';
import { featureKey } from './event.selectors';
import { filter, uniqBy } from 'lodash';
import { PageInfo } from 'src/app/shared';

const initialState: EventState = {
  events: [],
  eventCreated: null,
  eventDeleted: null,
  eventsUpdated: null,
  locations: [],
  locationPageInfo: null
};

const eventReducer = createReducer(
  initialState,
  on(EventActions.setEvents, (state, { events }) => ({ ...state, events })),
  on(EventActions.createEventSuccess, (state, { event }) => ({
    ...state,
    events: [...state.events, event],
    eventCreated: event
  })),
  on(EventActions.updateEventsSuccess, (state, { events: _events }) => ({
    ...state,
    events: uniqBy([..._events, ...state.events], 'id'),
    eventsUpdated: _events
  })),
  on(EventActions.deleteEventSuccess, (state, { event }) => ({
    ...state,
    events: state.events?.filter(e => e.id !== event.id),
    eventDeleted: event
  })),
  on(EventActions.setLocations, (state, { locations }) => ({
    ...state,
    locations: locations.content,
    locationPageInfo: PageInfo.mapFromList2Res(locations)
  })),
  on(EventActions.createLocationSuccess, (state, { location }) => ({
    ...state,
    locations: [...state.locations, location],
    locationPageInfo: PageInfo.setPagingAddElement(state.locationPageInfo)
  })),
  on(EventActions.updateLocationSuccess, (state, { location }) => ({
    ...state,
    locations: state.locations.map(oldLocation =>
      oldLocation.id === location.id ? { ...oldLocation, ...location } : oldLocation
    ),

  })),
  on(EventActions.deleteLocationSuccess, (state, { locationId }) => ({
    ...state,
    locations: filter(state.locations, (l) => l.id !== locationId),
    locationPageInfo: PageInfo.setPagingDeleteElement(state.locationPageInfo)
  })),
);

export const eventFeature = createFeature({
  name: featureKey,
  reducer: eventReducer
});

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AccountsService } from 'src/app/authentication/_services/accounts.service';
import { environment } from 'src/environments/environment';
import { MultiCompanyService } from 'src/app/site-management/company/_services/multi-company.service';
import { LOCALHOST, routerObject } from 'src/app/shared';

@Injectable({ providedIn: 'root' })
export class EmptyPathGuard {
  constructor(
    private router: Router,
    private accountService: AccountsService,
    private multiCompanyService: MultiCompanyService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return true;
    const isMainDomain = window.location.href === `https://${environment.mainDomain}/`
      && window.location.host !== LOCALHOST;

    if (!isMainDomain) {
      return true;
    }

    const mainAcc = this.accountService.getMainAccount();
    const baseToken = this.multiCompanyService.getBaseToken();

    if (!baseToken) {
      this.accountService.validateUserSignOut(mainAcc?.user?.email, mainAcc?.authResponse?.domain);
      this.router.navigate([routerObject.landingPage.fullPath]);
      return false;
    }

    if (mainAcc) {
      const redirectURL = `https://${mainAcc.authResponse.domain}`;
      window.open(redirectURL, '_self');
    }

    return false;
  }
}

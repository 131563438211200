import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TIME_FORMAT, userTrackingReportStatus } from 'src/app/shared';

@Pipe({ name: 'getUserTrackingReportStatus' })
export class GetUserTrackingReportStatusPipe implements PipeTransform {
  transform(currentTime: string, status: string, flag: boolean, time: string, limitBefore: number = 0, limitAfter: number = 0, isUserReport: boolean) {

    if (!isUserReport || !status || !flag) {
      return userTrackingReportStatus.none;
    }

    if (status === userTrackingReportStatus.waiting) {
      const timeAfter = moment(time, TIME_FORMAT).add(limitAfter, 'm').format(TIME_FORMAT);

      if (currentTime > timeAfter) {
        return userTrackingReportStatus.missed;
      }
    }

    return status;
  }
}

export class JiraTableProcessor {
  convertToAdf(node: HTMLElement, callback) {
    const processElement = (element: HTMLElement) => {
      switch (element.nodeName) {
        case 'TABLE':
          return this.processTable(element, callback);
        case 'TR':
          return this.processTableRow(element, callback);
        case 'TH':
        case 'TD':
          return this.processTableCell(element, callback);
        default:
          return callback(element.outerHTML).content;
      }
    };

    const process = (_node: HTMLElement) => {
      if (_node.nodeType === Node.TEXT_NODE || node.nodeName === 'BR') {
        return {
          type: 'paragraph',
          content: [{ type: 'text', text: _node.textContent.trim() }]
        };
      }
      return processElement(_node);
    };

    return process(node);
  }

  private processTable(node: HTMLElement, callback) {
    return {
      type: 'table',
      attrs: {
        isNumberColumnEnabled: false,
        layout: 'default'
      },
      content: [...node.querySelectorAll('tr')].map((row) => this.convertToAdf(row, callback))
    };
  }

  private processTableRow(node: HTMLElement, callback) {
    return {
      type: 'tableRow',
      content: Array.from(node.childNodes)
        .filter(child => child.nodeName === 'TD' || child.nodeName === 'TH')
        .map((cell) => this.convertToAdf(cell as any, callback))
    };
  }

  private processTableCell(node: HTMLElement, callback) {
    return {
      type: node.nodeName === 'TH' ? 'tableHeader' : 'tableCell',
      attrs: this.getTableCellAttrs(node),
      content: [...node.childNodes].map((child) => this.convertToAdf(child as any, callback)).flat()
    };
  }

  private getTableCellAttrs(node: HTMLElement) {
    const attrs: {
      colspan?: number,
      rowspan?: number,
      colwidth?: number[],
      background?: string
    } = {
      colspan: +node.getAttribute('colspan') || 1,
      rowspan: +node.getAttribute('rowspan') || 1,
    };

    const colWidth = node.getAttribute('colwidth');
    if (colWidth) {
      attrs.colwidth = [+colWidth];
    }

    const background = node?.style?.backgroundColor;
    if (background) {
      attrs.background = background;
    }

    return attrs;
  }
}

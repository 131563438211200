<div class="formly-switch-wrapper d-flex-center"
     (click)="onChanges($event)">
  <nz-switch
    [formControl]="formControl"
    [formlyAttributes]="field"
    [nzSize]="props?.size || 'small'"
    [disabled]="props?.disabled"
    stopEvent
  >
  </nz-switch>

  <div class="label ml-2" [tooltip]="props?.tooltip | translate">
    {{ props?.name | translate }}
  </div>
  <mat-icon
    *ngIf="props?.labelInfo"
    class="info-icon ml-1"
    [svgIcon]="'info-circle-fill'"
    tooltip="{{ props?.labelInfo | translate }}"
  ></mat-icon>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService, EmptyPathGuard } from './core/_guards';
import { XcorpAppGuardService } from './core/_guards/xcorp-app-guard.service';
import { SiteManagementGuardService } from './core/_guards/site-management-guard.service';
import { TenantIdentityGuard } from './core/_guards/tenant-identity.guard';
import { IntegrationType, LanguageCode, appDefaultLang } from './shared';
import { DeactiveLoadingGuard } from './core/_guards/loading.guard';

const routes: Routes = [
  {
    path: 'w',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
    canActivate: [DeactiveLoadingGuard, XcorpAppGuardService],
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [DeactiveLoadingGuard, XcorpAppGuardService],
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-slack',
    data: { integrationType: IntegrationType.SLACK_OAUTH },
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-jira',
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-google-drive',
    data: { integrationType: IntegrationType.GOOGLE_DRIVE },
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-gitlab',
    data: { integrationType: IntegrationType.GITLAB_OAUTH },
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-bitbucket',
    data: { integrationType: IntegrationType.BITBUCKET_OAUTH },
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'redirect-github',
    data: { integrationType: IntegrationType.GITHUB_OAUTH },
    loadChildren: () => import('./pages/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
    canActivate: [DeactiveLoadingGuard]
  },
  {
    path: 'extending-trial',
    loadChildren: () => import('./pages/extend-trial/extend-trial.module').then(m => m.ExtendTrialModule),
    canActivate: [DeactiveLoadingGuard, TenantIdentityGuard]
  },
  {
    path: '',
    loadChildren: () => import('./site-management/site-management.module').then(m => m.SiteManagementModule),
    canActivate: [EmptyPathGuard, AuthGuardService, SiteManagementGuardService]
    // pathMatch: 'full',
  },
  { path: '', redirectTo: 'w', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: 'w'
  }
];

const appRoutes: Routes = [
  {
    path: LanguageCode.vi,
    children: routes,
    // canActivate: [LanguageGuard],
  },
  {
    path: LanguageCode.en,
    children: routes,
    // canActivate: [LanguageGuard],
  },
  {
    path: '',
    redirectTo: appDefaultLang,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: appDefaultLang
  }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';
import { bsConfig, DATE_FORMAT, DATE_FORMAT_MONTH_NAME_DAY } from 'src/app/shared';

@Component({
  selector: 'app-cell-due-date',
  templateUrl: './cell-due-date.component.html',
  styleUrls: ['./cell-due-date.component.scss']
})
export class CellDueDateComponent implements OnInit, OnChanges {
  @Input() dueDate = '';
  @Input() canEdit = true;
  @Input() showIcon = false;
  @Input() showBackground = false;
  @Output() dueDateChange: EventEmitter<string> = new EventEmitter<string>();

  selectedDate: Date = null;
  bsConfig = bsConfig;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dueDate?.currentValue) {
      this.selectedDate = moment(this.dueDate).toDate();
    }
  }

  get taskDueDateString() {
    if (!this.dueDate) {
      return {dueDateString: '<span class="text-muted f-italic">Due</span>', dueDateDescription: 'Set due date'};
    }
    const dueDate = moment(this.dueDate, DATE_FORMAT);
    const today = moment(moment().format(DATE_FORMAT));
    const diffDate = today.diff(dueDate, 'days');
    if (diffDate === 0) {
      return {
        dueDateString: '<span class="text-warning due-date-label">Today</span>',
        dueDateDescription: 'Due Today'
      };
    } else if (diffDate > 0) {
      return {
        dueDateString: `<span class="text-danger due-date-label">${diffDate}d</span>`,
        dueDateDescription: `Late ${diffDate + (diffDate > 1 ? ' days' : 'day')}`
      };
    } else {
      const text = moment(this.dueDate).format(DATE_FORMAT_MONTH_NAME_DAY);
      return {
        dueDateString: `<span class="text-dodgerblue due-date-label">${text}</span>`,
        dueDateDescription: `Due ${text}`
      };
    }
  }

  onDueDateChange(date: Date) {
    if (!date) {
      return;
    }
    const dateFormatted = UtilService.getDateFormat(date);
    if (dateFormatted !== this.dueDate) {
      this.dueDate = dateFormatted;
      this.dueDateChange.emit(this.dueDate);
    }
  }

}

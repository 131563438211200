<div class="feedback-comment-wrapper">
  <div class="feedback-comment need-feedback" *ngIf="!feedback && !feedbackOpened" (click)="toggleFeedback(true)">
    <span class="font-semibold"><app-i18n [key]="'Need Feedback'"></app-i18n>?</span>
  </div>
  <div class="feedback-comment" *ngIf="feedback || feedbackOpened">
    <div class="user-group" cdkOverlayOrigin
      #userRequestDropdown="cdkOverlayOrigin"
      (click)="showUserRequestList = !showUserRequestList; dropdownRequestMembers = requestMembers">
      <ng-container *ngFor="let member of selectedRequestMembers | slice:0:3; let index = index">
        <div class="selected-member" [ngClass]="{'over': index !== selectedRequestMembers.length - 1}">
          <app-avatar [url]="member?.avatar" [name]="member.fullName"></app-avatar>
        </div>
      </ng-container>
      <div class="add-more-avatar" *ngIf="selectedRequestMembers.length > 3"><span>+{{selectedRequestMembers.length - 3}}</span></div>
      <span class="unassigned-avatar material-icons text-gray unassigned-icon" *ngIf="selectedRequestMembers.length === 0"> account_circle </span>
    </div>
    <div class="ml-2"><app-i18n [key]="'needs feedback from'"></app-i18n></div>
    <div class="user-group ml-2" cdkOverlayOrigin
      #userDropdown="cdkOverlayOrigin"
      (click)="showUserList = !showUserList; dropdownMembers = members">
      <ng-container *ngFor="let member of selectedMembers | slice:0:3; let index = index">
        <div class="selected-member" [ngClass]="{'over': index !== selectedMembers.length - 1}">
          <app-avatar [url]="member?.avatar" [name]="member.fullName"></app-avatar>
        </div>
      </ng-container>
      <div class="add-more-avatar" *ngIf="selectedMembers.length > 3"><span>+{{selectedMembers.length - 3}}</span></div>
      <span class="unassigned-avatar material-icons text-gray unassigned-icon" *ngIf="selectedMembers.length === 0"> account_circle </span>
    </div>
    <div class="ml-2"><app-i18n [key]="'before'"></app-i18n></div>
    <div class="flex calendar-group pointer" (click)="openCalendar()">
      <mat-icon class="icon-md" [svgIcon]="'calendar'" *ngIf="!dateControl.value"></mat-icon>
      <span class="font-semibold" *ngIf="dateControl.value">{{dateControl.value | dateFormat: DATE_UI_FORMAT}}</span>
    </div>
    <div class="date-picker">
      <input type="text" #datePicker class="form-control" bsDatepicker [formControl]="dateControl" [bsConfig]="bsConfig">
    </div>
    <div class="action-group d-flex-center pointer">
      <ng-container *ngIf="feedback?.statusFeedback">
        <ng-container *ngTemplateOutlet="statusTemplate"></ng-container>
      </ng-container>
      <button class="btn"
        appRestrictMultiSubmit
        (multiClick)="submitFeedback()"
        [disabled]="disabledSaveFeedback()"
        *ngIf="!feedback?.statusFeedback">
        <span class="material-icons-outlined icon-md"> done </span>
      </button>
      <button class="btn" appRestrictMultiSubmit (multiClick)="onDeleteFeedback()">
        <span class="material-icons-outlined icon-md" (click)="toggleFeedback(false)"> close </span>
      </button>
    </div>
  </div>
</div>

<ng-template #statusTemplate>
  <div class="dropdown-control" (click)="openStatusDropdown()" [title]="feedback?.statusFeedback || ''">
    <button class="btn">
      <mat-icon class="mobile-icon icon-md" [svgIcon]="selectedStatusFeedback.icon"></mat-icon>
    </button>

    <ng-select #statusDropdown class="status-select" [(ngModel)]="selectedStatusFeedback" [items]="statusFeedbacks" appendTo="body"
      [searchable]="false" [clearable]="false" [multiple]="false" [loading]="true" (change)="onStatusChanged()">
      <ng-template ng-option-tmp let-item="item">
        <div class="custom-option">
          <mat-icon class="icon-md" [svgIcon]="item.icon"></mat-icon>
          <span class="option-label">{{item.name}}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</ng-template>

<ng-container *ngIf="userRequestDropdown">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="userRequestDropdown"
    [cdkConnectedOverlayOpen]="showUserRequestList"
    (overlayOutsideClick)="showUserRequestList = false">
    <ng-container *ngTemplateOutlet="userListTemplate; context: {members: dropdownRequestMembers, type: UserFeedbackType.NEED_FEEDBACK}"></ng-container>
  </ng-template>
</ng-container>

<ng-container *ngIf="userDropdown">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="userDropdown"
    [cdkConnectedOverlayOpen]="showUserList"
    (overlayOutsideClick)="showUserList = false">
    <ng-container *ngTemplateOutlet="userListTemplate; context: {members: dropdownMembers, type: UserFeedbackType.FEEDBACK}"></ng-container>
  </ng-template>
</ng-container>

<ng-template #userListTemplate let-members="members" let-type="type">
  <ul class="feedback-user-lists">
    <div class="header">
      <app-search-input (search)="onSearchMembers($event, type)"></app-search-input>
    </div>
    <ng-container *ngIf="members.length > 0">
      <li class="user-item" *ngFor="let member of members" (click)="toggleMember(member, type)">
        <div class="d-flex-center custom-control pl-0 mr-2">
          <input type="checkbox" [checked]="member.isSelected">
        </div>
        <app-avatar
          [url]="member?.avatar"
          [name]="member.fullName"
        ></app-avatar>
        <span class="ml-2">{{member.fullName}}</span>
      </li>
    </ng-container>
    <ng-container *ngIf="members.length === 0">
      <div class="user-item">
        <span><app-i18n [key]="'No members found'"></app-i18n></span>
      </div>
    </ng-container>
  </ul>
</ng-template>

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { LicenseCode } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class LicenseDataService {
  constructor(
    private authService: AuthService,
  ) { }

  canActive(licenseCode: LicenseCode) {
    if (this.isTrialLicense()) {
      return true;
    }
    return this.isValidLicense(licenseCode);
  }

  isKanbanLicense() {
    return this.authService.loggedUser?.company.license?.allowProjectType === 'Kanban';
  }

  isTrialLicense() {
    const establishmentDate = this.authService.loggedUser?.company?.establishmentDate;
    if (!establishmentDate) {
      return false;
    }
    const expiredTrialDate = moment(establishmentDate).add(30, 'd');
    return expiredTrialDate.isAfter(moment());
  }

  isValidLicense(licenseCode: LicenseCode) {
    return this.authService.loggedUser?.company.license[licenseCode || ''];
  }
}

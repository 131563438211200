<div class="date-picker-wrapper">
  <input
    [formControl]="formControl"
    [formlyAttributes]="field"
    [class]="'form-control'"
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="bsConfig"
    [placement]="props.placement"
    [placeholder]="props.placeholder"
  />
</div>

import { createAction, props } from '@ngrx/store';
import {
  EditorConfig, GetWikiOptions, MoveWikiRequest, WikiDeleteRequest, WikiItem, WikiMemberListRequest,
  WikiMentionMember, WikiMentionRequest, WikiRequest
} from './wiki.models';

export const setData = createAction(
  '[Wiki] Set Data',
  props<{id?: number, data: WikiItem[]}>()
);

export const updateWikiItem = createAction(
  '[Wiki] Update Wiki Item',
  props<{ item: WikiItem, id?: number }>()
);

export const getContent = createAction(
  '[Wiki] Get Content',
  props<{item: WikiItem}>()
);

export const setContent = createAction(
  '[Wiki] Set Content',
  props<{data: string}>()
);

export const getContentFailed = createAction(
  '[Wiki] Get Content',
  props<{error: string}>()
);

export const setEditorConfigs = createAction(
  '[Wiki] Set Editor Configs',
  props<{configs: EditorConfig}>()
);

export const getTopicItems = createAction(
  '[Wiki] Get Topic Items',
  props<{topic: WikiItem, options?: GetWikiOptions}>()
);

export const getTopicItemsFailed = createAction(
  '[Wiki] Get Topic Items Failed',
  props<{error: string}>()
);

export const getProjectTopicItems = createAction(
  '[Wiki] Get Project Topic Items',
  props<{topic: WikiItem, options?: GetWikiOptions}>()
);

export const getCompanyTopicItems = createAction(
  '[Wiki] Get Company Topic Items',
  props<{topic: WikiItem, options?: GetWikiOptions}>()
);

export const activateWiki = createAction(
  '[Wiki] Activate Wiki',
  props<{folder: WikiItem, payload: WikiRequest}>()
);

export const activateWikiSuccess = createAction(
  '[Wiki] Activate Wiki Success',
  props<{data: WikiItem}>()
);

export const activateWikiFailed = createAction(
  '[Wiki] Activate Wiki Failed',
  props<{error: string}>()
);

export const moveWikiItem = createAction(
  '[Wiki] Move Wiki Item',
  props<{ payload: MoveWikiRequest }>()
);

export const moveWikiItemSuccess = createAction(
  '[Wiki] Move Wiki Item Success',
  props<{ payload: MoveWikiRequest }>()
);

export const moveWikiItemFailed = createAction(
  '[Wiki] Move Wiki Item Failed',
  props<{ error: string }>()
);

export const getMentionMembers = createAction(
  '[Mention] Get Mention Member',
  props<{ request: WikiMemberListRequest }>()
);

export const setMentionMembers = createAction(
  '[Mention] Set Mention Member',
  props<{ mentionList: WikiMentionMember[] }>()
);

export const mentionMembers = createAction(
  '[Mention] Mention Member',
  props<{ id: number; payload: WikiMentionRequest }>()
);

export const mentionMembersSuccess = createAction(
  '[Mention] Mention Member Success',
  props<{ mention: any }>()
);

export const mentionMembersFailed = createAction(
  '[Mention] Mention Member Failed',
  props<{ error: string }>()
);


export const topicActions = {
  get: createAction('[Wiki] Get Topic', props<{id: number}>()),
  getFailed: createAction('[Wiki] Get Topic Failed', props<{error: string}>()),

  create: createAction('[Wiki] Create Topic', props<{topic: WikiItem, payload: WikiRequest}>()),
  createSuccess: createAction('[Wiki] Create Topic Success', props<{topic: WikiItem}>()),
  createFailed: createAction('[Wiki] Create Topic Failed', props<{error: string}>()),

  edit: createAction('[Wiki] Edit Topic', props<{id: number, payload: WikiRequest}>()),
  editSuccess: createAction('[Wiki] Edit Topic Success', props<{topic: WikiItem}>()),
  editFailed: createAction('[Wiki] Edit Topic Failed', props<{error: string}>()),

  delete: createAction('[Wiki] Delete Topic', props<{topic: WikiItem, payload: WikiDeleteRequest }>()),
  deleteSuccess: createAction('[Wiki] Delete Topic Success', props<{topic: WikiItem, payload: WikiDeleteRequest }>()),
  deleteFailed: createAction('[Wiki] Delete Topic Failed', props<{error: string}>()),
};

export const pageActions = {
  get: createAction('[Wiki] Get Page', props<{id: number}>()),
  getFailed: createAction('[Wiki] Get Page Failed', props<{error: string}>()),

  create: createAction('[Wiki] Create Page', props<{id: number, payload: WikiRequest}>()),
  createSuccess: createAction('[Wiki] Create Page Success', props<{page: WikiItem}>()),
  createFailed: createAction('[Wiki] Create Topic Failed', props<{error: string}>()),

  edit: createAction('[Wiki] Edit Page', props<{id: number, payload: WikiRequest}>()),
  editSuccess: createAction('[Wiki] Edit Page Success', props<{page: WikiItem}>()),
  editFailed: createAction('[Wiki] Edit Page Failed', props<{error: string}>()),

  delete: createAction('[Wiki] Delete Page', props<{page: WikiItem}>()),
  deleteSuccess: createAction('[Wiki] Delete Page Success', props<{page: WikiItem}>()),
  deleteFailed: createAction('[Wiki] Delete Page Failed', props<{error: string}>()),
};

import { Component, Input } from '@angular/core';
import { BoardReleaseListResponse } from 'src/app/shared';

@Component({
  selector: 'app-releases-option',
  template: `
    <div class="custom-option mr-1">
      <span class="badge-label text-ellipsis">{{ item.name }}</span>
    </div>
  `
})
export class ReleasesOptionComponent {
  @Input() item: BoardReleaseListResponse;
}


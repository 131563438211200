import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { PageInfo, ProjectRoles, Role } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RoleService extends EntityCollectionServiceBase<Role> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
  ) {
    super('roles', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getProjectRoles() {
    return this.http.get<Role[]>(`${environment.apiUrl}/roles/project-roles`).pipe();
  }

  getUserRoles() {
    return this.http.get<Role[]>(`${environment.apiUrl}/roles/default`).pipe();
  }

  getUserRolesOnProjects(getAllProject = true) {
    return this.http.get<ProjectRoles[]>(`${environment.apiUrl}/roles/user-projects?getAllProject=${getAllProject}`);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { HOUR_MINUTE, TIME_FORMAT } from 'src/app/shared';

@Pipe({ name: 'getMilitaryTime' })
export class GetMilitaryTimePipe implements PipeTransform {
  transform(time: string) {
    return moment(time, TIME_FORMAT).format(HOUR_MINUTE);
  }
}

import FroalaEditor from 'froala-editor';
import { forEach } from 'lodash';
import { FroalaEvent } from '../models';
import { EDITOR_PREVIEW_FILE } from 'src/app/shared/_utils';
import { UtilService } from 'src/app/core';

FroalaEditor.PLUGINS.lazyUploadFile = function (editor: FroalaEditor) {
  const _files: { file: File; blobUrl: string }[] = [];

  function files() {
    return _files;
  }

  function _init() {
    editor.events.on('file.beforeUpload', function (files: FileList) {
      if (editor.opts.autoUpload?.lazy) {
        forEach(files, (file) => {
          fileToBlob(file, ({ blobUrl }) => {
            const isImage = UtilService.isImageURL(file.name);

            // Trigger attachmentPushed event
            editor.events.trigger(FroalaEvent.ATTACHMENT_PUSHED, [
              {
                file: file,
                previewUrl: isImage ? blobUrl : EDITOR_PREVIEW_FILE,
                blobUrl
              }
            ], true);

            if (isImage) {
              editor.image.insert(
                blobUrl,
                false,
                {
                  name: file.name,
                  origin: blobUrl
                },
                null
              );
            }
            else if (UtilService.isVideoURL(file.name)) {
              editor.video.insert(`
                <video origin="${blobUrl}" style="width: 600px;" controls class="fr-draggable"></video>
                `);
              $(`video[origin="${blobUrl}"]`).attr('src', blobUrl);
            }
            else {
              editor.image.insert(
                `${EDITOR_PREVIEW_FILE}?file-url=${blobUrl}`,
                false,
                {
                  name: file.name,
                  origin: blobUrl,
                }, null
              );
            }

            // Add file to current plugin
            _files.push({ file, blobUrl });
          });
        });

        return false;
      }
    });
  }

  function fileToBlob(file, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      const blobUrl = URL.createObjectURL(blob);
      callback({ blob, blobUrl });
    };

    reader.readAsArrayBuffer(file);
  }

  return {
    _init,
    files
  };
};

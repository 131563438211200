import { createFeature, createReducer, on } from '@ngrx/store';
import { COMPANY_DOCUMENT_WIKI, MD_FILE_EXTENSION, MD_FOLDER_NAME, PROJECT_WIKI, WikiMode, WikiState } from './wiki.models';
import * as WikiActions from './wiki.actions';
import { featureKey } from './wiki.selectors';
import { filterWikiItem, findWikiItem, getSamePage } from './wiki.utils';
import { cloneDeep } from 'lodash';
import { FileType } from 'src/app/shared';

const initialState: WikiState = {
  data: [PROJECT_WIKI, COMPANY_DOCUMENT_WIKI], // All tree data of wiki
  content: null, // Blob of wiki file
  editorConfigs: { mode: WikiMode.View, type: null },
  error: null, // Current error message
  mentionList: []
};

const wikiReducer = createReducer(
  initialState,
  on(WikiActions.setData, (state, payload) => {
    const updatedState = cloneDeep(state);
    const markdownData = payload.data.filter(item =>
      item.type === FileType.dir || item.name.includes(MD_FILE_EXTENSION)
    );
    const data = filterWikiItem(markdownData);
    const parent = findWikiItem(updatedState.data, payload.id);
    if (parent) {
      parent.isOpen = true;
      parent.items = data;
      /** Update id for system folder:
       *  - Project
       *  - Company Document
       */
      if (parent.id < 0 && data.length > 0) {
        const parentData = data[0].parentFile;
        parent.id = parentData.id;
        parent.wikiFlg = parentData.wikiFlg;
        parent.publishFlg = parentData.draftFlg;
      }
      return updatedState;
    }

    return { ...updatedState, data: [...updatedState.data, ...data] };
  }),

  on(WikiActions.updateWikiItem, (state, { item, id }) => {
    const updatedData = cloneDeep(state.data);
    const wikiId = id ? id : item.id;
    const wikiItem = findWikiItem(updatedData, wikiId);

    Object.keys(item).forEach(key => {
      if (wikiItem[key] !== item?.[key]) {
        wikiItem[key] = item[key];
      }
    });

    return { ...state, data: updatedData };
  }),

  on(WikiActions.setContent, (state, { data }) => ({ ...state, content: data })),
  on(WikiActions.setEditorConfigs, (state, { configs }) => ({ ...state, editorConfigs: configs })),

  on(WikiActions.activateWikiSuccess, (state, { data }) => {
    const updatedData = cloneDeep(state.data);
    const topic = findWikiItem(updatedData, data.parentFile.parentFile.id);

    if (topic) {
      Object.keys(data).forEach(key => {
        if (key.startsWith('wiki')) {
          topic[key] = data[key];
        }
      });
      topic.items = data.items;
    }
    return { ...state, data: updatedData };
  }),
  on(WikiActions.activateWikiFailed, (state, { error }) => ({ ...state, error })),

  /** Topic Reducers */
  on(WikiActions.topicActions.createFailed, (state, { error }) => ({ ...state, error })),
  on(WikiActions.topicActions.editSuccess, (state, { topic }) => {
    const updatedData = cloneDeep(state.data);
    const updatedParentData = topic.parentFile.parentFile;
    const updatedMdData = topic.parentFile;

    const parent = findWikiItem(updatedData, updatedParentData.id);
    const mdFolder = findWikiItem(parent.items, updatedMdData.id);
    const oldTopic = findWikiItem(mdFolder.items, topic.id);

    const updateProperties = (target: any, source: any) => {
      for (const key of Object.keys(source)) {
        if (source[key] && target[key]) {
          target[key] = source[key];
        }
      }
    };

    updateProperties(parent, updatedParentData);
    updateProperties(mdFolder, updatedMdData);

    if (oldTopic) {
      updateProperties(oldTopic, topic);
    } else {
      mdFolder.items.push(topic);
    }

    return { ...state, data: updatedData };
  }),
  on(WikiActions.topicActions.editFailed, (state, { error }) => ({ ...state, error })),

  on(WikiActions.topicActions.deleteSuccess, (state, { topic, payload }) => {
    const updatedData = cloneDeep(state.data);
    const deletedTopic = findWikiItem(updatedData, topic.id);

    if (payload.deleteFolder) {
      /** Delete all topic */
      const parent = findWikiItem(updatedData, (deletedTopic?.parentUI?.id ?? deletedTopic?.parentFile?.id));
      const deletedIdx = parent?.items?.findIndex(item => item.id === deletedTopic.id);
      parent.items.splice(deletedIdx, 1);
    } else {
      /** Delete .MD folder */
      const mdFolder = deletedTopic.items?.find(item => item.name === MD_FOLDER_NAME);
      const deletedIdx = deletedTopic.items?.findIndex(item => item.id === mdFolder.id);
      deletedTopic.items?.splice(deletedIdx, 1);
      deletedTopic.wikiFlg = false;
    }

    return { ...state, data: updatedData };
  }),

  on(WikiActions.topicActions.deleteFailed, (state, { error }) => ({ ...state, error })),

  /** Page Reducers */
  on(WikiActions.pageActions.createSuccess, (state, { page }) => {
    const updatedData = cloneDeep(state.data);
    const parent = findWikiItem(updatedData, page.parentFile.id);
    parent.items.push(page);

    return { ...state, data: updatedData };
  }),

  on(WikiActions.pageActions.createFailed, (state, { error }) => ({ ...state, error })),
  on(WikiActions.pageActions.editSuccess, (state, { page }) => {
    const updatedData = cloneDeep(state.data);
    const oldPage = findWikiItem(updatedData, page.id);

    Object.keys(oldPage).forEach(key => {
      if (oldPage[key] && page[key]) {
        oldPage[key] = page[key];
      }
    });

    return { ...state, data: updatedData };
  }),
  on(WikiActions.pageActions.editFailed, (state, { error }) => ({ ...state, error })),

  on(WikiActions.pageActions.deleteSuccess, (state, { page }) => {
    const updatedData = cloneDeep(state.data);
    const parent = findWikiItem(updatedData, page.parentFile.id);

    const theSame = getSamePage(parent.items, page);
    parent.items.splice(parent.items.findIndex(item => item.id === page.id), 1);

    if (theSame) {
      parent.items.splice(parent.items.findIndex(item => item.id === theSame.id), 1);
    }

    return { ...state, data: updatedData };
  }),

  on(WikiActions.pageActions.deleteFailed, (state, { error }) => ({ ...state, error })),
  on(WikiActions.setMentionMembers, (state, { mentionList }) => {
    return {
      ...state,
      mentionList
    };
  }),
  on(WikiActions.moveWikiItemSuccess, (state, { payload }) => {
    /** Remove movedWiki to source container */
    const updatedData = cloneDeep(state.data);
    const source = findWikiItem(updatedData, payload.source.id);
    source.items.splice(payload.sourceIndex, 1);
    return { ...state, data: updatedData };
  }),
  on(WikiActions.moveWikiItemFailed, (state, { error }) => ({ ...state, error })),
);

export const wikiFeature = createFeature({
  name: featureKey,
  reducer: wikiReducer
});
